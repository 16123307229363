import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import Select from 'react-select';
import { NumberModal } from './components/number-table-modal';
import DatePicker from 'react-datepicker';
import LoginModal from '../login-modal';
import { TableWithStickyHeader } from '../../TableWithStickyHeader';
import dayjs from 'dayjs';
import { useAuth } from '../../../../container/authContext';
import cx from 'classnames';
import { DATE_FORMAT, US_DATE_FORMAT } from '../../../../constants';
import { get } from 'lodash';
import { useIsRequiredElement } from '../../../../hook/useIsRequiredElement';
import { TABLE_COLUMN_MIN_WIDTH } from '../../../from-builder/elements/table-element';
import { useUserFormRole } from '../../../../hook/useUserFormRole';
import isObject from 'lodash/isObject';
import NewRowButton from './components/NewRowButton';
import { useDebouncedCallback } from 'use-debounce';
import { useIsMobile } from '../../../../hook/useIsMobile';
import Multiline from '../../../from-builder/elements/multiline';

export const TableElement = function FromElement({ element, handlerChange, addNewRow, handlerChangeLabel, isPrint }) {
  const { rows, options } = element ?? {};
  const isRequiredElement = useIsRequiredElement(element);
  const userFormRole = useUserFormRole();
  const scrollRow = useRef();
  const tableWithStickyHeader = useRef(null);
  const { user } = useAuth();
  const isMobile = useIsMobile();
  const addRowButtonHideTimeoutRef = useRef(null);
  const rowsLength = rows?.length ?? 0;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndexCol, setSelectedIndexCol] = useState(null);
  const [selectedIndexRow, setSelectedRow] = useState(null);
  const [currentCol, setCurrentCol] = useState(null);
  const [activeCol, setActiveCol] = useState(null);
  const [activeRow, setActiveRow] = useState(null);
  const [isOpenLogin, setIsOpenLogin] = useState(false);
  const [isFixedAutoScroll, setIsFixedAutoScroll] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  // States of the button to add a new row between other rows
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [addNewRowButtonPositions, setAddNewRowButtonPositions] = useState([]);

  const formattedRows = useMemo(() => {
    let newRows = [];
    if (rowsLength && !isPrint) {
      if (options.addNewRowInsideTable) {
        newRows = rows.reduce((acc, row, i) => {
          acc.push({ ...row, index: i });
          if (i < rowsLength - 1) {
            const newRowId = 'new' + new Date().getTime() + i;
            const newRow = {
              label: '',
              newRow: true,
              id: newRowId,
              cols: [],
              asAddNewRowButton: true,
            };
            acc.push(newRow);
          }
          return acc;
        }, []);
      } else {
        newRows = rows.map((row, i) => ({ ...row, index: i }));
      }
    }
    return newRows;
  }, [rowsLength, isPrint, options.addNewRowInsideTable, rows]);

  const calculateAddNewRowButtonPositions = useCallback(() => {
    if (formattedRows?.length) {
      if (tableWithStickyHeader.current) {
        const tableContainerRect = tableWithStickyHeader.current.getBoundingClientRect();
        const trs = tableWithStickyHeader.current.querySelectorAll('tbody tr');
        const newPositions = [];

        trs.forEach((tr, index) => {
          const row = formattedRows[index];
          if (row?.asAddNewRowButton) {
            const trRect = tr.getBoundingClientRect();
            const topPosition = trRect.top + window.scrollY + trRect.height / 2;
            let leftPosition;

            if (isMobile) {
              leftPosition = tableContainerRect.left + tableContainerRect.width - 6;
            } else {
              leftPosition = tableContainerRect.left + tableContainerRect.width + 20;
            }

            newPositions.push({
              top: topPosition,
              left: leftPosition,
              rowIndex: index,
            });
          }
        });

        setAddNewRowButtonPositions(newPositions);
      }
    }
  }, [formattedRows, isMobile]);

  const debouncedResize = useDebouncedCallback(() => {
    calculateAddNewRowButtonPositions();
  }, 100);

  useLayoutEffect(() => {
    calculateAddNewRowButtonPositions();

    window.addEventListener('resize', debouncedResize);

    return () => {
      window.removeEventListener('resize', debouncedResize);
    };
  }, [calculateAddNewRowButtonPositions, debouncedResize]);

  const handleRowMouseEnter = (index) => {
    clearTimeout(addRowButtonHideTimeoutRef.current);
    setHoveredRowIndex(index);
  };

  const handleRowMouseLeave = () => {
    // Delay hiding the button to give time to move the cursor to the button
    addRowButtonHideTimeoutRef.current = setTimeout(() => {
      setHoveredRowIndex(null);
    }, 500);
  };

  const handleAddRowButtonMouseEnter = () => {
    clearTimeout(addRowButtonHideTimeoutRef.current); // Stop hiding the button
  };

  const handleAddRowButtonMouseLeave = () => {
    addRowButtonHideTimeoutRef.current = setTimeout(() => {
      setHoveredRowIndex(null); // Hide the button after cursor leaves
    }, 300);
  };

  const handelCloseModal = () => {
    setIsOpen(false);
    setSelectedIndexCol(null);
    setSelectedRow(null);
    setCurrentCol(null);
  };

  const openModal = () => {
    setIsOpenLogin(true);
  };

  const closeModal = () => {
    setIsOpenLogin(false);
    setActiveCol(null);
    setActiveRow(null);
  };

  const handelChangeLogin = (userInfo) => {
    handlerChange(element, userInfo, true, activeCol, activeRow);
    closeModal();
  };

  const addCurrentUserInfo = (element, isTable, colIndex, indexRow) => {
    const options = get(element, `cols[${colIndex}].options`, []);
    let userInfo = '';

    options.forEach((option) => {
      switch (option.value) {
        case 'name': {
          if (user.first_name) {
            userInfo = userInfo + `${user.first_name}`;
          }
          break;
        }
        case 'surname': {
          if (user.last_name) {
            userInfo = userInfo + ` ${user.last_name}`;
          }
          break;
        }
        case 'position': {
          if (user?.position?.name) {
            userInfo = userInfo + ` ${user?.position?.name}`;
          }
          break;
        }
        case 'date': {
          userInfo = userInfo + ` ${dayjs(new Date()).format(DATE_FORMAT)}`;
          break;
        }
      }
    });

    handlerChange(element, userInfo, isTable, colIndex, indexRow);
  };

  useEffect(() => {
    if (!isFixedAutoScroll && element) {
      handlerChange(element);
      setIsFixedAutoScroll(true);
    }
  }, [element, handlerChange, isFixedAutoScroll]);

  useEffect(() => {
    if (element.options.enableScroll && isFixedAutoScroll && !isScrolled && scrollRow.current) {
      setTimeout(() => {
        const reactModalOverlayElements = document.querySelectorAll('.ReactModal__Overlay');
        const refBoundingClientRectTop = scrollRow.current.getBoundingClientRect().top;
        if (reactModalOverlayElements.length) {
          reactModalOverlayElements[0].scrollTo(0, refBoundingClientRectTop - 44);
        } else {
          const scrollTopValue = refBoundingClientRectTop - 200; // header height + actions height = 200
          window.scrollTo(0, scrollTopValue);
        }
      }, 100);

      setTimeout(() => {
        setIsScrolled(true);
      }, 100);
    }
  }, [element, isFixedAutoScroll, isScrolled]);

  const renderDateCol = (row, colIndex) => {
    let layout = null;
    const col = element?.cols[colIndex];

    if (col && col.type?.value === 'date') {
      const { editable, editRole } = col;
      const rowCol = get(row, `cols[${colIndex}]`, {});
      const { value, readOnly } = rowCol;
      const rowIndex = rows?.findIndex((r) => r.id === row.id);
      const selected = value ? new Date(value) : null;
      const isActive = !readOnly && !isPrint && editable && (!editRole || editRole === userFormRole);
      layout = (
        <DatePicker
          selected={selected}
          readOnly={!isActive}
          format={US_DATE_FORMAT}
          placeholderText={'Choose Date'}
          className={cx('form-control', { readonly: !isActive })}
          popperProps={isPrint ? undefined : { strategy: 'fixed' }}
          onChange={(date) => isActive && handlerChange(element, date, true, colIndex, rowIndex)}
        />
      );
    }
    return layout;
  };

  const renderSingleLineCol = (row, col) => {
    let layout = null;
    if (col && col.type?.value === 'single-line') {
      const colIndex = row?.cols?.findIndex((c) => c.id === col.id);
      const rowCol = get(row, `cols[${colIndex}]`);
      if (rowCol) {
        const rowIndex = element?.rows.findIndex((r) => r.id === row.id);
        const { value = '', readOnly, filledByRole } = rowCol;
        const isActive = !readOnly && !isPrint && !filledByRole;
        layout = (
          <input
            type="text"
            value={value}
            readOnly={!isActive}
            className="form-control"
            placeholder={'Enter Text'}
            onChange={(e) => isActive && handlerChange(element, e.target.value, true, colIndex, rowIndex)}
          />
        );
      }
    }

    return layout;
  };

  const renderTimeCol = (row, colIndex) => {
    let layout = null;
    if (rows) {
      const col = element?.cols[colIndex];
      if (col && col.type?.value === 'time') {
        const rowIndex = rows.findIndex((r) => r.id === row.id);
        const rowCol = get(row, `cols[${colIndex}]`, {});
        const { editable, editRole } = col;
        const { value, readOnly } = rowCol;
        const selected = value ? new Date(value) : null;
        const isActive = !readOnly && !isPrint && editable && (!editRole || editRole === userFormRole);

        layout = (
          <DatePicker
            showTimeSelect
            showTimeSelectOnly
            selected={selected}
            readOnly={!isActive}
            timeIntervals={60}
            dateFormat="h:mm aa"
            placeholderText={'Choose Time'}
            className={cx('form-control', { readonly: !isActive })}
            popperProps={isPrint ? undefined : { strategy: 'fixed' }}
            onChange={(date) => isActive && handlerChange(element, date, true, colIndex, rowIndex)}
          />
        );
      }
    }
    return layout;
  };

  const renderDropdownCol = (row, col) => {
    let layout = null;
    if (col && col.type?.value === 'dropdown') {
      const { options = [] } = col;
      const colIndex = row?.cols?.findIndex((c) => c.id === col.id);
      const rowCol = get(row, `cols[${colIndex}]`);

      if (rowCol) {
        const rowIndex = element.rows.findIndex((r) => r.id === row.id);
        const { value, defaultOptionId } = rowCol;
        // Or readonly
        const isActive = !isPrint;
        let selectedOption;

        if (isObject(value)) {
          selectedOption = value;
        } else if (defaultOptionId) {
          selectedOption = options.find((option) => option.id === defaultOptionId);
        }

        layout = (
          <Select
            value={selectedOption}
            menuPosition={'fixed'}
            options={options}
            readOnly={!isActive}
            classNamePrefix="react-select"
            className="form-control-select"
            onChange={(e) => isActive && handlerChange(element, e, true, colIndex, rowIndex)}
          />
        );
      }
    }
    return layout;
  };

  const renderMultilineCol = (row, col) => {
    let layout = null;
    if (col && col.type?.value === 'multiline') {
      const colIndex = row?.cols?.findIndex((c) => c.id === col.id);
      const rowCol = get(row, `cols[${colIndex}]`);
      if (rowCol) {
        const rowIndex = element?.rows.findIndex((r) => r.id === row.id);
        const { value = '', readOnly, filledByRole } = rowCol;
        const isActive = !readOnly && !isPrint && !filledByRole;
        layout = (
          <Multiline
            value={value}
            readOnly={!isActive}
            placeholder={'Enter Text'}
            onChange={(e) => isActive && handlerChange(element, e.target.value, true, colIndex, rowIndex)}
          />
        );
      }
    }

    return layout;
  };

  const renderNoPrintTableTBody = () => {
    return (
      <tbody>
        {formattedRows.map((row, rowIndex) => {
          const { asAddNewRowButton, id: rowID, index: originalRowIndex } = row;
          return (
            <tr
              key={rowID}
              ref={element.options.enableScroll && row?.scrollToRow ? scrollRow : null}
              className={cx({ scrollTo: row?.scrollToRow, asAddNewRowButton })}
              onMouseEnter={() => handleRowMouseEnter(rowIndex)}
              onMouseLeave={handleRowMouseLeave}
              onClick={() => setHoveredRowIndex(rowIndex)}
            >
              {asAddNewRowButton ? (
                <td colSpan={100} />
              ) : (
                <>
                  {!element.options.showQuestions && (
                    <>
                      {!row.newRow ? (
                        <td className={'first-section'} style={{ width: element.options.width ?? '130px' }}>
                          {row.label}
                        </td>
                      ) : (
                        <td className={'first-section'} style={{ width: element.options.width ?? '130px' }}>
                          <input
                            type="text"
                            className="form-control"
                            value={row?.label}
                            onChange={(e) => handlerChangeLabel(element, e.target.value, originalRowIndex)}
                          />
                        </td>
                      )}
                    </>
                  )}

                  {element.cols.map((col, indexCol) => {
                    let isReadOnlyTd = row?.cols && row?.cols[indexCol]?.readOnly;
                    if (col?.type?.value === 'dropdown') {
                      isReadOnlyTd = false;
                    }
                    return (
                      <td
                        key={indexCol}
                        className={cx({
                          'current-user': col?.type?.value === 'current-user',
                          'readOnly-td': isReadOnlyTd,
                        })}
                        style={{ maxWidth: col.width }}
                      >
                        {renderSingleLineCol(row, col)}

                        {col?.type?.value === 'current-user' && (
                          <>
                            {!get(row, `cols[${indexCol}].value`) ? (
                              <button
                                className={'btn btn-outline btn-outline-secondary btn-signature'}
                                onClick={() => {
                                  addCurrentUserInfo(element, true, indexCol, originalRowIndex);
                                }}
                              />
                            ) : (
                              <p>{row?.cols[indexCol]?.value}</p>
                            )}
                          </>
                        )}

                        {renderDateCol(row, indexCol)}
                        {renderTimeCol(row, indexCol)}
                        {renderDropdownCol(row, col)}
                        {renderMultilineCol(row, col)}

                        {col?.type?.value === 'number' && (
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            value={row?.cols ? row?.cols[indexCol]?.value ?? '' : ''}
                            onClick={async () => {
                              if (!get(row?.cols, `[${indexCol}].readOnly`)) {
                                setSelectedIndexCol(indexCol);
                                setSelectedRow(originalRowIndex);
                                if (row.cols) {
                                  setCurrentCol(row?.cols[indexCol] ? row?.cols[indexCol] : '');
                                } else {
                                  handlerChange(element, '', true, selectedIndexCol, selectedIndexRow);
                                  setCurrentCol(true);
                                }
                                setIsOpen(true);
                              }
                            }}
                          />
                        )}

                        {col.type?.value === 'electronic-signature' && (
                          <input
                            type="text"
                            className="form-control"
                            readOnly={true}
                            value={row?.cols ? row?.cols[indexCol]?.value : ''}
                            onClick={() => {
                              if (!get(row?.cols, `[${indexCol}].readOnly`)) {
                                if (!row?.cols) {
                                  handlerChange(element, '', true, indexCol, originalRowIndex);
                                  openModal();
                                  setActiveRow(originalRowIndex);
                                  setActiveCol(indexCol);
                                } else {
                                  if (!row?.cols[indexCol]?.value) {
                                    openModal();
                                    setActiveRow(originalRowIndex);
                                    setActiveCol(indexCol);
                                  }
                                }
                              }
                            }}
                          />
                        )}

                        {!col.type && (
                          <input
                            type="text"
                            className="form-control"
                            readOnly={row?.cols ? row?.cols[indexCol]?.readOnly : false}
                            value={row?.cols ? row?.cols[indexCol]?.value : ''}
                            onChange={(e) => handlerChange(element, e.target.value, true, indexCol, originalRowIndex)}
                          />
                        )}
                      </td>
                    );
                  })}
                </>
              )}
            </tr>
          );
        })}
      </tbody>
    );
  };

  return (
    <>
      <div
        className={cx('form-control-table-wrapper', {
          'form-control-table-wrapper-print': isPrint,
          [`alignment--${element.options.tableAlignment}`]: element.options.tableAlignment,
        })}
      >
        <div id={element.id} className="form-control-table-responsive">
          <label htmlFor={element.id} className="form-label w-full">
            {element.options.label} <span className="required">{isRequiredElement ? '*' : ''}</span>
          </label>
          {!isPrint && (
            <TableWithStickyHeader ref={tableWithStickyHeader}>
              <thead>
                <tr>
                  {!element.options.showQuestions && (
                    <th
                      className={'first-section'}
                      style={{
                        minWidth: TABLE_COLUMN_MIN_WIDTH,
                        width: element.options.width ?? '130px',
                        maxWidth: element.options.width,
                      }}
                    >
                      {element.options.rowHeaderTitle ?? 'Question'}
                    </th>
                  )}
                  {element.cols.map((col) => (
                    <th id={col.id} key={col.id} style={{ width: col.width, minWidth: TABLE_COLUMN_MIN_WIDTH, maxWidth: col.width }}>
                      {col.label}
                    </th>
                  ))}
                </tr>
              </thead>
              {renderNoPrintTableTBody()}
            </TableWithStickyHeader>
          )}

          {isPrint && (
            <TableWithStickyHeader isPrint={isPrint}>
              <thead>
                <tr>
                  {!element.options.showQuestions && (
                    <th
                      className={'first-section'}
                      style={{
                        minWidth: TABLE_COLUMN_MIN_WIDTH,
                        width: element.options.width ?? '130px',
                        maxWidth: element.options.width,
                      }}
                    >
                      {element.options.rowHeaderTitle ? element.options.rowHeaderTitle : 'Question'}
                    </th>
                  )}
                  {element.cols.map((col) => (
                    <th id={col.id} key={col.id} style={{ width: col.width, minWidth: TABLE_COLUMN_MIN_WIDTH, maxWidth: col.width }}>
                      {col.label}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {element.rows.map((row, indexRow) => (
                  <tr
                    key={indexRow}
                    ref={element.options.enableScroll && row?.scrollToRow ? scrollRow : null}
                    className={cx({ scrollTo: row?.scrollToRow })}
                  >
                    {!element.options.showQuestions && (
                      <>
                        {!row.newRow && (
                          <td className={'first-section'} style={{ width: element.options.width ?? '130px' }}>
                            {row.label}
                          </td>
                        )}
                        {row.newRow && (
                          <td className={'first-section'} style={{ width: element.options.width ?? '130px' }}>
                            <input
                              type="text"
                              className="form-control"
                              value={row?.label}
                              onChange={(e) => handlerChangeLabel(element, e.target.value, indexRow)}
                            />
                          </td>
                        )}
                      </>
                    )}
                    {element.cols.map((cols, indexCol) => {
                      const colType = cols?.type?.value;
                      const rowColValue = get(row, `cols[${indexCol}].value`);
                      return (
                        <td
                          key={indexCol}
                          style={{ width: cols.width }}
                          className={`${cols?.type?.value === 'current-user' ? 'current-user' : ''}`}
                        >
                          {isPrint && cols?.type?.value !== 'date' && cols?.type?.value !== 'time' && row?.cols
                            ? row?.cols[indexCol] &&
                              typeof row?.cols[indexCol].value === 'object' &&
                              row?.cols[indexCol].value !== null &&
                              row?.cols[indexCol].value.hasOwnProperty('id')
                              ? row?.cols[indexCol]?.value.label
                              : row?.cols[indexCol]?.value
                            : ''}

                          {isPrint && colType === 'date' && rowColValue && rowColValue !== ''
                            ? dayjs(new Date(rowColValue)).format(DATE_FORMAT)
                            : undefined}

                          {isPrint && colType === 'time' && rowColValue && rowColValue !== ''
                            ? dayjs(new Date(rowColValue)).format('hh:mm a')
                            : undefined}

                          {!isPrint && (
                            <>
                              {cols?.type?.value === 'current-user' && (
                                <>
                                  {row?.cols && !row?.cols[indexCol]?.value && <></>}

                                  {row?.cols && row?.cols[indexCol]?.value && <p>{row?.cols[indexCol]?.value}</p>}
                                </>
                              )}

                              {renderSingleLineCol(row, cols)}
                              {renderDateCol(row, indexCol)}
                              {renderTimeCol(row, indexCol)}
                              {renderDropdownCol(row, cols)}
                              {renderMultilineCol(row, cols)}

                              {cols?.type?.value === 'number' && (
                                <input
                                  type="text"
                                  className="form-control"
                                  readOnly
                                  value={row?.cols ? row?.cols[indexCol]?.value ?? '' : ''}
                                  onClick={async () => {
                                    setSelectedIndexCol(indexCol);
                                    setSelectedRow(indexRow);
                                    if (row.cols) {
                                      setCurrentCol(row?.cols[indexCol] ? row?.cols[indexCol] : '');
                                    } else {
                                      handlerChange(element, '', true, selectedIndexCol, selectedIndexRow);
                                      setCurrentCol(true);
                                    }
                                    setIsOpen(true);
                                  }}
                                />
                              )}

                              {cols.type?.value === 'electronic-signature' && (
                                <input
                                  type="text"
                                  className="form-control"
                                  readOnly={true}
                                  value={row?.cols ? row?.cols[indexCol]?.value : ''}
                                  onClick={() => {
                                    if (!row?.cols) {
                                      handlerChange(element, '', true, indexCol, indexRow);
                                      openModal();
                                      setActiveRow(indexRow);
                                      setActiveCol(indexCol);
                                    } else {
                                      if (!row?.cols[indexCol]?.value) {
                                        openModal();
                                        setActiveRow(indexRow);
                                        setActiveCol(indexCol);
                                      }
                                    }
                                  }}
                                />
                              )}
                              {!cols.type && (
                                <input
                                  type="text"
                                  className="form-control"
                                  readOnly={row?.cols ? row?.cols[indexCol]?.readOnly : false}
                                  value={row?.cols ? row?.cols[indexCol]?.value : ''}
                                  onChange={(e) => handlerChange(element, e.target.value, true, indexCol, indexRow)}
                                />
                              )}
                            </>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </TableWithStickyHeader>
          )}
        </div>
      </div>
      {element.options.addRowsButton && (
        <button className={'btn ml-auto d-flex'} onClick={() => addNewRow(element)}>
          <i className={'icon-plus-svgrepo-com mr-2'} /> Add Row
        </button>
      )}

      {addNewRowButtonPositions.map((item) => {
        let layout = null;
        if (hoveredRowIndex === item.rowIndex) {
          layout = (
            <NewRowButton
              key={item.rowIndex}
              top={item.top}
              left={item.left}
              onClick={() => addNewRow(element, item.rowIndex)}
              onMouseEnter={handleAddRowButtonMouseEnter}
              onMouseLeave={handleAddRowButtonMouseLeave}
            />
          );
        }
        return layout;
      })}

      {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}

      {isOpen && currentCol && (
        <NumberModal
          isOpen={isOpen}
          element={element}
          currentCol={currentCol}
          selectedIndexRow={selectedIndexRow}
          selectedIndexCol={selectedIndexCol}
          handlerChange={handlerChange}
          handelCloseModal={handelCloseModal}
        />
      )}

      {isOpenLogin && (
        <LoginModal
          element={element}
          closeModal={closeModal}
          isOpen={isOpenLogin}
          handelSuccess={handelChangeLogin}
          activeCol={activeCol}
          title={'Login Modal'}
        />
      )}
    </>
  );
};
