import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../constants';

export const SubmissionItem = function SubmissionItem({ item, url }) {
  const { saved_at, assigned_at } = item;

  const date = useMemo(() => {
    let result = undefined;
    if (saved_at || assigned_at) {
      result = {};
      if (saved_at) {
        result.label = 'Saved';
        result.value = dayjs(saved_at).format(DATE_FORMAT);
      } else if (assigned_at) {
        result.label = 'Assigned';
        result.value = dayjs(assigned_at).format(DATE_FORMAT);
      }
    }
    return result;
  }, [assigned_at, saved_at]);

  const navigate = useNavigate();
  return (
    <div
      className="library-item cursor-pointer"
      onClick={() => {
        navigate(url ? url : `/initialization-form/${item.id}`);
      }}
      data-tooltip-id={`table-tooltip-${item.id}`}
    >
      <div className="library-item-text">
        <img src="/images/file-big-icon.png" alt="" /> {item.name}
        <Tooltip opacity={1} id={`table-tooltip-${item.id}`} place="top" content={item.name} />
        {date && (
          <div className={'library-item-date'}>
            <span className="label">{`${date.label}:`}</span>
            <span className="value">{date.value}</span>
          </div>
        )}
      </div>
    </div>
  );
};
