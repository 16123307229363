import React, { useState } from 'react';
import Modal from 'react-modal';
// import {getCurrentUserInfo, loginService} from "../../servises/auth";
// import dayjs from "dayjs";
import { cancelSMS } from '../../services/notifications';
import InputMask from 'react-input-mask';

function SMSModal({ isOpen, closeModal, title }) {
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  // const [subject, setSubject] = useState('')
  // const [message, setMessage] = useState('')
  const [agree_to_opt_in, setAgreeTo] = useState('dont_agree_to_opt_in');
  const [errors, setErrors] = useState({});
  const handelCloseModal = () => {
    closeModal();
    clear();
  };

  const clear = () => {
    setFirstName('');
    setLastName('');
    setPhone('');
    // setSubject('')
    // setMessage('')
    setAgreeTo('dont_agree_to_opt_in');
    setErrors({});
  };

  const getUserData = () => {
    const bodyRequest = {
      first_name,
      last_name,
      phone,
      // subject,
      // message,
      agree_to_opt_in: agree_to_opt_in === 'agree_to_opt_in',
      dont_agree_to_opt_in: agree_to_opt_in === 'dont_agree_to_opt_in',
    };
    setErrors({});

    cancelSMS(bodyRequest)
      .then((res) => {
        handelCloseModal();
      })
      .catch((e) => {
        if (e.response.data) {
          setErrors(e.response.data);
        }
      });
  };

  return (
    <Modal
      className="modal-default modal-sms"
      isOpen={isOpen}
      onRequestClose={handelCloseModal}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <h2 className="modal-header">
        <span>
          {/*<img src="/images/user-ok.png" alt=""/>*/}
          {title}
        </span>
        <button className="btn btn-icon" onClick={handelCloseModal}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>

      <div className={''}>
        <div className={'w-full'}>
          <div className="form-control-item w-50">
            <label className="form-label">
              First Name <span className={'required'}>*</span>
            </label>
            <input
              placeholder={'Enter First Name'}
              value={first_name}
              onChange={(e) => setFirstName(e.target.value)}
              type="text"
              className="form-control"
            />

            <div className={'form-error mt-1'}>{errors?.first_name && errors?.first_name[0]}</div>
          </div>
          <div className="form-control-item w-50">
            <label className="form-label">
              Last Name <span className={'required'}>*</span>
            </label>
            <input
              placeholder={'Enter Last Name'}
              value={last_name}
              onChange={(e) => setLastName(e.target.value)}
              type="text"
              className="form-control"
            />

            <div className={'form-error mt-1'}>{errors?.last_name && errors?.last_name[0]}</div>
          </div>
          <div className="form-control-item">
            <label className="form-label">
              Phone <span className={'required'}>*</span>
            </label>
            <InputMask
              id="signer_phone"
              placeholder="XXX-XXX-XXXX"
              mask="999-999-9999"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="form-control"
            ></InputMask>
            <div className={'form-error mt-1'}>{errors?.phone && errors?.phone[0]}</div>
          </div>
          {/*<div className="form-control-item">*/}
          {/*    <label className="form-label">Subject <span className={'required'}>*</span></label>*/}
          {/*    <input placeholder={'Enter Subject'} value={subject} onChange={(e) => setSubject(e.target.value)} type="text" className="form-control"/>*/}

          {/*    <div className={'form-error mt-1'}>{errors?.subject && errors?.subject[0]}</div>*/}
          {/*</div>*/}
          {/*<div className="form-control-item">*/}
          {/*    <label className="form-label">Message <span className={'required'}>*</span></label>*/}
          {/*    <textarea placeholder={'Enter Message'} value={message} onChange={(e) => setMessage(e.target.value)} className="form-control"/>*/}

          {/*    <div className={'form-error mt-1'}>{errors?.message && errors?.message[0]}</div>*/}
          {/*</div>*/}
          <div className={'form-control-item'}>
            <p className={'form-label'}>Terms, Conditions and Privacy:</p>
            <ul className={'form-label form-list'}>
              <li>
                When you opt-in, you may receive SMS, such as notifications of new policies, educational materials, or status of maintenance
                requests. Your consent will enhance our communication, but it is optional for you.
              </li>
              <li>To cancel SMS at any time, complete this form and check Opt-out, or respond STOP to any text.</li>
              <li>Message data rates may apply. You may receive up to four SMS messages per week.</li>
              <li>
                Your privacy is important! Information you provide in this form will be used only for Opt-in or Opt-out guidance. It will
                not be shared or used for any other purpose.
              </li>
            </ul>
          </div>

          <div className={'form-control-item'}>
            <label className="form-label">Do you agree to opt-in to receive Text messages? You may opt-out at any time.</label>
            <div className={`form-control-radio`}>
              <input
                name={'daily'}
                type="radio"
                checked={agree_to_opt_in === 'agree_to_opt_in'}
                onChange={() => setAgreeTo('agree_to_opt_in')}
              />
              <span>I agree to opt-in</span>
            </div>
            <div className={`form-control-radio`}>
              <input
                name={'daily'}
                type="radio"
                checked={agree_to_opt_in === 'dont_agree_to_opt_in'}
                onChange={() => setAgreeTo('dont_agree_to_opt_in')}
              />
              <span>I want to opt-out</span>
            </div>
            <div className={'form-error mt-1 mb-3'}>{errors?.agree_to_opt_in && errors?.agree_to_opt_in[0]}</div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={handelCloseModal}>
          Cancel
        </button>
        <button className="btn d-flex" onClick={getUserData}>
          Submit
        </button>
      </div>
    </Modal>
  );
}

export default SMSModal;
