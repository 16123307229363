import { MULTILINE_CHARACTER_LIMIT } from './settings/form-initialization';

export const TextFormElement = {
  name: 'Text',
  type: 'text',
  options: {
    value: 'Text',
    valueInput: '',
    fontSize: 12,
    fontFamily: 'Times New Roman',
    textDecoration: 'none',
    fontWeight: 'normal',
    fontStyle: 'italic',
    color: '#000',
  },
};
export const LinkFormElement = {
  name: 'Web Link attachment',
  type: 'link',
  options: {
    alignment: 'left',
    value: 'Empty Link',
    valueInput: '',
    link: 'Web Link attachment',
    label: 'Web Link attachment',
    fontSize: 12,
    fontFamily: 'Times New Roman',
    textDecoration: 'none',
    fontWeight: 'normal',
    fontStyle: 'italic',
    color: '#000',
    initiator_can_edit: false,
  },
};

export const RatingFormElement = {
  name: 'Rating',
  type: 'rating',
  options: {
    alignment: 'left',
    size: 'medium',
    valueInput: '',
    value: 0,
    fontSize: 20,
    name: 'Rating',
    instructions: '',
  },
};
export const SignatureFormElement = {
  name: 'Signature',
  type: 'signature',
  options: {
    alignment: 'left',
    valueInput: '',
    label: 'Signature',
    size: 'medium',
    name: 'Signature',
    instructions: '',
  },
};

export const SingleLineFormElement = {
  name: 'Single Line',
  type: 'single-line',
  options: {
    alignment: 'left',
    size: 'medium',
    valueInput: '',
    name: 'single-line',
    label: 'Single Line',
    instructions: '',
    characterLimit: 255,
  },
};

export const SingleLineHeaderFormElement = {
  name: 'Add Text to Form Name',
  type: 'single-line-header',
  options: {
    alignment: 'left',
    size: 'medium',
    valueInput: '',
    name: 'single-line-header',
    label: 'Text Field For Title',
    instructions: '',
  },
};

export const SubmittersFieldFormElement = {
  name: 'Submitters info auto entry',
  type: 'submitters-field',
  options: {
    alignment: 'left',
    valueInput: '',
    name: 'Select Submitters Field',
    labels: [
      {
        value: 'name',
        label: 'Name',
      },
      {
        value: 'surname',
        label: 'Last name',
      },
      {
        value: 'position',
        label: 'Position',
      },
      {
        value: 'date',
        label: 'Date',
      },
    ],
  },
};

export const NumberFormElement = {
  name: 'Number',
  type: 'number',
  options: {
    alignment: 'left',
    size: 'medium',
    name: 'number',
    valueInput: '',
    label: 'Number',
    instructions: '',
    min: null,
    max: null,
  },
};

export const MultipleLineFormElement = {
  name: 'Multiline',
  type: 'multiline-line',
  options: {
    alignment: 'left',
    size: 'medium',
    valueInput: '',
    name: 'multiline-line',
    label: 'Multiline Line',
    instructions: '',
    characterLimit: MULTILINE_CHARACTER_LIMIT,
  },
};

export const EmailFormElement = {
  name: 'Email',
  type: 'email',
  options: {
    alignment: 'left',
    size: 'medium',
    valueInput: '',
    name: 'email',
    label: 'Email',
    instructions: '',
  },
};

export const PhoneFormElement = {
  name: 'Phone',
  type: 'phone',
  options: {
    alignment: 'left',
    size: 'medium',
    valueInput: '',
    name: 'phone',
    label: 'Phone',
    instructions: '',
  },
};

export const NameFormElement = {
  name: 'Full Name',
  type: 'name',
  options: {
    alignment: 'left',
    size: 'large',
    instructions: '',
  },
  inputs: [
    {
      name: 'first-name',
      label: 'First Name',
      value: '',
      valueInput: '',
    },
    {
      name: 'second-name',
      label: 'Last Name',
      value: '',
      valueInput: '',
    },
  ],
};

export const AddressFormElement = {
  name: 'Address',
  type: 'address',
  options: {
    alignment: 'left',
    size: 'large',
    instructions: '',
  },
  inputs: [
    {
      name: 'street',
      label: 'Street',
      value: '',
      valueInput: '',
    },
    {
      name: 'city',
      label: 'City',
      value: '',
      valueInput: '',
    },
    {
      name: 'state',
      label: 'State, Zip code',
      value: '',
      valueInput: '',
    },
  ],
};

export const DateFormElement = {
  name: 'Date',
  type: 'date',
  options: {
    alignment: 'left',
    size: 'medium',
    name: 'date',
    label: 'Date',
    instructions: '',
    valueInput: '',
    isEditable: false,
  },
};

export const TimeFormElement = {
  name: 'Time',
  type: 'time',
  options: {
    alignment: 'left',
    size: 'medium',
    name: 'time',
    label: 'Time',
    valueInput: '',
    instructions: '',
    isEditable: false,
  },
};
export const LoginElement = {
  name: 'Electronic signature',
  type: 'login',
  options: {
    alignment: 'left',
    size: 'medium',
    name: 'login',
    label: 'Electronic signature',
    hasTime: false,
    hasDate: false,
    canAddNew: false,
    valueInput: '',
    instructions: '',
  },
};

export const DropdownFormElement = {
  name: 'Dropdown',
  type: 'dropdown',
  options: {
    alignment: 'left',
    size: 'medium',
    name: 'dropdown',
    label: 'Dropdown',
    valueInput: '',
    instructions: '',
  },
  inputs: [
    {
      id: 'option-1',
      label: 'Option 1',
    },
  ],
};

export const CheckBoxFormElement = {
  name: 'Checkbox',
  type: 'checkbox',
  options: {
    alignment: 'left',
    size: 'medium',
    name: 'checkbox',
    label: 'Checkbox',
    instructions: '',
    valueInput: '',
  },
  inputs: [
    {
      id: 'option-1',
      label: 'Option 1',
    },
  ],
};

export const RadioButtonFormElement = {
  name: 'RadioButton',
  type: 'radiobutton',
  options: {
    alignment: 'left',
    size: 'medium',
    name: 'radiobutton',
    label: 'RadioButton',
    instructions: '',
    valueInput: '',
  },
  inputs: [
    {
      id: 'option-1',
      label: 'Option 1',
    },
  ],
};

export const TableFormElement = {
  name: 'Table',
  type: 'table',
  options: {
    alignment: 'left',
    type: {
      value: 'single-line',
      label: 'Single Line',
    },
    showQuestions: false,
    addRowsButton: false,
    size: 'medium',
    name: 'table',
    label: 'Table',
    instructions: '',
    enableScroll: false,
    rowHeaderTitle: 'Question',
    tableAlignment: 'left',
  },
  cols: [
    {
      id: 'answer-1',
      label: 'Answer 1',
      value: '',
      width: '160px',
      options: [],
      type: { value: 'single-line', label: 'Single Line' },
    },
  ],
  rows: [
    {
      cols: [
        {
          id: 'answer-1',
          label: 'Answer 1',
          value: '',
          width: '160px',
          options: [],
          type: { value: 'single-line', label: 'Single Line' },
        },
      ],
      original: true,
      id: 'question-1',
      label: 'Question 1',
    },
  ],
};

export const FileUploaderFormElement = {
  name: 'Submitter file attach',
  type: 'file-upload',
  options: {
    alignment: 'left',
    size: 'medium',
    name: 'file-upload',
    label: 'Submitter file attach',
    instructions: '',
    fileSize: '100',
    fileCount: 1,
    fileType: null,
    valueInput: '',
    file: null,
  },
};

export const FileAttachFormElement = {
  name: 'Initiator file attach',
  type: 'file-attach',
  options: {
    label: 'Initiator file attach',
    file_url: null,
    valueInput: '',
    file: null,
  },
};

export const contentComponentsArray = [TextFormElement, SingleLineFormElement, MultipleLineFormElement, NumberFormElement, NameFormElement];
export const formComponentsArray = [AddressFormElement, EmailFormElement, PhoneFormElement, DateFormElement, TimeFormElement];
export const selectionComponentsArray = [DropdownFormElement, CheckBoxFormElement, RadioButtonFormElement, RatingFormElement];
export const otherComponentsArray = [
  LinkFormElement,
  TableFormElement,
  FileAttachFormElement,
  FileUploaderFormElement,
  SingleLineHeaderFormElement,
  SubmittersFieldFormElement,
  SignatureFormElement,
  LoginElement,
];
