import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

import InputMask from 'react-input-mask';

import { editContacts } from '../../servises/contacts';
import { useParams } from 'react-router-dom';
// import Select from "react-select";

function UserModal({ isOpen, companyId, closeModal, companyName, userData, handelCreateUser, representatives }) {
  const [errorUser, setErrorUser] = useState({});
  const { resident_id } = useParams();
  // const [relationshipOptions] = useState([
  //         {
  //             label: 'son',
  //             value: 'son'
  //         },
  //         {
  //             label: 'daughter',
  //             value: 'daughter'
  //         },
  //         {
  //             label: 'wife',
  //             value: 'wife'
  //         },{
  //             label: 'husband',
  //             value: 'husband'
  //         },
  //         {
  //             label: 'cousin',
  //             value: 'cousin'
  //         }
  //     ]
  // )
  const [user, setUser] = useState({
    representatives: [],
  });

  useEffect(() => {
    const newState = { ...userData };
    newState['relation_with_resident_id'] = { value: userData.relation_with_resident, label: userData.relation_with_resident };
    setUser({ representatives: [{ ...newState }] });
  }, [userData, isOpen]);

  const handleChangeRepresentative = (value, key, index) => {
    setUser((prevState) => {
      let newState = [...user.representatives];
      newState[index][key] = value;
      return {
        ...user,
        representatives: [...newState],
      };
    });
  };

  const resetForm = () => {
    setUser({
      representatives: [],
    });
    setErrorUser({});
  };

  const handleSubmit = () => {
    const formDate = { ...user };
    formDate.representatives.forEach((item) => {
      item.relation_with_resident = item.relation_with_resident_id.value;
    });
    formDate.representatives = [...formDate.representatives, ...representatives.filter((item) => item.id !== userData.id)];
    editContacts(formDate, resident_id)
      .then((res) => {
        resetForm();
        handelCreateUser(res);
      })
      .catch((e) => {
        if (e?.response?.data) {
          setErrorUser(JSON.parse(JSON.stringify(e.response.data)));
        }
      });
  };
  const handelCloseModal = () => {
    closeModal();
    resetForm();
  };

  return (
    <Modal className="modal-default" isOpen={isOpen} onRequestClose={handelCloseModal} contentLabel="Example Modal" ariaHideApp={false}>
      <h2 className="modal-header">
        {userData ? 'Edit' : 'Add New'} Representative{' '}
        <button className="btn btn-icon" onClick={handelCloseModal}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>
      {!!companyName && <h3 className="modal-subheader">for {companyName} company</h3>}
      {user.representatives.map((item, index) => (
        <div key={index} className="form-wrapper">
          <h3 className="form-wrapper-title">
            <img src="/images/company-add-icon-3.svg" alt="" />
            Representative information
          </h3>
          <div className={`form-item form-item-half ${errorUser.representatives && errorUser.representatives[index].first_name ? 'form-item-error' : ''}`}>
            <label className="form-label">Representative Name</label>
            <input
              placeholder="Enter First Name"
              value={item.first_name}
              onChange={(e) => handleChangeRepresentative(e.target.value, 'first_name', index)}
              type="text"
              className="form-control"
            />
            <img className="danger-icon" src="/images/red-alert.svg" alt="" />
            <span className="form-error">{errorUser.representatives && errorUser.representatives[index]?.first_name && errorUser.representatives[index].first_name[0]}</span>
          </div>
          <div className={`form-item form-item-half ${errorUser.representatives && errorUser.representatives[index]?.last_name ? 'form-item-error' : ''}`}>
            <label className="form-label">Representative Last Name</label>
            <input
              placeholder="Enter Last Name"
              value={item.last_name}
              onChange={(e) => handleChangeRepresentative(e.target.value, 'last_name', index)}
              type="text"
              className="form-control"
            />
            <img className="danger-icon" src="/images/red-alert.svg" alt="" />
            <span className="form-error">{errorUser.representatives && errorUser.representatives[index]?.last_name && errorUser.representatives[index].last_name[0]}</span>
          </div>
          <div className={`form-item form-item-half form-item-mail ${errorUser.representatives && errorUser.representatives[index]?.email ? 'form-item-error' : ''}`}>
            <label className="form-label">
              Email <span className="optional">optional</span>
            </label>
            <img className="mail-icon" src="/images/mail-icon.svg" alt="" />
            <input
              placeholder="mail@example.com"
              id="email"
              value={item.email}
              onChange={(e) => handleChangeRepresentative(e.target.value, 'email', index)}
              type="text"
              className="form-control"
            />
            <img className="danger-icon" src="/images/red-alert.svg" alt="" />
            <span className="form-error">{errorUser.representatives && errorUser.representatives[index]?.email && errorUser.representatives[index].email[0]}</span>
          </div>
          <div className={`form-item form-item-half ${errorUser.representatives && errorUser.representatives[index]?.phone ? 'form-item-error' : ''}`}>
            <label className="form-label">Phone Number</label>
            <InputMask
              placeholder="XXX-XXX-XXXX"
              mask="999-999-9999"
              value={item.phone}
              onChange={(e) => handleChangeRepresentative(e.target.value, 'phone', index)}
              className="form-control"
            ></InputMask>

            <img className="danger-icon" src="/images/red-alert.svg" alt="" />
            <span className="form-error">{errorUser.representatives && errorUser.representatives[index]?.phone && errorUser.representatives[index].phone[0]}</span>
          </div>

          <div className={`form-item form-item-select ${errorUser.representatives && errorUser.representatives[index]?.relation_with_resident ? 'form-item-error' : ''}`}>
            <label className="form-label">Relation with Resident</label>
            <input
              placeholder="Enter Relation"
              value={item.relation_with_resident}
              onChange={(e) => handleChangeRepresentative(e.target.value, 'relation_with_resident', index)}
              type="text"
              className="form-control"
            />
            <img className="danger-icon" src="/images/red-alert.svg" alt="" />
            <span className="form-error">
              {errorUser.representatives && errorUser.representatives[index]?.relation_with_resident && errorUser.representatives[index].relation_with_resident[0]}
            </span>
          </div>
        </div>
      ))}

      <div className="modal-footer">
        <button className="btn btn-outline" onClick={handelCloseModal}>
          Cancel
        </button>
        <button className="btn" onClick={handleSubmit}>
          Save
        </button>
      </div>
    </Modal>
  );
}

export default UserModal;
