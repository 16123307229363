import React, { useEffect, useState } from 'react';
import { MultiSelect } from '../other/MultiSelect';
import { getUsersListSettingsInit } from '../../servises/user';
import { getCompanyUserGroupListSettingsInit } from '../../servises/company';
import { useAuth } from '../../container/authContext';
import { useParams } from 'react-router-dom';

export const RecipientSettingsInit = function RecipientSettings(props) {
  const { formData, handelInitSettings, initData, errors, tabs, changeTab, handleSubmitSettings } = props;
  const [recipient_settings_init, setRecipientSettingsInit] = useState({});
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [usersGroupsOptions, setUsersGroupsOptions] = useState([]);
  const [individualUsersOptions, setIndividualUsersOptions] = useState([]);
  const { user } = useAuth();
  const { facility: userFacility } = user ?? {};
  const companyId = user.company?.id;
  const { id } = useParams();

  useEffect(() => {
    const { recipient_settings } = formData ?? {};
    const isNeedGetCompanyUserGroup =
      !!recipient_settings?.recipients_only_from_initiator_facility ||
      (!recipient_settings?.recipients_only_from_initiator_facility && recipient_settings_init?.facilities?.length);
    if (isNeedGetCompanyUserGroup) {
      getCompanyUserGroup(userFacility ? [userFacility.id] : []);
    }
  }, [formData, recipient_settings_init?.facilities?.length, userFacility]);

  const getCompanyUserGroup = (facilities_ids) => {
    getCompanyUserGroupListSettingsInit({
      company_id: companyId,
      status: 'active',
      form_id: id,
      field: 'recipient_settings',
      facilities_ids: facilities_ids,
    })
      .then((res) => {
        const options = res.map((item) => ({ value: item.id, label: item.name, entity_type: 'user_group' }));
        setUsersGroupsOptions(options);
        const newState = [];
        const prevState = {
          ...recipient_settings_init,
          user_groups: initData?.recipient_settings?.user_groups,
        };
        prevState['user_groups']?.forEach((item) => {
          if (options.find((option) => option.value === item.value)) {
            newState.push(item);
          }
        });
        prevState['user_groups'] = newState;
        setRecipientSettingsInit(() => {
          return prevState;
        });
      })
      .catch((e) => console.log(e));
  };

  const getUsersList = (facilities_ids = []) => {
    getUsersListSettingsInit({
      company_id: companyId,
      status: 'active',
      form_id: id,
      field: 'recipient_settings',
      facilities_ids: facilities_ids,
      user_groups_ids: recipient_settings_init.user_groups ? [...recipient_settings_init.user_groups.map((item) => item.value)] : [],
    })
      .then((resUsers) => {
        const options = resUsers.map((item) => ({
          value: item.id,
          label: item.last_name + ' ' + item.first_name,
          entity_type: 'user',
        }));
        setIndividualUsersOptions(options);
        const newState = [];
        const prevState = {
          ...recipient_settings_init,
          individual_users: initData?.recipient_settings?.individual_users,
        };
        prevState['individual_users']?.forEach((item) => {
          if (options.find((option) => option.value === item.value)) {
            newState.push(item);
          }
        });
        prevState['individual_users'] = newState;
        setRecipientSettingsInit(() => {
          return prevState;
        });
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (formData?.recipient_settings) {
      const prevState = formData.recipient_settings;
      if (prevState.facilities?.length) {
        setFacilityOptions(prevState.facilities.map((item) => ({ value: item.id, label: item.name })));
      }
    }
    // eslint-disable-next-line
  }, [formData, recipient_settings_init]);

  useEffect(() => {
    if (!formData?.recipient_settings?.recipients_only_from_initiator_facility) {
      if (!recipient_settings_init?.facilities?.length) {
        const prevState = { ...recipient_settings_init };
        prevState['user_groups'] = [];
        prevState['individual_users'] = [];
        setRecipientSettingsInit(() => {
          return prevState;
        });
        setIndividualUsersOptions([]);
        setUsersGroupsOptions([]);
        handelInitSettings(prevState, 'recipient_settings');
      }
    }
    // eslint-disable-next-line
  }, [recipient_settings_init.facilities]);

  useEffect(() => {
    if (!formData?.recipient_settings?.recipients_only_from_initiator_facility) {
      if (recipient_settings_init?.facilities?.length) {
        getUsersList(recipient_settings_init?.facilities ? [...recipient_settings_init.facilities.map((item) => item.value)] : []);
      }
    } else {
      getUsersList(user.facility ? [user.facility.id] : []);
    }
    // eslint-disable-next-line
  }, [recipient_settings_init.facilities, recipient_settings_init.user_groups]);

  useEffect(() => {
    if (initData?.recipient_settings) {
      setRecipientSettingsInit({ ...initData.recipient_settings });
    }

    // eslint-disable-next-line
  }, [initData]);

  const handelRemove = (key, value) => {
    const prevState = { ...recipient_settings_init };
    prevState[key] = prevState[key].filter((item) => item.value !== value.value);
    setRecipientSettingsInit(() => {
      return prevState;
    });
    handelInitSettings(prevState, 'recipient_settings');
  };

  const handelSettings = (value, key) => {
    const prevState = { ...recipient_settings_init };
    prevState[key] = value;
    setRecipientSettingsInit(() => {
      return prevState;
    });

    handelInitSettings(prevState, 'recipient_settings');
  };

  const [tab, setTab] = useState(null);
  const [tabBack, setTabBack] = useState(null);

  useEffect(() => {
    const submissionTab = tabs.find((item) => item.component === 'submission-settings-init');
    if (!submissionTab?.isDisable) {
      setTabBack(submissionTab);
    } else {
      setTabBack(tabs.find((item) => item.component === 'view-form'));
    }

    setTab(tabs.find((item) => item.component === 'notification-settings-init'));
  }, [tabs]);

  const onChangeTab = () => {
    changeTab(tab);
  };
  const onBackTab = () => {
    changeTab(tabBack);
  };

  return (
    <>
      <div className="form-builder-setting-tab-content">
        <div className="form-builder-recipient-settings" style={{ paddingBottom: 80 }}>
          {formData?.name && (
            <div className="from-builder-title from-builder-title-setting">
              <h1>
                <img src="/images/file-big-icon.png" className="mr-2" alt="" />
                {formData.name}
              </h1>
            </div>
          )}

          <p className="info-text">
            <i className="icon-icon-info"></i> Please ensure all mandatory fields are filled out for form initialization.
          </p>
          <span className="form-error mb-2 d-flex">{errors?.non_field_errors && errors.non_field_errors[0]}</span>

          <div className="form-control-item-settings">
            <div className="form-settings-label">
              <label className="form-label form-label-big">Specify recipients</label>
              <span>Recipients are employees who will have access to completed submissions of Forms.</span>
            </div>
            <div className="form-settings-inputs">
              <>
                <div className={`form-item form-item-select mb-4`}>
                  <label className="form-label form-label-big">Facility</label>
                  <div className="select-multy-wrapper">
                    <span className="select-placeholder">Choose one or several facilities</span>
                    <MultiSelect
                      isDisabled={!facilityOptions.length}
                      options={facilityOptions}
                      value={recipient_settings_init.facilities}
                      onChange={(e) => handelSettings(e, 'facilities')}
                      placeholder=""
                      allName="All facilities"
                    ></MultiSelect>
                    {recipient_settings_init.facilities?.length > 0 && (
                      <>
                        <ul>
                          {recipient_settings_init.facilities.map((item, key) => (
                            <li key={key}>
                              {item.label}
                              <button className="btn btn-icon" onClick={() => handelRemove('facilities', item)}>
                                <img src="/images/x-circle.svg" alt="" />
                              </button>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                  <span className="form-error">{errors?.recipient_settings?.facilities && errors.recipient_settings?.facilities[0]}</span>
                </div>

                <div className={`form-item form-item-select mb-4`}>
                  <label className="form-label form-label-big">User groups</label>
                  <div className="select-multy-wrapper">
                    <span className="select-placeholder">Choose one or several user groups</span>
                    <MultiSelect
                      isDisabled={!usersGroupsOptions.length}
                      options={usersGroupsOptions}
                      value={recipient_settings_init.user_groups}
                      onChange={(e) => handelSettings(e, 'user_groups')}
                      placeholder=""
                      allName="All user groups"
                    />
                    {recipient_settings_init.user_groups?.length > 0 && (
                      <>
                        <ul>
                          {recipient_settings_init.user_groups.map((item, key) => (
                            <li key={key}>
                              {item.label}
                              <button className="btn btn-icon" onClick={() => handelRemove('user_groups', item)}>
                                <img src="/images/x-circle.svg" alt="" />
                              </button>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                  <span className="form-error">{errors?.recipient_settings?.user_groups && errors.recipient_settings?.user_groups[0]}</span>
                </div>

                <div className={`form-item form-item-select mb-4`}>
                  <label className="form-label form-label-big">Individual Users</label>
                  <div className="select-multy-wrapper">
                    <span className="select-placeholder">Choose one or several individual_users</span>
                    <MultiSelect
                      isDisabled={!individualUsersOptions.length}
                      options={individualUsersOptions}
                      value={recipient_settings_init.individual_users}
                      onChange={(e) => handelSettings(e, 'individual_users')}
                      placeholder=""
                      allName="All individual users"
                    ></MultiSelect>
                    {recipient_settings_init.individual_users?.length > 0 && (
                      <>
                        <ul className={'individual-users-list'}>
                          {recipient_settings_init.individual_users
                            .sort(function (a, b) {
                              if (a.label < b.label) {
                                return -1;
                              }
                              if (a.label > b.label) {
                                return 1;
                              }
                              return 0;
                            })
                            .map((item, key) => (
                              <li key={key}>
                                {item.label}
                                <button className="btn btn-icon" onClick={() => handelRemove('individual_users', item)}>
                                  <img src="/images/x-circle.svg" alt="" />
                                </button>
                              </li>
                            ))}
                        </ul>
                      </>
                    )}
                    <span className="form-error">{errors?.recipient_settings?.individual_users && errors.recipient_settings?.individual_users[0]}</span>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      <div className="form-control-actions form-init-content">
        <div>
          {tabBack && (
            <button onClick={onBackTab} className={`btn btn-outline btn-wide`}>
              <svg className={'mr-2'} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M15.8327 10.0013H4.16602M4.16602 10.0013L9.99935 15.8346M4.16602 10.0013L9.99935 4.16797"
                  stroke="#101828"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Back
            </button>
          )}
          {tab && !tab?.isDisable ? (
            <button disabled={tab?.isDisable} className="btn btn-outline btn-outline-secondary btn-wide" onClick={onChangeTab}>
              Next
              <svg className={'ml-2'} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M4.16602 10.0013H15.8327M15.8327 10.0013L9.99935 4.16797M15.8327 10.0013L9.99935 15.8346"
                  stroke="#7F56D9"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          ) : (
            <button className="btn btn-wide" onClick={handleSubmitSettings} style={{ height: 41 }}>
              Submit
            </button>
          )}
        </div>
      </div>
    </>
  );
};
