import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../../layout/init-form';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Components from '../../components/from-builder/settings/components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getFromById, initForm } from '../../servises/form';
import { Tooltip } from 'react-tooltip';
import dayjs from 'dayjs';
import ErrorModal from '../../components/library/error-modal';
import { DATE_FORMAT, DATE_PICKER_TIME_FORMAT } from '../../constants';
import { dateToISOString } from '../../utility/dates/dateToISOString';
import { useUserFormRole } from '../../hook/useUserFormRole';
import { getFormFieldsWithInitialValues } from '../../utility/getFormFieldsWithInitialValues';
import {
  ARCHIVE_DATE_KEY,
  ARCHIVE_DATE_TIME_KEY,
  ARCHIVE_RELATIVE_TO_INITIATION_KEY,
  ARCHIVE_TIME_KEY,
  ARCHIVE_TIME_OFFSET_KEY,
  ARCHIVE_TIME_OFFSET_UNIT_KEY,
  ARCHIVE_WITH_NEXT_INITIATION_KEY,
  AUTOMATICALLY_ARCHIVE_FORM_KEY,
  INITIATE_FORM_IMMEDIATELY_KEY,
  ONE_TIME_INITIATION_OR_ARCHIVING_KEY,
  ONE_TIME_INITIATION_START_DATE_KEY,
  ONE_TIME_INITIATION_START_TIME_KEY,
  RECURRENT_INITIATION_END_DAY_KEY,
  RECURRENT_INITIATION_OR_ARCHIVING_KEY,
  RECURRENT_INITIATION_REPEAT_DAYS_KEY,
  RECURRENT_INITIATION_REPEAT_EVERY_KEY,
  RECURRENT_INITIATION_REPEAT_MONTH_DAYS_KEY,
  RECURRENT_INITIATION_REPEAT_PERIOD_KEY,
  RECURRENT_INITIATION_REPEAT_YEAR_MONTHS_KEY,
  RECURRENT_INITIATION_START_DAY_KEY,
  RECURRENT_INITIATION_TIME_KEY,
} from '../../components/init-form/submition-settings-public/contstants';
import { updateDateTimeSetting } from '../../utility/dates/updateDateTimeSetting';
import { localToUTCDate } from '../../utility/dates/localToUTCDate';
import { get } from 'lodash';

function ViewFormPage() {
  const navigation = useNavigate();
  const userFormRole = useUserFormRole();

  const [tabIndex, setTabIndex] = useState(0);
  const [formData, setFormData] = useState({});
  const [initData, setInitData] = useState({});
  const [errors, setError] = useState({});
  const { id } = useParams();
  const location = useLocation();
  const [isDisabled, setIsDisabled] = useState(false);
  const [showTemplateError, setShowTemplateError] = useState(false);
  const [isAlreadySetDefaultOptions, setIsAlreadySetDefaultOptions] = useState(false);

  const [tabs, setTabs] = useState([
    {
      name: 'View Form',
      component: 'view-form',
      index: 0,
      url: `/initialization-form/${id}`,
    },
    {
      name: 'Submission Settings',
      component: 'submission-settings',
      index: 1,
      isDisable: true,
      url: `/initialization-form/${id}`,
    },
    {
      name: 'Recipient Settings',
      component: 'recipient-settings-init',
      index: 2,
      isDisable: true,
      url: `/initialization-form/${id}`,
    },
    {
      name: 'Notification Settings',
      component: 'notification-settings-init',
      index: 3,
      isDisable: true,
      url: `/initialization-form/${id}`,
    },
  ]);

  useEffect(() => {
    if (id) {
      getFromById(id)
        .then((res) => {
          setFormData(res);
          setIsDisabled(res.deleted_at);
          if (res?.form_fields) {
            setInitData((prevState) => {
              return {
                ...prevState,
                form_fields: getFormFieldsWithInitialValues(JSON.parse(JSON.stringify(res?.form_fields)), userFormRole),
                will_have_period_availability: false,
                will_have_frequency: false,
                archive_settings: res.archive_settings,
              };
            });
          }
        })
        .catch((e) => console.log(e));
    }
    // eslint-disable-next-line
  }, [id, location]);

  useEffect(() => {
    if (formData?.submission_settings?.filling_type) {
      if (formData.submission_settings.filling_type === 'PUBLIC') {
        setTabs([
          {
            name: 'View Form',
            component: 'view-form',
            index: 0,
            hasError: false,
          },
          {
            name: 'Submission Settings',
            component: 'submission-settings-init',
            index: 1,
            hasError:
              errors.hasOwnProperty('public_folder_id') ||
              errors.hasOwnProperty('archive_settings') ||
              errors.hasOwnProperty('frequency') ||
              errors.hasOwnProperty('start_date_time'),
          },
        ]);
      } else if (formData.submission_settings.filling_type === 'MIXED') {
        setTabs([
          {
            name: 'View Form',
            component: 'view-form',
            index: 0,
            hasError: false,
          },
          {
            name: 'Recipient Settings',
            component: 'recipient-settings-init',
            index: 2,
            hasError: false,
            isDisable: !formData.recipient_settings?.can_initiator_select_recipients,
          },
        ]);
      } else if (formData.submission_settings.filling_type === 'SPECIAL') {
        const isDisabledSubmissionSettings =
          !formData?.submission_settings?.can_initiator_select_submitters && !formData?.submission_settings?.can_initiator_specify_period_availability_and_frequency;

        setTabs([
          {
            name: 'View Form',
            component: 'view-form',
            index: 0,
            hasError: errors.hasOwnProperty('form_fields'),
          },
          {
            name: 'Submission Settings',
            component: 'submission-settings-init',
            index: 1,
            isDisable: isDisabledSubmissionSettings,
            hasError:
              errors.hasOwnProperty('submitters_settings') ||
              errors.hasOwnProperty('frequency') ||
              errors.hasOwnProperty('will_have_frequency') ||
              errors.hasOwnProperty('submission_deadline_settings') ||
              errors.hasOwnProperty('start_date_time') ||
              errors.hasOwnProperty('immediately_initiation'),
          },
          {
            name: 'Recipient Settings',
            component: 'recipient-settings-init',
            index: 2,
            hasError: errors.hasOwnProperty('recipient_settings'),
            isDisable: !formData.recipient_settings?.can_initiator_select_recipients,
          },
          {
            name: 'Notification Settings',
            component: 'notification-settings-init',
            index: 3,
            isDisable: !initData?.will_have_frequency && !initData?.will_have_period_availability,
            hasError: errors.hasOwnProperty('notification_settings'),
          },
        ]);
      }

      if (isDisabled) {
        setTabs([
          {
            name: 'View Form',
            component: 'view-form',
            index: 0,
            hasError: true,
          },
          {
            name: 'Submission Settings',
            component: 'submission-settings-init',
            index: 1,
            hasError: false,
            isDisable: true,
          },
          {
            name: 'Recipient Settings',
            component: 'recipient-settings-init',
            index: 2,
            hasError: false,
            isDisable: true,
          },
          {
            name: 'Notification Settings',
            component: 'notification-settings-init',
            index: 3,
            hasError: false,
            isDisable: true,
          },
        ]);
      }
    }
  }, [formData, id, errors, initData, isDisabled]);

  // Set default option for dropdown col
  useEffect(() => {
    if (!isAlreadySetDefaultOptions && initData?.form_fields?.length) {
      setInitData((prevState) => {
        const form_fields = prevState.form_fields.map((field) => {
          const newField = { ...field };
          if (field && field.type === 'table') {
            const { cols, rows } = field;
            if (Array.isArray(cols) && Array.isArray(rows)) {
              newField.rows = [...field.rows].map((row) => {
                cols.forEach((tableCol, colIndex) => {
                  const { defaultOptionId, options, type: colType } = tableCol;
                  if (colType?.value === 'dropdown' && Array.isArray(options) && defaultOptionId) {
                    const defaultOption = options.find((o) => o.id === defaultOptionId);
                    if (defaultOption) {
                      if (!Array.isArray(row.cols)) {
                        row.cols = [];
                      }
                      row.cols[colIndex] = {
                        ...tableCol,
                        value: defaultOption,
                      };
                    }
                  }
                });
                return row;
              });
            }
          }

          return newField;
        });

        return { ...prevState, form_fields };
      });

      setIsAlreadySetDefaultOptions(true);
    }
  }, [initData.form_fields, isAlreadySetDefaultOptions]);

  const getBodyRequest = () => {
    const bodyRequest = {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    if (initData.form_fields) {
      bodyRequest['form_fields'] = initData.form_fields;
    }

    if (formData.filling_type === 'SPECIAL') {
      if (initData.will_have_frequency === true || initData.will_have_frequency === false) {
        bodyRequest['will_have_frequency'] = initData.will_have_frequency ?? false;
      }

      if (initData.will_have_period_availability === true || initData.will_have_period_availability === false) {
        bodyRequest['will_have_period_availability'] = initData.will_have_period_availability ?? false;
      }

      if (formData.submission_settings?.can_initiator_select_submitters) {
        bodyRequest['submitters_settings'] = {};
        if (initData?.submitters_settings?.facilities) {
          bodyRequest['submitters_settings']['facilities'] = initData.submitters_settings.facilities.map((item) => item.value);
        }
        if (initData?.submitters_settings?.user_groups) {
          bodyRequest['submitters_settings']['user_groups'] = initData.submitters_settings.user_groups.map((item) => item.value);
        }
        if (initData?.submitters_settings?.individual_users) {
          bodyRequest['submitters_settings']['individual_users'] = initData.submitters_settings.individual_users.map((item) => item.value);
        }
        if (initData?.submitters_settings?.submitters_order) {
          bodyRequest['submitters_settings']['strict_order'] = initData.submitters_settings.submitters_order;
        }
        if (initData?.submitters_settings?.residents) {
          bodyRequest['submitters_settings']['contacts'] = initData.submitters_settings.residents.map((item) => item.value);
        }

        if (initData?.submitters_settings?.unit) {
          bodyRequest['submitters_settings']['units'] = initData.submitters_settings.unit.map((item) => item.value);
        }

        if (initData?.submitters_settings?.hasOwnProperty('contact_person_submitter_type')) {
          bodyRequest['submitters_settings']['contact_person_submitter_type'] = initData.submitters_settings.contact_person_submitter_type;
        }

        if (initData?.submitters_settings?.unregistered_submitters) {
          bodyRequest['submitters_settings']['unregistered_submitters'] = initData?.submitters_settings?.unregistered_submitters;
        }
      }

      if (initData.will_have_frequency || initData.will_have_period_availability) {
        if (initData.will_have_period_availability) {
          bodyRequest['immediately_initiation'] = initData?.immediately_initiation;

          if (typeof initData?.immediately_initiation === 'boolean' && !initData?.immediately_initiation) {
            let newStartDateTime = new Date();

            bodyRequest['start_date_time'] = '';

            if (initData.start_date) {
              const newLocalStartDate = new Date(initData.start_date);
              newStartDateTime = updateDateTimeSetting(null, newLocalStartDate);
            }
            if (initData.start_time) {
              const newLocalStartTime = new Date(initData.start_time);
              newStartDateTime = updateDateTimeSetting(newStartDateTime, null, newLocalStartTime);
            }

            newStartDateTime = localToUTCDate(newStartDateTime).toISOString();

            bodyRequest['start_date_time'] = newStartDateTime;
          }

          if (initData?.submission_avaliable_settings?.value) {
            bodyRequest['submission_avaliable_settings'] = initData.submission_avaliable_settings.value;
          }

          if (initData.submission_avaliable_settings?.value === 'TIME_FRAME') {
            if (initData.end_date || initData.end_time) {
              const sourceDateTime = bodyRequest.end_date_time ?? new Date();
              let newDateTime = sourceDateTime;

              if (initData?.end_date) {
                const newLocalDate = new Date(initData.end_date);
                newDateTime = updateDateTimeSetting(sourceDateTime, newLocalDate);
              }

              if (initData?.end_time) {
                const newLocalTime = new Date(initData.end_time);
                newDateTime = updateDateTimeSetting(newDateTime, null, newLocalTime);
              }

              newDateTime = localToUTCDate(newDateTime).toISOString();

              bodyRequest['end_date_time'] = newDateTime;
            }
          } else if (initData.submission_avaliable_settings?.value === 'SAME_DAY') {
            // Create end_date_time
            const rangeValue = initData.rage?.value;
            const rangeQuality = initData.rageQuality?.value;
            let endDateTime = dayjs(bodyRequest['start_date_time'] ?? new Date());

            switch (rangeValue) {
              case 'hours': {
                endDateTime = new Date((endDateTime.unix() + 3600 * Number(rangeQuality)) * 1000);
                break;
              }
              case 'days': {
                endDateTime = new Date((endDateTime.unix() + 86400 * Number(rangeQuality)) * 1000);
                break;
              }
              case 'weeks': {
                endDateTime = new Date((endDateTime.unix() + 604800 * Number(rangeQuality)) * 1000);
                break;
              }
              case 'months': {
                endDateTime = new Date((endDateTime.unix() + 2629743 * Number(rangeQuality)) * 1000);
                break;
              }
            }

            bodyRequest['end_date_time'] = dateToISOString(endDateTime);
          }
        } else if (initData.will_have_frequency) {
          const { first_initiation_day, last_initiation_day, frequency_type, time } = initData.frequency ?? {};
          bodyRequest['frequency'] = {
            frequency_type: frequency_type?.value,
          };

          if (initData?.frequency?.frequency_type?.value === 'custom_interval') {
            bodyRequest['frequency'].first_initiation_day = first_initiation_day ? dayjs(first_initiation_day).format(DATE_FORMAT) : undefined;
            bodyRequest['frequency'].last_initiation_day = last_initiation_day ? dayjs(last_initiation_day).format(DATE_FORMAT) : undefined;

            bodyRequest['frequency']['time'] = time ? dayjs(time).format('hh:mm A') : undefined;

            if (initData?.frequency?.type) {
              bodyRequest['frequency']['type'] = initData.frequency.type.value;
            }
            if (initData?.frequency?.times) {
              bodyRequest['frequency']['times'] = initData.frequency.times.value;
            }

            if (initData?.frequency?.type?.value === 'yearly' && initData?.frequency?.months) {
              bodyRequest['frequency']['months'] = initData.frequency.months.map((item, index) => (item ? index + 1 : null)).filter((n) => typeof n === 'number');
              bodyRequest['frequency']['month_day'] = initData.frequency.month_day?.map((item) => item.day);
            }
            if (initData?.frequency?.type?.value === 'weekly' && initData?.frequency?.week_days) {
              bodyRequest['frequency']['week_days'] = initData.frequency.week_days.map((item, index) => (item === true ? index : null)).filter((n) => typeof n === 'number');
            }
            if (initData?.frequency?.type?.value === 'monthly' && initData?.frequency?.month_day) {
              bodyRequest['frequency']['month_day'] = initData.frequency.month_day.map((item) => item.day);
            }
          } else if (initData?.frequency?.frequency_type?.value === 'specific_dates') {
            bodyRequest['frequency']['specific_dates'] = initData.frequency.specific_dates?.map((item) => dayjs(new Date(item.toString())).format(DATE_FORMAT));
            bodyRequest['frequency']['time'] = dayjs(initData.frequency.time).format('hh:mm A');
          }

          if (initData?.submission_deadline_settings) {
            bodyRequest['submission_deadline_settings'] = {};
            if (initData?.submission_deadline_settings?.deadline_type) {
              bodyRequest['submission_deadline_settings']['deadline_type'] = initData.submission_deadline_settings.deadline_type.value;
            }

            if (initData?.submission_deadline_settings?.deadline_type?.value === 'SAME_DAY') {
              bodyRequest['submission_deadline_settings']['time'] = dayjs(initData.submission_deadline_settings.time).format('hh:mm A');
            }

            if (initData?.submission_deadline_settings?.deadline_type?.value === 'TIME_FRAME') {
              if (initData?.submission_deadline_settings?.deadline_period_type) {
                bodyRequest['submission_deadline_settings']['deadline_period_type'] = initData.submission_deadline_settings.deadline_period_type.value;
              }
              if (initData?.submission_deadline_settings?.deadline_period) {
                bodyRequest['submission_deadline_settings']['deadline_period'] = initData.submission_deadline_settings.deadline_period.value;
              }
            }
          }
        }
      }

      if (initData.will_have_frequency === true || initData.will_have_period_availability === true) {
        bodyRequest['notification_settings'] = {};
        bodyRequest['notification_settings']['should_notify_about_submitting_deadline'] = initData?.notification_settings?.should_notify_about_submitting_deadline
          ? initData?.notification_settings?.should_notify_about_submitting_deadline
          : false;
        bodyRequest['notification_settings']['should_notify_about_late_submission'] = initData?.notification_settings?.should_notify_about_late_submission
          ? initData?.notification_settings?.should_notify_about_late_submission
          : false;
        bodyRequest['notification_settings']['should_notify_about_form_activity'] = initData?.notification_settings?.should_notify_about_form_activity
          ? initData?.notification_settings?.should_notify_about_form_activity
          : false;

        if (initData?.notification_settings?.should_notify_about_submitting_deadline) {
          if (initData.notification_settings.submitting_deadline_notification_lead_time) {
            bodyRequest['notification_settings']['submitting_deadline_notification_lead_time'] = initData.notification_settings.submitting_deadline_notification_lead_time.value;
          }
          if (initData.notification_settings.submitting_deadline_notification_type) {
            bodyRequest['notification_settings']['submitting_deadline_notification_type'] = initData.notification_settings.submitting_deadline_notification_type.value;
          }
          bodyRequest['notification_settings']['submitting_deadline_notification_text'] = initData.notification_settings.submitting_deadline_notification_text;
        }

        if (initData?.notification_settings?.should_notify_about_late_submission) {
          if (initData.notification_settings.late_submission_notification_recipients) {
            bodyRequest['notification_settings']['late_submission_notification_recipients'] = initData.notification_settings.late_submission_notification_recipients.map(
              (item) => item.value,
            );
          }
          if (initData.notification_settings.late_submission_notification_type) {
            bodyRequest['notification_settings']['late_submission_notification_type'] = initData.notification_settings.late_submission_notification_type.value;
          }
          bodyRequest['notification_settings']['late_submission_notification_text'] = initData.notification_settings.late_submission_notification_text;
        }

        if (initData?.notification_settings?.should_notify_about_form_activity) {
          if (initData.notification_settings.form_activity_notification_recipients) {
            bodyRequest['notification_settings']['form_activity_notification_recipients'] = initData.notification_settings.form_activity_notification_recipients.map(
              (item) => item.value,
            );
          }
          if (initData.notification_settings.form_activity_notification_type) {
            bodyRequest['notification_settings']['form_activity_notification_type'] = initData.notification_settings.form_activity_notification_type.value;
          }

          if (initData.notification_settings.form_activity_notification_settings) {
            bodyRequest['notification_settings']['form_activity_notification_settings'] = { ...initData.notification_settings.form_activity_notification_settings };
            if (initData.notification_settings.form_activity_notification_settings.notification_time) {
              bodyRequest['notification_settings']['form_activity_notification_settings']['notification_time'] =
                initData.notification_settings.form_activity_notification_settings.notification_time.value;
            }
            if (initData.notification_settings.form_activity_notification_settings.before_or_after_deadline) {
              bodyRequest['notification_settings']['form_activity_notification_settings']['before_or_after_deadline'] =
                initData.notification_settings.form_activity_notification_settings.before_or_after_deadline.value;
            }
          }
        }
      }
    }

    if (formData.filling_type !== 'PUBLIC') {
      if (formData?.recipient_settings?.can_initiator_select_recipients) {
        bodyRequest['recipient_settings'] = {};
        if (initData?.recipient_settings?.facilities) {
          bodyRequest['recipient_settings']['facilities'] = initData.recipient_settings.facilities.map((item) => item.value);
        }
        if (initData?.recipient_settings?.individual_users) {
          bodyRequest['recipient_settings']['individual_users'] = initData.recipient_settings.individual_users.map((item) => item.value);
        }
        if (initData?.recipient_settings?.user_groups) {
          bodyRequest['recipient_settings']['user_groups'] = initData.recipient_settings.user_groups.map((item) => item.value);
        }
      }
    }

    if (formData.filling_type === 'PUBLIC') {
      if (initData.public_folder_id) {
        bodyRequest['public_folder_id'] = initData.public_folder_id.id;
      }

      if (initData[ONE_TIME_INITIATION_OR_ARCHIVING_KEY] || initData[RECURRENT_INITIATION_OR_ARCHIVING_KEY]) {
        bodyRequest.archive_settings = {};
        if (initData[AUTOMATICALLY_ARCHIVE_FORM_KEY]) {
          if (initData[ONE_TIME_INITIATION_OR_ARCHIVING_KEY]) {
            bodyRequest.archive_settings.archive_type = 'ONE_TIME';

            if (initData[ARCHIVE_DATE_KEY] || initData[ARCHIVE_TIME_KEY]) {
              const sourceDateTime = bodyRequest.archive_settings[ARCHIVE_DATE_TIME_KEY] ?? new Date();
              let newDateTime = sourceDateTime;

              if (initData[ARCHIVE_DATE_KEY]) {
                const newLocalDate = new Date(initData[ARCHIVE_DATE_KEY]);
                newDateTime = updateDateTimeSetting(sourceDateTime, newLocalDate);
              }
              if (initData[ARCHIVE_TIME_KEY]) {
                const newLocalTime = new Date(initData[ARCHIVE_TIME_KEY]);
                newDateTime = updateDateTimeSetting(newDateTime, null, newLocalTime);
              }

              newDateTime = localToUTCDate(newDateTime).toISOString();

              bodyRequest.archive_settings[ARCHIVE_DATE_TIME_KEY] = newDateTime;
            }
          } else if (initData[RECURRENT_INITIATION_OR_ARCHIVING_KEY]) {
            if (initData[ARCHIVE_WITH_NEXT_INITIATION_KEY]) {
              bodyRequest.archive_settings = {
                archive_type: 'WHEN_NEW_INITIALIZED',
              };
            } else {
              bodyRequest.archive_settings = {
                archive_type: 'RECURRING',
                time_relation: initData[ARCHIVE_RELATIVE_TO_INITIATION_KEY],
                time_unit: initData[ARCHIVE_TIME_OFFSET_UNIT_KEY],
                time_value: initData[ARCHIVE_TIME_OFFSET_KEY],
              };
            }
          }
        }
      }

      if (initData[ONE_TIME_INITIATION_OR_ARCHIVING_KEY]) {
        if (initData[INITIATE_FORM_IMMEDIATELY_KEY]) {
          bodyRequest[INITIATE_FORM_IMMEDIATELY_KEY] = initData[INITIATE_FORM_IMMEDIATELY_KEY];
        } else {
          if (initData[ONE_TIME_INITIATION_START_DATE_KEY] || initData[ONE_TIME_INITIATION_START_TIME_KEY]) {
            const sourceDateTime = bodyRequest.start_date_time ?? new Date();
            let newDateTime = sourceDateTime;

            if (initData[ONE_TIME_INITIATION_START_DATE_KEY]) {
              const newLocalDate = new Date(initData[ONE_TIME_INITIATION_START_DATE_KEY]);
              newDateTime = updateDateTimeSetting(sourceDateTime, newLocalDate);
            }
            if (initData[ONE_TIME_INITIATION_START_TIME_KEY]) {
              const newLocalTime = new Date(initData[ONE_TIME_INITIATION_START_TIME_KEY]);
              newDateTime = updateDateTimeSetting(newDateTime, null, newLocalTime);
            }

            newDateTime = localToUTCDate(newDateTime).toISOString();

            bodyRequest.start_date_time = newDateTime;
          }
        }
      }

      if (initData[RECURRENT_INITIATION_OR_ARCHIVING_KEY]) {
        const firstInitiationDay = initData[RECURRENT_INITIATION_START_DAY_KEY];
        const lastInitiationDay = initData[RECURRENT_INITIATION_END_DAY_KEY];
        bodyRequest.frequency = {
          frequency_type: 'custom_interval',
          [RECURRENT_INITIATION_START_DAY_KEY]: firstInitiationDay ? dayjs(firstInitiationDay).format(DATE_FORMAT) : null,
          [RECURRENT_INITIATION_END_DAY_KEY]: lastInitiationDay ? dayjs(lastInitiationDay).format(DATE_FORMAT) : null,
          type: initData[RECURRENT_INITIATION_REPEAT_PERIOD_KEY],
          times: initData[RECURRENT_INITIATION_REPEAT_EVERY_KEY],
        };

        if (initData[RECURRENT_INITIATION_REPEAT_PERIOD_KEY] === 'weekly') {
          bodyRequest.frequency.week_days = initData[RECURRENT_INITIATION_REPEAT_DAYS_KEY]?.map((item, index) => (item === true ? index : null)).filter(
            (n) => typeof n === 'number',
          );
        }

        if (initData[RECURRENT_INITIATION_REPEAT_PERIOD_KEY] === 'monthly') {
          bodyRequest.frequency.month_day = initData[RECURRENT_INITIATION_REPEAT_MONTH_DAYS_KEY]?.map((item) => item.day);
        }

        if (initData[RECURRENT_INITIATION_REPEAT_PERIOD_KEY] === 'yearly') {
          bodyRequest.frequency.months = initData[RECURRENT_INITIATION_REPEAT_YEAR_MONTHS_KEY]?.map((item, index) => (item ? index + 1 : null)).filter(
            (n) => typeof n === 'number',
          );
          bodyRequest.frequency.month_day = initData[RECURRENT_INITIATION_REPEAT_MONTH_DAYS_KEY]?.map((item) => item.day);
        }

        if (initData[RECURRENT_INITIATION_TIME_KEY]) {
          bodyRequest.frequency.time = dayjs(initData[RECURRENT_INITIATION_TIME_KEY]).format(DATE_PICKER_TIME_FORMAT);
        }
      }
    }

    return bodyRequest;
  };

  const closeTemplateErrorModal = () => setShowTemplateError(false);

  const handelInitSettings = (value, key) => {
    setInitData((prevState) => {
      let newState = { ...prevState, [key]: value };
      // Set now start date/time for will_have_period_availability
      if (key === 'start_date_time') {
        newState = {
          ...prevState,
          start_date: value,
          start_time: value,
        };
      } else if (key === ARCHIVE_DATE_KEY || key === ARCHIVE_TIME_KEY) {
        newState = {
          ...prevState,
          [key]: value?.toString() ?? undefined,
        };
      } else if (key === RECURRENT_INITIATION_START_DAY_KEY || key === RECURRENT_INITIATION_END_DAY_KEY) {
        if (key === RECURRENT_INITIATION_START_DAY_KEY) {
          newState = {
            ...prevState,
            [key]: value,
          };

          delete newState[RECURRENT_INITIATION_END_DAY_KEY];
        }

        delete newState[RECURRENT_INITIATION_REPEAT_MONTH_DAYS_KEY];
        delete newState[RECURRENT_INITIATION_REPEAT_YEAR_MONTHS_KEY];
      } else if (key === RECURRENT_INITIATION_REPEAT_DAYS_KEY) {
        const isSelected = typeof get(prevState, `[${RECURRENT_INITIATION_REPEAT_DAYS_KEY}][${value}]`) === 'boolean';
        let newSelectedDays = [...(prevState[RECURRENT_INITIATION_REPEAT_DAYS_KEY] ?? [])];

        if (isSelected) {
          newSelectedDays = newSelectedDays.filter((_, index) => index !== value);
        } else {
          newSelectedDays[value] = true;
        }

        newState = {
          ...prevState,
          [key]: newSelectedDays,
        };
      } else if (key === RECURRENT_INITIATION_REPEAT_YEAR_MONTHS_KEY) {
        const isSelected = typeof get(prevState, `[${RECURRENT_INITIATION_REPEAT_DAYS_KEY}][${value}]`) === 'boolean';
        let newSelectedMonths = [...(prevState[RECURRENT_INITIATION_REPEAT_YEAR_MONTHS_KEY] ?? [])];

        if (isSelected) {
          newSelectedMonths = newSelectedMonths.filter((_, index) => index !== value);
        } else {
          newSelectedMonths[value] = true;
        }
        newState = {
          ...prevState,
          [key]: newSelectedMonths,
        };
      } else if (key === ONE_TIME_INITIATION_OR_ARCHIVING_KEY || key === RECURRENT_INITIATION_OR_ARCHIVING_KEY) {
        newState = {
          ...prevState,
          [key]: value,
        };

        delete newState[AUTOMATICALLY_ARCHIVE_FORM_KEY];
      } else if (key === ARCHIVE_WITH_NEXT_INITIATION_KEY) {
        newState = {
          ...prevState,
          [key]: value,
        };

        delete newState[ARCHIVE_TIME_OFFSET_KEY];
        delete newState[ARCHIVE_TIME_OFFSET_UNIT_KEY];
        delete newState[ARCHIVE_RELATIVE_TO_INITIATION_KEY];
      }

      return newState;
    });
  };

  const changeTab = (tab) => setTabIndex(tab.index);

  const handleSubmitSettings = () => {
    const bodyRequest = getBodyRequest();
    initForm(bodyRequest, id)
      .then(() => {
        navigation('/corporate-library');
      })
      .catch((e) => {
        if (e?.response?.data) {
          setError(e.response.data);

          if (e?.response?.data?.type === 'template') {
            setShowTemplateError(true);
          }
        }
      });
  };

  const duplicateElement = useCallback(
    (elementId) => {
      const elementIndex = initData?.form_fields.findIndex((item) => item.id === elementId);
      if (elementIndex !== -1) {
        const element = initData?.form_fields[elementIndex];
        const newElement = JSON.parse(JSON.stringify(element));
        newElement.id = new Date().getTime();

        if (newElement.type === 'text') {
          const elementValue = element.options.value;
          const elementValueInput = element.options.valueInput;
          newElement.options.value = elementValueInput?.length ? elementValueInput : elementValue;
        }

        setInitData((prevState) => {
          const form_fields = [...(prevState?.form_fields ?? [])];
          form_fields.splice(elementIndex + 1, 0, newElement);
          return { ...prevState, form_fields };
        });
      }
    },
    [initData?.form_fields],
  );

  const removeElement = useCallback((elementId) => {
    setInitData((prevState) => {
      const form_fields = prevState?.form_fields?.filter((item) => item.id !== elementId);
      return { ...prevState, form_fields };
    });
  }, []);

  return (
    <Layout title="Form Builder - Settings" pageClassName="form-builder-wrapper-settings init-page">
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <div className="form-builder-aside-left p-0">
          <TabList className="form-builder-aside-tab">
            {tabs.map((item, index) => (
              <Tab disabled={item.isDisable} key={item.index}>
                {item.name}
                {item.hasError && (
                  <>
                    <i data-tooltip-id={`table-tooltip-${index}`} className="icon-icon-info tab__error"></i>
                    <Tooltip opacity={1} id={`table-tooltip-${index}`} place="bottom" content={'Please fill in all required fields'} />
                  </>
                )}
              </Tab>
            ))}
          </TabList>
        </div>
        <div className={`form-builder-setting-tab ${tabIndex === 0 ? 'bg-transparent' : ''}`}>
          {tabs.map((tab) => (
            <TabPanel key={tab.index}>
              {Components(tab, formData, handelInitSettings, initData, setError, errors, tabs, changeTab, handleSubmitSettings, duplicateElement, removeElement)}
            </TabPanel>
          ))}
        </div>
      </Tabs>

      <ErrorModal
        isOpen={showTemplateError}
        closeModal={closeTemplateErrorModal}
        title={'Initialization Form'}
        subtitle={'Something wrong with this form. Please contact your administrator'}
      />
    </Layout>
  );
}

export default ViewFormPage;
