import React from 'react';
import Select from 'react-select';
import { useIsRequiredElement } from '../../../hook/useIsRequiredElement';
import ElementErrorMessage from './components/element-error-message';

export const DropdownElement = function FromElement({ element, handlerChange, error }) {
  const isRequiredElement = useIsRequiredElement(element);
  return (
    <>
      <label htmlFor={element.id} className="form-label">
        {element.options.label} <span className="required">{isRequiredElement ? '*' : ''}</span>
      </label>
      <Select
        classNamePrefix="react-select"
        name={element.options.name}
        id={element.id}
        className={`form-control-select form-control-${element.options.size}`}
        options={element.inputs.map((item) => ({ label: item.label, value: item.id }))}
        onChange={(e) => handlerChange(element, e)}
        readOnly={element.options.readOnly}
        value={element.options.valueInput}
      >
        {/*{element.inputs.map((option, index) => (*/}
        {/*    <option key={index} value={option.id}>{option.value}</option>*/}
        {/*))}*/}
      </Select>
      <ElementErrorMessage message={error} />
      {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}
    </>
  );
};
