import React, { useState, useEffect } from 'react';
import Layout from '../../../layout/default';
import { Breadcrumbs } from '../../../components/library/Breadcrumbs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getSubmission, getSubmissionItem } from '../../../servises/folder/form-avaliable';
import { useAuth } from '../../../container/authContext';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { getSubmissionFacilities, getSubmissionInfo, getSubmissionInitiators, getSubmissionPositions, getSubmissionSubmitters } from '../../../servises/folder/sabmissions';
import ReactPaginate from 'react-paginate';
import { Tooltip } from 'react-tooltip';
import dayjs from 'dayjs';
import PrintModal from '../../../components/init-form/print-modal';
import { DATE_FORMAT } from '../../../constants';
import { dateToISOString } from '../../../utility/dates/dateToISOString';
import { utcToLocalDate } from '../../../utility/dates/utcToLocalDate';
import ExportToPDFModal from './components/ExportToPDFModal';

const getBreadcrumbs = (breadcrumbs) => {
  const list = [];
  getProp(breadcrumbs);

  function getProp(item) {
    if (item) {
      const { id, url, children } = item;
      let formattedItem = { ...item };
      if (id && !url) {
        formattedItem = { ...item, url: url ?? `/corporate-library/forms/submissions?folder_id=${id}` };
      }

      delete formattedItem.children;

      list.push(formattedItem);

      if (children) {
        getProp(children);
      }
    }
  }

  // Fix submissions page breadcrumb
  if (list[0]?.name) {
    list[0].name = 'Submissions';
    list[0].url = '/corporate-library/forms/submissions';
  }

  return list;
};

function SubmissionPage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { corporate_form_id } = useParams();
  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      name: 'Company Library',
      url: '/corporate-library',
    },
    {
      name: 'Submissions',
      url: '/corporate-library/forms/submissions',
    },
  ]);

  const [formsSearch, setFormsSearch] = useState([]);
  const [optionsFacility, setOptionsFacility] = useState([]);
  const [optionsInitiators, setOptionsInitiators] = useState([]);
  const [optionsPositions, setOptionsPositions] = useState([]);
  const [optionsSubmitters, setOptionsSubmitters] = useState([]);
  const [search, setSearch] = useState('');
  const [isFilterMode, setIsFilterMode] = useState(true);
  const [column, setColumn] = useState([]);
  const [filters, setFilters] = useState({
    submission_status: null,
    positions: [],
    submitters: [],
    facilities: [],
    initiator: null,
    initiation_date_before: null,
    initiation_date_after: null,
    submission_date_before: null,
    submission_date_after: null,
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateSubmission, setStartDateSubmission] = useState(null);
  const [endDateSubmission, setEndDateSubmission] = useState(null);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalPage, setTotalPages] = useState(0);
  const [formData, setFormData] = useState(null);
  const [name, setName] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenExportToPDFModal, setIsOpenExportToPDFModal] = useState(false);

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  useEffect(() => {
    setColumn([
      {
        name: 'Submission',
        total: total,
      },
      {
        name: 'Submitters',
      },
      {
        name: 'Facility',
      },
      {
        name: 'Position',
      },
      {
        name: 'Form Initiator',
      },
      {
        name: 'Status',
      },
      {
        name: 'Initiation date',
      },
      {
        name: 'Date received',
      },
      {
        name: 'Submission date',
      },
    ]);
  }, [formsSearch, total]);

  useEffect(() => {
    initPage();
    if (search || isHasFilter()) {
      changeFolder();
    } else {
      setFormsSearch([]);
    }
    // eslint-disable-next-line
  }, [search, filters, page]);
  useEffect(() => {
    setPage(1);
  }, [search, filters]);
  useEffect(() => {
    initPage();

    getSubmissionInfo(corporate_form_id)
      .then((res) => {
        if (res) {
          const { data, breadcrumbs } = res;
          setName(data?.name ?? 'Submission');
          setBreadcrumbs([
            {
              name: 'Company Library',
              url: '/corporate-library',
            },
            ...getBreadcrumbs(breadcrumbs),
          ]);
        }
      })
      .catch((e) => console.log(e));

    // eslint-disable-next-line
  }, [user, corporate_form_id]);

  const isHasFilter = () => {
    return !!(
      filters.positions.length ||
      filters.submitters.length ||
      filters.facilities.length ||
      filters.initiator !== null ||
      filters.submission_status !== null ||
      filters.initiation_date_before !== null ||
      filters.initiation_date_after !== null ||
      filters.submission_date_before !== null ||
      filters.submission_date_after !== null
    );
  };

  const initPage = () => {
    getSubmissionFacilities(corporate_form_id, { get_all: true, status: 'all' })
      .then((res) => {
        setOptionsFacility(res.map((item) => ({ value: item.id, label: item.name })));
      })
      .catch((e) => console.log(e));

    getSubmissionSubmitters(corporate_form_id, {
      get_all: true,
      facilities: filters.facilities ? filters.facilities.map((item) => item.value) : [],
      status: 'all',
    })
      .then((res) => {
        setOptionsSubmitters(res.map((item) => ({ value: item.id, label: item.full_name })));
      })
      .catch((e) => console.log(e));

    getSubmissionInitiators(corporate_form_id, { get_all: true, status: 'all' })
      .then((res) => {
        setOptionsInitiators(res.map((item) => ({ value: item.id, label: item.full_name })));
      })
      .catch((e) => console.log(e));

    getSubmissionPositions(corporate_form_id, {
      get_all: true,
      facilities: filters.facilities ? filters.facilities.map((item) => item.value) : [],
      status: 'all',
    })
      .then((res) => {
        setOptionsPositions(res.map((item) => ({ value: item.id, label: item.name })));
      })
      .catch((e) => console.log(e));
  };

  const goToPrev = (id, type) => {
    if (type === 'root') {
      navigate(id);
    }
    if (type === 'folder') {
      const url = breadcrumbs?.find((item) => item.id === id)?.url;
      url && navigate(url);
    }
  };

  const handleDateChange = (date) => {
    setStartDate(date[0]);
    setEndDate(date[1]);
  };

  const handleDateChangeSubmission = (date) => {
    setStartDateSubmission(date[0]);
    setEndDateSubmission(date[1]);
  };

  useEffect(() => {
    if (endDate) {
      setFilters({
        ...filters,
        initiation_date_after: startDate,
        initiation_date_before: endDate,
      });
    } else {
      setFilters({
        ...filters,
        initiation_date_before: null,
        initiation_date_after: null,
      });
    }
    // eslint-disable-next-line
  }, [endDate, startDate]);
  useEffect(() => {
    if (endDateSubmission) {
      setFilters({
        ...filters,
        submission_date_before: endDateSubmission,
        submission_date_after: startDateSubmission,
      });
    } else {
      setFilters({
        ...filters,
        submission_date_before: null,
        submission_date_after: null,
      });
    }
    // eslint-disable-next-line
  }, [endDateSubmission, startDateSubmission]);

  const clearFilter = () => {
    setFormsSearch([]);
    setSearch('');
    setFilters({
      positions: [],
      submitters: [],
      facilities: [],
      initiator: null,
      initiation_date_before: null,
      initiation_date_after: null,
      submission_date_before: null,
      submission_date_after: null,
      submission_status: null,
    });
    setStartDate(null);
    setEndDate(null);
    setStartDateSubmission(null);
    setEndDateSubmission(null);
  };
  const handleFilter = (value, key) => {
    setFilters(() => {
      const prevState = { ...filters };
      prevState[key] = value;
      return prevState;
    });
  };
  const openModal = (id) => {
    if (id > 0) {
      setFormData(null);
      getSubmissionItem(corporate_form_id, id, { status: 'all' })
        .then((res) => {
          setFormData(res);
          setIsOpen(true);
        })
        .catch((e) => console.log(e));
    }
  };

  // eslint-disable-next-line
  const closeModal = () => {
    setFormData(null);
    setIsOpen(false);
  };

  const changeFolder = () => {
    const bodyRequest = { page: page, search: search };

    if (!search) {
      delete bodyRequest.search;
    }

    bodyRequest['status'] = 'all';

    if (filters.positions?.length) {
      bodyRequest['positions'] = filters.positions.map((item) => item.value);
    }

    if (filters.facilities?.length) {
      bodyRequest['facilities'] = filters.facilities.map((item) => item.value);
    }

    if (filters.initiator) {
      bodyRequest['initiators'] = filters.initiator.map((item) => item.value);
    }

    if (filters.submitters.length) {
      bodyRequest['submitters'] = filters.submitters.map((item) => item.value);
    }

    if (filters.submission_status) {
      bodyRequest['submission_status'] = filters.submission_status.value;
    }

    if (filters.initiation_date_before) {
      bodyRequest['initiation_date_before'] = dateToISOString(filters.initiation_date_before);
    }

    if (filters.initiation_date_after) {
      bodyRequest['initiation_date_after'] = dateToISOString(filters.initiation_date_after);
    }

    if (filters.submission_date_after) {
      bodyRequest['submission_date_after'] = dateToISOString(filters.submission_date_after);
    }

    if (filters.submission_date_before) {
      bodyRequest['submission_date_before'] = dateToISOString(filters.submission_date_before);
    }

    getSubmission(corporate_form_id, bodyRequest)
      .then((res) => {
        setFormsSearch(res.results);
        setTotalPages(res.total_pages);
        setTotal(res.count);
      })
      .catch((e) => console.log(e));
  };

  return (
    <Layout title="Company Library">
      <div className="library">
        <div className="library--header">
          <div className="library--header__main">
            <DndProvider backend={HTML5Backend}>
              <Breadcrumbs breadcrumbs={breadcrumbs} goToLevel={goToPrev} />
            </DndProvider>
            <h2 className="library-title">
              <Link to={'/corporate-library/forms/submissions'} className="mr-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M15.8337 10.0013H4.16699M4.16699 10.0013L10.0003 15.8346M4.16699 10.0013L10.0003 4.16797"
                    stroke="#101828"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
              {name ?? ''}
            </h2>
          </div>
          <div className="library--header__aside">
            <button disabled={!formsSearch?.length} className="btn btn-full" onClick={() => setIsOpenExportToPDFModal(true)}>
              Export to PDF
            </button>
          </div>
        </div>
        <div className="library-filter">
          <div className="library-filter-search form-control-item">
            <input
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              type="text"
              className="form-control"
              placeholder="Search"
            />
            <i className="icon-search"></i>
          </div>
          <div>
            <button className={`btn-outline btn ${isFilterMode ? 'btn-active' : ''}`} onClick={() => setIsFilterMode(!isFilterMode)}>
              <i className="icon-icon-sliders"></i>
            </button>
            {isFilterMode && (
              <button className="btn btn-outline" onClick={clearFilter}>
                Clear All
              </button>
            )}
          </div>
        </div>
        {isFilterMode && (
          <div className="library-filters">
            <div className="library-filters-wrapper">
              <div className="form-item">
                <label className="form-label">Facility</label>
                <Select
                  isMulti
                  options={optionsFacility}
                  value={filters.facilities}
                  onChange={(e) => handleFilter(e, 'facilities')}
                  className="form-control-select"
                  classNamePrefix="react-select-multi"
                ></Select>
              </div>
              <div className="form-item">
                <label className="form-label">Submitters position</label>
                <Select
                  isMulti
                  options={optionsPositions}
                  value={filters.positions}
                  onChange={(e) => handleFilter(e, 'positions')}
                  className="form-control-select"
                  classNamePrefix="react-select-multi"
                ></Select>
              </div>
              <div className="form-item">
                <label className="form-label">Submitters</label>
                <Select
                  isMulti
                  options={optionsSubmitters}
                  value={filters.submitters}
                  onChange={(e) => handleFilter(e, 'submitters')}
                  className="form-control-select"
                  classNamePrefix="react-select-multi"
                ></Select>
              </div>
              <div className="form-item">
                <label className="form-label">Initiators</label>
                <Select
                  options={optionsInitiators}
                  value={filters.initiator}
                  isMulti
                  onChange={(e) => handleFilter(e, 'initiator')}
                  className="form-control-select"
                  classNamePrefix="react-select-multi"
                ></Select>
              </div>
              <div className="form-item">
                <label className="form-label">Submission status</label>
                <Select
                  options={[
                    {
                      value: '',
                      label: 'All',
                    },
                    {
                      value: 'Sent',
                      label: 'Sent',
                    },
                    {
                      value: 'In Progress',
                      label: 'In Progress',
                    },
                    {
                      value: 'Finished',
                      label: 'Finished',
                    },
                  ]}
                  value={filters.submission_status}
                  onChange={(e) => handleFilter(e, 'submission_status')}
                  className="form-control-select"
                  classNamePrefix="react-select"
                ></Select>
              </div>
              <div className="form-item">
                <label className="form-label">Initiation date</label>
                <DatePicker
                  selected={startDate}
                  className="form-control"
                  onChange={handleDateChange}
                  selectsRange
                  startDate={startDate}
                  endDate={endDate}
                  isClearable
                  disabledKeyboardNavigation
                  placeholderText={'Select...'}
                ></DatePicker>
              </div>
              <div className="form-item">
                <label className="form-label">Submission date</label>
                <DatePicker
                  selected={startDateSubmission}
                  className="form-control"
                  onChange={handleDateChangeSubmission}
                  selectsRange
                  startDate={startDateSubmission}
                  endDate={endDateSubmission}
                  isClearable
                  disabledKeyboardNavigation
                  placeholderText={'Select...'}
                ></DatePicker>
              </div>
            </div>
          </div>
        )}

        <>
          {formsSearch?.length > 0 && (
            <div className="table-responsive table-default table-full">
              <div className="table-wrapper" key={'formsSearch-key'}>
                <div className="table">
                  <div className="tr">
                    {column.map((item, index) => (
                      <div className="th" key={`table-th-${item.name}`}>
                        {item.name}
                        {item.tooltip && (
                          <>
                            <i data-tooltip-id={`table-tooltip-${index}`} className="icon-icon-info"></i>
                            <Tooltip opacity={1} id={`table-tooltip-${index}`} place="bottom" content={item.tooltip} />
                          </>
                        )}
                        {!!item.total && <span className="th-total">(Total: {item.total} submissions)</span>}
                      </div>
                    ))}
                  </div>
                  {formsSearch.map((item, index) => (
                    <div
                      key={`${item.submitter}-${item.id}-${index}`}
                      className={`tr ${item.submission_id > 0 ? 'cursor-pointer' : ''}`}
                      onClick={() => openModal(item.submission_id)}
                    >
                      <>
                        <div className="td">{item.name}</div>
                        <div className="td">{item.submitter}</div>
                        <div className="td">{item.facility}</div>
                        <div className="td">{item.position}</div>
                        <div className="td">{item.form_initiator}</div>
                        <div className="td">{item.status}</div>
                        <div className="td">{item.initiation_date ? dayjs(utcToLocalDate(item.initiation_date)).format(DATE_FORMAT) : '-'}</div>
                        <div className="td">{item.date_received ? dayjs(utcToLocalDate(item.date_received)).format(DATE_FORMAT) : '-'}</div>
                        <div className="td">{item.submitted_at ? dayjs(utcToLocalDate(item.submitted_at)).format(DATE_FORMAT) : '-'}</div>
                      </>
                    </div>
                  ))}
                </div>

                {totalPage > 1 && (
                  <ReactPaginate
                    className={'react-pagination'}
                    breakLabel="..."
                    nextLabel="next ->"
                    pageRangeDisplayed={5}
                    forcePage={page - 1}
                    pageCount={totalPage}
                    previousLabel="<- previous"
                    onPageChange={handlePageClick}
                  />
                )}
              </div>
            </div>
          )}
          {/*{!formsSearch.length && !!search &&*/}
          {/*    <>*/}
          {/*        <div className="empty-page">*/}
          {/*            <h3 className="empty-page-title">Unfortunately, nothing was found for your query.</h3>*/}
          {/*            <h4 className="empty-page-subtitle">Try to change the range of keywords. We're here to help you find what you're looking for.</h4>*/}
          {/*        </div>*/}
          {/*    </>*/}
          {/*}*/}
          {!formsSearch?.length && (
            <>
              <div className="empty-page">
                <h3 className="empty-page-title">To view a list of submissions, please use the search by name or filters.</h3>
              </div>
            </>
          )}
        </>
      </div>
      {isOpen && <PrintModal isOpen={isOpen} closeModal={closeModal} formData={formData}></PrintModal>}
      {isOpenExportToPDFModal && <ExportToPDFModal search={search} filters={filters} onClose={() => setIsOpenExportToPDFModal(false)} />}
    </Layout>
  );
}

export default SubmissionPage;
