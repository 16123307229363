import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FromBuilderItem } from './form-item';
import { TitleElement } from './elemments/title-element';
import dayjs from 'dayjs';
import { cloneDeep, get } from 'lodash';
import { DATE_FORMAT } from '../../constants';
import { useUserFormRole } from '../../hook/useUserFormRole';

export const RenderForm = function RenderForm({ formData, formElement, title, handelInitSettings, isPrint, isSubmitter, isDisabled, errors, duplicateElement, removeElement }) {
  const userFormRole = useUserFormRole();

  const [lastIndex, setLastIndex] = useState(null);

  const errorsObj = useMemo(() => {
    return errors?.reduce((acc, item) => {
      const [id, errorMessage] = Object.entries(item)?.flat() ?? [];
      acc[Number(id)] = errorMessage;
      return acc;
    }, {});
  }, [errors]);

  useEffect(() => {
    if (formElement) {
      setLastIndex(formElement.findLastIndex((item) => item.options.canAddNew));
    }
  }, [formElement]);

  const handlerChange = useCallback(
    (field, value, isTable, index, rowIndex) => {
      const formFields = JSON.parse(JSON.stringify(formElement));
      const currentIndex = formElement.findIndex((item) => item.id === field.id);
      if (isTable) {
        formFields.forEach((item) => {
          if (item.type === 'table') {
            if (item.rows?.length) {
              if (!get(item, 'rows[0].cols')) {
                item.rows.forEach((row) => {
                  row['cols'] = item.cols.map((col) => {
                    const { autoSetValueFor, type: colType } = col;
                    if (colType?.value === 'date' || colType?.value === 'time') {
                      let value = null;
                      if (autoSetValueFor === userFormRole) {
                        value = new Date();
                      }
                      return {
                        ...col,
                        value,
                      };
                    }
                    return { value: '', id: col.id, type: col.type };
                  });
                });
              }
            }
          }
        });

        if (!formFields[currentIndex].rows[rowIndex]?.cols) {
          formFields[currentIndex].rows.forEach((row, index) => {
            if (!row['cols']) {
              row['cols'] = formFields[currentIndex].cols.map((col) => {
                const { autoSetValueFor, type: colType } = col;

                if (colType?.value === 'date' || colType?.value === 'time') {
                  let value = null;
                  if (autoSetValueFor === userFormRole) {
                    value = new Date();
                  }
                  return {
                    ...col,
                    value,
                  };
                }

                return { value: '', id: col.id, type: col.type };
              });
            }
          });

          if (formFields[currentIndex]?.rows[rowIndex]?.cols[index]) {
            if (formFields[currentIndex].rows[rowIndex].cols[index].type?.value === 'date') {
              formFields[currentIndex].rows[rowIndex].cols[index]['value'] = formFields[currentIndex].rows[rowIndex].cols[index].editable ? new Date() : null;
            } else if (formFields[currentIndex].rows[rowIndex].cols[index].type?.value === 'time') {
              formFields[currentIndex].rows[rowIndex].cols[index]['value'] = formFields[currentIndex].rows[rowIndex].cols[index].editable ? new Date() : null;
            } else {
              formFields[currentIndex].rows[rowIndex].cols[index]['value'] = value ?? '';
            }
          }
        } else {
          if (formFields[currentIndex].rows[rowIndex]?.cols[index]?.hasOwnProperty('value')) {
            formFields[currentIndex].rows[rowIndex].cols[index]['value'] = value;
          } else {
            formFields.forEach((item) => {
              if (item.type === 'table') {
                if (!item.rows[0]['cols']) {
                  item.rows.forEach((row) => {
                    row['cols'] = item.cols.map((col) => {
                      if (col?.type?.value === 'date') {
                        return {
                          value: col.editable ? new Date() : null,
                          id: col.id,
                          type: col.type,
                          editable: col.editable,
                        };
                      } else if (col?.type?.value === 'time') {
                        return {
                          value: col.editable ? new Date() : null,
                          id: col.id,
                          type: col.type,
                          editable: col.editable,
                        };
                      }
                      return { value: '', id: col.id, type: col.type };
                    });
                  });
                }
              }
            });
          }
        }
      } else if (index >= 0) {
        formFields[currentIndex].inputs[index]['valueInput'] = value;
      } else {
        formFields[currentIndex].options['valueInput'] = value;
      }

      if (formFields[currentIndex].options.enableScroll) {
        try {
          const tableRows = formFields[currentIndex].rows.map((item) => {
            delete item.scrollToRow;
            return item;
          });
          const lastNoEmptyRowIndex = tableRows.findLastIndex((row) => {
            let isNoEmptyRow = false;
            if (row.label !== '' && row.label !== null) {
              isNoEmptyRow = true;
            } else if (row.cols) {
              for (const col of row.cols) {
                if (col?.value !== '' && col?.value !== null) {
                  isNoEmptyRow = true;
                  break;
                }
              }
            }
            return isNoEmptyRow;
          });
          const tableRowsLength = tableRows?.length;
          if (lastNoEmptyRowIndex !== -1) {
            tableRows[lastNoEmptyRowIndex].scrollToRow = true;
          } else {
            tableRows[tableRowsLength - 1].scrollToRow = true;
          }
        } catch (e) {
          console.log(e);
        }
      }

      if (field?.type === 'link' || field?.type === 'checkbox') {
        formFields[currentIndex] = field;
      }

      if (field?.type === 'checkbox' && typeof value === 'boolean' && typeof index === 'number' && formFields[currentIndex].inputs[index]) {
        formFields[currentIndex].inputs[index].valueInput = value;
      }

      handelInitSettings(formFields, 'form_fields');
    },
    [formElement, handelInitSettings, userFormRole],
  );

  const handlerChangeFile = (field, value) => {
    const formFields = JSON.parse(JSON.stringify(formElement));

    const currentIndex = formElement.findIndex((item) => item.id === field.id);
    formFields[currentIndex].options['file'] = value;

    handelInitSettings(formFields, 'form_fields');
  };

  const addNewLogin = (field) => {
    let newElement = { ...JSON.parse(JSON.stringify(field)) };
    const index = formElement.findIndex((item) => item.id === field.id);
    newElement.id = new Date().getTime();
    newElement.options.valueInput = '';
    newElement.options.isClone = true;
    const formFields = JSON.parse(JSON.stringify(formElement));
    formFields.splice(index + 1, 0, newElement);
    handelInitSettings(formFields, 'form_fields');
  };

  const addNewRow = (field) => {
    const formFields = JSON.parse(JSON.stringify(formElement));
    const currentIndex = formElement.findIndex((item) => item.id === field.id);

    const newRow = {
      label: ``,
      newRow: true,
      id: 'new' + new Date().getTime(),
      cols: formFields[currentIndex].cols.map((col) => {
        const colType = col.type?.value;
        if (colType === 'date' || colType === 'time') {
          const { autoSetValueFor } = col;
          let value = null;

          if (autoSetValueFor === userFormRole) {
            value = new Date();
          }

          return { ...col, value };
        } else if (colType === 'dropdown') {
          const { defaultOptionId, options } = col;
          const newCol = cloneDeep(col);
          if (defaultOptionId && Array.isArray(options)) {
            newCol.value = options.find((o) => o.id === defaultOptionId);
          }

          return newCol;
        }

        return { ...col, value: '' };
      }),
    };

    formFields[currentIndex].rows.push(newRow);
    handelInitSettings(formFields, 'form_fields');
  };

  const handlerChangeLabel = (field, value, indexRow) => {
    const formFields = JSON.parse(JSON.stringify(formElement));
    const currentIndex = formElement.findIndex((item) => item.id === field.id);
    formFields[currentIndex].rows[indexRow].label = value;
    handelInitSettings(formFields, 'form_fields');
  };

  return (
    <div className={`${isDisabled ? 'render-form-disabled' : ''} render-form`}>
      {isDisabled && <span className={'is-disabled'}>Deactivated: {dayjs(new Date(isDisabled)).format(DATE_FORMAT)}</span>}
      <TitleElement formData={formData} element={title}></TitleElement>
      {formElement &&
        formElement.map((item, index) => {
          const error = get(errorsObj, `${item.id}`);
          return (
            <FromBuilderItem
              error={error}
              handlerChangeFile={handlerChangeFile}
              lastIndex={lastIndex}
              index={index}
              addNewLogin={addNewLogin}
              isSubmitter={isSubmitter}
              isPrint={isPrint}
              key={item.id}
              element={item}
              handlerChange={handlerChange}
              addNewRow={addNewRow}
              handlerChangeLabel={handlerChangeLabel}
              duplicateElement={duplicateElement}
              removeElement={removeElement}
            />
          );
        })}
    </div>
  );
};
