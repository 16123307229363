import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { createFilesFolder, createFilesGeneralFolder, editFilesFolder, editFilesGeneralFolder } from '../../services/folder/form-avaliable';
import { MultiSelect } from '../other/MultiSelect';
import { getFacilitiesByFileSettings, getFolderInfo } from '../../services/facility';
import { getUsersListFileSettings } from '../../services/user';
import { getCompanyUserGroupListFileSettings } from '../../services/company';
import { useParams } from 'react-router-dom';

function FilesModal({ isOpen, closeModal, folderData, handelCreate, parent_id, company_id, isGeneral }) {
  const [errorFolder, setErrorFolder] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [facilities, setFacilities] = useState([]);
  const [user_groups, setUserGroups] = useState([]);
  const [individual_users, setIndividualUsers] = useState([]);
  const { id } = useParams();

  const [facilityOptions, setFacilityOptions] = useState([]);
  const [usersGroupsOptions, setUsersGroupsOptions] = useState([]);
  const [individualUsersOptions, setIndividualUsersOptions] = useState([]);

  const [folder, setFolder] = useState({
    name: '',
    parent_id: parent_id,
    company_id: company_id,
  });

  const getFacilityOptions = () => {
    getFacilitiesByFileSettings({ company_id: company_id })
      .then((resFacilities) => {
        setFacilityOptions(resFacilities.map((item) => ({ value: item.id, label: item.name })));
      })
      .catch((e) => console.log(e));
  };
  const getUserGroupsOptions = () => {
    if (facilities.length) {
      getCompanyUserGroupListFileSettings({
        get_all: true,
        company_id: company_id,
        status: 'active',
        facilities_ids: [...facilities.map((item) => item.value)],
      })
        .then((res) => {
          const options = res.map((item) => ({ value: item.id, label: item.name }));
          setUsersGroupsOptions([...options]);

          setUserGroups((prevState) => {
            const newArray = [];
            prevState.forEach((item) => {
              if (options.find((option) => option.label === item.label)) {
                newArray.push(item);
              }
            });

            return newArray;
          });
        })
        .catch((e) => console.log(e));
    } else {
      setUsersGroupsOptions([]);
      setUserGroups([]);
    }
  };

  const getIndividualUsersOptions = () => {
    if (facilities.length) {
      getUsersListFileSettings({
        company_id: company_id,
        get_all: true,
        status: 'active',
        facilities_ids: [...facilities.map((item) => item.value)],
        user_groups_ids: [...user_groups.map((item) => item.value)],
      })
        .then((resUsers) => {
          const options = resUsers.map((item) => ({ value: item.id, label: item.last_name + ' ' + item.first_name }));
          setIndividualUsersOptions([...options]);
          setIndividualUsers((prevState) => {
            const newArray = [];
            prevState.forEach((item) => {
              if (options.find((option) => option.value === item.value)) {
                newArray.push(item);
              }
            });
            return newArray;
          });
        })
        .catch((e) => console.log(e));
    } else {
      setIndividualUsersOptions([]);
      setIndividualUsers([]);
    }
  };

  useEffect(() => {
    if (!isGeneral) {
      getFacilityOptions();
    }

    // eslint-disable-next-line
  }, [isOpen, folderData]);

  useEffect(() => {
    if (!isGeneral) {
      getUserGroupsOptions();
    }

    // eslint-disable-next-line
  }, [isOpen, folderData, facilities]);
  useEffect(() => {
    if (!isGeneral) {
      getIndividualUsersOptions();
    }
    // eslint-disable-next-line
  }, [isOpen, folderData, facilities, user_groups]);

  useEffect(() => {
    if (folderData) {
      setIsDisabled(false);
      setFolder({
        name: folderData.name,
        parent_id: parent_id,
      });
      if (!isGeneral) {
        getFolderInfo(folderData.id)
          .then((res) => {
            if (res?.access_settings) {
              if (res?.access_settings?.facilities) {
                setFacilities(res?.access_settings?.facilities.map((item) => ({ value: item.id, label: item.name })));
              }

              if (res?.access_settings?.user_groups) {
                setUserGroups(res?.access_settings?.user_groups.map((item) => ({ value: item.id, label: item.name })));
              }
              if (res?.access_settings?.individual_users) {
                setIndividualUsers(
                  res?.access_settings?.individual_users.map((item) => ({ value: item.id, label: item.last_name + ' ' + item.first_name })),
                );
              }
            }
          })
          .catch((e) => console.log(e));
      }
    } else {
      if (parent_id) {
        getFolderInfo(parent_id)
          .then((res) => {
            if (res?.access_settings) {
              if (res?.access_settings?.facilities) {
                setFacilities(res?.access_settings?.facilities.map((item) => ({ value: item.id, label: item.name })));
              }

              if (res?.access_settings?.user_groups) {
                setUserGroups(res?.access_settings?.user_groups.map((item) => ({ value: item.id, label: item.name })));
              }
              if (res?.access_settings?.individual_users) {
                setIndividualUsers(
                  res?.access_settings?.individual_users.map((item) => ({ value: item.id, label: item.last_name + ' ' + item.first_name })),
                );
              }
            }
          })
          .catch((e) => console.log(e));
      }
    }
    // eslint-disable-next-line
  }, [folderData]);

  const handleChange = (value, key) => {
    setFolder({
      ...folder,
      [key]: value,
    });

    if (errorFolder.hasOwnProperty(key)) {
      delete errorFolder[key];
    }
  };

  const resetForm = () => {
    setFolder({
      name: '',
      parent_id: parent_id,
      company_id: company_id,
    });
    setErrorFolder({});
    setFacilities([]);
    setUserGroups([]);
    setIndividualUsers([]);
    setFacilityOptions([]);
    setUsersGroupsOptions([]);
    setIndividualUsersOptions([]);
  };
  const handelCloseModal = () => {
    closeModal();
    resetForm();
  };

  const handleSubmit = async () => {
    setIsDisabled(true);
    if (!isGeneral) {
      if (folderData) {
        const bodyRequest = {
          ...folder,
          access_settings: {
            facilities: facilities.length ? facilities.map((item) => item.value) : [],
            user_groups: user_groups.length ? user_groups.map((item) => item.value) : [],
            individual_users: individual_users.length ? individual_users.map((item) => item.value) : [],
          },
        };
        editFilesFolder(bodyRequest, folderData.id)
          .then(() => {
            setIsDisabled(false);
            handelCreate();
            handelCloseModal();
          })
          .catch((e) => {
            if (e?.response?.data) {
              setIsDisabled(false);
              setErrorFolder(JSON.parse(JSON.stringify(e.response.data)));
            }
          });
      } else {
        createFilesFolder({
          name: folder.name,
          parent_id: parent_id,
          company_id: company_id,
          access_settings: {
            facilities: facilities.length ? facilities.map((item) => item.value) : [],
            user_groups: user_groups.length ? user_groups.map((item) => item.value) : [],
            individual_users: individual_users.length ? individual_users.map((item) => item.value) : [],
          },
        })
          .then(() => {
            setIsDisabled(false);
            handelCreate();
            handelCloseModal();
          })
          .catch((e) => {
            if (e?.response?.data) {
              setIsDisabled(false);
              setErrorFolder(JSON.parse(JSON.stringify(e.response.data)));
            }
          });
      }
    } else {
      if (folderData) {
        const bodyRequest = {
          ...folder,
        };

        editFilesGeneralFolder(bodyRequest, folderData.id)
          .then(() => {
            setIsDisabled(false);
            handelCreate();
            handelCloseModal();
          })
          .catch((e) => {
            if (e?.response?.data) {
              setIsDisabled(false);
              setErrorFolder(JSON.parse(JSON.stringify(e.response.data)));
            }
          });
      } else {
        createFilesGeneralFolder({
          name: folder.name,
          parent_id: parent_id,
        })
          .then(() => {
            setIsDisabled(false);
            handelCreate();
            handelCloseModal();
          })
          .catch((e) => {
            if (e?.response?.data) {
              setIsDisabled(false);
              setErrorFolder(JSON.parse(JSON.stringify(e.response.data)));
            }
          });
      }
    }
  };

  const handelRemove = (handlerChange, elementToRemove) => {
    handlerChange((prevState) => {
      return prevState.filter((item) => item.value !== elementToRemove.value);
    });
  };

  return (
    <Modal className="modal-default" isOpen={isOpen} onRequestClose={handelCloseModal} contentLabel="Example Modal" ariaHideApp={false}>
      <h2 className="modal-header">
        {folderData ? 'Edit' : 'Add New'} folder{' '}
        <button className="btn btn-icon" onClick={handelCloseModal}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>
      <div className="form-wrapper">
        <h3 className="form-wrapper-title">
          <img src="/images/folder-icon-default.png" alt="" />
          Folder information
        </h3>
        <div className={`form-item ${errorFolder.name && errorFolder.name[0] ? 'form-item-error' : ''}`}>
          <label className="form-label">Folder Name</label>
          <input
            placeholder="Enter the folder name"
            value={folder.name}
            onChange={(e) => handleChange(e.target.value, 'name')}
            type="text"
            className="form-control"
          />
          <span className="form-error">{errorFolder.name && errorFolder.name[0]}</span>
          <span className="form-error">{errorFolder.parent_id && errorFolder.parent_id[0]}</span>
          <span className="form-error">{errorFolder.company_id && errorFolder.company_id[0]}</span>
        </div>
      </div>
      {!isGeneral && (
        <div className="form-wrapper form-settings-inputs">
          <h3 className="form-wrapper-title">
            <img src="/images/settings-icon.svg" alt="" />
            Ability to view files and attach them to forms
          </h3>
          <div
            className={`form-item form-item-select form-control-item-settings ${errorFolder?.access_settings?.facilities ? 'form-item-error' : ''}`}
          >
            <label className="form-label">Facility</label>
            <div className="select-multy-wrapper">
              <span className="select-placeholder">Choose one or several facilities</span>
              <MultiSelect
                isDisabled={!facilityOptions.length || id}
                options={facilityOptions}
                value={facilities}
                onChange={setFacilities}
                placeholder=""
                allName="All facilities"
              ></MultiSelect>
              {facilities.length > 0 && (
                <>
                  <ul>
                    {facilities.map((item, key) => (
                      <li key={key}>
                        {item.label}
                        <button className="btn btn-icon" onClick={() => handelRemove(setFacilities, item)}>
                          <img src="/images/x-circle.svg" alt="" />
                        </button>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
            <span className="form-error">{errorFolder?.access_settings?.facilities && errorFolder.access_settings?.facilities[0]}</span>
          </div>
          <div
            className={`form-item form-item-select form-control-item-settings ${errorFolder?.access_settings?.user_groups ? 'form-item-error' : ''}`}
          >
            <label className="form-label">Users Groups</label>
            <div className="select-multy-wrapper">
              <span className="select-placeholder">Choose one or several users groups</span>
              <MultiSelect
                isDisabled={!usersGroupsOptions.length || id}
                options={usersGroupsOptions}
                value={user_groups}
                onChange={setUserGroups}
                placeholder=""
                allName="All User Groups"
              ></MultiSelect>
              {user_groups.length > 0 && (
                <>
                  <ul>
                    {user_groups.map((item, key) => (
                      <li key={key}>
                        {item.label}
                        <button className="btn btn-icon" onClick={() => handelRemove(setUserGroups, item)}>
                          <img src="/images/x-circle.svg" alt="" />
                        </button>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
            <span className="form-error">{errorFolder?.access_settings?.user_groups && errorFolder.access_settings?.user_groups[0]}</span>
          </div>
          <div
            className={`form-item form-item-select form-control-item-settings ${errorFolder?.access_settings?.individual_users ? 'form-item-error' : ''}`}
          >
            <label className="form-label">Individual Users</label>
            <div className="select-multy-wrapper">
              <span className="select-placeholder">Choose one or several individual users</span>
              <MultiSelect
                isDisabled={!individualUsersOptions.length || id}
                options={individualUsersOptions}
                value={individual_users}
                onChange={setIndividualUsers}
                placeholder=""
                allName="All Individual Users"
              ></MultiSelect>
              {individual_users.length > 0 && (
                <>
                  <ul>
                    {individual_users.map((item, key) => (
                      <li key={key}>
                        {item.label}
                        <button className="btn btn-icon" onClick={() => handelRemove(setIndividualUsers, item)}>
                          <img src="/images/x-circle.svg" alt="" />
                        </button>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
            <span className="form-error">
              {errorFolder?.access_settings?.individual_users && errorFolder.access_settings?.individual_users[0]}
            </span>
          </div>
        </div>
      )}

      <div className="modal-footer">
        <button className="btn btn-outline" onClick={handelCloseModal}>
          Cancel
        </button>
        <button disabled={isDisabled} className="btn" onClick={handleSubmit}>
          Save
        </button>
      </div>
    </Modal>
  );
}

export default FilesModal;
