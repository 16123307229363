import React, { useEffect, useState } from 'react';
// import {MultiSelect} from "../other/MultiSelect";
import InputMask from 'react-input-mask';

export const SubmissionSettingsInitUnregister = function SubmissionSettingsInitUnregister({ formData, handelInitSettings, initData, errors }) {
  const [recipient_settings_init, setRecipientSettingsInit] = useState({});
  // const [facilityOptions,setFacilityOptions] = useState([])
  // const [usersGroupsOptions, setUsersGroupsOptions] = useState([])
  // const [individualUsersOptions, setIndividualUsersOptions] = useState([])
  // eslint-disable-next-line

  // useEffect(() => {
  //     if(formData?.recipient_settings) {
  //         const prevState = formData.recipient_settings
  //         if(prevState.facilities?.length) {
  //             setFacilityOptions(prevState.facilities.map(item => ({value: item.id, label: item.name})))
  //         }
  //         if(prevState.individual_users?.length) {
  //             setIndividualUsersOptions(prevState.individual_users.map(item => ({value: item.id, label: item.first_name + ' ' + item.last_name})))
  //         }
  //
  //         if(prevState.user_groups?.length) {
  //             setUsersGroupsOptions(prevState.user_groups.map(item => ({value: item.id, label: item.name})))
  //         }
  //     }
  // }, [formData])

  useEffect(() => {
    if (initData?.submitters_settings) {
      setRecipientSettingsInit({ ...initData?.submitters_settings });
    }
  }, [initData]);

  // const handelRemove = (key, value) => {
  //     setRecipientSettingsInit(() => {
  //         const prevState = {...recipient_settings_init}
  //         prevState[key] = prevState[key].filter(item => item.value !== value.value)
  //         return prevState
  //     })
  // }
  //
  // const handelSettings = (value, key) => {
  //     setRecipientSettingsInit(() => {
  //         const prevState = {...recipient_settings_init}
  //         prevState[key] = value
  //         return prevState
  //     })
  // }

  const addNewUser = () => {
    const prevState = { ...recipient_settings_init };
    if (!prevState['unregistered_submitters']?.length) {
      prevState['unregistered_submitters'] = [
        {
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
        },
      ];
    }

    prevState['unregistered_submitters'].push({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
    });

    setRecipientSettingsInit(prevState);
    handelInitSettings(prevState, 'submitters_settings');
  };

  const removeUser = (element) => {
    const prevState = { ...recipient_settings_init };

    prevState['unregistered_submitters'] = prevState['unregistered_submitters'].filter((item) => item !== element);

    setRecipientSettingsInit(prevState);
    handelInitSettings(prevState, 'submitters_settings');
  };

  const handelUserSettings = (value, key, index) => {
    const prevState = { ...recipient_settings_init };

    if (!prevState['unregistered_submitters']) {
      prevState['unregistered_submitters'] = [
        {
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
        },
      ];
    }

    prevState['unregistered_submitters'][index][key] = value;
    setRecipientSettingsInit(() => {
      return prevState;
    });
    handelInitSettings(prevState, 'submitters_settings');
  };

  return (
    <div className="form-builder-setting-tab-content">
      <div className="form-builder-recipient-settings">
        <p className="info-text">
          <i className="icon-icon-info"></i> Choose submitters and submission rules.
        </p>
        <span className="form-error mb-2 d-flex">{errors?.non_field_errors && errors.non_field_errors[0]}</span>

        <div className="form-control-item-settings">
          <div className="form-settings-label">
            <label className="form-label form-label-big">Specify Submitters (required)</label>
            <span>Submitters are users to whom you are sending this form completion.</span>
          </div>
          <div className="form-settings-inputs">
            <>
              <div className={'form-item mb-4 form-item-title'}>
                <span className={'d-flex'}>
                  <img src="/images/company-add-icon-3.svg" className={'mr-2'} alt="" />
                  Submitter 1 information
                </span>
              </div>
              <div className={`form-item mb-4`}>
                <label className="form-label">First Name</label>
                <input
                  placeholder="Enter First Name"
                  value={
                    recipient_settings_init['unregistered_submitters'] && recipient_settings_init['unregistered_submitters'][0]
                      ? recipient_settings_init['unregistered_submitters'][0].first_name
                      : ''
                  }
                  onChange={(e) => handelUserSettings(e.target.value, 'first_name', 0)}
                  type="text"
                  className="form-control"
                />
                {/*<img className="danger-icon" src="/images/red-alert.svg" alt=""/>*/}
                <span className="form-error">
                  {errors?.submitters_settings?.unregistered_submitters &&
                    errors?.submitters_settings?.unregistered_submitters[0] &&
                    errors.submitters_settings?.unregistered_submitters[0].first_name &&
                    errors.submitters_settings?.unregistered_submitters[0].first_name[0]}
                </span>
                <span className="form-error">
                  {errors?.submitters_settings?.unregistered_submitters &&
                    errors?.submitters_settings?.unregistered_submitters[0] &&
                    typeof errors?.submitters_settings?.unregistered_submitters[0] === 'string' &&
                    errors?.submitters_settings?.unregistered_submitters[0]}
                </span>
              </div>
              <div className={`form-item mb-4`}>
                <label className="form-label">Last Name</label>
                <input
                  placeholder="Enter Last Name"
                  value={
                    recipient_settings_init['unregistered_submitters'] && recipient_settings_init['unregistered_submitters'][0]
                      ? recipient_settings_init['unregistered_submitters'][0].last_name
                      : ''
                  }
                  onChange={(e) => handelUserSettings(e.target.value, 'last_name', 0)}
                  type="text"
                  className="form-control"
                />
                {/*<img className="danger-icon" src="/images/red-alert.svg" alt=""/>*/}
                <span className="form-error">
                  {errors?.submitters_settings?.unregistered_submitters &&
                    errors?.submitters_settings?.unregistered_submitters[0] &&
                    errors.submitters_settings?.unregistered_submitters[0].last_name &&
                    errors.submitters_settings?.unregistered_submitters[0].last_name[0]}
                </span>
              </div>
              <div className={`form-item  form-item-mail mb-4`}>
                <label className="form-label">
                  Email<span className="optional">optional</span>
                </label>
                {/*<img className="mail-icon"  src="/images/mail-icon.svg" alt=""/>*/}
                <input
                  placeholder="mail@example.com"
                  id="email"
                  value={
                    recipient_settings_init['unregistered_submitters'] && recipient_settings_init['unregistered_submitters'][0]
                      ? recipient_settings_init['unregistered_submitters'][0].email
                      : ''
                  }
                  onChange={(e) => handelUserSettings(e.target.value, 'email', 0)}
                  type="text"
                  className="form-control"
                />
                {/*<img className="danger-icon" src="/images/red-alert.svg" alt=""/>*/}
                <span className="form-error">
                  {errors?.submitters_settings?.unregistered_submitters &&
                    errors?.submitters_settings?.unregistered_submitters[0] &&
                    errors.submitters_settings?.unregistered_submitters[0].email &&
                    errors.submitters_settings?.unregistered_submitters[0].email[0]}
                </span>
              </div>
              <div className={`form-item mb-4`}>
                <label className="form-label">Phone Number</label>
                <InputMask
                  placeholder="XXX-XXX-XXXX"
                  mask="999-999-9999"
                  value={
                    recipient_settings_init['unregistered_submitters'] && recipient_settings_init['unregistered_submitters'][0]
                      ? recipient_settings_init['unregistered_submitters'][0].phone
                      : ''
                  }
                  onChange={(e) => handelUserSettings(e.target.value, 'phone', 0)}
                  className="form-control"
                ></InputMask>

                {/*<input placeholder="XXX-XXX-XXXX" value={user.phone} onChange={(e) => handleChange(e.target.value, 'phone')} type="text" className="form-control" />*/}
                {/*<img className="danger-icon" src="/images/red-alert.svg" alt=""/>*/}
                {/*<span className="form-error">{errorUser.phone && errorUser.phone[0]}</span>*/}
                <span className="form-error">
                  {errors?.submitters_settings?.unregistered_submitters &&
                    errors?.submitters_settings?.unregistered_submitters[0] &&
                    errors.submitters_settings?.unregistered_submitters[0].phone &&
                    errors.submitters_settings?.unregistered_submitters[0].phone[0]}
                </span>
              </div>

              {recipient_settings_init.unregistered_submitters?.slice(1, recipient_settings_init.unregistered_submitters.length).map((item, index) => (
                <div className="mt-4" key={index}>
                  <div className={'form-item mb-4 form-item-title'}>
                    <span className={'d-flex'}>
                      <img src="/images/company-add-icon-3.svg" className={'mr-2'} alt="" />
                      Submitter {index + 2} information{' '}
                      <button onClick={() => removeUser(item, index + 1)} className="ml-2 btn btn-remove btn-icon">
                        <i className="icon-remove"></i>
                      </button>
                    </span>
                  </div>
                  <div className={`form-item mb-4`}>
                    <label className="form-label">First Name</label>
                    <input
                      placeholder="Enter First Name"
                      value={item ? item.first_name : ''}
                      onChange={(e) => handelUserSettings(e.target.value, 'first_name', index + 1)}
                      type="text"
                      className="form-control"
                    />
                    <span className="form-error">
                      {errors?.submitters_settings?.unregistered_submitters[index + 1] &&
                        errors.submitters_settings?.unregistered_submitters[index + 1].first_name &&
                        errors.submitters_settings?.unregistered_submitters[index + 1].first_name[0]}
                    </span>

                    {/*<img className="danger-icon" src="/images/red-alert.svg" alt=""/>*/}
                    {/*<span className="form-error">{errorUser.first_name && errorUser.first_name[0]}</span>*/}
                  </div>
                  <div className={`form-item mb-4`}>
                    <label className="form-label">Last Name</label>
                    <input
                      placeholder="Enter Last Name"
                      value={item ? item.last_name : ''}
                      onChange={(e) => handelUserSettings(e.target.value, 'last_name', index + 1)}
                      type="text"
                      className="form-control"
                    />
                    <span className="form-error">
                      {errors?.submitters_settings?.unregistered_submitters[index + 1] &&
                        errors.submitters_settings?.unregistered_submitters[index + 1].last_name &&
                        errors.submitters_settings?.unregistered_submitters[index + 1].last_name[0]}
                    </span>
                    {/*<img className="danger-icon" src="/images/red-alert.svg" alt=""/>*/}
                    {/*<span className="form-error">{errorUser.last_name && errorUser.last_name[0]}</span>*/}
                  </div>
                  <div className={`form-item  form-item-mail mb-4`}>
                    <label className="form-label">
                      Email<span className="optional">optional</span>
                    </label>
                    {/*<img className="mail-icon"  src="/images/mail-icon.svg" alt=""/>*/}
                    <input
                      placeholder="mail@example.com"
                      id="email"
                      value={item ? item.email : ''}
                      onChange={(e) => handelUserSettings(e.target.value, 'email', index + 1)}
                      type="text"
                      className="form-control"
                    />
                    <span className="form-error">
                      {errors?.submitters_settings?.unregistered_submitters[index + 1] &&
                        errors.submitters_settings?.unregistered_submitters[index + 1].email &&
                        errors.submitters_settings?.unregistered_submitters[index + 1].email[0]}
                    </span>
                    {/*<img className="danger-icon" src="/images/red-alert.svg" alt=""/>*/}
                    {/*<span className="form-error">{errorUser.email && errorUser.email[0]}</span>*/}
                  </div>
                  <div className={`form-item mb-4`}>
                    <label className="form-label">Phone Number</label>
                    <InputMask
                      placeholder="XXX-XXX-XXXX"
                      mask="999-999-9999"
                      value={item ? item.phone : ''}
                      onChange={(e) => handelUserSettings(e.target.value, 'phone', index + 1)}
                      className="form-control"
                    ></InputMask>
                    <span className="form-error">
                      {errors?.submitters_settings?.unregistered_submitters[index + 1] &&
                        errors.submitters_settings?.unregistered_submitters[index + 1].phone &&
                        errors.submitters_settings?.unregistered_submitters[index + 1].phone[0]}
                    </span>
                    {/*<input placeholder="XXX-XXX-XXXX" value={user.phone} onChange={(e) => handleChange(e.target.value, 'phone')} type="text" className="form-control" />*/}
                    {/*<img className="danger-icon" src="/images/red-alert.svg" alt=""/>*/}
                    {/*<span className="form-error">{errorUser.phone && errorUser.phone[0]}</span>*/}
                  </div>
                </div>
              ))}

              <span className="add-facility cursor-pointer" onClick={addNewUser}>
                <i className="icon-plus-svgrepo-com mr-2"></i>Add Unregistered User
              </span>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};
