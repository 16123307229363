import React from 'react';
import { Routes, Route } from 'react-router-dom';
// import Dashboard from "../pages/dashboard";
import FromBuilder from '../pages/from-builder';
import NotFound from '../pages/not-found';
import Settings from '../pages/from-builder/FromBuilderSettings';
import FromBuilderPlaceInFrom from '../pages/from-builder/FromBuilderPlaceInFrom';
import Login from '../pages/auth/login';
import CompanyList from '../pages/company/company-list';
import CompanyProfile from '../pages/company/company-profile';
import FacilityProfile from '../pages/facility/facility-profile';
import UserProfile from '../pages/user/user-profile';
import UserGroupProfile from '../pages/user-group/user-group-profile';
// Guard components
import { ProtectedRoute } from '../components/auth/ProtectedRoute';
import { LoginRoute } from '../components/auth/LoginRoute';
import CorporateLibrary from '../pages/corporate-library';
import UserList from '../pages/user/user-list';
import UserGroupList from '../pages/user-group/user-group-list';
import PositionsList from '../pages/positions/positions-list';
import UnitsList from '../pages/units/units-list';
import ResidentsList from '../pages/residents/residents-list';
import ResidentsProfile from '../pages/residents/residents-profile';
import Confirmation from '../pages/auth/confirmation';
import Forgot from '../pages/auth/forgot';
import FormsAvailableToMe from '../pages/corporate-library/forms-available-to-me';
import Drafts from '../pages/corporate-library/drafts';
import DraftsGeneral from '../pages/general-library/drafts';
import FormsAssignedToMe from '../pages/corporate-library/forms-assigned-to-me';
import ViewFormPage from '../pages/initialization/index';
import Forbidden from '../pages/forbidden';
import FormsInProgress from '../pages/corporate-library/forms-in-progress';
import PublicForms from '../pages/corporate-library/public-forms';
import SendForm from '../pages/initialization/sendForm';
import ForgotConfirmation from '../pages/auth/forgot-confirmation';
import SendPublicForm from '../pages/initialization/sendPublicForm';
import GeneralLibrary from '../pages/general-library';
import FromBuilderPlaceInFromGeneral from '../pages/from-builder/FromBuilderPlaceInFromGeneral';
import ViewGeneralFormPage from '../pages/general-library/general-view-form';
import CorporateLibraryFiles from '../pages/corporate-library/files';
import FilesGeneral from '../pages/general-library/files';
import ReportBuilder from '../pages/report-builder';
import Notifications from '../pages/notifications';
import FormExpired from '../pages/forbidden/form-expired';
import MyProfile from '../pages/profile/index';
// import ProfileForms from "../pages/profile/profile-forms";
// import ProfileFormsTable from "../pages/profile/profile-forms-table";
import UserProfileForms from '../pages/user/profile-forms';
import UserProfileFormsId from '../pages/user/profile-forms-table';
import FormSabmited from '../pages/forbidden/form-sabmited';
import Home from '../pages/home/home';
import CorporateLibrarySubmissionsPage from '../pages/corporate-library/submissions';
import SubmissionPage from '../pages/corporate-library/submission';

function Router() {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <LoginRoute>
            <Login />
          </LoginRoute>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <LoginRoute>
            <Forgot />
          </LoginRoute>
        }
      />
      <Route
        path="/forgot-password/:key"
        element={
          <LoginRoute>
            <ForgotConfirmation />
          </LoginRoute>
        }
      />
      <Route
        path="/login/confirmation/:key"
        element={
          <LoginRoute>
            <Confirmation />
          </LoginRoute>
        }
      />

      <Route path="/" element={<Home />} />

      <Route
        path="form-builder/"
        element={
          <ProtectedRoute>
            <FromBuilder />
          </ProtectedRoute>
        }
      />

      <Route
        path="my-profile/"
        element={
          <ProtectedRoute>
            <MyProfile />
          </ProtectedRoute>
        }
      />
      {/*<Route path="my-profile/forms" element={*/}
      {/*    <ProtectedRoute>*/}
      {/*        <ProfileForms />*/}
      {/*    </ProtectedRoute>*/}
      {/*} />*/}
      {/*<Route path="my-profile/forms/:corporate_form_id" element={*/}
      {/*    <ProtectedRoute>*/}
      {/*        <ProfileFormsTable />*/}
      {/*    </ProtectedRoute>*/}
      {/*} />*/}
      <Route
        path="notifications/"
        element={
          <ProtectedRoute>
            <Notifications />
          </ProtectedRoute>
        }
      />
      <Route
        path="form-builder/constructor"
        element={
          <ProtectedRoute>
            <FromBuilder />
          </ProtectedRoute>
        }
      />
      <Route
        path="form-builder/:id/constructor"
        element={
          <ProtectedRoute>
            <FromBuilder />
          </ProtectedRoute>
        }
      />
      <Route
        path="form-builder/:id/settings"
        element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }
      />
      <Route
        path="form-builder/:id/settings/submission"
        element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }
      />

      <Route
        path="form-builder/:id/settings/recipient"
        element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }
      />
      <Route
        path="form-builder/:id/settings/notification"
        element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }
      />
      <Route
        path="form-builder/:id/place-in-form"
        element={
          <ProtectedRoute>
            <FromBuilderPlaceInFrom />
          </ProtectedRoute>
        }
      />
      <Route
        path="form-builder/:id/place-in-general-form"
        element={
          <ProtectedRoute>
            <FromBuilderPlaceInFromGeneral />
          </ProtectedRoute>
        }
      />
      <Route
        path="initialization-form/:id"
        element={
          <ProtectedRoute>
            <ViewFormPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="general-view-form/:id"
        element={
          <ProtectedRoute>
            <ViewGeneralFormPage />
          </ProtectedRoute>
        }
      />

      <Route
        path="send-form/:id"
        element={
          <ProtectedRoute>
            <SendForm />
          </ProtectedRoute>
        }
      />
      <Route
        path="report-builder"
        element={
          <ProtectedRoute>
            <ReportBuilder />
          </ProtectedRoute>
        }
      />
      <Route path="form-view-contact/:id" element={<SendPublicForm />} />

      <Route path="form-view-unregister/:id" element={<SendPublicForm />} />

      <Route
        path="companies"
        element={
          <ProtectedRoute>
            <CompanyList />
          </ProtectedRoute>
        }
      />
      <Route
        path="company/:id"
        element={
          <ProtectedRoute>
            <CompanyProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path="company/:id/facility/:facility_id"
        element={
          <ProtectedRoute>
            <FacilityProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path="company/:id/user/:user_id"
        element={
          <ProtectedRoute>
            <UserProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path="company/:id/user/:user_id/forms"
        element={
          <ProtectedRoute>
            <UserProfileForms />
          </ProtectedRoute>
        }
      />
      <Route
        path="company/:id/user/:user_id/forms/:corporate_form_id"
        element={
          <ProtectedRoute>
            <UserProfileFormsId />
          </ProtectedRoute>
        }
      />
      <Route
        path="company/:id/user-group/:user_group_id"
        element={
          <ProtectedRoute>
            <UserGroupProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path="company/:id/users/:user_id"
        element={
          <ProtectedRoute>
            <UserProfile />
          </ProtectedRoute>
        }
      />
      {/*<Route path="company/:id/users/:user_id/forms" element={*/}
      {/*    <ProtectedRoute>*/}
      {/*        <UserProfileForms />*/}
      {/*    </ProtectedRoute>*/}
      {/*} />*/}
      <Route
        path="company/:id/users"
        element={
          <ProtectedRoute>
            <UserList />
          </ProtectedRoute>
        }
      />

      <Route
        path="company/:id/residents"
        element={
          <ProtectedRoute>
            <ResidentsList />
          </ProtectedRoute>
        }
      />
      <Route
        path="company/:id/residents/:resident_id"
        element={
          <ProtectedRoute>
            <ResidentsProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path="company/:id/user-groups"
        element={
          <ProtectedRoute>
            <UserGroupList />
          </ProtectedRoute>
        }
      />

      <Route
        path="company/:id/positions"
        element={
          <ProtectedRoute>
            <PositionsList />
          </ProtectedRoute>
        }
      />

      <Route
        path="company/:id/units"
        element={
          <ProtectedRoute>
            <UnitsList />
          </ProtectedRoute>
        }
      />

      <Route
        path="company/:id/user-groups/:user_group_id"
        element={
          <ProtectedRoute>
            <UserGroupProfile />
          </ProtectedRoute>
        }
      />

      <Route
        path="corporate-library/"
        element={
          <ProtectedRoute>
            <CorporateLibrary />
          </ProtectedRoute>
        }
      />
      <Route
        path="corporate-library/:form"
        element={
          <ProtectedRoute>
            <CorporateLibrary />
          </ProtectedRoute>
        }
      />

      <Route
        path="corporate-library/forms/forms-available-to-me"
        element={
          <ProtectedRoute>
            <FormsAvailableToMe />
          </ProtectedRoute>
        }
      />
      <Route
        path="corporate-library/forms/drafts"
        element={
          <ProtectedRoute>
            <Drafts />
          </ProtectedRoute>
        }
      />
      <Route
        path="corporate-library/forms/forms-assigned-to-me"
        element={
          <ProtectedRoute>
            <FormsAssignedToMe />
          </ProtectedRoute>
        }
      />
      <Route
        path="corporate-library/forms/forms-in-progress"
        element={
          <ProtectedRoute>
            <FormsInProgress />
          </ProtectedRoute>
        }
      />
      <Route
        path="corporate-library/forms/public-forms"
        element={
          <ProtectedRoute>
            <PublicForms />
          </ProtectedRoute>
        }
      />
      <Route
        path="corporate-library/forms/submissions/:corporate_form_id"
        element={
          <ProtectedRoute>
            <SubmissionPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="corporate-library/forms/submissions"
        element={
          <ProtectedRoute>
            <CorporateLibrarySubmissionsPage />
          </ProtectedRoute>
        }
      />
      <Route path="form-view/:id" element={<SendForm />} />
      <Route
        path="corporate-library/files"
        element={
          <ProtectedRoute>
            <CorporateLibraryFiles />
          </ProtectedRoute>
        }
      />
      <Route
        path="corporate-library/files/:id"
        element={
          <ProtectedRoute>
            <CorporateLibraryFiles />
          </ProtectedRoute>
        }
      />

      <Route
        path="general-library/"
        element={
          <ProtectedRoute>
            <GeneralLibrary />
          </ProtectedRoute>
        }
      />
      <Route
        path="general-library/forms"
        element={
          <ProtectedRoute>
            <GeneralLibrary />
          </ProtectedRoute>
        }
      />
      <Route
        path="general-library/forms/:id"
        element={
          <ProtectedRoute>
            <GeneralLibrary />
          </ProtectedRoute>
        }
      />
      <Route
        path="general-library/forms/drafts"
        element={
          <ProtectedRoute>
            <DraftsGeneral />
          </ProtectedRoute>
        }
      />
      <Route
        path="general-library/files"
        element={
          <ProtectedRoute>
            <FilesGeneral />
          </ProtectedRoute>
        }
      />
      <Route
        path="general-library/files/:id"
        element={
          <ProtectedRoute>
            <FilesGeneral />
          </ProtectedRoute>
        }
      />
      <Route path="not-found" element={<NotFound />} />
      <Route path="forbidden" element={<Forbidden />} />
      <Route path="form-expired" element={<FormExpired />} />
      <Route path="form-submitted" element={<FormSabmited />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Router;
