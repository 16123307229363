import React, { useEffect, useState } from 'react';
import Layout from '../../layout/default';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../container/authContext';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { getFacilitiesByCompany } from '../../servises/facility';
import { getCompanyUserGroupList } from '../../servises/company';
import UserGroupTab from '../../components/company/user-group-tab';
import UserGroupModal from '../../components/company/user-group-modal';
import { usePermission } from '../../container/permissionContext';
import { redirectToLibrary } from '../../utility';

function CompanyList() {
  const { id } = useParams();
  const [users, setUsers] = useState([]);
  const [userPage, setUserPage] = useState(1);
  const [totalUserPage, setTotalUserPage] = useState([]);
  const [totalUser, setTotalUser] = useState(0);
  const [filtersUser, setFiltersUser] = useState({
    facilities_ids: [],
    status: 'active',
    search: '',
  });
  const [modalIsOpen, setIsOpen] = useState(false);
  const { user } = useAuth();
  const [facilities, setFacilities] = useState([]);
  const [pageBreadcrumbs] = useState([
    {
      url: `/company/${id}`,
      name: `${user.company.name}`,
    },
    {
      url: `/company/${id}/user-groups`,
      name: 'User Group List',
    },
  ]);
  const [defaultIndex, setDefaultIndex] = useState(0);
  const navigation = useNavigate();
  const { isEmployee } = usePermission();
  useEffect(() => {
    if (isEmployee()) {
      redirectToLibrary(navigation);
    }
  }, [isEmployee, navigation]);
  useEffect(() => {
    initPage();
    // eslint-disable-next-line
  }, [id, userPage, filtersUser]);

  const handlePageUserClick = (event) => {
    setUserPage(event.selected + 1);
  };
  const initPage = () => {
    let params = {
      page: userPage,
      ...filtersUser,
    };

    getCompanyUserGroupList(id, params)
      .then((resUsers) => {
        setUsers(resUsers.results);
        setTotalUserPage(resUsers.total_pages);
        setTotalUser(resUsers.count);
      })
      .catch((e) => console.log(e));

    getFacilitiesByCompany(id, 'getAll')
      .then((resFacilities) => {
        setFacilities(resFacilities);
      })
      .catch((e) => console.log(e));
  };
  function closeAddModal() {
    setIsOpen(false);
  }
  const handelCreateUserGroup = () => {
    initPage();
    closeAddModal();
  };
  const openAddModal = () => {
    setIsOpen(true);
  };

  const applyFilters = (filters) => {
    setFiltersUser(filters);
  };
  return (
    <Layout title={'User Group List'} breadcrumbs={pageBreadcrumbs}>
      <div className={`page-wrapper`}>
        <div className="company-profile">
          <div className="company-profile-tab">
            <Tabs selectedIndex={defaultIndex} onSelect={(index) => setDefaultIndex(index)}>
              <TabList>
                <Tab>All User Groups</Tab>
                <Tab onClick={() => navigation(`/company/${id}/positions`)}>Positions</Tab>
                <Tab onClick={() => navigation(`/company/${id}/units`)}>Units</Tab>
              </TabList>

              <TabPanel>
                {/*<h3 className="company-profile-title">Users</h3>*/}
                <UserGroupTab
                  facilities={facilities}
                  handelFilter={applyFilters}
                  filtersUser={filtersUser}
                  totalItem={totalUser}
                  totalPage={totalUserPage}
                  handlePageClick={handlePageUserClick}
                  userGroup={users}
                  openAddModal={openAddModal}
                />
                <UserGroupModal
                  companyName={user.company.name}
                  facilities={facilities}
                  closeModal={closeAddModal}
                  isOpen={modalIsOpen}
                  openModal={openAddModal}
                  handelCreateUserGroup={handelCreateUserGroup}
                />
              </TabPanel>
              <TabPanel>Positions</TabPanel>
              <TabPanel>Units</TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default CompanyList;
