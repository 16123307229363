import React, { memo, useEffect, useState } from 'react';
import Modal from 'react-modal';

const SettingsModal = ({ element, isOpen, onClose, handlerChange }) => {
  const {
    options: { label: optionsLabel },
  } = element;
  let layout = null;

  const [label, setLabel] = useState(optionsLabel);
  // inputs labels
  const [inputs, setInputs] = useState(element.inputs);

  useEffect(() => {
    setLabel(optionsLabel);
  }, [optionsLabel]);

  const removeInput = (item) => {
    setInputs((prevState) => {
      return prevState.filter((label) => label.id !== item.id);
    });
  };

  const addInput = () => {
    setInputs((prevState) => {
      const newState = [...prevState];
      newState.push({
        id: 'new' + new Date().getTime(),
        label: 'New Option',
      });
      return newState;
    });
  };

  const handleUpdateSettings = () => {
    try {
      const newElement = {
        ...element,
        options: {
          ...(element.options ?? {}),
          label: label.replace(/ /g, '-').toLowerCase(),
        },
        inputs,
      };
      handlerChange(newElement);
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  const handleTrimInputLabel = (inputIndex) => {
    setInputs((prevState) => {
      return prevState.map((label, index) => {
        if (inputIndex === index) {
          label.id = label.label.replace(/ /g, '-').toLowerCase();
        }
        return label;
      });
    });
  };

  if (isOpen) {
    layout = (
      <Modal className={'modal-default checkbox-element-settings-modal'} isOpen={isOpen} onRequestClose={onClose} contentLabel="Example Modal" ariaHideApp={false}>
        <h2 className="modal-header">
          <span>{'Checkbox Properties'}</span>
          <button className="btn btn-icon" onClick={onClose}>
            <i className="icon-icon-x" />
          </button>
        </h2>
        <div>
          <div className="property-item w-half">
            <h3 className="form-builder-aside-property-sub-title">Name Label</h3>
            <div className="form-control-item">
              <input
                type="text"
                className="form-control"
                placeholder="Enter text"
                value={label}
                onChange={(e) => {
                  setLabel(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="property-item">
            <h3 className="form-builder-aside-property-sub-title">Name Inputs</h3>
            {inputs.map((item, index) => (
              <div className="form-control-item w-half" key={index}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter text"
                  value={item.label}
                  onChange={(e) => {
                    setInputs((prevState) => {
                      return prevState.map((label, prevIndex) => {
                        if (index === prevIndex) {
                          label.label = e.target.value;
                          label.id = e.target.value.replace(/ /g, '-').toLowerCase() + '-' + new Date().getTime();
                        }
                        return label;
                      });
                    });
                  }}
                  onBlur={() => handleTrimInputLabel(index)}
                />
                {inputs.length > 1 && (
                  <div className="settings-actions">
                    <button className="btn btn-remove btn-icon" onClick={() => removeInput(item)}>
                      <i className="icon-remove"></i>
                    </button>
                  </div>
                )}
              </div>
            ))}
            <button className="btn-link" onClick={() => addInput()}>
              <i className="icon-plus-svgrepo-com"></i>
              Add Choice
            </button>
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn btn-outline" onClick={onClose}>
            Cancel
          </button>
          <button className="btn d-flex" onClick={handleUpdateSettings}>
            Update
          </button>
        </div>
      </Modal>
    );
  }

  return layout;
};

export default memo(SettingsModal);
