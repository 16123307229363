import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EmptyPage } from '../other/EmptyPage';
import UserGroupItem from './user-group-item';
import { activateUserGroupDefault, deactivateUserGroupDefault } from '../../servises/user-group';
function UserGroupTab({ userGroup, openAddModal, handelEdit, onSearch, handelCreateUserGroup }) {
  const { id } = useParams();
  const [userGroupList, setUserGroupList] = useState(userGroup);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setUserGroupList(userGroup);
  }, [userGroup]);
  const handelDeactivate = (userGroup) => {
    console.log(userGroup);
    deactivateUserGroupDefault(id, userGroup.id)
      .then((res) => handelCreateUserGroup())
      .catch((e) => console.log(e));
  };

  const handelActivated = (userGroup) => {
    activateUserGroupDefault(id, userGroup.id)
      .then((res) => handelCreateUserGroup())
      .catch((e) => console.log(e));
  };
  return (
    <>
      <div className="library-filter">
        <div className="library-filter-search form-control-item">
          <input
            type="text"
            className="form-control"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              onSearch(e.target.value);
            }}
            placeholder="Search"
          />
          <i className="icon-search"></i>
        </div>
        <div>
          <button className="btn" onClick={openAddModal}>
            <i className="icon-plus-svgrepo-com mr-2"></i> Add Default User Group
          </button>
        </div>
      </div>

      {userGroupList.length > 0 && (
        <div className="company-profile-facilities-grid company-profile-user-group-grid">
          {userGroupList.map((item) => (
            <UserGroupItem handelDeactivate={handelDeactivate} handelActivated={handelActivated} handelEdit={handelEdit} key={item.id} userGroup={item}></UserGroupItem>
          ))}
        </div>
      )}

      {!userGroupList.length && !search && (
        <EmptyPage
          imageUrl={'/images/empty-user-group.png'}
          title={'At the moment, there are no default user groups'}
          subtitle={'Simply click the button “Add User Group”  to start the user group creation process'}
        />
      )}
      {!userGroupList.length && search && (
        <EmptyPage
          imageUrl={'/images/no-resoult.png'}
          title={'Unfortunately, nothing was found for your query.'}
          subtitle={"Try to change the range of keywords. We're here to help you find what you're looking for."}
        />
      )}
    </>
  );
}

export default UserGroupTab;
