import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { getSubmission } from '../../../servises/folder/form-avaliable';
import { Tooltip } from 'react-tooltip';
import ReactPaginate from 'react-paginate';

const SubmissionsModal = ({ selectedForm, isOpen, closeModal, handelExportDataSelected, company_id }) => {
  const [formsSearch, setFormsSearch] = useState([]);
  const [exportData, setExportData] = useState(null);
  const [isDisable, setIsDisable] = useState(true);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalPage, setTotalPages] = useState(0);
  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  const [column] = useState([
    {
      name: 'Submission',
      total: total,
    },
    {
      name: 'Submitters',
    },
  ]);
  useEffect(() => {
    if (isOpen && selectedForm) {
      changeFolder();
    }
    // eslint-disable-next-line
  }, [isOpen, search]);

  useEffect(() => {
    if (exportData) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  }, [exportData]);

  const changeFolder = () => {
    const bodyRequest = { page: page, search: search, company_id: company_id };

    if (!search) {
      delete bodyRequest.search;
    }

    if (!company_id) {
      delete bodyRequest.company_id;
    }

    if (selectedForm.id) {
      getSubmission(selectedForm.id, bodyRequest)
        .then((res) => {
          setFormsSearch(res.results);
          setTotalPages(res.total_pages);
          setTotal(res.count);
        })
        .catch((e) => console.log(e));
    } else {
      setFormsSearch([]);
    }
  };

  const handelForm = () => {
    handelExportDataSelected(exportData);
    setExportData(null);
    setSearch('');
    closeModal();
  };
  const handelAttach = (folderItem) => {
    setExportData(folderItem);
  };

  return (
    <Modal className="modal-default  modal-with-tabs modal-export pt-6" isOpen={isOpen} onRequestClose={closeModal} ariaHideApp={false}>
      <div className={'modal-header'}>
        <h2 className={'modal-title'}>Select Submission</h2>
        <button className="btn btn-icon" onClick={closeModal}>
          <i className="icon-icon-x"></i>
        </button>
      </div>
      <div className="modal-body ">
        <div className={'library'}>
          <div className="library-filter">
            <div className="library-filter-search form-control-item">
              <input
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                type="text"
                className="form-control"
                placeholder="Search"
              />
              <i className="icon-search"></i>
            </div>
          </div>
          {formsSearch?.length > 0 && (
            <div className="table-responsive table-default table-full">
              <div className="table-wrapper" key={'formsSearch-key'}>
                <div className="table">
                  <div className="tr">
                    {column.map((item, index) => (
                      <div className="th" key={`table-th-${item.name}`}>
                        {item.name}
                        {item.tooltip && (
                          <>
                            <i data-tooltip-id={`table-tooltip-${index}`} className="icon-icon-info"></i>
                            <Tooltip opacity={1} id={`table-tooltip-${index}`} place="bottom" content={item.tooltip} />
                          </>
                        )}
                        {!!item.total && <span className="th-total">(Total: {item.total} submissions)</span>}
                      </div>
                    ))}
                  </div>
                  {formsSearch.map((item, index) => (
                    <div
                      key={`${item.submitter}-${item.id}-${index}`}
                      className={`tr ${item.submission_id > 0 ? 'cursor-pointer' : ''} ${exportData?.submission_id === item?.submission_id ? 'tr-active' : ''}`}
                      onClick={() => handelAttach(item)}
                    >
                      <>
                        <div className="td">{item.name}</div>
                        <div className="td">{item.submitter}</div>
                      </>
                    </div>
                  ))}
                </div>
              </div>
              {totalPage > 1 && (
                <ReactPaginate
                  className={'react-pagination'}
                  breakLabel="..."
                  nextLabel="next ->"
                  pageRangeDisplayed={5}
                  forcePage={page - 1}
                  pageCount={totalPage}
                  previousLabel="<- previous"
                  onPageChange={handlePageClick}
                />
              )}
            </div>
          )}

          {!formsSearch?.length && (
            <>
              <div className="empty-page">
                <h3 className="empty-page-title">To view a list of submissions, please use the search by name or filters.</h3>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn d-flex" onClick={handelForm} disabled={isDisable}>
          Select
        </button>
      </div>
    </Modal>
  );
};

export default SubmissionsModal;
