import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';
import { updateForm } from '../../../servises/form';

const SET_FORM_SUBMITTER_NOTIFICATION_KEY = 'should_submitters_receive_notifications';
const initialNotificationsSettings = {
  submitters_notification_type: '',
  submitters_notification_text: '',
  should_form_initiator_receive_notifications: false,
  should_form_recipients_receive_notifications: false,
  form_initiator_notification_type: '',
  form_initiator_notification_text: '',
  form_recipients_notification_type: '',
  form_recipients_notification_text: '',
  [SET_FORM_SUBMITTER_NOTIFICATION_KEY]: false,
};

export const NotificationSettings = ({ formData }) => {
  const { id } = useParams();
  const navigation = useNavigate();
  const formType = formData?.submission_settings?.filling_type;

  const [notifications_settings, setNotificationsSettings] = useState(initialNotificationsSettings);
  const [errors, setErrors] = useState(null);
  const [notificationOptions] = useState([
    {
      value: 'SMS',
      label: 'SMS',
    },
    {
      value: 'EMAIL',
      label: 'Email',
    },
  ]);

  useEffect(() => {
    if (formData?.notifications_settings?.hasOwnProperty('submitters_notification_type')) {
      setNotificationsSettings((prevState) => {
        const newState = { ...prevState, ...formData.notifications_settings };

        if (!formData.notifications_settings.should_form_initiator_receive_notifications) {
          newState.should_form_initiator_receive_notifications = false;
        }
        if (!formData.notifications_settings.should_form_recipients_receive_notifications) {
          newState.should_form_recipients_receive_notifications = false;
        }

        if (formData.notifications_settings.submitters_notification_type) {
          newState.submitters_notification_type = notificationOptions.find((item) => item.value === formData.notifications_settings.submitters_notification_type);
        }

        if (formData.notifications_settings.form_recipients_notification_type) {
          newState.form_recipients_notification_type = notificationOptions.find((item) => item.value === formData.notifications_settings.form_recipients_notification_type);
        }

        if (formData.notifications_settings.form_initiator_notification_type) {
          newState.form_initiator_notification_type = notificationOptions.find((item) => item.value === formData.notifications_settings.form_initiator_notification_type);
        }

        if (formData.notifications_settings[SET_FORM_SUBMITTER_NOTIFICATION_KEY]) {
          newState[SET_FORM_SUBMITTER_NOTIFICATION_KEY] = formData.notifications_settings[SET_FORM_SUBMITTER_NOTIFICATION_KEY] ?? false;
        }

        return newState;
      });
    }
    // eslint-disable-next-line
  }, [formData]);

  const handelSettings = (value, key) => {
    setNotificationsSettings((prevState) => {
      let newState;
      if (key === SET_FORM_SUBMITTER_NOTIFICATION_KEY && value === false) {
        newState = {
          ...prevState,
          [key]: value,
          submitters_notification_type: '',
          submitters_notification_text: '',
        };
      } else {
        newState = {
          ...prevState,
          [key]: value,
        };
      }
      return newState;
    });
  };

  const onUpdate = () => {
    const formData = {
      notifications_settings: JSON.parse(JSON.stringify(notifications_settings)),
    };

    if (notifications_settings.submitters_notification_type?.value) {
      formData.notifications_settings.submitters_notification_type = notifications_settings.submitters_notification_type.value;
    }
    if (notifications_settings.form_initiator_notification_type?.value) {
      formData.notifications_settings.form_initiator_notification_type = notifications_settings.form_initiator_notification_type.value;
    }

    if (notifications_settings.form_recipients_notification_type?.value) {
      formData.notifications_settings.form_recipients_notification_type = notifications_settings.form_recipients_notification_type.value;
    }

    if (!notifications_settings.should_form_initiator_receive_notifications) {
      delete formData.notifications_settings.form_initiator_notification_text;
      delete formData.notifications_settings.form_initiator_notification_type;
    }

    if (!notifications_settings.should_form_recipients_receive_notifications) {
      delete formData.notifications_settings.form_recipients_notification_text;
      delete formData.notifications_settings.form_recipients_notification_type;
    }

    updateForm(id, formData)
      .then(() => {
        navigation(`/form-builder/${id}/place-in-form`);
      })
      .catch((e) => {
        console.log(e);
        if (e?.response?.data?.notifications_settings) {
          setErrors(e.response.data.notifications_settings);
        }
      });
  };

  return (
    <div className="form-builder-setting-tab-content">
      <div className="form-builder-recipient-settings">
        {formData?.name && (
          <div className="from-builder-title from-builder-title-setting">
            <h1>
              <img src="/images/file-big-icon.png" className="mr-2" alt="" />
              {formData.name}
            </h1>
          </div>
        )}
        <p className="info-text">
          <i className="icon-icon-info"></i> Set rules for notifications regarding Form submissions.
        </p>
        <span className="form-error mb-2 d-flex">{errors?.non_field_errors && errors.non_field_errors[0]}</span>

        {!formData?.submission_settings?.is_one_submitter && (
          <>
            {(formType === 'SPECIAL' || formType === 'MIXED') && (
              <div className="form-control-item-settings">
                <div className="form-settings-label">
                  <label className="form-label form-label-big">Set up the notification to form submitters</label>
                  <span>Set up the notification to users, who were assigned as form submitters </span>
                </div>
                <div className="form-settings-inputs">
                  <div className="d-flex">
                    <div className={`form-control-radio`}>
                      <input
                        type="radio"
                        value="true"
                        name={SET_FORM_SUBMITTER_NOTIFICATION_KEY}
                        onChange={() => handelSettings(true, SET_FORM_SUBMITTER_NOTIFICATION_KEY)}
                        checked={notifications_settings[SET_FORM_SUBMITTER_NOTIFICATION_KEY]}
                      />
                      <span>Yes</span>
                    </div>
                    <div className={`form-control-radio`}>
                      <input
                        type="radio"
                        value="true"
                        name={SET_FORM_SUBMITTER_NOTIFICATION_KEY}
                        onChange={() => handelSettings(false, SET_FORM_SUBMITTER_NOTIFICATION_KEY)}
                        checked={!notifications_settings[SET_FORM_SUBMITTER_NOTIFICATION_KEY]}
                      />
                      <span>No</span>
                    </div>
                  </div>

                  {notifications_settings[SET_FORM_SUBMITTER_NOTIFICATION_KEY] && (
                    <>
                      <div className={`form-control-item-group form-item form-item-select ${errors?.submitters_notification_type ? 'form-item-error' : ''}`}>
                        <label className="form-label">Notification type</label>
                        <div className="select-multy-wrapper">
                          <Select
                            hideSelectedOptions={false}
                            isSearchable={false}
                            options={notificationOptions}
                            onChange={(e) => handelSettings(e, 'submitters_notification_type')}
                            value={notifications_settings.submitters_notification_type}
                            className="form-control-select"
                            placeholder="Sms / Email"
                            classNamePrefix="react-select"
                          />
                          <span className="form-error">{errors?.submitters_notification_type && errors.submitters_notification_type[0]}</span>
                        </div>
                      </div>
                      <div className="form-control-item-group">
                        <label className="form-label">Notification text</label>
                        <textarea
                          onChange={(e) => handelSettings(e.target.value, 'submitters_notification_text')}
                          value={notifications_settings.submitters_notification_text}
                          placeholder={'Enter notification or message text that will be sent to users  who were assigned as form submitters'}
                          className="form-control"
                        />
                        <span className="form-error">{errors?.submitters_notification_text && errors.submitters_notification_text[0]}</span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </>
        )}

        {formData?.submission_settings?.filling_type !== 'MIXED' && (
          <>
            <div className="form-control-item-settings">
              <div className="form-settings-label">
                <label className="form-label form-label-big">Notify recipients about filled form</label>
                <span>Notification sent to recipients when the form has been completely filled out by all submitters</span>
              </div>
              <div className="form-settings-inputs">
                <div className="d-flex">
                  <div className={`form-control-radio`}>
                    <input
                      name="should_form_recipients_receive_notifications"
                      type="radio"
                      value="true"
                      onChange={() => handelSettings(true, 'should_form_recipients_receive_notifications')}
                      checked={notifications_settings.should_form_recipients_receive_notifications}
                    />
                    <span>Yes</span>
                  </div>
                  <div className={`form-control-radio`}>
                    <input
                      name="should_form_recipients_receive_notifications"
                      type="radio"
                      value="true"
                      onChange={() => handelSettings(false, 'should_form_recipients_receive_notifications')}
                      checked={!notifications_settings.should_form_recipients_receive_notifications}
                    />
                    <span>No</span>
                  </div>
                </div>

                {notifications_settings.should_form_recipients_receive_notifications && (
                  <>
                    <div className="form-control-item-group">
                      <label className="form-label">Notification type</label>
                      <div className="select-multy-wrapper">
                        <Select
                          hideSelectedOptions={false}
                          isSearchable={false}
                          options={notificationOptions}
                          value={notifications_settings.form_recipients_notification_type}
                          onChange={(e) => handelSettings(e, 'form_recipients_notification_type')}
                          className="form-control-select"
                          placeholder="Sms / Email"
                          classNamePrefix="react-select"
                        ></Select>
                      </div>
                      <span className="form-error">{errors?.form_recipients_notification_type && errors.form_recipients_notification_type[0]}</span>
                    </div>
                    <div className="form-control-item-group">
                      <label className="form-label">Notification text</label>
                      <textarea
                        onChange={(e) => handelSettings(e.target.value, 'form_recipients_notification_text')}
                        value={notifications_settings.form_recipients_notification_text}
                        placeholder={'Enter notification or message text that will be sent to users  who were assigned as form submitters'}
                        className="form-control"
                      ></textarea>
                      <span className="form-error">{errors?.form_recipients_notification_text && errors.form_recipients_notification_text[0]}</span>
                    </div>
                  </>
                )}
              </div>
            </div>
            {!formData?.submission_settings?.is_one_submitter && (
              <div className="form-control-item-settings">
                <div className="form-settings-label">
                  <label className="form-label form-label-big">Notify initiator about filled form</label>
                  <span>Notification to the form initiator when the form has been completely filled out by all submitters</span>
                </div>
                <div className="form-settings-inputs">
                  <div className="d-flex">
                    <div className={`form-control-radio`}>
                      <input
                        name="should_form_initiator_receive_notifications"
                        type="radio"
                        value="true"
                        onChange={() => handelSettings(true, 'should_form_initiator_receive_notifications')}
                        checked={notifications_settings.should_form_initiator_receive_notifications}
                      />
                      <span>Yes</span>
                    </div>
                    <div className={`form-control-radio`}>
                      <input
                        name="should_form_initiator_receive_notifications"
                        type="radio"
                        value="true"
                        onChange={() => handelSettings(false, 'should_form_initiator_receive_notifications')}
                        checked={notifications_settings.should_form_initiator_receive_notifications === false}
                      />
                      <span>No</span>
                    </div>
                  </div>

                  {notifications_settings.should_form_initiator_receive_notifications && (
                    <>
                      <div className="form-control-item-group">
                        <label className="form-label">Notification type</label>
                        <div className="select-multy-wrapper">
                          <Select
                            hideSelectedOptions={false}
                            isSearchable={false}
                            options={notificationOptions}
                            value={notifications_settings.form_initiator_notification_type}
                            onChange={(e) => handelSettings(e, 'form_initiator_notification_type')}
                            className="form-control-select"
                            placeholder="Sms / Email"
                            classNamePrefix="react-select"
                          ></Select>
                        </div>
                        <span className="form-error">{errors?.form_initiator_notification_type && errors.form_initiator_notification_type[0]}</span>
                      </div>
                      <div className="form-control-item-group">
                        <label className="form-label">Notification text</label>
                        <textarea
                          onChange={(e) => handelSettings(e.target.value, 'form_initiator_notification_text')}
                          value={notifications_settings.form_initiator_notification_text}
                          placeholder={'Enter notification or message text that will be sent to users  who were assigned as form submitters'}
                          className="form-control"
                        ></textarea>
                        <span className="form-error">{errors?.form_initiator_notification_text && errors.form_initiator_notification_text[0]}</span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <div className="form-control-actions">
        <button
          className="btn btn-outline"
          onClick={() => {
            if (formData.submission_settings?.filling_type === 'SPECIAL') {
              navigation(`/form-builder/${id}/settings/recipient`);
            } else if (formData.submission_settings?.filling_type === 'MIXED') {
              navigation(`/form-builder/${id}/settings/submission`);
            }
          }}
        >
          Back to {formData.submission_settings?.filling_type === 'SPECIAL' ? 'Recipient' : 'Submission'} Settings
        </button>
        <button className="btn" onClick={onUpdate}>
          Save Changes
        </button>
      </div>
    </div>
  );
};
