import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { stateUSA } from '../../constants/state';
import Select from 'react-select';
import { createCompany, updateCompany } from '../../servises/company';
import { validateEmail } from '../../utility';
import InputMask from 'react-input-mask';
import { uploadFile } from '../../servises/upload';
import FileLoader from '../other/FileLoader';

function CompanyModal({ isOpen, closeModal, companyData, handelCreateCompany, editMode }) {
  const [form, setForm] = useState({
    name: '',
    nickname: '',
    signer_name: '',
    signer_lastname: '',
    signer_email: '',
    signer_phone: '',
    contact_person_name: '',
    contact_person_lastname: '',
    contact_person_email: '',
    contact_person_phone: '',
    contract_file: null,
    date_added: '',
    facilities: [],
  });

  const [errorForm, setErrorForm] = useState({});
  const [errorFacilities, setErrorFacilities] = useState({});

  const [facility, setFacility] = useState({
    name: '',
    nickname: '',
    license_number: '',
    state_of_location: '',
    address: '',
    monthly_fee: '',
    // signer_name: '',
    // signer_lastname: '',
    // signer_email: '',
    // signer_phone: '',
    // contact_person_name: '',
    // contact_person_lastname: '',
    // contact_person_email: '',
    // contact_person_phone: '',
  });
  const [activeFacility, setActiveFacility] = useState(false);

  useEffect(() => {
    if (companyData) {
      setForm({ ...companyData });
    }
  }, [companyData]);
  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.id]: event.target.value,
    });

    if ((event.target.id === 'signer_email' || event.target.id === 'contact_person_email') && event.target.value) {
      if (validateEmail(event.target.value)) {
        delete errorForm[event.target.id];
      }
      return;
    }

    if (errorForm.hasOwnProperty(event.target.id)) {
      delete errorForm[event.target.id];
    }
  };
  const handleFacilityChange = (value, key) => {
    setFacility({
      ...facility,
      [key]: value,
    });

    if ((key === 'signer_email' || key === 'contact_person_email') && value) {
      if (validateEmail(value)) {
        delete errorFacilities[key];
      }
      return;
    }

    if (errorFacilities.hasOwnProperty(key)) {
      delete errorFacilities[key];
    }
  };

  const [loader, setLoader] = useState(false);

  const handleFile = (event) => {
    setLoader(true);
    uploadFile(event.target.files[0])
      .then((res) => {
        console.log(res);
        setForm({
          ...form,
          [event.target.id]: res,
        });
        setLoader(false);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoader(false);
      });
  };
  const removeFile = () => {
    setForm({
      ...form,
      contract_file: null,
    });
  };
  const resetForm = () => {
    setFacility({
      name: '',
      nickname: '',
      license_number: '',
      state_of_location: '',
      address: '',
      monthly_fee: '',
      // signer_name: '',
      // signer_lastname: '',
      // signer_email: '',
      // signer_phone: '',
      // contact_person_name: '',
      // contact_person_lastname: '',
      // contact_person_email: '',
      // contact_person_phone: '',
    });

    setForm({
      name: '',
      nickname: '',
      signer_name: '',
      signer_lastname: '',
      signer_email: '',
      signer_phone: '',
      contact_person_name: '',
      contact_person_lastname: '',
      contact_person_email: '',
      contact_person_phone: '',
      contract_file: null,
      date_added: '',
      facilities: [],
    });

    setErrorForm({});
    setErrorFacilities({});
  };

  const handleSubmit = () => {
    const formDate = { ...form };

    if (activeFacility) {
      formDate.facilities = [];
      const newFacility = { ...facility };
      newFacility.state_of_location = facility.state_of_location.value;
      formDate.facilities.push(newFacility);
    }

    if (form.contract_file) {
      formDate.contract_file = form.contract_file.uuid;
    }

    if (form.other_files) {
      formDate.other_files = form.other_files.map((item) => item.uuid);
    }

    if (editMode) {
      delete formDate.id;
      updateCompany(companyData.id, formDate)
        .then((res) => {
          handelCreateCompany(res);
        })
        .catch((e) => {
          if (e?.response?.data) {
            setErrorForm(JSON.parse(JSON.stringify(e.response.data)));

            if (e.response.data.hasOwnProperty('facilities')) {
              setErrorFacilities(JSON.parse(JSON.stringify(e.response.data.facilities[0])));
            }
          }
        });
    } else {
      createCompany(formDate)
        .then((res) => {
          handelCreateCompany(res);
          resetForm();
        })
        .catch((e) => {
          if (e?.response?.data) {
            setErrorForm(JSON.parse(JSON.stringify(e.response.data)));

            if (e.response.data.hasOwnProperty('facilities')) {
              setErrorFacilities(JSON.parse(JSON.stringify(e.response.data.facilities[0])));
            }
          }
        });
    }
  };
  const handelCloseModal = () => {
    closeModal();
    resetForm();
  };
  return (
    <Modal className="modal-default" isOpen={isOpen} onRequestClose={handelCloseModal} contentLabel="Example Modal" ariaHideApp={false}>
      <h2 className="modal-header">
        {editMode ? 'Edit' : 'Add New'} Company{' '}
        <button className="btn btn-icon" onClick={handelCloseModal}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>

      <div className="form-wrapper">
        <h3 className="form-wrapper-title">
          <img src="/images/company-add-icon-1.svg" alt="" />
          Company information
        </h3>
        <div className={`form-item ${errorForm.name ? 'form-item-error' : ''}`}>
          <label className="form-label">Company Name</label>
          <input id="name" value={form.name} onChange={handleChange} type="text" className="form-control" placeholder="Enter the official legal name of the company" />
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorForm.name && errorForm.name[0]}</span>
        </div>
        <div className={`form-item ${errorForm.nickname ? 'form-item-error' : ''}`}>
          <label className="form-label">Company Nickname</label>
          <input id="nickname" value={form.nickname} onChange={handleChange} type="text" className="form-control" placeholder="Enter the Brand Name" />
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorForm.nickname && errorForm.nickname[0]}</span>
        </div>
      </div>
      <div className="form-wrapper">
        <h3 className="form-wrapper-title">
          <img src="/images/company-add-icon-2.svg" alt="" />
          Authorized Officer information{' '}
        </h3>
        <div className={`form-item form-item-half ${errorForm.signer_name ? 'form-item-error' : ''}`}>
          <label className="form-label">Authorized Officer Name</label>
          <input id="signer_name" value={form.signer_name} onChange={handleChange} type="text" className="form-control" placeholder="Enter First Name" />
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorForm.signer_name && errorForm.signer_name[0]}</span>
        </div>
        <div className={`form-item form-item-half ${errorForm.signer_lastname ? 'form-item-error' : ''}`}>
          <label className="form-label">Authorized Officer Last Name</label>
          <input id="signer_lastname" value={form.signer_lastname} onChange={handleChange} type="text" className="form-control" placeholder="Enter Last Name" />
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorForm.signer_lastname && errorForm.signer_lastname[0]}</span>
        </div>
        <div className={`form-item form-item-half ${errorForm.signer_phone ? 'form-item-error' : ''}`}>
          <label className="form-label">Authorized Officer Phone Number</label>
          <InputMask id="signer_phone" placeholder="XXX-XXX-XXXX" mask="999-999-9999" value={form.signer_phone} onChange={handleChange} className="form-control"></InputMask>
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorForm.signer_phone && errorForm.signer_phone[0]}</span>
        </div>
        <div className={`form-item form-item-mail form-item-half ${errorForm.signer_email ? 'form-item-error' : ''}`}>
          <label className="form-label">Authorized Officer Email</label>
          <img className="mail-icon" src="/images/mail-icon.svg" alt="" />
          <input id="signer_email" value={form.signer_email} onChange={handleChange} type="email" className="form-control" placeholder="mail@example.com" />
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorForm.signer_email && errorForm.signer_email[0]}</span>
        </div>
        <div className="form-item">
          {!form.contract_file && (
            <div className={`form-control-file form-control-file-default`}>
              <input accept=".jpg,.webp,.jpeg,.png,.pdf,image/*,.doc,.docx,video/mp4,video/x-m4v,video/*" type="file" id="contract_file" onChange={handleFile} />
              <div>
                <img className="icon-icon-upload" src="/images/upload-svg.svg" alt="" />
                <span>Click to upload</span>
                <span className="sub-text">You can upload pdf, word and png / jpg</span>
              </div>

              {/*{file && <span>{file.name}</span>}*/}
            </div>
          )}
          {form.contract_file && (
            <>
              <ul className="file-list">
                <li className={'align-center'}>
                  <div className="d-flex align-center">
                    <img src="/images/icon-file-list.svg" alt="" />
                    <p>{form.contract_file.name}</p>
                  </div>
                  <button className="btn btn-icon" onClick={removeFile}>
                    <i className="icon-remove"></i>
                  </button>
                </li>
              </ul>
            </>
          )}
          {!form.contract_file && <p className="text-secondary mt-2">No files uploaded</p>}
          <span className="form-error">{errorForm.contract_file && errorForm.contract_file[0]}</span>
        </div>
      </div>
      <div className="form-wrapper">
        <h3 className="form-wrapper-title">
          <img src="/images/company-add-icon-3.svg" alt="" />
          Contact Person{' '}
        </h3>
        <div className={`form-item form-item-half ${errorForm.contact_person_name ? 'form-item-error' : ''}`}>
          <label className="form-label">Contact Person Name</label>
          <input id="contact_person_name" value={form.contact_person_name} onChange={handleChange} type="text" className="form-control" placeholder="Enter First Name" />
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorForm.contact_person_name && errorForm.contact_person_name[0]}</span>
        </div>
        <div className={`form-item form-item-half ${errorForm.contact_person_lastname ? 'form-item-error' : ''}`}>
          <label className="form-label">Contact Person Last Name</label>
          <input id="contact_person_lastname" value={form.contact_person_lastname} onChange={handleChange} type="text" className="form-control" placeholder="Enter Last Name" />
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorForm.contact_person_lastname && errorForm.contact_person_lastname[0]}</span>
        </div>
        <div className={`form-item form-item-half ${errorForm.contact_person_phone ? 'form-item-error' : ''}`}>
          <label className="form-label">Contact Person Phone Number</label>
          {/*<input*/}
          {/*    id="contact_person_phone"*/}
          {/*    value={form.contact_person_phone}*/}
          {/*    onChange={handleChange}*/}
          {/*    type="text"*/}
          {/*    className="form-control"*/}
          {/*    placeholder="XXX-XXX-XXXX"*/}
          {/*/>*/}
          <InputMask
            id="contact_person_phone"
            placeholder="XXX-XXX-XXXX"
            mask="999-999-9999"
            value={form.contact_person_phone}
            onChange={handleChange}
            className="form-control"
          ></InputMask>

          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorForm.contact_person_phone && errorForm.contact_person_phone[0]}</span>
        </div>
        <div className={`form-item form-item-mail form-item-half ${errorForm.contact_person_email ? 'form-item-error' : ''}`}>
          <label className="form-label">Contact Person Email</label>
          <img className="mail-icon" src="/images/mail-icon.svg" alt="" />
          <input id="contact_person_email" value={form.contact_person_email} onChange={handleChange} type="email" className="form-control" placeholder="mail@example.com" />
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorForm.contact_person_email && errorForm.contact_person_email[0]}</span>
        </div>
      </div>
      {!editMode && (
        <>
          {!activeFacility && (
            <>
              <span className="add-facility cursor-pointer" onClick={() => setActiveFacility(true)}>
                <i className="icon-plus-svgrepo-com mr-2"></i>Add facility
              </span>
            </>
          )}
          {activeFacility && (
            <div className="form-wrapper">
              <h3 className="form-wrapper-title">
                <img src="/images/company-add-icon-4.svg" alt="" />
                Facility information
              </h3>
              <div className={`form-item ${errorFacilities.name ? 'form-item-error' : ''}`}>
                <label className="form-label">Facility Name</label>
                <input
                  value={facility.name}
                  onChange={(e) => handleFacilityChange(e.target.value, 'name')}
                  type="text"
                  className="form-control"
                  placeholder="Enter the official legal name of the Facility"
                />
                <img className="danger-icon" src="/images/red-alert.svg" alt="" />
                <span className="form-error">{errorFacilities.name && errorFacilities.name[0]}</span>
              </div>
              <div className={`form-item form-item-half ${errorFacilities.nickname ? 'form-item-error' : ''}`}>
                <label className="form-label">Facility Nickname</label>
                <input
                  id="nickname"
                  value={facility.nickname}
                  onChange={(e) => handleFacilityChange(e.target.value, 'nickname')}
                  type="text"
                  className="form-control"
                  placeholder="Enter the Facility Brand Name"
                />
                <img className="danger-icon" src="/images/red-alert.svg" alt="" />
                <span className="form-error">{errorFacilities.nickname && errorFacilities.nickname[0]}</span>
              </div>
              <div className={`form-item form-item-half ${errorFacilities.license_number ? 'form-item-error' : ''}`}>
                <label className="form-label">License Number</label>
                <input
                  value={facility.license_number}
                  onChange={(e) => handleFacilityChange(e.target.value, 'license_number')}
                  type="text"
                  className="form-control"
                  placeholder="Enter the Facility Brand Name"
                />
                <img className="danger-icon" src="/images/red-alert.svg" alt="" />
                <span className="form-error">{errorFacilities.license_number && errorFacilities.license_number[0]}</span>
              </div>

              <div className={`form-item form-item-select ${errorFacilities.state_of_location ? 'form-item-error' : ''}`}>
                <label className="form-label">State</label>
                <Select
                  options={stateUSA}
                  className="form-control-select"
                  classNamePrefix="react-select"
                  value={facility.state_of_location}
                  onChange={(e) => handleFacilityChange(e, 'state_of_location')}
                  placeholder="Select State"
                ></Select>
                <img className="danger-icon" src="/images/red-alert.svg" alt="" />
                <span className="form-error">{errorFacilities.state_of_location && errorFacilities.state_of_location[0]}</span>
              </div>

              <div className={`form-item form-item-mail ${errorFacilities.address ? 'form-item-error' : ''}`}>
                <label className="form-label">Address</label>
                <img className="mail-icon" src="/images/map-pin.svg" alt="" />
                <input
                  value={facility.address}
                  onChange={(e) => handleFacilityChange(e.target.value, 'address')}
                  type="text"
                  className="form-control"
                  placeholder="Enter the legal address"
                />
                <img className="danger-icon" src="/images/red-alert.svg" alt="" />
                <span className="form-error">{errorFacilities.address && errorFacilities.address[0]}</span>
              </div>

              <div className={`form-item ${errorFacilities.monthly_fee ? 'form-item-error' : ''}`}>
                <label className="form-label">Monthly Fee</label>
                <span className="form-control-monthly_fee">
                  <input value={facility.monthly_fee} onChange={(e) => handleFacilityChange(e.target.value, 'monthly_fee')} type="text" className="form-control" />
                </span>
                <img className="danger-icon" src="/images/red-alert.svg" alt="" />
                <span className="form-error">{errorFacilities.monthly_fee && errorFacilities.monthly_fee[0]}</span>
              </div>
            </div>
          )}
        </>
      )}

      <div className="modal-footer">
        <button className="btn btn-outline" onClick={handelCloseModal}>
          Cancel
        </button>
        <button className="btn" onClick={handleSubmit}>
          Save
        </button>
      </div>

      {loader && <FileLoader />}
    </Modal>
  );
}

export default CompanyModal;
