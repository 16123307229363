import { useAuth } from '../../container/authContext';
import { Initials } from '../../utility';
import React, { useEffect, useState, useRef } from 'react';
import { getCompaniesAll } from '../../servises/company';
import useOutsideClick from '../../hook/useOutsideClick';
import { usePermission } from '../../container/permissionContext';
import { useNavigate } from 'react-router-dom';

export const UserMenu = ({ companyUpdated = null }) => {
  const { user, changeCompany } = useAuth();
  const [companiesList, setCompaniesList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  const { isSuperAdmin } = usePermission();
  const navigation = useNavigate();
  const closeMenu = () => {
    setIsOpen(false);
  };

  useOutsideClick(wrapperRef, closeMenu);

  useEffect(() => {
    if (isSuperAdmin()) {
      getCompaniesAll('getAll')
        .then((res) => {
          setCompaniesList(res);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [isSuperAdmin, companyUpdated]);

  const toUserProfile = (e) => {
    setTimeout(() => setIsOpen(false));
    e.preventDefault();
    e.stopPropagation();
    navigation('/my-profile');
  };

  if (user) {
    return (
      <div className="user-menu" ref={wrapperRef}>
        <div className="user-menu-wrapper">
          {user.first_name && (
            <div className="user-logo" onClick={(e) => toUserProfile(e)}>
              <span className="avatar">{Initials(`${user.first_name} ${user.last_name}`)}</span>
            </div>
          )}
          <div className="user-name cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
            <span onClick={(e) => toUserProfile(e)}>{user.first_name ? `${user.first_name} ${user.last_name}` : user.username}</span>
            {isSuperAdmin() && (
              <div className="user-dropdown">
                {user.company && (
                  <span className="text-secondary">
                    {user.company.name} <i className="icon-arrow-down"></i>
                  </span>
                )}
                {!user.company && (
                  <span className="text-secondary">
                    Select a Company <i className="icon-arrow-down"></i>
                  </span>
                )}

                {isOpen && (
                  <div className="user-dropdown-menu">
                    <ul className="user-dropdown-item">
                      <li className={`${!user.company ? 'active' : ''} cursor-pointer`} onClick={() => changeCompany(null)}>
                        No company selected
                      </li>
                      {companiesList.map((item, index) => (
                        <li className={`${user.company?.id === item.id ? 'active' : ''} cursor-pointer`} key={index} onClick={() => changeCompany(item)}>
                          {item.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
            {!isSuperAdmin() && (
              <div className="user-dropdown" onClick={(e) => toUserProfile(e)}>
                {user.company && <span className="text-secondary">{user.company.name}</span>}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  return null;
};
