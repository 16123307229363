import React, { useEffect, useState } from 'react';
import Layout from '../../layout/default';
import { useNavigate, useParams } from 'react-router-dom';
import { getProfile, resetPassword } from '../../services/user';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import dayjs from 'dayjs';
import Modal from 'react-modal';
import { DATE_FORMAT } from '../../constants';
import { utcToLocalDateFormatter } from '../../utility/dates/utcToLocalDate';

function MyProfile() {
  const { user_id } = useParams();
  const [user, setUser] = useState({});
  const [defaultIndex, setDefaultIndex] = useState(0);
  const [moreIsOpen, setMoreIsOpen] = useState(false);
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState([
    {
      url: '/my-profile',
      name: 'My Profile',
    },
  ]);

  const [current_password, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const [showCurrentPassword, setCurrentShowPassword] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    initPage();
    // eslint-disable-next-line
  }, [user_id]);
  const initPage = () => {
    getProfile()
      .then((res) => {
        setUser(res);
        let breadcrumbs = [
          {
            url: `/my-profile`,
            name: `Profile - ${res.first_name} ${res.last_name}`,
          },
        ];
        setPageBreadcrumbs(breadcrumbs);
      })
      .catch((e) => console.log(e));
  };

  const handelCloseModal = () => {
    setModalIsOpen(false);
    setConfirmPassword('');
    setPassword('');
    setCurrentPassword('');
    setShowPassword(false);
    setCurrentShowPassword(false);
    setConfirmShowPassword(false);
  };

  const handelOpenModal = () => {
    setModalIsOpen(true);
    setConfirmPassword('');
    setPassword('');
    setCurrentPassword('');
    setShowPassword(false);
    setCurrentShowPassword(false);
    setConfirmShowPassword(false);
  };

  const handleSubmit = () => {
    const bodyRequest = {
      current_password: current_password,
      password: password,
      password_confirmation: confirm_password,
    };

    setErrors({});

    resetPassword(bodyRequest)
      .then((res) => {
        handelCloseModal();
        setConfirmPassword('');
        setPassword('');
        setCurrentPassword('');
        setShowPassword(false);
        setCurrentShowPassword(false);
        setConfirmShowPassword(false);
      })
      .catch((e) => {
        console.log(e);
        if (e.response.data) {
          setErrors(e.response.data);
        }
      });
  };

  const navigate = useNavigate();

  return (
    <Layout breadcrumbs={pageBreadcrumbs} title={'User Profile'}>
      <div className={`page-wrapper ${user.deleted_at ? 'page-wrapper-deactivated' : ''}`}>
        <div className="company-profile">
          {user.first_name && (
            <>
              <div className="company-profile-header">
                <div className="user-profile-header">
                  <div>
                    <span className="date-add">
                      Date Added: {utcToLocalDateFormatter(user.created_at)}{' '}
                      {user.deleted_at && (
                        <span className="date-add-deactivated">Deactivated: {utcToLocalDateFormatter(user.deleted_at)}</span>
                      )}
                    </span>
                    <h2 className="company-profile-title">
                      {user.last_name} {user.first_name}
                    </h2>
                    <h3 className="company-profile-subtitle">{user.role}</h3>
                  </div>
                </div>
                <div className="company-profile-header-action">
                  <button onClick={handelOpenModal} className={'btn btn-outline btn-outline-secondary d-flex'}>
                    <svg className={'mr-2'} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path
                        d="M5.83333 9.1665V5.83317C5.83333 4.7281 6.27232 3.66829 7.05372 2.88689C7.83512 2.10549 8.89493 1.6665 10 1.6665C11.1051 1.6665 12.1649 2.10549 12.9463 2.88689C13.7277 3.66829 14.1667 4.7281 14.1667 5.83317V9.1665M4.16667 9.1665H15.8333C16.7538 9.1665 17.5 9.9127 17.5 10.8332V16.6665C17.5 17.587 16.7538 18.3332 15.8333 18.3332H4.16667C3.24619 18.3332 2.5 17.587 2.5 16.6665V10.8332C2.5 9.9127 3.24619 9.1665 4.16667 9.1665Z"
                        stroke="#7F56D9"
                        strokeWidth="1.67"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Reset Password
                  </button>
                </div>
              </div>
              <div className="company-profile-tab user-profile-tab">
                <Tabs selectedIndex={defaultIndex} onSelect={(index) => setDefaultIndex(index)}>
                  <TabList className={'d-none'}>
                    <Tab>Main Information</Tab>
                    <Tab onClick={() => navigate('/my-profile/forms')}>Forms</Tab>
                  </TabList>

                  <TabPanel>
                    <h3 className="company-profile-title">
                      <img src="/images/company-add-icon-3.svg" className="mr-2" alt="" />
                      Personal Information
                    </h3>
                    <div className="user-profile-tab-content">
                      <div className="user-profile-tab-item">
                        <h4 className="title">Facility Name:</h4>
                        <p>
                          <strong>{user.facility?.name ? user.facility?.name : '----'}</strong>
                        </p>

                        <h4 className="title">Position:</h4>
                        <p>
                          <strong>{user.position?.name ? user.position?.name : '----'}</strong>
                        </p>
                      </div>
                      <div className="user-profile-tab-item user-profile-tab-user-group">
                        <div>
                          <h4 className="title">Users Group:</h4>
                          {user.user_groups.map(
                            (item, index) =>
                              index < 3 && (
                                <p key={item.id}>
                                  <strong>{item.name}</strong>
                                </p>
                              ),
                          )}

                          {user.user_groups.length > 3 && (
                            <>
                              {moreIsOpen && (
                                <div className="more-group">
                                  {user.user_groups.map(
                                    (item, index) =>
                                      index >= 3 && (
                                        <p key={item.id}>
                                          <strong>{item.name}</strong>
                                        </p>
                                      ),
                                  )}
                                </div>
                              )}
                              <span className="show-more cursor-pointer" onClick={() => setMoreIsOpen(!moreIsOpen)}>
                                {!moreIsOpen ? `${user.user_groups.length - 3} more...` : 'Show Less'}
                              </span>
                            </>
                          )}

                          {!user.user_groups.length && '----'}
                        </div>
                        {/*<div>*/}
                        {/*    <button className="btn btn-outline">Change group</button>*/}
                        {/*</div>*/}
                      </div>
                      <div className="user-profile-tab-item ">
                        <h4 className="title">Email:</h4>
                        <p>
                          <strong>{user.email ? user.email : '----'}</strong>
                        </p>

                        <h4 className="title">Phone Number:</h4>
                        <p>
                          <strong>{user.phone ? user.phone : '----'}</strong>
                        </p>
                      </div>
                    </div>

                    <h3 className="company-profile-title">
                      <img src="/images/settings-icon.svg" className="mr-2" alt="" />
                      System Information
                    </h3>
                    <div className="user-profile-tab-content">
                      <div className="user-profile-tab-item ">
                        {user.creator && (
                          <>
                            <h4 className="title">Added by:</h4>
                            <p>
                              <strong>
                                {user.creator.last_name} {user.creator.first_name}
                              </strong>
                            </p>
                          </>
                        )}

                        <h4 className="title">Date Added:</h4>
                        <p>
                          <strong>{dayjs(user.created_at).format(DATE_FORMAT)}</strong>
                        </p>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <h3 className="company-profile-title">Forms</h3>
                  </TabPanel>
                </Tabs>

                <Modal
                  className="modal-default modal-reset"
                  isOpen={modalIsOpen}
                  onRequestClose={handelCloseModal}
                  contentLabel="Example Modal"
                  ariaHideApp={false}
                >
                  <h2 className="modal-header">
                    Reset Password{' '}
                    <button className="btn btn-icon" onClick={handelCloseModal}>
                      <i className="icon-icon-x"></i>
                    </button>
                  </h2>

                  <div className={'form-wrapper login-page'}>
                    <div className="form-control-item">
                      <label className="form-label"> Current Password</label>
                      <div className="form-control-item-login">
                        <span className="icon-login">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path
                              d="M5.83333 9.1665V5.83317C5.83333 4.7281 6.27232 3.66829 7.05372 2.88689C7.83512 2.10549 8.89493 1.6665 10 1.6665C11.1051 1.6665 12.1649 2.10549 12.9463 2.88689C13.7277 3.66829 14.1667 4.7281 14.1667 5.83317V9.1665M4.16667 9.1665H15.8333C16.7538 9.1665 17.5 9.9127 17.5 10.8332V16.6665C17.5 17.587 16.7538 18.3332 15.8333 18.3332H4.16667C3.24619 18.3332 2.5 17.587 2.5 16.6665V10.8332C2.5 9.9127 3.24619 9.1665 4.16667 9.1665Z"
                              stroke="#98A2B3"
                              strokeWidth="1.67"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <input
                          placeholder={'Enter your current password'}
                          value={current_password}
                          onChange={(e) => setCurrentPassword(e.target.value)}
                          type={showConfirmPassword ? 'text' : 'password'}
                          className="form-control"
                        />
                        <span className="show-password cursor-pointer" onClick={() => setConfirmShowPassword(!showConfirmPassword)}>
                          {!showConfirmPassword && (
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path
                                d="M12 5.24951C4.5 5.24951 1.5 12.0002 1.5 12.0002C1.5 12.0002 4.5 18.7495 12 18.7495C19.5 18.7495 22.5 12.0002 22.5 12.0002C22.5 12.0002 19.5 5.24951 12 5.24951Z"
                                stroke="#98A2B3"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z"
                                stroke="#98A2B3"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                          {showConfirmPassword && (
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path
                                d="M10.4955 4.82764C11.1525 4.68702 11.8252 4.61673 12.5 4.61818C19.1818 4.61818 23 11.6 23 11.6C22.4206 12.5911 21.7296 13.5241 20.9382 14.384M14.5236 13.4502C14.2615 13.7074 13.9453 13.9137 13.5941 14.0568C13.2428 14.1999 12.8636 14.2769 12.4791 14.2831C12.0946 14.2893 11.7127 14.2246 11.3561 14.0929C10.9995 13.9613 10.6756 13.7653 10.4037 13.5166C10.1317 13.268 9.91738 12.9719 9.77335 12.6459C9.62932 12.3199 9.55859 11.9707 9.56538 11.6191C9.57216 11.2676 9.65632 10.9209 9.81284 10.5997C9.96935 10.2786 10.195 9.98951 10.4764 9.74982M2 2L23 21.2M18.17 16.784C16.5383 17.9212 14.5514 18.5512 12.5 18.5818C5.81818 18.5818 2 11.6 2 11.6C3.18735 9.57693 4.83417 7.8094 6.83 6.416L18.17 16.784Z"
                                stroke="#98A2B3"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </span>
                      </div>
                      <div className={'form-error mt-1'}>{errors.current_password && errors.current_password[0]}</div>
                    </div>

                    <div className="form-control-item">
                      <label className="form-label">New Password</label>
                      <div className="form-control-item-login">
                        <span className="icon-login">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path
                              d="M5.83333 9.1665V5.83317C5.83333 4.7281 6.27232 3.66829 7.05372 2.88689C7.83512 2.10549 8.89493 1.6665 10 1.6665C11.1051 1.6665 12.1649 2.10549 12.9463 2.88689C13.7277 3.66829 14.1667 4.7281 14.1667 5.83317V9.1665M4.16667 9.1665H15.8333C16.7538 9.1665 17.5 9.9127 17.5 10.8332V16.6665C17.5 17.587 16.7538 18.3332 15.8333 18.3332H4.16667C3.24619 18.3332 2.5 17.587 2.5 16.6665V10.8332C2.5 9.9127 3.24619 9.1665 4.16667 9.1665Z"
                              stroke="#98A2B3"
                              strokeWidth="1.67"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <input
                          placeholder={'Enter the password'}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          type={showPassword ? 'text' : 'password'}
                          className="form-control"
                        />
                        <span className="show-password cursor-pointer" onClick={() => setShowPassword(!showPassword)}>
                          {!showPassword && (
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path
                                d="M12 5.24951C4.5 5.24951 1.5 12.0002 1.5 12.0002C1.5 12.0002 4.5 18.7495 12 18.7495C19.5 18.7495 22.5 12.0002 22.5 12.0002C22.5 12.0002 19.5 5.24951 12 5.24951Z"
                                stroke="#98A2B3"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z"
                                stroke="#98A2B3"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                          {showPassword && (
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path
                                d="M10.4955 4.82764C11.1525 4.68702 11.8252 4.61673 12.5 4.61818C19.1818 4.61818 23 11.6 23 11.6C22.4206 12.5911 21.7296 13.5241 20.9382 14.384M14.5236 13.4502C14.2615 13.7074 13.9453 13.9137 13.5941 14.0568C13.2428 14.1999 12.8636 14.2769 12.4791 14.2831C12.0946 14.2893 11.7127 14.2246 11.3561 14.0929C10.9995 13.9613 10.6756 13.7653 10.4037 13.5166C10.1317 13.268 9.91738 12.9719 9.77335 12.6459C9.62932 12.3199 9.55859 11.9707 9.56538 11.6191C9.57216 11.2676 9.65632 10.9209 9.81284 10.5997C9.96935 10.2786 10.195 9.98951 10.4764 9.74982M2 2L23 21.2M18.17 16.784C16.5383 17.9212 14.5514 18.5512 12.5 18.5818C5.81818 18.5818 2 11.6 2 11.6C3.18735 9.57693 4.83417 7.8094 6.83 6.416L18.17 16.784Z"
                                stroke="#98A2B3"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </span>
                      </div>
                      <div className={'form-error mt-1'}>{errors.password && errors.password[0]}</div>
                      <span className={'info'}>Minimum 5 characters</span>
                    </div>

                    <div className="form-control-item">
                      <label className="form-label">Confirm New Password</label>
                      <div className="form-control-item-login">
                        <span className="icon-login">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path
                              d="M5.83333 9.1665V5.83317C5.83333 4.7281 6.27232 3.66829 7.05372 2.88689C7.83512 2.10549 8.89493 1.6665 10 1.6665C11.1051 1.6665 12.1649 2.10549 12.9463 2.88689C13.7277 3.66829 14.1667 4.7281 14.1667 5.83317V9.1665M4.16667 9.1665H15.8333C16.7538 9.1665 17.5 9.9127 17.5 10.8332V16.6665C17.5 17.587 16.7538 18.3332 15.8333 18.3332H4.16667C3.24619 18.3332 2.5 17.587 2.5 16.6665V10.8332C2.5 9.9127 3.24619 9.1665 4.16667 9.1665Z"
                              stroke="#98A2B3"
                              strokeWidth="1.67"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <input
                          placeholder={'Repeat the password'}
                          value={confirm_password}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          type={showCurrentPassword ? 'text' : 'password'}
                          className="form-control"
                        />
                        <span className="show-password cursor-pointer" onClick={() => setCurrentShowPassword(!showCurrentPassword)}>
                          {!showCurrentPassword && (
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path
                                d="M12 5.24951C4.5 5.24951 1.5 12.0002 1.5 12.0002C1.5 12.0002 4.5 18.7495 12 18.7495C19.5 18.7495 22.5 12.0002 22.5 12.0002C22.5 12.0002 19.5 5.24951 12 5.24951Z"
                                stroke="#98A2B3"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z"
                                stroke="#98A2B3"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                          {showCurrentPassword && (
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path
                                d="M10.4955 4.82764C11.1525 4.68702 11.8252 4.61673 12.5 4.61818C19.1818 4.61818 23 11.6 23 11.6C22.4206 12.5911 21.7296 13.5241 20.9382 14.384M14.5236 13.4502C14.2615 13.7074 13.9453 13.9137 13.5941 14.0568C13.2428 14.1999 12.8636 14.2769 12.4791 14.2831C12.0946 14.2893 11.7127 14.2246 11.3561 14.0929C10.9995 13.9613 10.6756 13.7653 10.4037 13.5166C10.1317 13.268 9.91738 12.9719 9.77335 12.6459C9.62932 12.3199 9.55859 11.9707 9.56538 11.6191C9.57216 11.2676 9.65632 10.9209 9.81284 10.5997C9.96935 10.2786 10.195 9.98951 10.4764 9.74982M2 2L23 21.2M18.17 16.784C16.5383 17.9212 14.5514 18.5512 12.5 18.5818C5.81818 18.5818 2 11.6 2 11.6C3.18735 9.57693 4.83417 7.8094 6.83 6.416L18.17 16.784Z"
                                stroke="#98A2B3"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </span>
                      </div>
                      <div className={'form-error mt-1'}>{errors.password_confirmation && errors.password_confirmation[0]}</div>
                      <span className={'info'}>Minimum 5 characters</span>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button className="btn btn-outline" onClick={handelCloseModal}>
                      Cancel
                    </button>
                    <button className="btn" onClick={handleSubmit}>
                      Save Changes
                    </button>
                  </div>
                </Modal>
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default MyProfile;
