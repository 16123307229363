import React, { useEffect, useState } from 'react';
import { SubmissionSettingsInitPublic } from './submition-settings-public';
import { SubmissionSettingsInitUnregister } from './submition-settings-unregister';
import { SubmissionSettingsInitSystem } from './submition-settings-system';
import { SubmissionSettingsInitContact } from './submition-settings-contact';
import { SubmissionSettingsInitAvailability } from './submission-settings-availability';
import { SubmissionSettingsInitFrequency } from './submition-settings-frequency';

export const SubmissionSettingsInit = function SubmissionSettingsInit(props) {
  const { formData, handelInitSettings, initData, errors, tabs, changeTab, handleSubmitSettings } = props;
  const [tab, setTab] = useState(null);
  const [tabBack, setTabBack] = useState(null);

  useEffect(() => {
    const recipientTab = tabs.find((item) => item.component === 'recipient-settings-init');
    if (!recipientTab?.isDisable) {
      setTab(recipientTab);
    } else {
      const notificationTab = tabs.find((item) => item.component === 'notification-settings-init');
      if (!notificationTab?.isDisable) {
        setTab(notificationTab);
      }
    }

    setTabBack(tabs.find((item) => item.component === 'view-form'));
  }, [tabs]);

  const onChangeTab = () => {
    changeTab(tab);
  };
  const onBackTab = () => {
    changeTab(tabBack);
  };

  return (
    <>
      <div className="form-builder-setting-tab-content">
        <div className={'form-builder-recipient-settings form-init-settings'}>
          {formData?.name && (
            <div className="from-builder-title from-builder-title-setting">
              <h1>
                <img src="/images/file-big-icon.png" className="mr-2" alt="" />
                {formData.name}
              </h1>
            </div>
          )}

          {formData.filling_type === 'PUBLIC' && <SubmissionSettingsInitPublic formData={formData} handelInitSettings={handelInitSettings} errors={errors} initData={initData} />}

          {formData.filling_type === 'SPECIAL' && (
            <>
              {formData.submission_settings.can_initiator_select_submitters && (
                <>
                  {formData.submission_settings.submitters.submitters_type === 'user' && (
                    <SubmissionSettingsInitSystem errors={errors} formData={formData} handelInitSettings={handelInitSettings} initData={initData} />
                  )}
                  {formData.submission_settings.submitters.submitters_type === 'unregistered' && (
                    <SubmissionSettingsInitUnregister errors={errors} formData={formData} handelInitSettings={handelInitSettings} initData={initData} />
                  )}
                  {formData.submission_settings.submitters.submitters_type === 'contact' && (
                    <SubmissionSettingsInitContact errors={errors} formData={formData} handelInitSettings={handelInitSettings} initData={initData} />
                  )}
                </>
              )}
              <SubmissionSettingsInitAvailability errors={errors} formData={formData} handelInitSettings={handelInitSettings} initData={initData} />
              <SubmissionSettingsInitFrequency errors={errors} formData={formData} handelInitSettings={handelInitSettings} initData={initData} />
            </>
          )}
        </div>
      </div>
      <div className="form-control-actions form-init-content">
        <div>
          <button onClick={onBackTab} className={`btn btn-outline mr-2 btn-wide`}>
            <svg className={'mr-2'} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M15.8327 10.0013H4.16602M4.16602 10.0013L9.99935 15.8346M4.16602 10.0013L9.99935 4.16797"
                stroke="#101828"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Back
          </button>
          {tab && !tab?.isDisable ? (
            <button disabled={tab?.isDisable} className="btn btn-outline btn-outline-secondary btn-wide" onClick={onChangeTab}>
              Next
              <svg className={'ml-2'} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M4.16602 10.0013H15.8327M15.8327 10.0013L9.99935 4.16797M15.8327 10.0013L9.99935 15.8346"
                  stroke="#7F56D9"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          ) : (
            <button className="btn btn-wide" onClick={handleSubmitSettings} style={{ height: 41 }}>
              Submit
            </button>
          )}
        </div>
      </div>
    </>
  );
};
