import React from 'react';
import { TextElement } from './elemments/text-element';
import { SingleLineElement } from './elemments/single-line-element';
import { MultilineElement } from './elemments/multiline-element';
import { NumberElement } from './elemments/number-element';
import { PhoneElement } from './elemments/phone-element';
import { EmailElement } from './elemments/email-element';
import { FullNameElement } from './elemments/full-name-element';
import { AddressElement } from './elemments/address-element';
import { DateElement } from './elemments/date-element';
import { TimeElement } from './elemments/time-element';
import { DropdownElement } from './elemments/dropdown-element';
import { CheckboxElement } from './elemments/checkbox';
import { RadiobuttonElement } from './elemments/radiobutton-element';
import { LinkElement } from './elemments/link';
import { RatingElement } from './elemments/rating-element';
import { SignatureElement } from './elemments/signature-element';
import { TableElement } from './elemments/table-element';
import { SingleLineHeaderElement } from './elemments/single-line-header-element';
import { SubmittersFieldElement } from './elemments/submitters-field-element';
import { FileUploadElement } from './elemments/file-upload-element';
import { FileAttachElement } from './elemments/file-attach-element';
import { LoginElement } from './elemments/login-element';
import cx from 'classnames';

export const FromBuilderItem = function FromElement(props) {
  const { handlerChangeFile, element, handlerChange, addNewRow, handlerChangeLabel, isPrint, isSubmitter, addNewLogin, lastIndex, index, error, duplicateElement, removeElement } =
    props;
  return (
    <div
      className={cx('form-builder-element-preview', {
        'show-button': lastIndex === index,
        [`form-builder-element-preview-${element.type}`]: !!element.type,
        'table-form-builder-element-preview': element.type === 'table',
        [`form-label-alignment-${element.options.alignment}`]: !!element.options.alignment,
        [`form-label-${element.options.size}`]: !!element.options.size,
        'form-readonlyy': element.options.readOnly,
        'with-aside-settings': element.options.initiator_can_edit,
      })}
    >
      <div className="form-builder-element-preview-wrapper">
        {element.type === 'text' && <TextElement element={element} error={error} handlerChange={handlerChange} removeElement={removeElement} duplicateElement={duplicateElement} />}
        {element.type === 'single-line' && <SingleLineElement handlerChange={handlerChange} element={element} error={error} />}
        {element.type === 'multiline-line' && <MultilineElement isPrint={isPrint} handlerChange={handlerChange} element={element} error={error} />}
        {element.type === 'number' && <NumberElement handlerChange={handlerChange} element={element} error={error} />}
        {element.type === 'phone' && <PhoneElement handlerChange={handlerChange} element={element} error={error} />}
        {element.type === 'email' && <EmailElement handlerChange={handlerChange} element={element} error={error} />}
        {element.type === 'name' && <FullNameElement handlerChange={handlerChange} element={element} error={error} />}
        {element.type === 'address' && <AddressElement handlerChange={handlerChange} element={element} error={error} />}
        {element.type === 'time' && <TimeElement handlerChange={handlerChange} element={element} error={error} />}
        {element.type === 'date' && <DateElement handlerChange={handlerChange} element={element} error={error} />}
        {element.type === 'dropdown' && <DropdownElement handlerChange={handlerChange} element={element} error={error} />}
        {element.type === 'checkbox' && (
          <CheckboxElement isPrint={isPrint} handlerChange={handlerChange} element={element} error={error} duplicateElement={duplicateElement} removeElement={removeElement} />
        )}
        {element.type === 'radiobutton' && <RadiobuttonElement isPrint={isPrint} handlerChange={handlerChange} element={element} error={error} />}
        {element.type === 'link' && <LinkElement element={element} handlerChange={handlerChange} error={error} removeElement={removeElement} />}
        {element.type === 'rating' && <RatingElement handlerChange={handlerChange} element={element} error={error} />}
        {element.type === 'signature' && <SignatureElement handlerChange={handlerChange} element={element} error={error} />}
        {element.type === 'table' && (
          <TableElement isPrint={isPrint} element={element} handlerChangeLabel={handlerChangeLabel} handlerChange={handlerChange} addNewRow={addNewRow} error={error} />
        )}
        {element.type === 'single-line-header' && <SingleLineHeaderElement element={element} handlerChange={handlerChange} error={error} />}
        {element.type === 'submitters-field' && <SubmittersFieldElement isSubmitter={isSubmitter} element={element} handlerChange={handlerChange} error={error} />}
        {element.type === 'file-upload' && <FileUploadElement handlerChange={handlerChangeFile} element={element} error={error} />}
        {element.type === 'file-attach' && <FileAttachElement element={element} error={error} handlerChange={handlerChangeFile} removeElement={removeElement} />}
        {element.type === 'login' && <LoginElement addNewLogin={addNewLogin} isSubmitter={isSubmitter} element={element} handlerChange={handlerChange} error={error} />}
      </div>
    </div>
  );
};
