import client from '../../api';
import { CancelToken } from 'axios';

let cancel;

export const patchFile = async (fileId, data) => {
  try {
    if (!fileId) return;

    if (cancel !== undefined) {
      cancel();
    }

    return await client.patch(`/api/corporate-library/files/${fileId}/`, data, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
    return e;
  }
};
