import React from 'react';
import { AccessSettings } from './access-settings';
import { RecipientSettings } from './recipient-settings';
import { NotificationSettings } from './notification-settings';
import { SubmissionSettings } from './submission-settings';
import { ViewForm } from '../../init-form/view-form';
import { RecipientSettingsInit } from '../../init-form/recipient-settings-init';
import { SubmissionSettingsInit } from '../../init-form/submition-settings-init';
import { NotificationSettingsInit } from '../../init-form/notification-settings-init';
import { ViewGeneralForm } from '../../init-form/view-general-form';
import { ShareWithCompany } from '../../init-form/share-with-company';
import { ReplaceTheForm } from '../../init-form/replace-the-form';

const Components = {
  'access-settings': AccessSettings,
  'view-form': ViewForm,
  'recipient-settings-init': RecipientSettingsInit,
  'notification-settings-init': NotificationSettingsInit,
  'submission-settings-init': SubmissionSettingsInit,
  'submission-settings': SubmissionSettings,
  'recipient-settings': RecipientSettings,
  'notification-settings': NotificationSettings,
  'view-general-form': ViewGeneralForm,
  'share-with-company': ShareWithCompany,
  'replace-the-form': ReplaceTheForm,
};

const Tab = (block, formData, handelInitSettings, initData, setError, errors, tabs, changeTab, handleSubmitSettings, duplicateElement, removeElement) => {
  if (typeof Components[block.component] !== 'undefined') {
    return React.createElement(Components[block.component], {
      block,
      formData,
      initData,
      errors,
      tabs,
      changeTab,
      handelInitSettings,
      key: block.component,
      handleSubmitSettings,
      handelError: setError,
      duplicateElement,
      removeElement,
    });
  }
  return React.createElement(() => <div>The {block.component} coming soon...</div>, { key: block.component });
};

export default Tab;
