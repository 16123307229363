import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { createUserGroupDefault, editUserGroupDefault } from '../../servises/user-group';

function FacilityModal({ isOpen, companyName, closeModal, userGroupData, handelCreateUserGroup, companyId, facility }) {
  const [errorUserGroup, setErrorUserGroup] = useState({});
  const [userGroup, setUserGroup] = useState({
    name: '',
  });

  useEffect(() => {
    if (userGroupData) {
      setUserGroup({ ...userGroupData });
    }
  }, [userGroupData]);

  const handleChange = (value, key) => {
    setUserGroup({
      ...userGroup,
      [key]: value,
    });

    if (errorUserGroup.hasOwnProperty(key)) {
      delete errorUserGroup[key];
    }
  };

  const resetForm = () => {
    setUserGroup({
      name: '',
    });
    setErrorUserGroup({});
  };
  const handelCloseModal = () => {
    closeModal();
    resetForm();
  };
  const handleSubmit = async () => {
    if (userGroupData) {
      const formData = { name: userGroup.name };

      delete formData.id;
      delete formData.deleted_at;
      delete formData.created_at;
      delete formData.users;
      delete formData.company;
      delete formData.actions;

      editUserGroupDefault(companyId, formData, userGroup.id)
        .then(() => {
          resetForm();
          handelCreateUserGroup();
        })
        .catch((e) => {
          if (e?.response?.data) {
            setErrorUserGroup(JSON.parse(JSON.stringify(e.response.data)));
          }
        });
    } else {
      const formData = { name: userGroup.name, company_id: companyId };
      createUserGroupDefault(companyId, formData)
        .then(() => {
          resetForm();
          handelCreateUserGroup();
        })
        .catch((e) => {
          if (e?.response?.data) {
            setErrorUserGroup(JSON.parse(JSON.stringify(e.response.data)));
          }
        });
    }
  };

  return (
    <Modal className="modal-default" isOpen={isOpen} onRequestClose={handelCloseModal} contentLabel="Example Modal" ariaHideApp={false}>
      <h2 className="modal-header">
        {userGroupData ? 'Edit' : 'Add New'} User Group{' '}
        <button className="btn btn-icon" onClick={handelCloseModal}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>
      {!!companyName && <h3 className="modal-subheader">for {companyName} company</h3>}
      <div className="form-wrapper">
        <h3 className="form-wrapper-title">
          <img src="/images/company-add-icon-5.svg" alt="" />
          Group information
        </h3>
        <div className={`form-item ${errorUserGroup.name ? 'form-item-error' : ''}`}>
          <label className="form-label">Name</label>
          <input placeholder="Enter name" value={userGroup.name} onChange={(e) => handleChange(e.target.value, 'name')} type="text" className="form-control" />
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorUserGroup.non_field_errors && errorUserGroup.non_field_errors[0]}</span>
          <span className="form-error">{errorUserGroup.name && errorUserGroup.name[0]}</span>
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={handelCloseModal}>
          Cancel
        </button>
        <button className="btn" onClick={handleSubmit}>
          Save
        </button>
      </div>
    </Modal>
  );
}

export default FacilityModal;
