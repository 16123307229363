import React, { useState, useCallback, useEffect, useMemo } from 'react';
import update from 'immutability-helper';
import Layout from '../../layout/form-builder';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { FormElement } from '../../components/from-builder/form-element';
import { DropArea } from '../../components/from-builder/drop-area';
import { contentComponentsArray, formComponentsArray, selectionComponentsArray, otherComponentsArray } from '../../constants/elements';
import { TitleElement } from '../../components/from-builder/elements/title-element';
import { FromBuilderItem } from '../../components/from-builder/from-builder-item';
import { FormBuilderElementSettings } from '../../components/from-builder/elements-settings/form-builder-element-settings';
import { useNavigate, useParams } from 'react-router-dom';
import { getFromById, updateForm, createForm, createFormAdmin, updateFormAdmin, getGeneralFromById } from '../../services/form';
import { useAuth } from '../../container/authContext';
import { usePermission } from '../../container/permissionContext';
import { RenderForm } from '../../components/init-form/render-form';
import { ATTACH_TO_FORM_NAME_MULTILINE_CHARACTER_LIMIT, MULTILINE_CHARACTER_LIMIT } from '../../constants/settings/form-initialization';

function FromBuilder() {
  const { id } = useParams();
  const [from, setFrom] = useState([]);
  const { user } = useAuth();
  const { isSuperAdmin } = usePermission();
  const navigate = useNavigate();
  const companyId = user.company?.id;
  const [title, setTitle] = useState({
    type: 'title',
    name: 'Form Name',
    options: {
      alignment: 'left',
      value: 'New Form',
      fontSize: 24,
    },
  });

  // { id?: string | number, type?: 'title' }. ID or type
  const [activeFormItemKey, setActiveFormItemKey] = useState(null);
  const [openSettings, setOpenSettings] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [search, setSearch] = useState('');
  const [isUpdated, setIsUpdated] = useState(false);
  const [contentComponents, setContentComponents] = useState([...contentComponentsArray]);
  const [formComponents, setFormComponents] = useState([...formComponentsArray]);
  const [selectionComponents, setSelectionComponents] = useState([...selectionComponentsArray]);
  const [otherComponents, setOtherComponents] = useState([...otherComponentsArray]);

  const activeFormItem = useMemo(() => {
    let formItem = null;
    if (activeFormItemKey) {
      if (from || title) {
        const { id: itemId, type: itemType } = activeFormItemKey;
        if (typeof itemId === 'string' || typeof itemId === 'number') {
          formItem = from.find((item) => item?.id === itemId);
        } else if (itemType) {
          formItem = title;
        }
      }
    }
    return formItem;
  }, [activeFormItemKey, from, title]);

  useEffect(() => {
    if (search) {
      const searchInLowerCase = search.toLowerCase();
      setContentComponents([...contentComponentsArray.filter((item) => item.name.toLowerCase().includes(searchInLowerCase))]);
      setFormComponents([...formComponentsArray.filter((item) => item.name.toLowerCase().includes(searchInLowerCase))]);
      setSelectionComponents([...selectionComponentsArray.filter((item) => item.name.toLowerCase().includes(searchInLowerCase))]);
      setOtherComponents([...otherComponentsArray.filter((item) => item.name.toLowerCase().includes(searchInLowerCase))]);
    } else {
      setContentComponents([...contentComponentsArray]);
      setFormComponents([...formComponentsArray]);
      setSelectionComponents([...selectionComponentsArray]);
      setOtherComponents([...otherComponentsArray]);
    }
  }, [search]);

  useEffect(() => {
    if (!isFirstLoad && id) {
      if (isUpdated) {
        patchForm();
      }
    } else if (!isFirstLoad) {
      createFrom();
    }
    // eslint-disable-next-line
  }, [from, title]);

  useEffect(() => {
    if (id) {
      if (user.company) {
        getFromById(id)
          .then((res) => {
            setTitle((prevState) => {
              if (res.title_options) {
                return { ...res.title_options };
              }

              return { ...prevState, options: { ...prevState.options, value: res.name } };
            });
            setFrom(res.form_fields);
            setIsFirstLoad(false);
          })
          .catch((e) => console.log(e));
      }
      if (isSuperAdmin() && !user.company) {
        getGeneralFromById(id)
          .then((res) => {
            setTitle((prevState) => {
              if (res.title_options) {
                return { ...res.title_options };
              }

              return { ...prevState, options: { ...prevState.options, value: res.name } };
            });
            setFrom(res.form_fields);
            setIsFirstLoad(false);
          })
          .catch((e) => console.log(e));
      }
    } else {
      setTitle({
        type: 'title',
        name: 'Form Name',
        options: {
          alignment: 'left',
          value: 'New Form',
          fontSize: 24,
        },
      });
      setFrom([]);
      setIsFirstLoad(true);
    }
    // eslint-disable-next-line
  }, [id]);

  const createFrom = () => {
    if (user.company) {
      const formData = {
        name: title.options.value,
        form_fields: from,
        company_id: companyId,
        title_options: title,
        status: 'draft',
      };
      createForm(formData)
        .then((res) => {
          navigate(`/form-builder/${res.id}/constructor`, { replace: true });
        })
        .catch((e) => console.log(e));
    }
    if (isSuperAdmin() && !user.company) {
      const formData = {
        name: title.options.value,
        title_options: title,
        form_fields: from,
        status: 'draft',
      };

      createFormAdmin(formData)
        .then((res) => {
          navigate(`/form-builder/${res.id}/constructor`, { replace: true });
        })
        .catch((e) => console.log(e));
    }
  };

  const patchForm = useCallback(() => {
    const form_fields = [...(from ?? [])].map((formField) => {
      if (formField.type === 'table') {
        const { cols: tableCols = [], rows: tableRows } = formField;
        formField.rows = tableRows?.map((row) => {
          if (row) {
            const { cols: rowCols } = row;
            if (!Array.isArray(rowCols)) {
              row.cols = [];
            }

            row.cols = tableCols.map((tableCol, tableColIndex) => {
              const rowCol = row.cols[tableColIndex];
              let newRowCol = tableCol;
              if (rowCol) {
                newRowCol = {
                  ...rowCol,
                  ...tableCol,
                  value: rowCol.value,
                };
              }
              return newRowCol;
            });

            row.original = true;
          }
          return row;
        });
      }
      return formField;
    });

    if (user.company) {
      const formData = {
        name: title.options.value,
        title_options: title,
        form_fields,
      };
      updateForm(id, formData)
        .then((res) => setFrom(res.form_fields))
        .catch((e) => console.log(e))
        .finally(() => setIsUpdated(false));
    }

    if (isSuperAdmin() && !user.company) {
      const formData = {
        name: title.options.value,
        title_options: title,
        form_fields,
      };
      updateFormAdmin(id, formData)
        .then((res) => {
          console.log(res);
        })
        .catch((e) => console.log(e));
    }
  }, [from, id, isSuperAdmin, title, user.company]);

  const handelAddElement = (item, index) => {
    setFrom((prevElements) => {
      let newElements = [...prevElements];
      if (typeof index === 'number' && index !== -1) {
        newElements.splice(index, 0, item);
      } else {
        newElements = [...prevElements, item];
      }
      return newElements;
    });
    setIsUpdated(true);
    setIsFirstLoad(false);
  };

  const handleChangeTableColumn = useCallback(({ elementId, rowIndex, colIndex, col }) => {
    setFrom((prevState) => {
      return prevState.map((obj) => {
        if (obj.id !== elementId) {
          return obj;
        }

        try {
          if (!Array.isArray(obj.rows[rowIndex].cols)) {
            obj.rows[rowIndex].cols = [];
          }
          obj.rows[rowIndex].cols[colIndex] = col;
        } catch (e) {
          console.log(e);
        }

        return obj;
      });
    });

    setIsUpdated(true);
  }, []);

  const handlerChangeOption = (value, key, itemId, index = null, isInputs = false) => {
    setFrom((prevState) => {
      return prevState.map((obj) => {
        if (obj.id !== itemId) {
          return obj;
        }

        if (key === 'cols' || key === 'rows') {
          const newInputsState = obj[key].map((input, inputIndex) => {
            if (index === inputIndex) {
              return {
                ...input,
                id: '',
              };
            }

            return input;
          });

          return { ...obj, [key]: [...newInputsState] };
        }

        if (index !== null) {
          const newInputsState = obj.inputs.map((input, inputIndex) => {
            if (index === inputIndex) {
              return { ...input, [key]: value };
            }

            return input;
          });
          return { ...obj, inputs: [...newInputsState] };
        }

        if (key === 'inputs' || key === 'colsCrud' || key === 'rowsCrud') {
          return { ...obj, [key.replace('Crud', '')]: [...value] };
        }

        if (key === 'dailyRowsCopyTime') {
          const newFormData = { ...obj, options: { ...obj.options, daily_copy_rows_time: value } };

          if (value) {
            newFormData.options.daily_auto_add_rows_enabled = true;
          } else {
            delete newFormData.options.daily_copy_rows_time;
            delete newFormData.options.need_copy_rows_data;
            delete newFormData.options.daily_auto_add_rows_enabled;
          }

          return newFormData;
        }

        if (key === 'singleLineValue') {
          // value = element
          return value;
        }

        // Set isFormName value and change character limit
        if (key === 'isFormName') {
          return {
            ...obj,
            options: {
              ...obj.options,
              [key]: value,
              characterLimit: value ? ATTACH_TO_FORM_NAME_MULTILINE_CHARACTER_LIMIT : MULTILINE_CHARACTER_LIMIT,
            },
          };
        }

        return { ...obj, options: { ...obj.options, [key]: value } };
      });
    });

    setIsUpdated(true);
  };

  const handlerChangeTitle = (value, key) => {
    setTitle((prevState) => {
      return { ...prevState, options: { ...prevState.options, [key]: value } };
    });
    setIsUpdated(true);
    setIsFirstLoad(false);
  };

  const handlerSettings = (item) => {
    setActiveFormItemKey({ id: item.id });
    setOpenSettings(true);
    setIsActive(false);
  };

  const handlerChangeWidth = (colId, width, elementId) => {
    setFrom((prevState) => {
      return prevState.map((obj) => {
        if (obj.cols && obj.id === elementId) {
          let colsNewState = obj.cols;
          if (colId) {
            colsNewState = obj.cols.map((col) => {
              if (col.id === colId) {
                col.width = width;
              }
              return col;
            });
          } else {
            obj.options.width = width;
          }
          return { ...obj, cols: [...colsNewState] };
        }
        return { ...obj };
      });
    });

    setIsUpdated(true);
  };

  const duplicateElement = useCallback(
    (elementId) => {
      if (from) {
        const elementIndex = from.findIndex((item) => item.id === elementId);
        if (elementIndex !== -1) {
          const element = from[elementIndex];
          const newElement = JSON.parse(JSON.stringify(element));
          newElement.id = new Date().getTime();

          handelAddElement(newElement, elementIndex + 1);
        }
      }
    },
    [from],
  );

  const handlerTitleSettings = () => {
    setActiveFormItemKey({ type: 'title' });
    setIsActive(true);
    setOpenSettings(true);
  };

  const handlerCloseSettings = () => {
    setActiveFormItemKey(null);
    setIsActive(false);
    setOpenSettings(false);
  };

  const findCard = useCallback(
    (id) => {
      if (!id) {
        return '';
      }

      const card = from.filter((c) => `${c.id}` === id)[0];
      return {
        card,
        index: from.indexOf(card),
      };
    },
    [from],
  );
  const moveCard = useCallback(
    (id, atIndex) => {
      if (!id) {
        return;
      }
      const { card, index } = findCard(id);
      setFrom(
        update(from, {
          $splice: [
            [index, 1],
            [atIndex, 0, card],
          ],
        }),
      );

      setIsUpdated(true);
    },
    [findCard, from],
  );
  const handlerRemove = (item, isActive) => {
    let oldArray = [...from];
    oldArray = oldArray.filter((element) => item.id !== element.id);
    setFrom([...oldArray]);
    if (isActive) {
      handlerCloseSettings();
    }
    setIsUpdated(true);
  };

  const saveElement = (item) => {
    setFrom((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.id === item.id) {
          return item;
        }

        return obj;
      });

      return newState;
    });
    setIsUpdated(true);
  };

  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [tabItem, setTabItem] = useState('desktop');
  const [orientation, setOrientation] = useState('vertical');

  const togglePreview = (isOpen) => {
    setIsOpenPreview(isOpen);
  };

  return (
    <Layout pageClassName={isOpenPreview ? 'modal-open' : ''} title="Form Builder - Constructor" togglePreview={togglePreview}>
      <DndProvider backend={HTML5Backend}>
        <div className="form-builder-aside-left">
          <div className="form-control-item">
            <input
              type="text"
              value={search}
              className="form-control"
              placeholder="Search components"
              onChange={(e) => setSearch(e.target.value)}
            />
            <i className="icon-search" />
          </div>

          {!!contentComponents.length && <h3 className="form-builder-element-title">Content Components</h3>}
          {contentComponents.map((item) => (
            <FormElement key={item.type} element={item} onAddItem={handelAddElement} />
          ))}

          {!!formComponents.length && <h3 className="form-builder-element-title">Form Components</h3>}
          {formComponents.map((item) => (
            <FormElement key={item.type} element={item} onAddItem={handelAddElement} />
          ))}

          {!!selectionComponents.length && <h3 className="form-builder-element-title">Selection Components</h3>}
          {selectionComponents.map((item) => (
            <FormElement key={item.type} element={item} onAddItem={handelAddElement} />
          ))}

          {!!otherComponents.length && <h3 className="form-builder-element-title">Other Components</h3>}
          {otherComponents.map((item) => (
            <FormElement key={item.type} element={item} onAddItem={handelAddElement} />
          ))}
        </div>
        <div className="form-builder-content">
          <div className="form-builder-drop-area">
            <TitleElement element={title} isActive={isActive} handlerTitleSettings={handlerTitleSettings} />
            <DropArea>
              {from.map((formItem, index) => (
                <FromBuilderItem
                  moveCard={moveCard}
                  findCard={findCard}
                  id={`${formItem?.id ? formItem.id : new Date().getTime()}`}
                  key={formItem?.id ?? new Date().getTime()}
                  index={index}
                  element={formItem}
                  isActive={activeFormItem?.id === formItem.id}
                  handlerSettings={handlerSettings}
                  handlerRemove={handlerRemove}
                  handlerChangeOption={handlerChangeOption}
                  handlerCloseSettings={handlerCloseSettings}
                  handlerChangeWidth={handlerChangeWidth}
                  duplicateElement={duplicateElement}
                  handleChangeTableColumn={handleChangeTableColumn}
                />
              ))}
            </DropArea>
          </div>
        </div>
        <FormBuilderElementSettings
          element={activeFormItem}
          isOpen={openSettings}
          onClose={handlerCloseSettings}
          handlerChangeOption={handlerChangeOption}
          handlerChangeTitle={handlerChangeTitle}
          duplicateElement={duplicateElement}
          saveElement={saveElement}
        />
      </DndProvider>
      {isOpenPreview && (
        <div className={'preview-modal init-page'}>
          <div className={'header-wrapper'}>
            <div className={'header-left'}>
              <div className="header-logo">
                <img src="/images/logo.svg" alt="" />
              </div>
            </div>
            <div className={'header-center tabs'}>
              <div className={`tabs-nav-item ${tabItem === 'desktop' ? 'tabs-nav-item-active' : ''}`} onClick={() => setTabItem('desktop')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M4.36719 15L16.299 15C16.958 15 17.4922 14.4404 17.4922 13.75V5C17.4922 4.30964 16.958 3.75 16.299 3.75L4.36719 3.75C3.70821 3.75 3.17401 4.30964 3.17401 5L3.17401 13.75C3.17401 14.4404 3.70821 15 4.36719 15Z"
                    stroke="#667085"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M12.72 17.5H7.94727" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M3.1748 11.875H17.493" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M10.334 15V17.5" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                Desktop
              </div>
              <div className={`tabs-nav-item ${tabItem === 'tablet' ? 'tabs-nav-item-active' : ''}`} onClick={() => setTabItem('tablet')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M15.9668 16.25V3.75C15.9668 3.05964 15.4326 2.5 14.7736 2.5L5.22816 2.5C4.56919 2.5 4.03498 3.05964 4.03498 3.75V16.25C4.03498 16.9404 4.56919 17.5 5.22816 17.5H14.7736C15.4326 17.5 15.9668 16.9404 15.9668 16.25Z"
                    stroke="#667085"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M4.03418 5H15.966" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M4.03418 15H15.966" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                Tablet
              </div>
              <div className={`tabs-nav-item ${tabItem === 'phone' ? 'tabs-nav-item-active' : ''}`} onClick={() => setTabItem('phone')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path
                    d="M12.3881 1.6875H5.94496C5.35188 1.6875 4.87109 2.19118 4.87109 2.8125V15.1875C4.87109 15.8088 5.35188 16.3125 5.94496 16.3125H12.3881C12.9812 16.3125 13.462 15.8088 13.462 15.1875V2.8125C13.462 2.19118 12.9812 1.6875 12.3881 1.6875Z"
                    stroke="#667085"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M4.87109 3.9375H13.462" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M4.87109 14.0625H13.462" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                Phone
              </div>
            </div>
            <div className={'header-right'}>
              <button onClick={() => togglePreview(false)} className={'btn btn-outline d-flex'}>
                <svg className={'mr-2'} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 4.375C3.75 4.375 1.25 10.0006 1.25 10.0006C1.25 10.0006 3.75 15.625 10 15.625C16.25 15.625 18.75 10.0006 18.75 10.0006C18.75 10.0006 16.25 4.375 10 4.375Z"
                    stroke="#202024"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 13.125C11.7259 13.125 13.125 11.7259 13.125 10C13.125 8.27411 11.7259 6.875 10 6.875C8.27411 6.875 6.875 8.27411 6.875 10C6.875 11.7259 8.27411 13.125 10 13.125Z"
                    stroke="#202024"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Close Preview
              </button>
            </div>
          </div>
          <div className={`form-view-wrapper ${'form-view-wrapper-' + tabItem}`}>
            <div className={'preview-modal-actions'}>
              Orientation
              <button
                className={`btn-orientation ${orientation === 'horizontal' ? 'active' : ''}`}
                onClick={() => setOrientation('horizontal')}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M2.6875 6.625L2.6875 13.375C2.6875 13.9963 3.19118 14.5 3.8125 14.5H16.1875C16.8088 14.5 17.3125 13.9963 17.3125 13.375V6.625C17.3125 6.00368 16.8088 5.5 16.1875 5.5H3.8125C3.19118 5.5 2.6875 6.00368 2.6875 6.625Z"
                    stroke="#7F56D9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M4.9375 14.5L4.9375 5.5" stroke="#7F56D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M15.0625 14.5V5.5" stroke="#7F56D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
              <button
                className={`btn-orientation ${orientation === 'vertical' ? 'active' : ''}`}
                onClick={() => setOrientation('vertical')}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M13.375 2.6875H6.625C6.00368 2.6875 5.5 3.19118 5.5 3.8125V16.1875C5.5 16.8088 6.00368 17.3125 6.625 17.3125H13.375C13.9963 17.3125 14.5 16.8088 14.5 16.1875V3.8125C14.5 3.19118 13.9963 2.6875 13.375 2.6875Z"
                    stroke="#6941C6"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M5.5 4.9375H14.5" stroke="#6941C6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5.5 15.0625H14.5" stroke="#6941C6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
            </div>
            <div className={`send-form-wrapper ${'send-form-wrapper-' + orientation}`}>
              <div className={'send-form'}>
                <RenderForm isSubmitter={false} isPrint={false} handelInitSettings={() => null} title={title} formElement={from} />
                <div className="form-control-actions">
                  <button className={'btn btn-outline mr-2'}>Clear form</button>
                  <button className="btn">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

export default FromBuilder;
