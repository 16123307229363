import React, { useState } from 'react';
import { getFileById, uploadFile } from '../../servises/upload';
import FileLoader from '../other/FileLoader';

function FileTab({ company, removeFile, addOther, error, updated }) {
  const [loader, setLoader] = useState(false);
  const handleFile = (event) => {
    setLoader(true);
    uploadFile(event.target.files[0])
      .then((res) => {
        addOther(res);
        setLoader(false);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoader(false);
      });
  };

  const openFile = (id, name) => {
    setLoader(true);
    let patt = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gim;
    let str = name.match(patt);
    getFileById(id)
      .then((res) => {
        if (str[0] === '.pdf') {
          const newWin = window.open(URL.createObjectURL(res), '_blank');
          if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
            let a = document.createElement('a');
            a.href = URL.createObjectURL(res);
            a.download = name;
            a.click();
          }
        } else {
          let a = document.createElement('a');
          a.href = URL.createObjectURL(res);
          a.download = name;
          a.click();
        }

        updated();
        setLoader(false);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoader(false);
      });
  };
  return (
    <div className={'file-tab'}>
      <div className="form-control-file form-control-file-default mb-1">
        <input accept=".jpg,.webp,.jpeg,.png,.pdf,image/*,.doc,.docx,video/mp4,video/x-m4v,video/*" type="file" id="contract_file" onChange={handleFile} />
        <div>
          <img className="icon-icon-upload" src="/images/upload-svg.svg" alt="" />
          <span>Click to upload</span>
          <span className="sub-text">You can upload pdf, word and png / jpg</span>
        </div>
      </div>
      <div className="form-error mb-2">{error?.contract_file && `contract_file: ${error.contract_file[0]}`}</div>
      <div className="form-error mb-2">{error?.other_files && `contract_file: ${error.other_files[0]}`}</div>

      {!company.contract_file && !company.other_files.length && <p className="text-secondary">No files uploaded yet</p>}

      {(company.contract_file?.name || !!company.other_files.length) && (
        <>
          <ul className="file-list">
            {company.contract_file?.name && (
              <li className={'align-center cursor-pointer'} onClick={() => openFile(company.contract_file.uuid, company.contract_file.name)}>
                <div className="d-flex align-center">
                  <img src="/images/icon-file-list.svg" alt="" />
                  <p>{company.contract_file.name}</p>
                </div>
                <button
                  className="btn btn-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeFile(company.contract_file.uuid, 'contract_file');
                  }}
                >
                  <i className="icon-remove"></i>
                </button>
              </li>
            )}

            {company.other_files.map((item) => (
              <li key={item.uuid} className={'align-center cursor-pointer'} onClick={() => openFile(item.uuid, item.name)}>
                <div className="d-flex align-center">
                  <img src="/images/icon-file-list.svg" alt="" />
                  <p>{item.name}</p>
                </div>
                <button
                  className="btn btn-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeFile(item.uuid, 'other_files');
                  }}
                >
                  <i className="icon-remove"></i>
                </button>
              </li>
            ))}
          </ul>
        </>
      )}

      {loader && <FileLoader />}
    </div>
  );
}

export default FileTab;
