import React from 'react';
import { useAuth } from '../../container/authContext';
import { Link, useLocation } from 'react-router-dom';
import { usePermission } from '../../container/permissionContext';
import { STATIC_ROUTES } from '../../constants/routes';

export const SideBarMenu = function SelectCustom() {
  const { user } = useAuth();
  const location = useLocation();
  const { isSuperAdmin, isEmployee, hasPermissionByCodeName, hasPermission } = usePermission();
  const canShowMenuItem = () => {
    if (user?.role === 'Super Admin') {
      return user?.company;
    }

    return true;
  };

  return (
    <>
      {user && (
        <ul className="sidebar-menu-top">
          <li>
            {isSuperAdmin() && (
              <>
                {user?.company && (
                  <Link
                    to={`/company/${user.company.id}`}
                    className={`sidebar-menu-link ${(location.pathname.includes('company') || location.pathname.includes('companies')) && !location.pathname.includes('users') && !location.pathname.includes('user-groups') && !location.pathname.includes('positions') && !location.pathname.includes('units') && !location.pathname.includes('residents') ? 'sidebar-menu-link-active' : ''}`}
                  >
                    <svg id="fi-rs-building" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <path
                          id="Vector"
                          d="M4 13H7V15H4V13ZM9 15H12V13H9V15ZM4 19H7V17H4V19ZM9 19H12V17H9V19ZM4 7H7V5H4V7ZM9 7H12V5H9V7ZM4 11H7V9H4V11ZM9 11H12V9H9V11ZM24 8V24H0V3C0 2.20435 0.31607 1.44129 0.87868 0.87868C1.44129 0.31607 2.20435 0 3 0L13 0C13.7956 0 14.5587 0.31607 15.1213 0.87868C15.6839 1.44129 16 2.20435 16 3V5H21C21.7956 5 22.5587 5.31607 23.1213 5.87868C23.6839 6.44129 24 7.20435 24 8ZM14 3C14 2.73478 13.8946 2.48043 13.7071 2.29289C13.5196 2.10536 13.2652 2 13 2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V22H14V3ZM22 8C22 7.73478 21.8946 7.48043 21.7071 7.29289C21.5196 7.10536 21.2652 7 21 7H16V22H22V8ZM18 15H20V13H18V15ZM18 19H20V17H18V19ZM18 11H20V9H18V11Z"
                        />
                      </g>
                      {/*<defs>*/}
                      {/*    <clipPath id="clip0_4123_3210">*/}
                      {/*        <rect width="24" height="24" fill="white"/>*/}
                      {/*    </clipPath>*/}
                      {/*</defs>*/}
                    </svg>
                  </Link>
                )}
                {!user?.company && (
                  <Link
                    to={`/companies`}
                    className={`sidebar-menu-link ${(location.pathname.includes('company') || location.pathname.includes('companies')) && (!location.pathname.includes('users') || !location.pathname.includes('user-groups') || !location.pathname.includes('positions') || !location.pathname.includes('units')) ? 'sidebar-menu-link-active' : ''}`}
                  >
                    <svg id="fi-rs-building" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <path
                          id="Vector"
                          d="M4 13H7V15H4V13ZM9 15H12V13H9V15ZM4 19H7V17H4V19ZM9 19H12V17H9V19ZM4 7H7V5H4V7ZM9 7H12V5H9V7ZM4 11H7V9H4V11ZM9 11H12V9H9V11ZM24 8V24H0V3C0 2.20435 0.31607 1.44129 0.87868 0.87868C1.44129 0.31607 2.20435 0 3 0L13 0C13.7956 0 14.5587 0.31607 15.1213 0.87868C15.6839 1.44129 16 2.20435 16 3V5H21C21.7956 5 22.5587 5.31607 23.1213 5.87868C23.6839 6.44129 24 7.20435 24 8ZM14 3C14 2.73478 13.8946 2.48043 13.7071 2.29289C13.5196 2.10536 13.2652 2 13 2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V22H14V3ZM22 8C22 7.73478 21.8946 7.48043 21.7071 7.29289C21.5196 7.10536 21.2652 7 21 7H16V22H22V8ZM18 15H20V13H18V15ZM18 19H20V17H18V19ZM18 11H20V9H18V11Z"
                        />
                      </g>
                      {/*<defs>*/}
                      {/*    <clipPath id="clip0_4123_3210">*/}
                      {/*        <rect width="24" height="24" fill="white"/>*/}
                      {/*    </clipPath>*/}
                      {/*</defs>*/}
                    </svg>
                  </Link>
                )}
              </>
            )}
          </li>
          {canShowMenuItem() && hasPermission('Users, Positions management') && (
            <li>
              {!isEmployee() && (
                <Link
                  to={`/company/${user.company.id}/user-groups`}
                  className={`sidebar-menu-link ${location.pathname.includes('user-groups') || location.pathname.includes('positions') || location.pathname.includes('units') ? 'sidebar-menu-link-active' : ''}`}
                >
                  <svg width="24" height="24" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1161_5294)">
                      <path
                        d="M14.1668 18.2427V16.576C14.1668 15.692 13.8156 14.8441 13.1905 14.219C12.5654 13.5939 11.7176 13.2427 10.8335 13.2427H4.16683C3.28277 13.2427 2.43493 13.5939 1.80981 14.219C1.18469 14.8441 0.833496 15.692 0.833496 16.576V18.2427M19.1668 18.2427V16.576C19.1663 15.8374 18.9205 15.12 18.468 14.5363C18.0155 13.9526 17.3819 13.5356 16.6668 13.351M13.3335 3.35101C14.0505 3.53459 14.686 3.95159 15.1399 4.53627C15.5937 5.12094 15.84 5.84003 15.84 6.58018C15.84 7.32032 15.5937 8.03941 15.1399 8.62408C14.686 9.20876 14.0505 9.62576 13.3335 9.80934M10.8335 6.57601C10.8335 8.41696 9.34111 9.90934 7.50016 9.90934C5.65921 9.90934 4.16683 8.41696 4.16683 6.57601C4.16683 4.73506 5.65921 3.24268 7.50016 3.24268C9.34111 3.24268 10.8335 4.73506 10.8335 6.57601Z"
                        strokeWidth="1.67"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1161_5294">
                        <rect width="20" height="20" fill="white" transform="translate(0 0.742676)" />
                      </clipPath>
                    </defs>
                  </svg>
                </Link>
              )}
              {isEmployee() && (
                <Link
                  to={`/company/${user.company.id}/positions`}
                  className={`sidebar-menu-link ${location.pathname.includes('user-groups') || location.pathname.includes('positions') || location.pathname.includes('units') ? 'sidebar-menu-link-active' : ''}`}
                >
                  <svg width="24" height="24" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1161_5294)">
                      <path
                        d="M14.1668 18.2427V16.576C14.1668 15.692 13.8156 14.8441 13.1905 14.219C12.5654 13.5939 11.7176 13.2427 10.8335 13.2427H4.16683C3.28277 13.2427 2.43493 13.5939 1.80981 14.219C1.18469 14.8441 0.833496 15.692 0.833496 16.576V18.2427M19.1668 18.2427V16.576C19.1663 15.8374 18.9205 15.12 18.468 14.5363C18.0155 13.9526 17.3819 13.5356 16.6668 13.351M13.3335 3.35101C14.0505 3.53459 14.686 3.95159 15.1399 4.53627C15.5937 5.12094 15.84 5.84003 15.84 6.58018C15.84 7.32032 15.5937 8.03941 15.1399 8.62408C14.686 9.20876 14.0505 9.62576 13.3335 9.80934M10.8335 6.57601C10.8335 8.41696 9.34111 9.90934 7.50016 9.90934C5.65921 9.90934 4.16683 8.41696 4.16683 6.57601C4.16683 4.73506 5.65921 3.24268 7.50016 3.24268C9.34111 3.24268 10.8335 4.73506 10.8335 6.57601Z"
                        strokeWidth="1.67"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1161_5294">
                        <rect width="20" height="20" fill="white" transform="translate(0 0.742676)" />
                      </clipPath>
                    </defs>
                  </svg>
                </Link>
              )}
            </li>
          )}
          {canShowMenuItem() && (hasPermission('Users, Positions management') || hasPermission('Contacts management')) && (
            <>
              {canShowMenuItem() && !hasPermission('Users, Positions management') && hasPermission('Contacts management') && (
                <li>
                  <Link
                    to={`/company/${user.company.id}/residents`}
                    className={`sidebar-menu-link ${location.pathname.includes('users') || location.pathname.includes('residents') ? 'sidebar-menu-link-active' : ''}`}
                  >
                    <svg width="24" height="24" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <path
                          d="M16.6668 18.2427V16.576C16.6668 15.692 16.3156 14.8441 15.6905 14.219C15.0654 13.5939 14.2176 13.2427 13.3335 13.2427H6.66683C5.78277 13.2427 4.93493 13.5939 4.30981 14.219C3.68469 14.8441 3.3335 15.692 3.3335 16.576V18.2427M13.3335 6.57601C13.3335 8.41696 11.8411 9.90934 10.0002 9.90934C8.15921 9.90934 6.66683 8.41696 6.66683 6.57601C6.66683 4.73506 8.15921 3.24268 10.0002 3.24268C11.8411 3.24268 13.3335 4.73506 13.3335 6.57601Z"
                          strokeWidth="1.67"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </Link>
                </li>
              )}
              {canShowMenuItem() && hasPermission('Users, Positions management') && (
                <li>
                  <Link
                    to={`/company/${user.company.id}/users`}
                    className={`sidebar-menu-link ${location.pathname.includes('users') || location.pathname.includes('residents') ? 'sidebar-menu-link-active' : ''}`}
                  >
                    <svg width="24" height="24" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <path
                          d="M16.6668 18.2427V16.576C16.6668 15.692 16.3156 14.8441 15.6905 14.219C15.0654 13.5939 14.2176 13.2427 13.3335 13.2427H6.66683C5.78277 13.2427 4.93493 13.5939 4.30981 14.219C3.68469 14.8441 3.3335 15.692 3.3335 16.576V18.2427M13.3335 6.57601C13.3335 8.41696 11.8411 9.90934 10.0002 9.90934C8.15921 9.90934 6.66683 8.41696 6.66683 6.57601C6.66683 4.73506 8.15921 3.24268 10.0002 3.24268C11.8411 3.24268 13.3335 4.73506 13.3335 6.57601Z"
                          strokeWidth="1.67"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </Link>
                </li>
              )}
            </>
          )}

          {hasPermissionByCodeName('corporate_library_management') && (
            <li>
              <Link to={'/form-builder'} className={`sidebar-menu-link ${location.pathname.includes('form-builder') ? 'sidebar-menu-link-active' : ''}`}>
                <svg id="formbuilder" width="24" height="24" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <g id="Subtract">
                      <path
                        d="M16.3638 4.79289C16.1592 4.60536 15.8817 4.5 15.5924 4.5H6.86504C6.57571 4.5 6.29823 4.60536 6.09365 4.79289C5.88906 4.98043 5.77412 5.23478 5.77412 5.5C5.77412 5.76522 5.88906 6.01957 6.09365 6.20711C6.29823 6.39464 6.57571 6.5 6.86504 6.5H15.5924C15.8817 6.5 16.1592 6.39464 16.3638 6.20711C16.5684 6.01957 16.6833 5.76522 16.6833 5.5C16.6833 5.23478 16.5684 4.98043 16.3638 4.79289Z"
                        fill="#98A2B3"
                      />
                      <path
                        d="M5.4546 22H12.0001C12.2894 22 12.5669 22.1054 12.7715 22.2929C12.9761 22.4804 13.091 22.7348 13.091 23C13.091 23.2652 12.9761 23.5196 12.7715 23.7071C12.5669 23.8946 12.2894 24 12.0001 24H5.4546C4.00848 23.9984 2.62209 23.4711 1.59953 22.5338C0.576968 21.5964 0.00173223 20.3256 0 19V5C0.00173223 3.67441 0.576968 2.40356 1.59953 1.46622C2.62209 0.528882 4.00848 0.00158786 5.4546 0H18.5456C19.4136 0 20.2461 0.31607 20.8598 0.878679C21.4736 1.44129 21.8184 2.20435 21.8184 3V11C21.8184 11.2652 21.7035 11.5196 21.4989 11.7071C21.2943 11.8946 21.0168 12 20.7275 12C20.4381 12 20.1607 11.8946 19.9561 11.7071C19.7515 11.5196 19.6366 11.2652 19.6366 11V3C19.6366 2.73478 19.5216 2.48043 19.317 2.29289C19.1124 2.10536 18.835 2 18.5456 2H5.4546C4.58661 2 3.75417 2.31607 3.14041 2.87868C2.52665 3.44129 2.18184 4.20435 2.18184 5V19C2.18184 19.7956 2.52665 20.5587 3.14041 21.1213C3.75417 21.6839 4.58661 22 5.4546 22Z"
                        fill="#98A2B3"
                      />
                      <path
                        d="M16.3638 8.29289C16.1592 8.10536 15.8817 8 15.5924 8H6.86504C6.57571 8 6.29823 8.10536 6.09365 8.29289C5.88906 8.48043 5.77412 8.73478 5.77412 9C5.77412 9.26522 5.88906 9.51957 6.09365 9.70711C6.29823 9.89464 6.57571 10 6.86504 10H15.5924C15.8817 10 16.1592 9.89464 16.3638 9.70711C16.5684 9.51957 16.6833 9.26522 16.6833 9C16.6833 8.73478 16.5684 8.48043 16.3638 8.29289Z"
                        fill="#98A2B3"
                      />
                    </g>
                    <path
                      id="Icon (Stroke)"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.2749 15.866C11.2598 16.1446 11.2771 16.4254 11.3277 16.7028C11.4583 17.4197 11.8043 18.0798 12.3196 18.5951C12.8349 19.1104 13.495 19.4564 14.2119 19.5871C14.9288 19.7177 15.6686 19.6267 16.3325 19.3263C16.6309 19.1912 16.9807 19.2432 17.2271 19.4591L21.275 23.0068C21.2888 23.0189 21.3022 23.0314 21.3151 23.0444C21.4516 23.1808 21.6366 23.2575 21.8296 23.2575C22.0226 23.2575 22.2077 23.1808 22.3442 23.0444C22.4806 22.9079 22.5573 22.7228 22.5573 22.5298C22.5573 22.3443 22.4864 22.166 22.3597 22.0313L18.3363 18.505C18.0597 18.2626 17.9743 17.8678 18.1259 17.5328C18.4264 16.8689 18.5173 16.1292 18.3867 15.4123C18.2561 14.6953 17.9101 14.0352 17.3948 13.52C16.8795 13.0047 16.2194 12.6587 15.5025 12.528C15.2251 12.4775 14.9442 12.4601 14.6657 12.4752L16.4112 14.2207C16.6991 14.5145 16.8604 14.9095 16.8604 15.3209C16.8604 15.7323 16.6991 16.1273 16.4111 16.421L16.4053 16.427L15.2207 17.6115C14.9269 17.8995 14.5319 18.0608 14.1205 18.0608C13.7092 18.0608 13.3142 17.8995 13.0204 17.6115L13.0144 17.6056L13.0071 17.5983L11.2749 15.866ZM9.68472 17.0022C9.49325 15.9513 9.62659 14.867 10.067 13.8938C10.18 13.644 10.4084 13.4657 10.678 13.4165C10.9477 13.3674 11.2243 13.4538 11.4182 13.6476L14.1205 16.35L15.1496 15.3209L12.4472 12.6185C12.2534 12.4247 12.1671 12.1481 12.2162 11.8784C12.2653 11.6087 12.4437 11.3803 12.6934 11.2673C13.6666 10.8269 14.7509 10.6936 15.8018 10.8851C16.8527 11.0766 17.8203 11.5838 18.5756 12.3391C19.331 13.0944 19.8382 14.062 20.0297 15.1129C20.1832 15.9557 20.1279 16.8199 19.8728 17.6311L23.485 20.7969C23.4987 20.809 23.5121 20.8215 23.525 20.8344C23.9747 21.2841 24.2273 21.8939 24.2273 22.5298C24.2273 23.1657 23.9747 23.7756 23.525 24.2252C23.0754 24.6749 22.4655 24.9275 21.8296 24.9275C21.2039 24.9275 20.6033 24.6829 20.1558 24.2466L16.507 21.0485C15.6738 21.3245 14.7818 21.3884 13.9126 21.23C12.8617 21.0385 11.8941 20.5313 11.1387 19.776C10.3834 19.0207 9.8762 18.0531 9.68472 17.0022Z"
                      fill="#98A2B3"
                    />
                  </g>
                </svg>
              </Link>
            </li>
          )}

          <li>
            {!isSuperAdmin() && (
              <Link
                to={'/corporate-library'}
                className={`sidebar-menu-link ${!user.company ? 'disabled' : ''} ${location.pathname.includes('corporate-library') ? 'sidebar-menu-link-active' : ''}`}
              >
                <svg width="24" height="24" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <path
                      d="M18.3332 16.576C18.3332 17.018 18.1576 17.442 17.845 17.7545C17.5325 18.0671 17.1085 18.2427 16.6665 18.2427H3.33317C2.89114 18.2427 2.46722 18.0671 2.15466 17.7545C1.8421 17.442 1.6665 17.018 1.6665 16.576V4.90934C1.6665 4.46731 1.8421 4.04339 2.15466 3.73083C2.46722 3.41827 2.89114 3.24268 3.33317 3.24268H7.49984L9.1665 5.74268H16.6665C17.1085 5.74268 17.5325 5.91827 17.845 6.23083C18.1576 6.54339 18.3332 6.96731 18.3332 7.40934V16.576Z"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              </Link>
            )}
            {isSuperAdmin() && user.company && (
              <Link
                to={'/corporate-library'}
                className={`sidebar-menu-link ${!user.company ? 'disabled' : ''} ${location.pathname.includes('corporate-library') ? 'sidebar-menu-link-active' : ''}`}
              >
                <svg width="24" height="24" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <path
                      d="M18.3332 16.576C18.3332 17.018 18.1576 17.442 17.845 17.7545C17.5325 18.0671 17.1085 18.2427 16.6665 18.2427H3.33317C2.89114 18.2427 2.46722 18.0671 2.15466 17.7545C1.8421 17.442 1.6665 17.018 1.6665 16.576V4.90934C1.6665 4.46731 1.8421 4.04339 2.15466 3.73083C2.46722 3.41827 2.89114 3.24268 3.33317 3.24268H7.49984L9.1665 5.74268H16.6665C17.1085 5.74268 17.5325 5.91827 17.845 6.23083C18.1576 6.54339 18.3332 6.96731 18.3332 7.40934V16.576Z"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              </Link>
            )}
            {isSuperAdmin() && !user.company && (
              <Link to={'/general-library'} className={`sidebar-menu-link ${location.pathname.includes('general-library') ? 'sidebar-menu-link-active' : ''}`}>
                <svg width="24" height="24" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <path
                      d="M18.3332 16.576C18.3332 17.018 18.1576 17.442 17.845 17.7545C17.5325 18.0671 17.1085 18.2427 16.6665 18.2427H3.33317C2.89114 18.2427 2.46722 18.0671 2.15466 17.7545C1.8421 17.442 1.6665 17.018 1.6665 16.576V4.90934C1.6665 4.46731 1.8421 4.04339 2.15466 3.73083C2.46722 3.41827 2.89114 3.24268 3.33317 3.24268H7.49984L9.1665 5.74268H16.6665C17.1085 5.74268 17.5325 5.91827 17.845 6.23083C18.1576 6.54339 18.3332 6.96731 18.3332 7.40934V16.576Z"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              </Link>
            )}
          </li>

          {((isSuperAdmin() && user.company) || (!isSuperAdmin() && hasPermissionByCodeName('report_building'))) && (
            <Link to={STATIC_ROUTES.REPORTS_REPORT_BUILDER} className={`sidebar-menu-link ${location.pathname.includes('reports') ? 'sidebar-menu-link-active' : ''}`}>
              <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.4039 18.5547H1.3999" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.60156 18.5571V6.55469H14.2027" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M19.8038 1.75H14.2026V18.5534H19.8038V1.75Z" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3 18.553V11.3516H8.60112" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </Link>
          )}

          {canShowMenuItem() && (
            <li>
              {/* eslint-disable-next-line */}
              <a href="#" className="sidebar-menu-link disabled">
                <svg width="24" height="24" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <path
                      d="M13.3333 2.4093V5.74264M6.66667 2.4093V5.74264M2.5 9.07597H17.5M4.16667 4.07597H15.8333C16.7538 4.07597 17.5 4.82216 17.5 5.74264V17.4093C17.5 18.3298 16.7538 19.076 15.8333 19.076H4.16667C3.24619 19.076 2.5 18.3298 2.5 17.4093V5.74264C2.5 4.82216 3.24619 4.07597 4.16667 4.07597Z"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              </a>
            </li>
          )}
        </ul>
      )}
    </>
  );
};
