import React, { memo, useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import RadioButton from '../../../../other/RadioButton';

import './styles.scss';

const StopInitiationModal = ({ isOpen, onClose, onStopInitiation, hasFrequencySettings }) => {
  let layout = null;

  // id
  const [selectedAction, setSelectedAction] = useState(null);

  const isActiveStopInitiationBtn = typeof selectedAction === 'string';

  useEffect(() => {
    if (!isOpen) {
      setSelectedAction(null);
    }
  }, [isOpen]);

  const handleStopInitiation = useCallback(() => isActiveStopInitiationBtn && onStopInitiation(selectedAction), [isActiveStopInitiationBtn, onStopInitiation, selectedAction]);

  const handleSetSelectedAction = useCallback(
    (actionId) => {
      const isAlreadySelected = selectedAction === actionId;
      if (isAlreadySelected) {
        setSelectedAction(null);
      } else {
        setSelectedAction(actionId);
      }
    },
    [selectedAction],
  );

  if (isOpen) {
    layout = (
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        className="modal-default form-management-stop-initiation-modal"
        overlayClassName={'form-management-stop-initiation-modal-overlay'}
        onRequestClose={onClose}
      >
        <h2 className="modal-header">
          <div className={'title'}>
            <img src="/images/alert-icon.svg" alt="" />
            Please confirm the actions
          </div>
          <button className="btn btn-icon" onClick={onClose}>
            <i className="icon-icon-x" />
          </button>
        </h2>
        <div className="modal-body">
          <RadioButton
            id={'stop'}
            disabled={!hasFrequencySettings}
            checked={selectedAction === 'stop'}
            label={
              'Stop all future scheduled initiations, but allow users to finish current submissions from their “Forms available to me” “Forms in progress” and “Public” folders.'
            }
            onChange={handleSetSelectedAction}
          />
          <RadioButton
            id={'remove'}
            checked={selectedAction === 'remove'}
            label={
              'Remove all current initiations from folders: "Forms available to me", "Forms in Progress" and "Public". Clicking this action, means that this initiation will not be available for submissions anymore. All future frequencies of this initiation will be stopped'
            }
            onChange={handleSetSelectedAction}
          />
        </div>
        <div className="modal-footer">
          <button className="btn btn-outline" onClick={onClose}>
            Cancel
          </button>
          <button className={'stop-initiation-button'} disabled={!isActiveStopInitiationBtn} onClick={handleStopInitiation}>
            <i className="icon-icon-x" />
            <span>Stop the Initiation</span>
          </button>
        </div>
      </Modal>
    );
  }

  return layout;
};

export default memo(StopInitiationModal);
