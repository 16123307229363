import React, { useEffect, useState } from 'react';
import Layout from '../../layout/default';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import dayjs from "dayjs";

import { activateUserGroup, addUsers, deactivateUserGroup, deleteActions, getUserGroupById, getUserGroupsActions } from '../../servises/user-group';
import dayjs from 'dayjs';
import { getFacilitiesByCompany } from '../../servises/facility';
import UserGroupActionsModal from '../../components/company/user-group-actions-modal';
import DeactivateModal from '../../components/company/deactivate-modal';
import UserGroupModal from '../../components/company/user-group-modal';
import PermissionsItem from '../../components/company/permissions-item';
import UserGroupUsersModal from '../../components/company/user-group-users-modal';
import { usePermission } from '../../container/permissionContext';
import { redirectToLibrary } from '../../utility';
import { useAuth } from '../../container/authContext';
import { DATE_FORMAT } from '../../constants';

function UserGroupProfile() {
  const { user_group_id, id } = useParams();
  const [userGroup, setUserGroup] = useState({});
  const [currentAction, setCurrentAction] = useState(null);
  const [userGroupActions, setUserGroupActions] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalEditIsOpen, setEditIsOpen] = useState(false);
  const [modalUserIsOpen, setUserIsOpen] = useState(false);
  const [deactivateIsOpen, setIsOpenDeactivate] = useState(false);
  const [errors, setErrors] = useState([]);
  const location = useLocation();
  const { updateUser } = useAuth();

  const navigation = useNavigate();
  const { isEmployee } = usePermission();
  useEffect(() => {
    if (isEmployee()) {
      redirectToLibrary(navigation);
    }
  }, [isEmployee, navigation]);
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState([
    {
      url: '/companies/',
      name: 'Company List',
    },
  ]);

  useEffect(() => {
    initPage();
    // eslint-disable-next-line
  }, [user_group_id, id]);

  const initPage = () => {
    getUserGroupById(user_group_id)
      .then((res) => {
        setUserGroup(res);
        updateUser();
        getUserGroupsActions()
          .then((resActions) => {
            let actionsOptions = resActions;

            res.actions.forEach((action) => {
              actionsOptions.splice(
                actionsOptions.findIndex((item) => item.id === action.action.id),
                1,
              );
            });

            setUserGroupActions(resActions);
          })
          .catch((e) => console.log(e));

        if (location.pathname.includes('user-groups')) {
          setPageBreadcrumbs([
            {
              url: `/company/${res.company.id}`,
              name: res.company.name,
            },
            {
              url: `/company/${res.company.id}/user-groups`,
              name: 'User Group List',
            },
            {
              url: `/company/${res.company.id}/user-groups/${res.id}`,
              name: `${res.name}`,
            },
          ]);
        } else {
          setPageBreadcrumbs([
            {
              url: '/companies/',
              name: 'Company List',
            },
            {
              url: `/company/${id}`,
              name: res.company.name,
            },
            {
              url: `/company/${id}/facility/${res.facility.id}`,
              name: res.facility.name,
            },
            {
              url: `/company/${id}/user/${res.id}`,
              name: res.name,
            },
          ]);
        }
      })
      .catch((e) => console.log(e));

    getFacilitiesByCompany(id, 'getAll')
      .then((res) => {
        setFacilities([...res]);
      })
      .catch((e) => console.log(e));
  };
  const openAddModal = () => {
    setIsOpen(true);
  };
  function closeAddModal() {
    setIsOpen(false);
    setCurrentAction(null);
  }
  function closeUserModal() {
    setUserIsOpen(false);
  }
  const openEditModal = () => {
    setEditIsOpen(true);
  };
  function closeEditModal() {
    setEditIsOpen(false);
  }

  const handelAddActions = () => {
    initPage();
    closeAddModal();
  };

  // const handelRemove = (handlerChange, elementToRemove) => {
  //     handlerChange(prevState => {
  //         return prevState.filter(item => item.value !== elementToRemove.value)
  //     })
  // }

  const handleDeactivateIsOpen = () => {
    setIsOpenDeactivate(false);
    setErrors([]);
  };

  const handelDeactivate = () => {
    deactivateUserGroup(user_group_id)
      .then((res) => {
        navigation(`/company/${id}/user-groups`);
        handleDeactivateIsOpen();
      })
      .catch((e) => {
        if (e?.response?.data) {
          setErrors(e.response.data);
        }
      });
  };

  const handelActivate = () => {
    activateUserGroup(user_group_id)
      .then((res) => initPage())
      .catch((e) => console.log(e));
  };
  const handelCreateUserGroup = () => {
    initPage();
    closeEditModal();
    closeUserModal();
  };

  const handelEdit = (item) => {
    setCurrentAction(item);
    openAddModal();
  };
  const handelRemoveAction = (item) => {
    deleteActions(userGroup.id, item.id)
      .then((res) => {
        initPage();
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const removeUserFromGroup = (item) => {
    const formDate = {
      user_ids: [...userGroup.users.filter((user) => user.id !== item.id).map((item) => item.id)],
    };

    addUsers(formDate, userGroup.id)
      .then((res) => {
        initPage();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Layout breadcrumbs={pageBreadcrumbs} title={'User Group Profile'}>
      <div className={`page-wrapper ${userGroup.deleted_at ? 'page-wrapper-deactivated' : ''}`}>
        <div className="company-profile">
          {userGroup.name && (
            <>
              <div className="company-profile-header">
                <div>
                  <span className="date-add">
                    Date Added: {dayjs(userGroup.date_added).format(DATE_FORMAT)}{' '}
                    {userGroup.deleted_at && <span className="date-add-deactivated">Deactivated: {dayjs(userGroup.deleted_at).format(DATE_FORMAT)}</span>}
                  </span>
                  <h2 className="company-profile-title">
                    {userGroup.name} {userGroup.type && <span className="special mr-2">{userGroup.type}</span>} {userGroup.is_special && <span className="special">Special</span>}
                  </h2>
                  <h3 className="company-profile-subtitle">
                    <span className="text-secondary">Facility:</span> {userGroup.facility.name} #{userGroup.facility.id}
                  </h3>
                </div>
                {userGroup.type !== 'DEFAULT' && (
                  <div className="company-profile-header-action">
                    {!userGroup.deleted_at && (
                      <>
                        <button className="btn btn-outline" onClick={openEditModal}>
                          <i className="icon-icon-edit mr-2"></i> Edit
                        </button>
                        <button className="btn btn-outline btn-danger" onClick={() => setIsOpenDeactivate(true)}>
                          <i className={'icon-remove mr-2'}></i>
                          Delete
                        </button>
                      </>
                    )}
                    {userGroup.deleted_at && (
                      <button className="btn btn-outline btn-success" onClick={handelActivate}>
                        <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clipPath="url(#clip0_1484_3438)">
                            <path
                              d="M18.3332 9.2333V9.99997C18.3321 11.797 17.7503 13.5455 16.6743 14.9848C15.5983 16.4241 14.0859 17.477 12.3626 17.9866C10.6394 18.4961 8.79755 18.4349 7.1119 17.8121C5.42624 17.1894 3.98705 16.0384 3.00897 14.5309C2.03089 13.0233 1.56633 11.24 1.68457 9.4469C1.80281 7.65377 2.49751 5.94691 3.66507 4.58086C4.83263 3.21482 6.41049 2.26279 8.16333 1.86676C9.91617 1.47073 11.7501 1.65192 13.3915 2.3833M18.3332 3.3333L9.99984 11.675L7.49984 9.17497"
                              stroke="#12B76A"
                              strokeWidth="1.67"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_1484_3438">
                              <rect width="20" height="20" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                        Activate
                      </button>
                    )}
                  </div>
                )}
              </div>
              <div className="user-group-profile-content">
                <div className="user-group-content-users">
                  <h3 className="title">
                    <span>
                      <img src="/images/company-add-icon-3.svg" className="mr-2" alt="" />
                      Users
                    </span>{' '}
                    <button className="btn" onClick={() => setUserIsOpen(true)}>
                      <i className="icon-plus-svgrepo-com mr-2"></i>Add User
                    </button>
                  </h3>

                  <div className="table-responsive table-default table-border table-full">
                    <div className="table">
                      <div className="tr">
                        <div className="th">
                          Name <span className="th-total">(Total: {userGroup.users.length} users)</span>
                        </div>
                        <div className="th">Position</div>
                        <div className="th"></div>
                      </div>

                      {userGroup.users.map((item) => (
                        <div className="tr" key={item.id}>
                          <div className="td no-wrap">
                            <div className="d-flex">
                              <strong>
                                {item.first_name} {item.last_name}
                              </strong>
                            </div>
                          </div>
                          <div className="td no-wrap">{item.position?.name}</div>
                          <div className="td text-right">
                            <i className="icon-remove cursor-pointer" onClick={() => removeUserFromGroup(item)}></i>
                          </div>
                        </div>
                      ))}
                      {!userGroup.users.length && (
                        <div className="empty-table">
                          <div>
                            <p>
                              <strong>No Users listed yet</strong>
                            </p>
                            <p>Simply click the button “Add User” to initiate the user addition process</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="user-group-content-permissions">
                  <h3 className="title">
                    <span>
                      <img src="/images/icon-permissions.svg" className="mr-2" alt="" />
                      Permissions
                    </span>{' '}
                    <button className="btn btn-text btn-text-primary" onClick={openAddModal}>
                      <i className="icon-plus-svgrepo-com mr-2"></i>Add Permission
                    </button>
                  </h3>
                  <UserGroupActionsModal
                    dataAction={currentAction}
                    isOpen={modalIsOpen}
                    userGroupId={user_group_id}
                    actions={userGroupActions}
                    currentActions={userGroup.actions}
                    facilities={facilities}
                    closeModal={closeAddModal}
                    handelAddActions={handelAddActions}
                  />
                  {userGroup.actions.map((item) => (
                    <PermissionsItem handelEdit={handelEdit} handelDeactivate={handelRemoveAction} item={item} key={item.id}></PermissionsItem>
                  ))}

                  {!userGroup.actions.length && (
                    <div className="empty-action">
                      <div>
                        <p>
                          <strong>No Permissions listed yet</strong>
                        </p>
                        <p>Simply click the button “Add permission” to start the permission creation process</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <DeactivateModal
                isOpen={deactivateIsOpen}
                closeModal={handleDeactivateIsOpen}
                handelSuccess={handelDeactivate}
                errors={errors}
                button={'Delete'}
                title="Confirm User Group deletion"
                subtitle="This action is irreversible and will permanently remove all associated entities. Deleted information cannot be restored. "
              ></DeactivateModal>

              {modalEditIsOpen && (
                <UserGroupModal
                  companyName={userGroup.company.name}
                  userGroupData={userGroup}
                  facilities={facilities}
                  closeModal={closeEditModal}
                  isOpen={modalEditIsOpen}
                  openModal={openEditModal}
                  handelCreateUserGroup={handelCreateUserGroup}
                />
              )}
              {modalUserIsOpen && (
                <UserGroupUsersModal
                  companyId={userGroup.company.id}
                  facilityId={userGroup.facility.id}
                  closeModal={closeUserModal}
                  dataAction={userGroup.users}
                  userGroupId={user_group_id}
                  isOpen={modalUserIsOpen}
                  handelAddUsers={handelCreateUserGroup}
                />
              )}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default UserGroupProfile;
