import { useState } from 'react';
import './styles.scss';
import cx from 'classnames';

const viewModes = [
  { id: 'LIST', label: 'List' },
  { id: 'GRID', label: 'Grid' },
];

const ViewModeSwitcher = ({ viewMode, onSwitch, styles, classNames }) => {
  return (
    <div className={cx('library-view-mode-switcher', classNames)} style={styles}>
      {viewModes.map(({ id, label }) => {
        const isActive = viewMode === id;
        return (
          <div key={id} id={id} className={cx('button', { active: isActive })} onClick={onSwitch}>
            {isActive && (
              <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.6668 1L5.50016 10.1667L1.3335 6" stroke="#7F56D9" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            )}
            {label}
          </div>
        );
      })}
    </div>
  );
};

const withLibraryViewMode = (WrappedComponent) => {
  return (props) => {
    const [libraryViewMode, setLibraryViewMode] = useState(viewModes[0].id);

    const handleSwitchLibraryViewMode = (e) => setLibraryViewMode(e.currentTarget.id);

    return (
      <WrappedComponent
        {...props}
        libraryViewMode={libraryViewMode}
        setLibraryViewMode={setLibraryViewMode}
        ViewModeSwitcher={(switcherProps) => <ViewModeSwitcher {...switcherProps} viewMode={libraryViewMode} onSwitch={handleSwitchLibraryViewMode} />}
      />
    );
  };
};

export default withLibraryViewMode;
