import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../../layout/default';
import { Breadcrumbs } from '../../components/library/Breadcrumbs';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useParams, useNavigate } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import {
  getFilesGeneralSearch,
  getFilesGeneralFolder,
  deleteFilesGeneralFolder,
  deleteGeneralFiles,
  activeFilesGeneral,
} from '../../services/folder/form-avaliable';
import CreateModal from '../../components/library/files-modal';
import FilesUploadModal from '../../components/library/files-upload-modal';
import ErrorModal from '../../components/library/error-modal';
import { GeneralFolder } from '../../components/library/folder-general-item';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { useAuth } from '../../container/authContext';
import FilesShareModal from '../../components/library/files-share-modal';
import { GeneralFile } from '../../components/library/files/general-file';
import DeactivateModal from '../../components/company/deactivate-modal';
import FilePreviewModal from '../../components/library/file-preview-modal';
import { localToUTCStringForDateRange } from '../../utility/dates/localToUTCForDateRange';

function GeneralLibrary() {
  const { folder } = useParams();
  const navigate = useNavigate();
  const [defaultIndex, setDefaultIndex] = useState(1);
  const [staticFolders, setStaticFolders] = useState([]);
  const { user } = useAuth();
  const [staticForms, setStaticForms] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [search, setSearch] = useState('');
  const [isErrorModal, setIsErrorModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [deactivateIsOpen, setDeactivateIsOpen] = useState(false);
  // const [currentFolderId, setFolderId] = useState(null)
  const [isFilterMode, setIsFilterMode] = useState(false);
  const [isListMode, setIsListMode] = useState(false);
  const [formsSearch, setFormsSearch] = useState([]);
  const { id } = useParams();
  const [status, setStatus] = useState({
    value: 'active',
    label: 'Active',
  });
  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      name: 'General Library',
      url: '/general-library',
    },
    {
      name: 'Files',
      url: '/general-library/files',
    },
    null,
  ]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // { uuid: string, url: string, name: string }
  const [previewFile, setPreviewFile] = useState(null);

  useEffect(() => {
    if (isListMode) {
      applySearch();
    }

    if (search) {
      setIsListMode(true);
    }

    if (!search) {
      setStatus({
        value: 'active',
        label: 'Active',
      });
      setFormsSearch([]);
    }
    // eslint-disable-next-line
  }, [search, startDate, endDate]);
  useEffect(() => {
    if (isFilterMode) {
      applySearch();
      setIsListMode(true);
    }
    // eslint-disable-next-line
  }, [status]);
  useEffect(() => {
    if (isListMode) {
      applySearch();
    } else {
      setFormsSearch([]);
      setIsFilterMode(false);
      setStartDate(false);
      setEndDate(false);
      setStatus({
        value: 'active',
        label: 'Active',
      });
    }
    // eslint-disable-next-line
  }, [isListMode]);
  useEffect(() => {
    initPage();
    // eslint-disable-next-line
  }, [id]);

  const applySearch = () => {
    const params = {
      get_all: true,
      status: 'all',
    };

    if (search) {
      params['search'] = search;
    }

    if (status) {
      params['status'] = status.value;
    }

    if (startDate && endDate) {
      params['creation_date_after'] = localToUTCStringForDateRange(startDate, 'start');
      params['creation_date_before'] = localToUTCStringForDateRange(endDate, 'end');
    }

    getFilesGeneralSearch(params)
      .then((res) => {
        setFormsSearch(res);
      })
      .catch((e) => console.log(e));
  };

  const clearFilter = () => {
    setSearch('');
    setStartDate(false);
    setEndDate(false);
    setStatus({
      value: 'active',
      label: 'Active',
    });
  };

  const goToPrev = (goToId) => {
    let url = goToId;
    if (typeof goToId === 'number') {
      url = `/general-library/files/${goToId}`;
    }
    clearFilter();
    setIsListMode(false);
    navigate(url, { replace: true });
  };

  const initPage = () => {
    const params = {
      parent_id: id ? id : null,
      company_id: user.company ? user.company.id : null,
    };

    getFilesGeneralFolder(params)
      .then((res) => {
        setBreadcrumbs([
          {
            name: 'General Library',
            url: '/general-library',
          },
          {
            name: 'Files',
            url: '/general-library/files',
          },
          ...getBreadcrumbs(res.breadcrumbs),
        ]);
        setStaticFolders(res.data.filter((item) => item.type === 'folder'));
        setStaticForms(res.data.filter((item) => item.type === 'file'));
        // setFolderId(getBreadcrumbs(res.breadcrumbs)[getBreadcrumbs(res.breadcrumbs).length - 1].id);
      })
      .catch((e) => console.log(e));
  };

  const getBreadcrumbs = (breadcrumbs) => {
    const list = [];
    if (breadcrumbs) {
      getProp(breadcrumbs);
    }

    function getProp(o) {
      list.push(o);
      if (o.children) {
        getProp(o.children);
      }
    }

    return list;
  };
  const handlerChangeStaticFolder = (folder) => navigate(`/general-library/files/${folder}`);

  const handelOpenModal = () => {
    setOpenModal(true);
  };
  const handelEdit = (item) => {
    handelOpenModal();
    setCurrentFolder(item);
  };
  const handelDelete = (item) => {
    deleteFilesGeneralFolder(item.id)
      .then(() => {
        initPage();
      })
      .catch((e) => {
        setIsErrorModal(true);
        console.log(e);
      });
  };
  const activeFile = (item) => {
    activeFilesGeneral(item.id)
      .then(() => {
        initPage();
        if (isFilterMode) {
          applySearch();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handelDeleteFile = () => {
    deleteGeneralFiles(currentFile.id)
      .then(() => {
        initPage();
        if (isFilterMode) {
          applySearch();
        }
      })
      .catch((e) => {
        setIsErrorModal(true);
        console.log(e);
      });
    handleDeactivateIsClose();
  };

  const closeErrorModal = () => {
    setIsErrorModal(false);
  };
  const handleDeactivateIsClose = () => {
    setCurrentFile(null);
    setDeactivateIsOpen(false);
  };
  const closeModal = () => {
    setOpenModal(false);
    setCurrentFolder(null);
  };
  const handelCreate = () => {
    closeModal();
    initPage();
  };
  const handleDateChange = (date) => {
    setStartDate(date[0]);
    setEndDate(date[1]);
  };

  const handelOpenAddModal = () => {
    setOpenAddModal(true);
  };
  const handelCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handelAdd = () => {
    handelCloseAddModal();
    initPage();
  };

  const moveFile = useCallback((folderId) => handlerChangeStaticFolder(folderId), [handlerChangeStaticFolder]);

  const [openShareModal, setOpenShareModal] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const handelShareAddModal = () => {
    setCurrentFile(false);
    setOpenShareModal(false);
  };
  const handelOpenShareModal = (file) => {
    setCurrentFile(file);
    setOpenShareModal(true);
  };
  const handelDeleteFileOpen = (file) => {
    setCurrentFile(file);
    setDeactivateIsOpen(true);
  };

  const handleSetPreviewFile = (file) => setPreviewFile(file);

  const closeFilePreviewModal = useCallback(() => setPreviewFile(null), []);

  const renderFilePreviewModal = () => {
    return <FilePreviewModal previewFile={previewFile} onClose={closeFilePreviewModal} />;
  };

  return (
    <Layout title="General Library">
      <div className="library">
        <DndProvider backend={HTML5Backend}>
          <Breadcrumbs breadcrumbs={breadcrumbs} goToLevel={goToPrev} />
          <h2 className="library-title">General Library</h2>
          <Tabs selectedIndex={defaultIndex} onSelect={(index) => setDefaultIndex(index)}>
            <TabList>
              <Tab
                onClick={() => {
                  navigate('/general-library/forms');
                  setDefaultIndex(0);
                }}
              >
                Forms
              </Tab>
              <Tab
                onClick={() => {
                  navigate('/general-library/files');
                  setDefaultIndex(1);
                }}
              >
                Files
              </Tab>
            </TabList>
            <TabPanel></TabPanel>
            <TabPanel>
              <div className="library-filter">
                <div className="library-filter-search form-control-item">
                  <input
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                  <i className="icon-search"></i>
                </div>
                <div>
                  <button
                    className={`btn-outline btn-filter btn ${isFilterMode ? 'btn-active' : ''}`}
                    onClick={() => setIsFilterMode(!isFilterMode)}
                  >
                    <i className="icon-icon-sliders"></i>
                  </button>

                  <button
                    className={`btn-outline btn btn-down ${isListMode ? 'btn-active' : ''}`}
                    onClick={() => setIsListMode(!isListMode)}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path
                        d="M2.50033 4.16797C2.04009 4.16797 1.66699 4.54106 1.66699 5.0013C1.66699 5.46154 2.04009 5.83464 2.50033 5.83464H2.50866C2.9689 5.83464 3.34199 5.46154 3.34199 5.0013C3.34199 4.54106 2.9689 4.16797 2.50866 4.16797H2.50033Z"
                        fill="#101828"
                      />
                      <path
                        d="M6.66699 4.16797C6.20676 4.16797 5.83366 4.54106 5.83366 5.0013C5.83366 5.46154 6.20676 5.83464 6.66699 5.83464H17.5003C17.9606 5.83464 18.3337 5.46154 18.3337 5.0013C18.3337 4.54106 17.9606 4.16797 17.5003 4.16797H6.66699Z"
                        fill="#101828"
                      />
                      <path
                        d="M6.66699 9.16797C6.20676 9.16797 5.83366 9.54106 5.83366 10.0013C5.83366 10.4615 6.20676 10.8346 6.66699 10.8346H17.5003C17.9606 10.8346 18.3337 10.4615 18.3337 10.0013C18.3337 9.54106 17.9606 9.16797 17.5003 9.16797H6.66699Z"
                        fill="#101828"
                      />
                      <path
                        d="M5.83366 15.0013C5.83366 14.5411 6.20676 14.168 6.66699 14.168H17.5003C17.9606 14.168 18.3337 14.5411 18.3337 15.0013C18.3337 15.4615 17.9606 15.8346 17.5003 15.8346H6.66699C6.20676 15.8346 5.83366 15.4615 5.83366 15.0013Z"
                        fill="#101828"
                      />
                      <path
                        d="M1.66699 10.0013C1.66699 9.54106 2.04009 9.16797 2.50033 9.16797H2.50866C2.9689 9.16797 3.34199 9.54106 3.34199 10.0013C3.34199 10.4615 2.9689 10.8346 2.50866 10.8346H2.50033C2.04009 10.8346 1.66699 10.4615 1.66699 10.0013Z"
                        fill="#101828"
                      />
                      <path
                        d="M2.50033 14.168C2.04009 14.168 1.66699 14.5411 1.66699 15.0013C1.66699 15.4615 2.04009 15.8346 2.50033 15.8346H2.50866C2.9689 15.8346 3.34199 15.4615 3.34199 15.0013C3.34199 14.5411 2.9689 14.168 2.50866 14.168H2.50033Z"
                        fill="#101828"
                      />
                    </svg>
                  </button>
                  <button className={'btn btn-outline'} onClick={handelOpenModal}>
                    <i className="icon-icon-folder mr-2"></i>Create folder
                  </button>
                  {id && (
                    <button className={'btn btn-file'} onClick={handelOpenAddModal}>
                      <i className="icon-plus-svgrepo-com mr-2"></i>Add File
                    </button>
                  )}
                </div>
              </div>

              {isFilterMode && (
                <div className="library-filters">
                  <div className="library-filters-wrapper">
                    <div className="form-item">
                      <label className="form-label">Creation date</label>
                      <DatePicker
                        selected={startDate}
                        className="form-control"
                        onChange={handleDateChange}
                        selectsRange
                        startDate={startDate}
                        endDate={endDate}
                        isClearable
                        disabledKeyboardNavigation
                        placeholderText={'Select...'}
                      ></DatePicker>
                    </div>
                    <div className="form-item">
                      <label className="form-label">Status</label>
                      <Select
                        options={[
                          {
                            value: 'all',
                            label: 'All',
                          },
                          {
                            value: 'active',
                            label: 'Active',
                          },
                          {
                            value: 'archived',
                            label: 'Archived',
                          },
                        ]}
                        value={status}
                        onChange={(e) => setStatus(e)}
                        className="form-control-select"
                        classNamePrefix="react-select"
                      ></Select>
                    </div>
                  </div>

                  <button className="btn btn-outline" onClick={clearFilter}>
                    Clear All
                  </button>
                </div>
              )}

              {!isListMode && (
                <>
                  {folder && (
                    <div className="library-filter">
                      <div className="library-filter-search form-control-item">
                        <input type="text" className="form-control" placeholder="Search" />
                        <i className="icon-search"></i>
                      </div>
                      <div>
                        <button className="btn-outline btn">
                          <i className="icon-icon-sliders"></i>
                        </button>
                      </div>
                    </div>
                  )}

                  {(!!staticFolders.length || !id) && <h3 className="library-subtitle">Folders</h3>}
                  <div className="library-wrapper">
                    {!folder &&
                      staticFolders.map((item) => (
                        <GeneralFolder
                          key={item.id}
                          item={item}
                          changeFolder={handlerChangeStaticFolder}
                          handelEdit={handelEdit}
                          handelDelete={handelDelete}
                        ></GeneralFolder>
                      ))}
                  </div>

                  {staticForms.length > 0 && <h3 className="library-subtitle mt-2">Files</h3>}
                  <div className="library-wrapper">
                    {staticForms.length > 0 &&
                      staticForms.map((item) => (
                        <GeneralFile
                          item={item}
                          key={item.id}
                          moveFile={moveFile}
                          activeFile={activeFile}
                          copyFile={handelOpenShareModal}
                          deleteFile={handelDeleteFileOpen}
                          onFilePreview={handleSetPreviewFile}
                        />
                      ))}
                  </div>

                  {!staticFolders.length && !staticForms.length && !search && (
                    <>
                      <div className="empty-page">
                        <h3 className="empty-page-title">This folder is empty</h3>
                        <h4 className="empty-page-subtitle">This folder currently does not contain any forms. </h4>
                      </div>
                    </>
                  )}
                </>
              )}
              {isListMode && (
                <>
                  {formsSearch.length > 0 && (
                    <>
                      <h3 className="library-subtitle mt-2">
                        Files <span className={'total'}>(Total: {formsSearch.length} files)</span>
                      </h3>
                      <div className="library-wrapper">
                        {formsSearch.map((item) => (
                          <GeneralFile
                            item={item}
                            key={item.id}
                            activeFile={activeFile}
                            copyFile={handelOpenShareModal}
                            deleteFile={handelDeleteFileOpen}
                            onFilePreview={handleSetPreviewFile}
                          />
                        ))}
                      </div>
                    </>
                  )}
                  {!formsSearch.length && !!search && (
                    <>
                      <div className="empty-page">
                        <h3 className="empty-page-title">Unfortunately, nothing was found for your query.</h3>
                        <h4 className="empty-page-subtitle">
                          Try to change the range of keywords. We're here to help you find what you're looking for.
                        </h4>
                      </div>
                    </>
                  )}
                  {!formsSearch.length && !search && (
                    <>
                      <div className="empty-page">
                        <h3 className="empty-page-title">This folder is empty</h3>
                        <h4 className="empty-page-subtitle">This folder currently does not contain any forms. </h4>
                      </div>
                    </>
                  )}
                </>
              )}
            </TabPanel>
          </Tabs>
        </DndProvider>
        {openModal && (
          <CreateModal
            isGeneral
            closeModal={closeModal}
            handelCreate={handelCreate}
            isOpen={openModal}
            parent_id={id}
            company_id={user.company ? user.company.id : null}
            folderData={currentFolder}
          ></CreateModal>
        )}

        {openAddModal && (
          <FilesUploadModal
            isGeneral
            isOpen={openAddModal}
            closeModal={handelCloseAddModal}
            handelCreate={handelAdd}
            parent_id={id ? id : null}
            company_id={user.company ? user.company.id : null}
          ></FilesUploadModal>
        )}
        {openShareModal && (
          <FilesShareModal
            isOpen={openShareModal}
            closeModal={handelShareAddModal}
            handelCreate={handelShareAddModal}
            currentFile={currentFile}
          ></FilesShareModal>
        )}

        <DeactivateModal
          isOpen={deactivateIsOpen}
          closeModal={handleDeactivateIsClose}
          handelSuccess={handelDeleteFile}
          title="Confirm file archiving"
          subtitle="The file will be deactivated, and you can activate it at any time."
          button={'Archive'}
        ></DeactivateModal>

        <ErrorModal
          isOpen={isErrorModal}
          closeModal={closeErrorModal}
          title={'Empty Folder Required for Deletion'}
          subtitle={
            'You can only delete folders when they are empty. Please remove all items from this folder before proceeding with the deletion.'
          }
        ></ErrorModal>
      </div>
      {renderFilePreviewModal()}
    </Layout>
  );
}

export default GeneralLibrary;
