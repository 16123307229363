import React, { memo, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { usePermission } from '../../../../container/permissionContext';
import { getFileGeneralInfo, getFileInfo } from '../../../../servises/facility';
import { getFileById } from '../../../../servises/upload';
import FileLoader from '../../../other/FileLoader';
import cx from 'classnames';
import { getFileExtFromFileName } from '../../../../utility/files/getFileExtFromFileName';
import { isWebVideoByFileExt } from '../../../../utility/files/isWebVideoByFileExt';

const File = (props) => {
  const { item, url, deleteFile, isGeneral, isAttach, handelAttach, file, onFilePreview } = props;
  const { hasPermissionByCodeName } = usePermission();

  const [loader, setLoader] = useState(false);
  const [isOpenTooltip, setIsOpenTooltip] = useState(false);

  const downloadFile = ({ name, downloadUrl }) => {
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = name;
    a.rel = 'noreferrer';
    a.target = '_blank';
    a.click();
  };

  const handleClickFile = () => {
    setIsOpenTooltip(false);
    if (url) {
      if (isGeneral) {
        if (!isAttach) {
          setLoader(true);
          getFileGeneralInfo(item.id)
            .then((fileInfoRes) => {
              getFileById(fileInfoRes.file.uuid)
                .then((res) => {
                  const fileData = { ...fileInfoRes, url: res.message, downloadUrl: res.message };
                  downloadFile(fileData);
                })
                .catch((e) => {
                  console.log(e);
                  setLoader(false);
                });
            })
            .catch((e) => {
              console.log(e);
              setLoader(false);
            });
        } else {
          handelAttach(item);
        }
      } else {
        if (!isAttach) {
          setLoader(true);
          getFileInfo(item.id)
            .then((fileInfoRes) => {
              if (fileInfoRes.file) {
                const fileExtension = getFileExtFromFileName(fileInfoRes?.file?.name);
                const isWebVideo = isWebVideoByFileExt(fileExtension);
                getFileById(fileInfoRes.file.uuid)
                  .then((fileByIdRes) => {
                    if (fileByIdRes?.config === 'success' && fileByIdRes?.message) {
                      const fileData = { ...fileInfoRes, url: fileByIdRes.message, downloadUrl: fileByIdRes.message };
                      if (onFilePreview && isWebVideo) {
                        onFilePreview(fileData);
                      } else {
                        downloadFile(fileData);
                      }
                    }

                    setLoader(false);
                  })
                  .catch((e) => {
                    console.log(e);
                    setLoader(false);
                  });
              }
            })
            .catch((e) => {
              console.log(e);
              setLoader(false);
            });
        } else {
          handelAttach(item);
        }
      }
    }
  };

  return (
    <>
      <Tooltip isOpen={isOpenTooltip} role={'tooltip'} opacity={1} id={`table-tooltip-${item.id}`} place="top" content={item.name} positionStrategy={'absolute'} />
      <div
        data-tooltip-id={`table-tooltip-${item.id}`}
        className={cx('library-item cursor-pointer', {
          'library-item-active': file?.id === item.id,
        })}
        onClick={handleClickFile}
        onMouseEnter={() => setIsOpenTooltip(true)}
        onMouseLeave={() => setIsOpenTooltip(false)}
      >
        <div className="library-item-text">
          <img src="/images/file-big-icon.png" alt="" /> {item.name}
        </div>
        {(hasPermissionByCodeName('corporate_library_management') || hasPermissionByCodeName('files_management')) && !isAttach && (
          <button
            className={'btn btn-remove btn-icon mr-4'}
            onClick={(e) => {
              e.stopPropagation();
              deleteFile(item.id);
            }}
          >
            <i className={'icon-remove'} />
          </button>
        )}
        {loader && <FileLoader />}
      </div>
    </>
  );
};

export default memo(File);
