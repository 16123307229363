import React, { useState } from 'react';
import Modal from 'react-modal';
import { createFiles, createGeneralFiles } from '../../services/folder/form-avaliable';
import { uploadFile } from '../../services/upload';
import FileLoader from '../other/FileLoader';

function FilesModal({ isOpen, closeModal, handelCreate, parent_id, company_id, isGeneral }) {
  const [errorFolder, setErrorFolder] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const handelCloseModal = () => {
    closeModal();
  };

  const [loader, setLoader] = useState(false);
  const handleSubmit = async (event) => {
    setIsDisabled(true);
    setLoader(true);
    uploadFile(event.target.files[0])
      .then((res) => {
        setLoader(false);
        if (!isGeneral) {
          createFiles({
            file: res.uuid,
            name: res.name,
            folder_id: parent_id,
            company_id: company_id,
          })
            .then(() => {
              setIsDisabled(false);
              handelCreate();
              handelCloseModal();
            })
            .catch((e) => {
              setIsDisabled(false);
              if (e?.response?.data) {
                setErrorFolder(JSON.parse(JSON.stringify(e.response.data)));
              }
            });
        } else {
          createGeneralFiles({
            file: res.uuid,
            name: res.name,
            folder_id: parent_id,
          })
            .then(() => {
              setIsDisabled(false);
              handelCreate();
              handelCloseModal();
            })
            .catch((e) => {
              setIsDisabled(false);
              if (e?.response?.data) {
                setErrorFolder(JSON.parse(JSON.stringify(e.response.data)));
              }
            });
        }
      })
      .catch((e) => {
        console.log(e);
        setIsDisabled(false);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <Modal
      className="modal-default modal-no-footer"
      isOpen={isOpen}
      onRequestClose={handelCloseModal}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      {loader && <FileLoader />}
      <h2 className="modal-header">
        Add File
        <button className="btn btn-icon" onClick={handelCloseModal}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>

      <div className={`form-control-file form-control-file-default mb-1 ${isDisabled ? 'pointer-none opacity-50' : ''}`}>
        <input
          accept=".jpg,.webp,.jpeg,.png,.pdf,image/*,.doc,.docx,video/mp4,video/x-m4v,video/*"
          type="file"
          id="contract_file"
          style={{ zIndex: 1 }}
          onChange={handleSubmit}
        />
        <div>
          <img className="icon-icon-upload" src="/images/upload-svg.svg" alt="" />
          <span>Click to upload</span>
          <span className="sub-text">You can upload pdf, word and png / jpg</span>
        </div>
      </div>
      <p className="text-secondary">No files uploaded yet</p>
      <span className="form-error">{errorFolder.name && errorFolder.name[0]}</span>
      <span className="form-error">{errorFolder.file && errorFolder.file[0]}</span>
      <span className="form-error">{errorFolder.folder_id && errorFolder.folder_id[0]}</span>
      <span className="form-error">{errorFolder.company_id && errorFolder.company_id[0]}</span>
    </Modal>
  );
}

export default FilesModal;
