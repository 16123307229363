import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import MultiDatePicker, { DateObject } from 'react-multi-date-picker';
import { get } from 'lodash';
import cx from 'classnames';
import { qualityFrequencyOptions, rangeFullOptions, frequencyOptions, deadlineRangeOptions, typeOptions, initialQualityOptions } from './constants';
import { DATE_FORMAT } from '../../../constants';
import { getDaysInInitiationPeriod, getMonthIndexByName, getMonthsInInitiationPeriod } from './utils';

export const SubmissionSettingsInitFrequency = (props) => {
  const { formData, handelInitSettings, initData, errors } = props;
  const toDay = new DateObject(new Date());
  const isDisabledSettings = initData?.will_have_period_availability;
  let layout = null;

  const [firstInitiationDay, setFirstInitiationDay] = useState(initData?.frequency?.first_initiation_day ?? null);
  const [lastInitiationDay, setLastInitiationDay] = useState(initData?.frequency?.last_initiation_day ?? null);
  const [recipient_settings_init, setRecipientSettingsInit] = useState({});
  const [qualityOptions, setQualityOptions] = useState(initialQualityOptions);

  useEffect(() => {
    if (initData) {
      setRecipientSettingsInit({ ...initData });
    }
  }, [initData]);

  useEffect(() => {
    setQualityOptions(() => {
      const newOptions = [];
      let max = 4;
      if (recipient_settings_init?.submission_deadline_settings?.deadline_period_type?.value === 'hours') {
        max = 23;
      }
      if (recipient_settings_init?.submission_deadline_settings?.deadline_period_type?.value === 'days') {
        max = 12;
      }
      if (recipient_settings_init?.submission_deadline_settings?.deadline_period_type?.value === 'weeks') {
        max = 4;
      }
      if (recipient_settings_init?.submission_deadline_settings?.deadline_period_type?.value === 'months') {
        max = 12;
      }
      for (let i = 1; i <= max; i++) {
        newOptions.push({
          value: i,
          label: i,
        });
      }

      return newOptions;
    });
  }, [recipient_settings_init?.submission_deadline_settings?.deadline_period_type]);

  const handelSettings = useCallback(
    (value, key) => {
      const newState = { ...recipient_settings_init };
      newState[key] = value;
      setRecipientSettingsInit(() => newState);

      handelInitSettings(newState[key], key);
    },
    [handelInitSettings, recipient_settings_init],
  );

  // Set "No" if selected period of availability
  useEffect(() => {
    if (initData?.will_have_frequency === true && initData?.will_have_period_availability) {
      handelSettings(false, 'will_have_frequency');
    }
  }, [initData?.will_have_period_availability, initData?.will_have_frequency]);

  const handelDeadLineSettings = (value, key) => {
    const prevState = { ...recipient_settings_init };
    if (!prevState['submission_deadline_settings']) {
      prevState['submission_deadline_settings'] = {};
    }

    prevState['submission_deadline_settings'][key] = value;
    setRecipientSettingsInit(() => {
      return prevState;
    });

    handelInitSettings(prevState['submission_deadline_settings'], 'submission_deadline_settings');
  };

  const handelFrequencySettings = useCallback(
    (value, key) => {
      const newState = { ...recipient_settings_init };
      if (!newState['frequency']) {
        newState['frequency'] = {};
      }

      newState['frequency'][key] = value;
      setRecipientSettingsInit(newState);

      handelInitSettings(newState['frequency'], 'frequency');
    },
    [handelInitSettings, recipient_settings_init],
  );

  const clearFrequencyArraySettings = useCallback(() => {
    const newState = { ...recipient_settings_init };
    if (!newState['frequency']) {
      newState['frequency'] = {};
    }
    newState['frequency'].months = [];
    newState['frequency'].week_days = [];

    handelInitSettings(newState['frequency'], 'frequency');
  }, [handelInitSettings, recipient_settings_init]);

  const handelFrequencyArraySettings = (e, key, index) => {
    const newState = { ...recipient_settings_init };
    if (!newState['frequency']) {
      newState['frequency'] = {};
    }

    if (!newState['frequency'][key]) {
      newState['frequency'][key] = [];
    }

    newState['frequency'][key][index] = e.target.checked;

    handelInitSettings(newState['frequency'], 'frequency');
  };

  // First/last initiation day
  const handleInitiationDay = useCallback(
    (date, type) => {
      if (type === 'first_initiation_day') {
        setFirstInitiationDay(date);
      } else {
        setLastInitiationDay(date);
      }

      handelFrequencySettings(date, type);

      // Clear dependent fields
      // handelFrequencySettings(null, 'times');
      // handelFrequencySettings(null, 'type');
      handelFrequencySettings(null, 'month_day');
      handelFrequencySettings(null, 'time');
      clearFrequencyArraySettings();
    },
    [clearFrequencyArraySettings, handelFrequencySettings],
  );

  const handleInitiationMonthDays = useCallback(
    (dates) => {
      handelFrequencySettings(
        dates.map((value) => value),
        'month_day',
      );
    },
    [handelFrequencySettings],
  );

  const handleChangePeriodType = useCallback(
    (e) => {
      handelFrequencySettings(e, 'type');
      handelFrequencySettings(null, 'month_day');
      clearFrequencyArraySettings();
    },
    [clearFrequencyArraySettings, handelFrequencySettings],
  );

  if (get(formData, 'submission_settings.can_initiator_specify_period_availability_and_frequency')) {
    const renderCustomDatesSettings = () => {
      let customIntervalsDatesLayout = null;
      if (recipient_settings_init?.frequency?.frequency_type?.value === 'specific_dates') {
        customIntervalsDatesLayout = (
          <>
            <div className={'form-item multi-date-picker mb-4'}>
              <label className="form-label">Initiation Date</label>
              <div className={'clear-datepicker'}>
                <MultiDatePicker
                  multiple
                  editable={false}
                  minDate={toDay}
                  placeholder={'Choose Date'}
                  className={''}
                  format={DATE_FORMAT}
                  value={initData?.frequency?.specific_dates}
                  onChange={(dates) => handelFrequencySettings(dates, 'specific_dates')}
                />
                {initData?.frequency?.specific_dates?.length > 0 && (
                  <button onClick={() => handelFrequencySettings([], 'specific_dates')} className={'clear'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <g clipPath="url(#clip0_5205_22763)">
                        <path
                          d="M12.5001 7.5013L7.50008 12.5013M7.50008 7.5013L12.5001 12.5013M18.3334 10.0013C18.3334 14.6037 14.6025 18.3346 10.0001 18.3346C5.39771 18.3346 1.66675 14.6037 1.66675 10.0013C1.66675 5.39893 5.39771 1.66797 10.0001 1.66797C14.6025 1.66797 18.3334 5.39893 18.3334 10.0013Z"
                          stroke="#D92D20"
                          strokeWidth="1.67"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_5205_22763">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                )}
              </div>
              {errors?.frequency?.specific_dates && <span className="form-error mb-2">{errors.frequency?.specific_dates[0]}</span>}{' '}
            </div>
            <div className={'form-item form-item-select form-item-time form-item-time-short mb-4'}>
              <label className="form-label">Initiation Time</label>
              <DatePicker
                selected={recipient_settings_init?.frequency?.time}
                onChange={(date) => handelFrequencySettings(date, 'time')}
                className={'form-control'}
                showTimeSelect
                showTimeSelectOnly
                isClearable
                placeholderText={'Choose Time'}
                timeIntervals={60}
                timeCaption=""
                dateFormat="h:mm aa"
              />
              {errors?.frequency?.time && <span className="form-error mb-2">{errors.frequency?.time[0]}</span>}
              <span className={'text-secondary'}>The form will be initiated for submission on the selected dates, at the selected time.</span>
            </div>
          </>
        );
      }
      return customIntervalsDatesLayout;
    };

    const renderCustomIntervalsSettings = () => {
      let customIntervalsSettingsLayout = null;
      if (recipient_settings_init?.frequency?.frequency_type?.value === 'custom_interval') {
        const daysInInitiationPeriod = getDaysInInitiationPeriod(firstInitiationDay, lastInitiationDay);
        customIntervalsSettingsLayout = (
          <>
            <div className="d-flex time-select mb-0" style={{ alignItems: 'flex-start' }}>
              <div className={'form-item form-item-select mb-4'} style={{ display: 'inline-flex', flexDirection: 'column' }}>
                <label className="form-label">First initiation day</label>
                <DatePicker
                  isClearable
                  minDate={new Date()}
                  dateFormat={'MM-dd-yyyy'}
                  maxDate={lastInitiationDay}
                  selected={firstInitiationDay}
                  className={'form-control'}
                  placeholderText={'Choose Date'}
                  onChange={(date) => handleInitiationDay(date, 'first_initiation_day')}
                />
                {errors?.frequency?.first_initiation_day && <span className="form-error mt-1">{errors.frequency.first_initiation_day}</span>}
              </div>
              <div className={'form-item form-item-select mb-4'} style={{ display: 'inline-flex', flexDirection: 'column' }}>
                <label className="form-label">Last initiation day (optional)</label>
                <DatePicker
                  isClearable
                  dateFormat={'MM-dd-yyyy'}
                  selected={lastInitiationDay}
                  className={'form-control'}
                  placeholderText={'Choose Date'}
                  minDate={firstInitiationDay ?? new Date()}
                  onChange={(date) => handleInitiationDay(date, 'last_initiation_day')}
                />
                {errors?.frequency?.last_initiation_day && <span className="form-error mt-1">{errors.frequency.last_initiation_day}</span>}
              </div>
            </div>

            <div className={'d-flex mb-0 time-select'} style={{ alignItems: 'flex-start' }}>
              <div className={'form-item form-item-select mb-4'}>
                <label className="form-label">Choose period</label>
                <Select
                  options={qualityFrequencyOptions}
                  className="form-control-select"
                  classNamePrefix="react-select"
                  value={recipient_settings_init?.frequency?.times}
                  placeholder="Choose quality"
                  onChange={(e) => handelFrequencySettings(e, 'times')}
                />
                {errors?.frequency?.times && <span className="form-error mt-1">{errors.frequency?.times[0]}</span>}
              </div>
              <div className={'form-item form-item-select mb-4'}>
                <label className="form-label opacity-0">Type</label>
                <Select
                  options={rangeFullOptions}
                  className="form-control-select"
                  classNamePrefix="react-select"
                  value={recipient_settings_init?.frequency?.type}
                  placeholder="Choose period"
                  onChange={handleChangePeriodType}
                />
                {errors?.frequency?.type && <span className="form-error mt-1">{errors.frequency?.type[0]}</span>}
              </div>
            </div>

            {recipient_settings_init?.frequency?.type?.value === 'weekly' && (
              <div className={'form-item mb-4'}>
                <label htmlFor="">Repeat on these days:</label>
                <div className={'week-grid'}>
                  <div className={'form-control-checkbox-span'}>
                    <input
                      type="checkbox"
                      checked={initData?.frequency?.week_days ? initData?.frequency?.week_days[0] : false}
                      onChange={(e) => handelFrequencyArraySettings(e, 'week_days', 0)}
                    />{' '}
                    <span>Sun</span>
                  </div>
                  <div className={'form-control-checkbox-span'}>
                    <input
                      type="checkbox"
                      checked={initData?.frequency?.week_days ? initData?.frequency?.week_days[1] : false}
                      onChange={(e) => handelFrequencyArraySettings(e, 'week_days', 1)}
                    />{' '}
                    <span>Mon</span>
                  </div>
                  <div className={'form-control-checkbox-span'}>
                    <input
                      type="checkbox"
                      checked={initData?.frequency?.week_days ? initData?.frequency?.week_days[2] : false}
                      onChange={(e) => handelFrequencyArraySettings(e, 'week_days', 2)}
                    />{' '}
                    <span>Tue</span>
                  </div>
                  <div className={'form-control-checkbox-span'}>
                    <input
                      type="checkbox"
                      checked={initData?.frequency?.week_days ? initData?.frequency?.week_days[3] : false}
                      onChange={(e) => handelFrequencyArraySettings(e, 'week_days', 3)}
                    />{' '}
                    <span>Wed</span>
                  </div>
                  <div className={'form-control-checkbox-span'}>
                    <input
                      type="checkbox"
                      checked={initData?.frequency?.week_days ? initData?.frequency?.week_days[4] : false}
                      onChange={(e) => handelFrequencyArraySettings(e, 'week_days', 4)}
                    />{' '}
                    <span>Thu</span>
                  </div>
                  <div className={'form-control-checkbox-span'}>
                    <input
                      type="checkbox"
                      checked={initData?.frequency?.week_days ? initData?.frequency?.week_days[5] : false}
                      onChange={(e) => handelFrequencyArraySettings(e, 'week_days', 5)}
                    />{' '}
                    <span>Fri</span>
                  </div>
                  <div className={'form-control-checkbox-span'}>
                    <input
                      type="checkbox"
                      checked={initData?.frequency?.week_days ? initData?.frequency?.week_days[6] : false}
                      onChange={(e) => handelFrequencyArraySettings(e, 'week_days', 6)}
                    />{' '}
                    <span>Sat</span>
                  </div>
                </div>
                {errors?.frequency?.week_days && <span className="form-error mb-2">{errors.frequency?.week_days[0]}</span>}
              </div>
            )}

            {firstInitiationDay && recipient_settings_init?.frequency?.type?.value === 'monthly' && (
              <div className={'form-item multi-date-picker disabled-change-month mb-4'}>
                <label className="form-label">Initiation Date</label>
                <div className={'clear-datepicker'}>
                  <MultiDatePicker
                    multiple
                    format={'DD'}
                    editable={false}
                    placeholder={'Choose Date'}
                    currentDate={
                      new DateObject({
                        year: 2024,
                        month: 12,
                        day: 1,
                      })
                    }
                    mapDays={({ date }) => {
                      const isActive = lastInitiationDay ? daysInInitiationPeriod.includes(date.day) : true;
                      return {
                        disabled: !isActive,
                      };
                    }}
                    value={recipient_settings_init?.frequency?.month_day}
                    onChange={handleInitiationMonthDays}
                  />
                  {initData?.frequency?.month_day?.length > 0 && (
                    <button onClick={() => handelFrequencySettings([], 'month_day')} className={'clear'}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <g clipPath="url(#clip0_5205_22763)">
                          <path
                            d="M12.5001 7.5013L7.50008 12.5013M7.50008 7.5013L12.5001 12.5013M18.3334 10.0013C18.3334 14.6037 14.6025 18.3346 10.0001 18.3346C5.39771 18.3346 1.66675 14.6037 1.66675 10.0013C1.66675 5.39893 5.39771 1.66797 10.0001 1.66797C14.6025 1.66797 18.3334 5.39893 18.3334 10.0013Z"
                            stroke="#D92D20"
                            strokeWidth="1.67"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_5205_22763">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  )}
                </div>

                {errors?.frequency?.month_day && <span className="form-error mb-2">{errors.frequency?.month_day[0]}</span>}
              </div>
            )}

            {recipient_settings_init?.frequency?.type?.value === 'yearly' && (
              <>
                <div className={'form-item mb-4'}>
                  <label htmlFor="">Repeat on these months:</label>
                  <div className={'week-grid month-grid'}>
                    {getMonthsInInitiationPeriod(firstInitiationDay, lastInitiationDay).map((item, index) => {
                      const monthNum = getMonthIndexByName(item);
                      const isChecked = get(initData, `frequency.months[${monthNum}]`);
                      return (
                        <div key={item} className={'form-control-checkbox-span'}>
                          <input type="checkbox" checked={isChecked} onChange={(e) => handelFrequencyArraySettings(e, 'months', monthNum)} />
                          <span>{item}</span>
                        </div>
                      );
                    })}
                  </div>
                  {errors?.frequency?.months && <span className="form-error mb-2">{errors.frequency?.months[0]}</span>}
                </div>
                <div className={'form-item multi-date-picker disabled-change-month mb-4'}>
                  <label className="form-label">Initiation Date</label>
                  <div className={'clear-datepicker'}>
                    <MultiDatePicker
                      multiple
                      format={'DD'}
                      editable={false}
                      placeholder={'Choose Date'}
                      currentDate={
                        new DateObject({
                          year: 2024,
                          month: 12,
                          day: 1,
                        })
                      }
                      mapDays={({ date }) => {
                        const isActive = lastInitiationDay ? daysInInitiationPeriod.includes(date.day) : true;
                        return {
                          disabled: !isActive,
                        };
                      }}
                      value={recipient_settings_init?.frequency?.month_day}
                      onChange={handleInitiationMonthDays}
                    />
                    {initData?.frequency?.month_day?.length > 0 && (
                      <button onClick={() => handelFrequencySettings([], 'month_day')} className={'clear'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <g clipPath="url(#clip0_5205_22763)">
                            <path
                              d="M12.5001 7.5013L7.50008 12.5013M7.50008 7.5013L12.5001 12.5013M18.3334 10.0013C18.3334 14.6037 14.6025 18.3346 10.0001 18.3346C5.39771 18.3346 1.66675 14.6037 1.66675 10.0013C1.66675 5.39893 5.39771 1.66797 10.0001 1.66797C14.6025 1.66797 18.3334 5.39893 18.3334 10.0013Z"
                              stroke="#D92D20"
                              strokeWidth="1.67"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_5205_22763">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    )}
                  </div>

                  {errors?.frequency?.month_day && <span className="form-error mb-2">{errors.frequency?.month_day[0]}</span>}
                </div>
              </>
            )}

            {recipient_settings_init?.frequency?.type && (
              <div className={'form-item form-item-select form-item-time form-item-time-short mb-4'}>
                <label className="form-label">Initiation Time</label>
                <DatePicker
                  selected={recipient_settings_init?.frequency?.time}
                  className={'form-control'}
                  showTimeSelect
                  isClearable
                  showTimeSelectOnly
                  placeholderText={'Choose Time'}
                  timeIntervals={60}
                  timeCaption=""
                  dateFormat="h:mm aa"
                  onChange={(date) => handelFrequencySettings(date, 'time')}
                />
                {errors?.frequency?.time && <span className="form-error mb-2 ">{errors.frequency?.time[0]}</span>}
                <span className={'text-secondary'}>The form will be initiated for submission on the selected dates, at the selected time.</span>
              </div>
            )}
          </>
        );
      }
      return customIntervalsSettingsLayout;
    };

    layout = (
      <div className="form-builder-setting-tab-content">
        <div className="form-builder-recipient-settings">
          <div className="form-control-item-settings">
            <div className="form-settings-label">
              <label className="form-label form-label-big">Does the form have frequency?</label>
              <span>Define whether there is a recurring pattern or regularity associated with how often this form needs to be filled out or submitted.</span>
            </div>
            <div className={cx('form-settings-inputs', { disabled: isDisabledSettings })}>
              <div className="d-flex">
                <div className={`form-control-radio`}>
                  <input
                    name="will_have_frequency"
                    type="radio"
                    disabled={isDisabledSettings}
                    checked={recipient_settings_init.will_have_frequency === true}
                    onChange={() => handelSettings(true, 'will_have_frequency')}
                  />
                  <span>Yes</span>
                </div>
                <div className={`form-control-radio`}>
                  <input
                    name="will_have_frequency"
                    type="radio"
                    disabled={isDisabledSettings}
                    checked={recipient_settings_init.will_have_frequency === false}
                    onChange={() => handelSettings(false, 'will_have_frequency')}
                  />
                  <span>No</span>
                </div>
              </div>
              <span className="form-error mb-2 ">{errors?.will_have_frequency && errors.will_have_frequency[0]}</span>
              {recipient_settings_init.will_have_frequency && (
                <>
                  <div className={'form-item form-item-select mb-4'}>
                    <label className="form-label">Frequency</label>
                    <Select
                      options={frequencyOptions}
                      className="form-control-select"
                      classNamePrefix="react-select"
                      value={recipient_settings_init?.frequency?.frequency_type}
                      placeholder="Choose"
                      onChange={(e) => handelFrequencySettings(e, 'frequency_type')}
                    />
                    {errors?.frequency?.frequency_type && <span className="form-error mb-2">{errors.frequency?.frequency_type[0]}</span>}
                  </div>

                  {renderCustomDatesSettings()}
                  {renderCustomIntervalsSettings()}
                </>
              )}
              {/*<span className="form-error mb-2 d-flex">{errors?.submitters?.contact_person_submitter_type && errors.submitters?.contact_person_submitter_type[0]}</span>*/}
            </div>
          </div>

          {recipient_settings_init.will_have_frequency && (
            <div className="form-control-item-settings">
              <div className="form-settings-label">
                <label className="form-label form-label-big">Specify Submission Deadline</label>
                <span>Specify submitting deadline or the time frame.</span>
              </div>
              <div className="form-settings-inputs">
                <div className={'form-item form-item-select mb-4'}>
                  <label className="form-label">Submission Deadline</label>
                  <Select
                    options={typeOptions}
                    className="form-control-select"
                    classNamePrefix="react-select"
                    value={recipient_settings_init?.submission_deadline_settings?.deadline_type}
                    placeholder="Choose"
                    onChange={(e) => handelDeadLineSettings(e, 'deadline_type')}
                  />
                  <span className="form-error mt-1">
                    {errors?.submission_deadline_settings?.deadline_type && errors.submission_deadline_settings?.deadline_type[0]}
                    {errors?.submission_deadline_settings && errors.submission_deadline_settings[0]}
                  </span>
                </div>
                {recipient_settings_init?.submission_deadline_settings?.deadline_type.value === 'SAME_DAY' && (
                  <div className={'form-item form-item-select form-item-time mb-4'}>
                    <label className="form-label">Submission End Time</label>
                    <DatePicker
                      selected={recipient_settings_init?.submission_deadline_settings?.time}
                      onChange={(date) => handelDeadLineSettings(date, 'time')}
                      className={'form-control'}
                      showTimeSelect
                      isClearable
                      showTimeSelectOnly
                      timeIntervals={60}
                      timeCaption=""
                      placeholderText={'Choose Time'}
                      dateFormat="h:mm aa"
                    />
                    <span className="form-error mb-2 d-flex">{errors?.submission_deadline_settings?.time && errors.submission_deadline_settings?.time[0]}</span>
                  </div>
                )}

                {recipient_settings_init?.submission_deadline_settings?.deadline_type.value === 'TIME_FRAME' && (
                  <div className={'d-flex time-select'}>
                    <div className={'form-item form-item-select mb-4'}>
                      <label className="form-label">Choose period</label>
                      <Select
                        options={deadlineRangeOptions}
                        className="form-control-select"
                        classNamePrefix="react-select"
                        value={recipient_settings_init?.submission_deadline_settings?.deadline_period_type}
                        placeholder="Choose period"
                        onChange={(e) => handelDeadLineSettings(e, 'deadline_period_type')}
                      ></Select>
                      <span className="form-error mb-2 d-flex">
                        {errors?.submission_deadline_settings?.deadline_period_type && errors.submission_deadline_settings?.deadline_period_type[0]}
                      </span>
                    </div>
                    <div className={'form-item form-item-select mb-4'}>
                      <label className="form-label opacity-0">End Time</label>
                      <Select
                        options={qualityOptions}
                        className="form-control-select"
                        classNamePrefix="react-select"
                        value={recipient_settings_init?.submission_deadline_settings?.deadline_period}
                        placeholder="Choose quality"
                        onChange={(e) => handelDeadLineSettings(e, 'deadline_period')}
                      />
                      <span className="form-error mb-2 d-flex">
                        {errors?.submission_deadline_settings?.deadline_period && errors.submission_deadline_settings?.deadline_period[0]}
                      </span>
                    </div>
                  </div>
                )}

                {/*<span className="form-error mb-2 d-flex">{errors?.submitters?.contact_person_submitter_type && errors.submitters?.contact_person_submitter_type[0]}</span>*/}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  return layout;
};
