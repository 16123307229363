import React from 'react';

export const MultilineElement = function FromElement({ element }) {
  return (
    <>
      <label htmlFor={element.id} className="form-label">
        {element.options.label} <span className="required">{element.options.requiredFor ? '*' : ''}</span>
      </label>
      <textarea name={element.options.name} id={element.id} readOnly className={`form-control form-control-${element.options.size}`}></textarea>
      {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}
    </>
  );
};
