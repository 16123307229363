import React, { useRef, useState } from 'react';
import { useDrag } from 'react-dnd';
import { updateForm } from '../../servises/form';
import { useNavigate } from 'react-router-dom';
import { usePermission } from '../../container/permissionContext';
import { Tooltip } from 'react-tooltip';
import mobile from 'is-mobile';
import cx from 'classnames';
import { useIsFormsAvailableToMePage } from '../../hook/pages/useIsFormsAvailableToMePage';
import useOutsideClick from '../../hook/useOutsideClick';

export const FormItem = function FormItem(props) {
  const { item, handelError, handelMove, url, isPublic, handelOpenPublicModal, handelArchived, isSearchItem, deleteForm, activateForm, openFormManagementModal } = props;
  const { deleted_at, original_form_deleted_at, can_manage_forms } = item ?? {};
  const navigate = useNavigate();
  const { hasPermissionByCodeName, isEmployee } = usePermission();
  const isFormsAvailableToMePage = useIsFormsAvailableToMePage();
  const menuButtonWrapperRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const [, drag] = useDrag(() => ({
    type: 'folder',
    canDrag: !isPublic && !!hasPermissionByCodeName('corporate_library_management'),
    item: { item },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult?.item) {
        if (dropResult?.item.id !== item.item.id) {
          const formData = {
            folder_id: dropResult.item.id,
          };
          if (!isPublic) {
            updateForm(item.item.id, formData)
              .then(() => {
                handelMove(dropResult.item.id);
              })
              .catch(() => {
                handelError(true);
              });
          }
        }
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const closeMenu = () => setIsOpen(false);

  useOutsideClick(menuButtonWrapperRef, closeMenu);

  const isUserAgentMobile = (req) => mobile({ ua: req });

  const isUserAgentTablet = (req) => {
    return mobile({ ua: req, tablet: true, featureDetect: true });
  };

  const { recipient_settings, submission_settings } = item;
  let isDisabledMobile =
    !isPublic &&
    (recipient_settings?.can_initiator_select_recipients ||
      submission_settings?.can_initiator_select_submitters ||
      submission_settings?.can_initiator_specify_period_availability_and_frequency ||
      submission_settings?.filling_type === 'PUBLIC');

  if (isSearchItem) {
    const { original_recipient_settings, original_submission_settings } = item;
    isDisabledMobile =
      !isPublic &&
      (original_recipient_settings?.can_initiator_select_recipients ||
        original_submission_settings?.can_initiator_select_submitters ||
        original_submission_settings?.can_initiator_specify_period_availability_and_frequency ||
        original_submission_settings?.filling_type === 'PUBLIC');
  }

  const handleNavigate = () => navigate(url ?? `/initialization-form/${item.id}`);

  const handleDeleteForm = async (e) => {
    e.stopPropagation();
    setIsOpen(false);
    if (deleteForm) {
      await deleteForm(item.id);
    }
  };

  const handleActivateForm = async (e) => {
    e.stopPropagation();
    setIsOpen(false);
    if (activateForm) {
      await activateForm(item.id);
    }
  };

  const handleOpenFormManagementModal = (e) => {
    e.stopPropagation();
    setIsOpen(false);
    if (openFormManagementModal) {
      openFormManagementModal(item.id);
    }
  };

  const renderActionsMenuButton = () => {
    return (
      <button
        className="btn btn-outline btn-small"
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M9.99984 10.832C10.4601 10.832 10.8332 10.4589 10.8332 9.9987C10.8332 9.53846 10.4601 9.16536 9.99984 9.16536C9.5396 9.16536 9.1665 9.53846 9.1665 9.9987C9.1665 10.4589 9.5396 10.832 9.99984 10.832Z"
            fill="#667085"
          />
          <path
            d="M9.99984 4.9987C10.4601 4.9987 10.8332 4.6256 10.8332 4.16536C10.8332 3.70513 10.4601 3.33203 9.99984 3.33203C9.5396 3.33203 9.1665 3.70513 9.1665 4.16536C9.1665 4.6256 9.5396 4.9987 9.99984 4.9987Z"
            fill="#667085"
          />
          <path
            d="M9.99984 16.6654C10.4601 16.6654 10.8332 16.2923 10.8332 15.832C10.8332 15.3718 10.4601 14.9987 9.99984 14.9987C9.5396 14.9987 9.1665 15.3718 9.1665 15.832C9.1665 16.2923 9.5396 16.6654 9.99984 16.6654Z"
            fill="#667085"
          />
          <path
            d="M9.99984 10.832C10.4601 10.832 10.8332 10.4589 10.8332 9.9987C10.8332 9.53846 10.4601 9.16536 9.99984 9.16536C9.5396 9.16536 9.1665 9.53846 9.1665 9.9987C9.1665 10.4589 9.5396 10.832 9.99984 10.832Z"
            stroke="#667085"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.99984 4.9987C10.4601 4.9987 10.8332 4.6256 10.8332 4.16536C10.8332 3.70513 10.4601 3.33203 9.99984 3.33203C9.5396 3.33203 9.1665 3.70513 9.1665 4.16536C9.1665 4.6256 9.5396 4.9987 9.99984 4.9987Z"
            stroke="#667085"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.99984 16.6654C10.4601 16.6654 10.8332 16.2923 10.8332 15.832C10.8332 15.3718 10.4601 14.9987 9.99984 14.9987C9.5396 14.9987 9.1665 15.3718 9.1665 15.832C9.1665 16.2923 9.5396 16.6654 9.99984 16.6654Z"
            stroke="#667085"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    );
  };

  const renderActions = () => {
    let layout = null;
    if (isPublic && item.filling_type === 'PUBLIC' && hasPermissionByCodeName('corporate_library_management')) {
      layout = (
        <div ref={menuButtonWrapperRef} className={cx('library-item-button', { 'library-item-button-open': isOpen })}>
          {renderActionsMenuButton()}
          {isOpen && (
            <div className="library-item-menu">
              {deleted_at ? (
                !original_form_deleted_at && (
                  <button style={{ color: '#12b76a' }} className="btn btn-full" onClick={handleActivateForm}>
                    <svg className="mr-2" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1484_3438)">
                        <path
                          d="M18.3332 9.2333V9.99997C18.3321 11.797 17.7503 13.5455 16.6743 14.9848C15.5983 16.4241 14.0859 17.477 12.3626 17.9866C10.6394 18.4961 8.79755 18.4349 7.1119 17.8121C5.42624 17.1894 3.98705 16.0384 3.00897 14.5309C2.03089 13.0233 1.56633 11.24 1.68457 9.4469C1.80281 7.65377 2.49751 5.94691 3.66507 4.58086C4.83263 3.21482 6.41049 2.26279 8.16333 1.86676C9.91617 1.47073 11.7501 1.65192 13.3915 2.3833M18.3332 3.3333L9.99984 11.675L7.49984 9.17497"
                          stroke="#12B76A"
                          strokeWidth="1.67"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1484_3438">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    Activate
                  </button>
                )
              ) : (
                <button
                  className="btn btn-full"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(false);
                    handelArchived(item);
                  }}
                >
                  <i className="icon-icon-x mr-2" />
                  Archive
                </button>
              )}
              {isPublic && (
                <button className="btn btn-full btn-remove" onClick={handleDeleteForm}>
                  <i className="icon-remove mr-2" />
                  Delete
                </button>
              )}
            </div>
          )}
        </div>
      );
    } else if (isFormsAvailableToMePage && can_manage_forms) {
      layout = (
        <div ref={menuButtonWrapperRef} className={cx('library-item-button', { 'library-item-button-open': isOpen })}>
          {renderActionsMenuButton()}
          {isOpen && openFormManagementModal && can_manage_forms && (
            <div className="library-item-menu">
              <button className="btn btn-full" onClick={handleOpenFormManagementModal}>
                <i className="icon-settings mr-2" />
                Form management
              </button>
            </div>
          )}
        </div>
      );
    }
    return layout;
  };

  return (
    <>
      <div
        ref={drag}
        className={cx('library-item cursor-pointer', { 'library-item-disabled-mobile': isDisabledMobile })}
        onClick={() => {
          if (!isPublic) {
            if (isUserAgentMobile() || isUserAgentTablet()) {
              if (!isDisabledMobile) {
                handleNavigate();
              }
              return;
            }

            handleNavigate();
            return;
          }

          if (isEmployee()) {
            handleNavigate();
          } else {
            handelOpenPublicModal(item.id);
          }
        }}
      >
        <div className="library-item-text" data-tooltip-id={`table-tooltip-${item.id}`}>
          <img src="/images/file-big-icon.png" alt="" /> {item.name}
          <Tooltip opacity={1} id={`table-tooltip-${item.id}`} place="top" content={item.name} />
        </div>
        {renderActions()}
      </div>
    </>
  );
};
