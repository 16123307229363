import React from 'react';
import Rating from 'react-rating';

export const RatingElement = function FromElement({ element }) {
  return (
    <>
      <label className="form-label w-full">
        {element.options.name} <span className="required">{element.options.requiredFor ? '*' : ''}</span>
      </label>
      <Rating className={`form-control-star form-control-star-${element.options.size}`} readonly fractions={2} emptySymbol="icon-star-empty" fullSymbol="icon-star-full" />
      {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}
    </>
  );
};
