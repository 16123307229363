import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

/**
 * @param {Date} localDate - "YYYY-MM-DDTHH:mm:ss".
 * @returns {Date}.
 * @throws {Error}.
 */
export const localToUTCDate = (localDate) => {
  let utcDate;
  try {
    if (localDate) {
      if (!(localDate instanceof Date) || isNaN(localDate.getTime())) {
        throw new Error(`Invalid local date: ${localDate}`);
      }
      utcDate = dayjs(localDate).utc().toDate();
    }
  } catch (e) {
    console.log(e);
  }
  return utcDate;
};
