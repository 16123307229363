import client from '../../api';
import { CancelToken } from 'axios';
import qs from 'qs';

let cancel;

export const getCorporateFormManagementForms = async (corporateFormId, params) => {
  try {
    if (!corporateFormId) return;
    if (cancel !== undefined) {
      cancel();
    }
    return await client.get(`/api/corporate-library/${corporateFormId}/form-management/initiated-forms`, {
      params,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  } catch (e) {
    console.log(e);
    return e;
  }
};
// Form details
export const getCorporateFormManagementFormDetails = async (corporateFormId, initiatedFormId) => {
  try {
    if (!corporateFormId || !initiatedFormId) return;
    if (cancel !== undefined) {
      cancel();
    }
    return await client.get(`/api/corporate-library/${corporateFormId}/form-management/initiated-forms/${initiatedFormId}`, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
    return e;
  }
};
export const getCorporateFormManagementInitiators = async (corporateFormId) => {
  try {
    if (!corporateFormId) return;
    if (cancel !== undefined) {
      cancel();
    }
    return await client.get(`/api/corporate-library/${corporateFormId}/form-management/initiated-forms/initiators`, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
    return e;
  }
};
export const stopCorporateFormManagementFormInitiation = async (corporateFormId, initiatedFormId) => {
  try {
    if (!corporateFormId || !initiatedFormId) return;
    if (cancel !== undefined) {
      cancel();
    }
    return await client.post(`/api/corporate-library/${corporateFormId}/form-management/initiated-forms/${initiatedFormId}/stop/`, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
    return e;
  }
};
export const removeCorporateFormManagementFormSubmissions = async (corporateFormId, initiatedFormId) => {
  try {
    if (!corporateFormId || !initiatedFormId) return;
    if (cancel !== undefined) {
      cancel();
    }
    return await client.post(`/api/corporate-library/${corporateFormId}/form-management/initiated-forms/${initiatedFormId}/stop/?hard_stop=true`, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
    return e;
  }
};
