import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import { createPosition, editPosition } from '../../servises/positions';

function FacilityModal({ isOpen, companyName, closeModal, userGroupData, handelCreateUserGroup, facilities, facility }) {
  const [errorUserGroup, setErrorUserGroup] = useState({});
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [userGroup, setUserGroup] = useState({
    name: '',
    facility_id: '',
    edition_name: [],
  });

  useEffect(() => {
    setFacilityOptions(facilities.filter((item) => !item.deleted_at).map((item) => ({ value: item.id, label: item.name })));
  }, [facilities]);

  useEffect(() => {
    if (userGroupData) {
      const newState = { ...userGroupData };
      newState['facility_id'] = { value: newState.facility.id, label: newState.facility.name };
      newState['edition_name'] = [];
      setUserGroup(newState);
    } else {
      setUserGroup({
        name: '',
        facility_id: '',
        edition_name: [],
      });
    }
  }, [userGroupData]);

  const handleChange = (value, key) => {
    setUserGroup({
      ...userGroup,
      [key]: value,
    });

    if (errorUserGroup.hasOwnProperty(key)) {
      delete errorUserGroup[key];
    }
  };
  const handleChangeName = (value, item) => {
    const newState = [...userGroup.edition_name];

    newState[newState.findIndex((name) => name.id === item.id)].name = value;

    setUserGroup({
      ...userGroup,
      edition_name: [...newState],
    });
  };

  const resetForm = () => {
    setUserGroup({
      name: '',
      facility_id: facility ? { value: facility.id, label: facility.name } : '',
      edition_name: [],
    });
    setErrorUserGroup({});
  };
  const handelCloseModal = () => {
    closeModal();
    resetForm();
  };

  const addMorePositions = () => {
    setUserGroup((prevState) => {
      const newState = { ...prevState };
      newState.edition_name = [...newState.edition_name, { id: new Date().getTime(), name: '' }];
      return newState;
    });
  };
  const handleSubmit = async () => {
    if (userGroupData) {
      const formData = { name: userGroup.name };

      delete formData.id;
      delete formData.deleted_at;
      delete formData.created_at;

      editPosition(formData, userGroupData.id)
        .then(() => {
          resetForm();
          handelCreateUserGroup();
        })
        .catch((e) => {
          if (e?.response?.data) {
            setErrorUserGroup(JSON.parse(JSON.stringify(e.response.data)));
          }
        });
    } else {
      const formData = { names: [userGroup.name, ...userGroup.edition_name.map((item) => item.name)], facility_id: userGroup.facility_id.value };
      createPosition(formData)
        .then((res) => {
          resetForm();
          handelCreateUserGroup();
        })
        .catch((e) => {
          if (e?.response?.data) {
            setErrorUserGroup(JSON.parse(JSON.stringify(e.response.data)));
          }
        });
    }
  };

  const handelRemove = (item) => {
    let newState = [...userGroup.edition_name];
    newState = newState.filter((name) => name.id !== item.id);
    setUserGroup({
      ...userGroup,
      edition_name: newState,
    });
  };

  return (
    <Modal className="modal-default" isOpen={isOpen} onRequestClose={handelCloseModal} contentLabel="Example Modal" ariaHideApp={false}>
      <h2 className="modal-header">
        {userGroupData ? 'Edit' : 'Add New'} Position{' '}
        <button className="btn btn-icon" onClick={handelCloseModal}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>
      {!!companyName && <h3 className="modal-subheader">for {companyName} company</h3>}
      <div className="form-wrapper">
        <h3 className="form-wrapper-title">
          <img src="/images/positions-icon.svg" alt="" />
          Position information
        </h3>
        <div className={`form-item form-item-select form-control-item-settings ${errorUserGroup.facility_id ? 'form-item-error' : ''}`}>
          <label className="form-label">Facility</label>
          <Select
            isDisabled={userGroupData}
            options={facilityOptions}
            className="form-control-select"
            classNamePrefix="react-select"
            value={userGroup.facility_id}
            onChange={(e) => handleChange(e, 'facility_id')}
            placeholder="Select Facility"
          ></Select>
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorUserGroup.facility_id && errorUserGroup.facility_id[0]}</span>
        </div>
        <div
          className={`form-item ${(errorUserGroup.names && errorUserGroup.names[0]) || (errorUserGroup.non_field_errors && errorUserGroup.non_field_errors[0]) ? 'form-item-error' : ''}`}
        >
          <label className="form-label">Name</label>
          <input placeholder="Enter name" value={userGroup.name} onChange={(e) => handleChange(e.target.value, 'name')} type="text" className="form-control" />
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorUserGroup.names && errorUserGroup.names[0] && errorUserGroup.names[0]}</span>
          <span className="form-error">{errorUserGroup.non_field_errors && errorUserGroup.non_field_errors[0] && errorUserGroup.non_field_errors[0]}</span>
        </div>

        {userGroup.edition_name.map((item, index) => (
          <div key={item.id} className={`form-item ${errorUserGroup.names && errorUserGroup.names[index + 1] ? 'form-item-error' : ''}`}>
            <label className="form-label">Name</label>
            <input placeholder="Enter name" value={item.name} onChange={(e) => handleChangeName(e.target.value, item)} type="text" className="form-control" />
            <i onClick={() => handelRemove(item)} className="remove-icon cursor-pointer icon-remove" />
            <span className="form-error">{errorUserGroup.names && errorUserGroup.names[index + 1] && errorUserGroup.names[index + 1]}</span>
          </div>
        ))}
      </div>
      {!userGroupData && (
        <span className="add-facility cursor-pointer" onClick={() => addMorePositions()}>
          <i className="icon-plus-svgrepo-com"></i> Add positions
        </span>
      )}

      <div className="modal-footer">
        <button className="btn btn-outline" onClick={handelCloseModal}>
          Cancel
        </button>
        <button className="btn" onClick={handleSubmit}>
          Save
        </button>
      </div>
    </Modal>
  );
}

export default FacilityModal;
