import React from 'react';
import { useDrop } from 'react-dnd';

export const BreadcrumbsItem = function Breadcrumbs({ item, goToLevel, isLast }) {
  const [, drop] = useDrop(() => ({
    accept: ['folder', 'form', 'file'],
    drop: () => ({ item }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));
  return (
    <>
      <li ref={item.id ? drop : null} className={`breadcrumbs-item ${isLast ? 'breadcrumbs-item-last' : ''}`}>
        <span className={'cursor-pointer'} onClick={() => goToLevel(item.id ? item.id : item.url, item.id ? 'folder' : 'root')}>
          {item.name}
        </span>
      </li>
    </>
  );
};
