import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../layout/form-builder';
import { getAvailableGeneralFolder } from '../../servises/folder/form-avaliable';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getGeneralFromById, updateFormAdmin } from '../../servises/form';
import useOutsideClick from '../../hook/useOutsideClick';
import { RenderForm } from '../../components/init-form/render-form';
const MenuItem = ({ data, handelFolder, folder }) => {
  const { id, name, folders } = data;

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (name === 'Forms Available To Me') {
      setIsActive(true);
    } else {
      setIsActive(folder && folder.id === id);
    }
  }, [folder, id, name]);

  return (
    <li className={isActive ? 'active-folder' : ''}>
      <span
        className={'cursor-pointer'}
        onClick={() => {
          if (name !== 'Forms Available To Me') {
            handelFolder(data);
          }
        }}
      >
        {!folders.length && (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M20.0005 17.4C20.0005 17.8774 19.8108 18.3352 19.4733 18.6728C19.1357 19.0104 18.6779 19.2 18.2005 19.2H3.80049C3.3231 19.2 2.86526 19.0104 2.5277 18.6728C2.19013 18.3352 2.00049 17.8774 2.00049 17.4V4.8C2.00049 4.32261 2.19013 3.86477 2.5277 3.52721C2.86526 3.18964 3.3231 3 3.80049 3H8.30049L10.1005 5.7H18.2005C18.6779 5.7 19.1357 5.88964 19.4733 6.22721C19.8108 6.56477 20.0005 7.02261 20.0005 7.5V17.4Z"
                stroke="#98A2B3"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </>
        )}
        {!!folders.length && (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
              <path d="M2.50049 3.75L5.00049 6.25L7.50049 3.75" stroke="#98A2B3" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path
                  d="M20.7502 11.5484V20.2984C20.7502 20.4841 20.6753 20.6621 20.5419 20.7934C20.4086 20.9247 20.2277 20.9984 20.0391 20.9984H4.7502C4.53802 20.9984 4.33454 20.9155 4.18451 20.7678C4.03448 20.6201 3.9502 20.4198 3.9502 20.2109V9.18594C3.9502 8.97708 4.03448 8.77678 4.18451 8.62909C4.33454 8.48141 4.53802 8.39844 4.7502 8.39844H9.28353C9.45662 8.39844 9.62505 8.4537 9.76353 8.55594L12.5369 10.6034C12.6753 10.7057 12.8438 10.7609 13.0169 10.7609H19.9502C20.1624 10.7609 20.3659 10.8439 20.5159 10.9916C20.6659 11.1393 20.7502 11.3396 20.7502 11.5484Z"
                  stroke="#98A2B3"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.05029 7.34922V4.98672C6.05029 4.77786 6.13985 4.57756 6.29925 4.42987C6.45866 4.28219 6.67486 4.19922 6.90029 4.19922H11.717C11.9009 4.19922 12.0798 4.25448 12.227 4.35672L15.1736 6.40422C15.3208 6.50645 15.4997 6.56172 15.6836 6.56172H23.0503C23.2757 6.56172 23.4919 6.64469 23.6513 6.79237C23.8107 6.94006 23.9003 7.14036 23.9003 7.34922V16.0992C23.9003 16.1911 23.8807 16.2822 23.8428 16.3671C23.8048 16.452 23.7492 16.5292 23.679 16.5942C23.6088 16.6592 23.5255 16.7108 23.4339 16.7459C23.3422 16.7811 23.244 16.7992 23.1447 16.7992H21.7003"
                  stroke="#98A2B3"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </>
        )}
        {name}
      </span>

      {!!folders.length && (
        <ul className="folder-select-ul-inner">
          {folders.map((i) => (
            <MenuItem data={i} key={i.id} handelFolder={handelFolder} folder={folder} />
          ))}
        </ul>
      )}
    </li>
  );
};

function FromBuilderPlaceInForm() {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState('corporate-library');
  const [foldersList, setFoldersList] = useState([]);
  const [current_folder, setFolder] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [errors, setErrors] = useState(null);
  const [formData, setFormData] = useState(null);
  const wrapperRef = useRef(null);
  const navigation = useNavigate();
  const closeMenu = () => {
    setIsOpen(false);
  };

  useOutsideClick(wrapperRef, closeMenu);

  useEffect(() => {
    init(null);
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (id) {
      getGeneralFromById(id)
        .then((res) => {
          setFormData(res);
        })
        .catch((e) => console.log(e));
    }
  }, [id]);

  useEffect(() => {
    if (formData && formData.folder_id && foldersList.length) {
      const folders = getFoldersList(foldersList);
      setFolder(folders.find((item) => item.id === formData.folder_id));
    }
  }, [formData, foldersList]);

  const changeActiveTab = (index) => {
    if (index === 'corporate-library') {
      setActiveTab(index);
    }
  };

  const init = (folder_id = null) => {
    getAvailableGeneralFolder({ folder_id })
      .then((res) => {
        // setFoldersList(res[0].folders)
        setFoldersList(res);
      })
      .catch((e) => console.log(e));
  };

  const getFoldersList = (folders) => {
    const list = [];
    getProp(folders);

    function getProp(o) {
      o.forEach((item) => {
        list.push(item);
        if (item.folders.length) {
          getProp(item.folders);
        }
      });
    }
    return list;
  };

  const saveInFolder = () => {
    const formData = {
      folder_id: current_folder ? current_folder.id : null,
      is_draft: !current_folder?.id,
    };
    updateFormAdmin(id, formData)
      .then(() => {
        navigation('/general-library/forms');
      })
      .catch((e) => {
        if (e?.response?.data) {
          setErrors(e.response.data);
        }
        console.log(e);
      });
  };

  const handelFolder = (item) => {
    setFolder(item);
    setIsOpen(false);
  };

  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [tabItem, setTabItem] = useState('desktop');
  const [orientation, setOrientation] = useState('vertical');

  const togglePreview = (isOpen) => {
    setIsOpenPreview(isOpen);
  };

  return (
    <Layout title="Form Builder - Place the form" pageClassName={`${isOpenPreview ? 'modal-open' : ''} form-builder-wrapper-settings`} togglePreview={togglePreview}>
      <div className="react-tabs">
        <div className="form-builder-aside-left p-0">
          <div className="form-builder-aside-tab">
            <div className={`react-tabs__tab ${activeTab === 'corporate-library' ? 'react-tabs__tab--selected' : ''}`} onClick={() => changeActiveTab('corporate-library')}>
              General Folders
            </div>
          </div>
        </div>
        <div className="form-builder-setting-tab">
          <div className="form-builder-setting-tab-content">
            <div className="form-builder-recipient-settings">
              {formData?.name && (
                <div className="from-builder-title from-builder-title-setting">
                  <h1>
                    <img src="/images/file-big-icon.png" className="mr-2" alt="" />
                    {formData.name}
                  </h1>
                </div>
              )}
              <p className="info-text">
                <i className="icon-icon-info"></i> Notifications Settings has manage who can see and fill the form. It’ll not be able to change the form template or show this
                filled template for another users.
              </p>
              <span className="form-error mb-2 d-flex">{errors?.non_field_errors && errors.non_field_errors[0]}</span>
              <div className={`form-item form-item-select form-control-item-settings`}>
                <div className="form-settings-label">
                  <label className="form-label form-label-big">Place the form in the General Library</label>
                  <span>
                    By default, all created forms are saved in the 'Drafts' section, but you also need to place the form in the following folder.<br></br>
                    <br></br>
                    Folder can contain other nested folders, so you will have the ability to delve deeper into the folder structure.{' '}
                  </span>
                </div>
                <div className="form-settings-inputs">
                  <div className={`form-control-item-group form-item form-item-select`}>
                    <label className="form-label">General Folders</label>
                    <div className="folder-select" ref={wrapperRef}>
                      <div className="folder-select-input cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
                        {!current_folder && <span className="folder-select-input-placeholder">Choose folder</span>}
                        {current_folder && current_folder.name}
                        <i className="icon-arrow-down"></i>
                      </div>
                      {isOpen && (
                        <div className="folder-select-menu">
                          <ul className="folder-select-ul">
                            {foldersList.map((folder) => (
                              <MenuItem data={folder} key={folder.id} folder={current_folder} handelFolder={handelFolder}></MenuItem>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                    <span className="form-error">{errors?.folder_id && errors.folder_id[0]}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-control-actions">
              <Link to={`/form-builder/${id}/settings`} className="btn btn-outline">
                Back
              </Link>
              <button className="btn" onClick={saveInFolder}>
                Save here
              </button>
            </div>
          </div>
        </div>
      </div>

      {isOpenPreview && (
        <div className={'preview-modal init-page'}>
          <div className={'header-wrapper'}>
            <div className={'header-left'}>
              <div className="header-logo">
                <img src="/images/logo.svg" alt="" />
              </div>
            </div>
            <div className={'header-center tabs'}>
              <div className={`tabs-nav-item ${tabItem === 'desktop' ? 'tabs-nav-item-active' : ''}`} onClick={() => setTabItem('desktop')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M4.36719 15L16.299 15C16.958 15 17.4922 14.4404 17.4922 13.75V5C17.4922 4.30964 16.958 3.75 16.299 3.75L4.36719 3.75C3.70821 3.75 3.17401 4.30964 3.17401 5L3.17401 13.75C3.17401 14.4404 3.70821 15 4.36719 15Z"
                    stroke="#667085"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M12.72 17.5H7.94727" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M3.1748 11.875H17.493" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M10.334 15V17.5" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                Desktop
              </div>
              <div className={`tabs-nav-item ${tabItem === 'tablet' ? 'tabs-nav-item-active' : ''}`} onClick={() => setTabItem('tablet')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M15.9668 16.25V3.75C15.9668 3.05964 15.4326 2.5 14.7736 2.5L5.22816 2.5C4.56919 2.5 4.03498 3.05964 4.03498 3.75V16.25C4.03498 16.9404 4.56919 17.5 5.22816 17.5H14.7736C15.4326 17.5 15.9668 16.9404 15.9668 16.25Z"
                    stroke="#667085"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M4.03418 5H15.966" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M4.03418 15H15.966" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                Tablet
              </div>
              <div className={`tabs-nav-item ${tabItem === 'phone' ? 'tabs-nav-item-active' : ''}`} onClick={() => setTabItem('phone')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path
                    d="M12.3881 1.6875H5.94496C5.35188 1.6875 4.87109 2.19118 4.87109 2.8125V15.1875C4.87109 15.8088 5.35188 16.3125 5.94496 16.3125H12.3881C12.9812 16.3125 13.462 15.8088 13.462 15.1875V2.8125C13.462 2.19118 12.9812 1.6875 12.3881 1.6875Z"
                    stroke="#667085"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M4.87109 3.9375H13.462" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M4.87109 14.0625H13.462" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                Phone
              </div>
            </div>
            <div className={'header-right'}>
              <button onClick={() => togglePreview(false)} className={'btn btn-outline d-flex'}>
                <svg className={'mr-2'} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 4.375C3.75 4.375 1.25 10.0006 1.25 10.0006C1.25 10.0006 3.75 15.625 10 15.625C16.25 15.625 18.75 10.0006 18.75 10.0006C18.75 10.0006 16.25 4.375 10 4.375Z"
                    stroke="#202024"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 13.125C11.7259 13.125 13.125 11.7259 13.125 10C13.125 8.27411 11.7259 6.875 10 6.875C8.27411 6.875 6.875 8.27411 6.875 10C6.875 11.7259 8.27411 13.125 10 13.125Z"
                    stroke="#202024"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Close Preview
              </button>
            </div>
          </div>
          <div className={`form-view-wrapper ${'form-view-wrapper-' + tabItem}`}>
            <div className={'preview-modal-actions'}>
              Orientation
              <button className={`btn-orientation ${orientation === 'horizontal' ? 'active' : ''}`} onClick={() => setOrientation('horizontal')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M2.6875 6.625L2.6875 13.375C2.6875 13.9963 3.19118 14.5 3.8125 14.5H16.1875C16.8088 14.5 17.3125 13.9963 17.3125 13.375V6.625C17.3125 6.00368 16.8088 5.5 16.1875 5.5H3.8125C3.19118 5.5 2.6875 6.00368 2.6875 6.625Z"
                    stroke="#7F56D9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M4.9375 14.5L4.9375 5.5" stroke="#7F56D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M15.0625 14.5V5.5" stroke="#7F56D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
              <button className={`btn-orientation ${orientation === 'vertical' ? 'active' : ''}`} onClick={() => setOrientation('vertical')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M13.375 2.6875H6.625C6.00368 2.6875 5.5 3.19118 5.5 3.8125V16.1875C5.5 16.8088 6.00368 17.3125 6.625 17.3125H13.375C13.9963 17.3125 14.5 16.8088 14.5 16.1875V3.8125C14.5 3.19118 13.9963 2.6875 13.375 2.6875Z"
                    stroke="#6941C6"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M5.5 4.9375H14.5" stroke="#6941C6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5.5 15.0625H14.5" stroke="#6941C6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
            </div>
            <div className={`send-form-wrapper ${'send-form-wrapper-' + orientation}`}>
              <div className={'send-form'}>
                <RenderForm
                  isSubmitter={false}
                  isPrint={false}
                  handelInitSettings={() => {}}
                  title={formData.title_options ? { ...formData?.title_options, options: { ...formData?.title_options.options, value: formData.name } } : formData.name}
                  formElement={formData.form_fields}
                ></RenderForm>
                <div className="form-control-actions">
                  <button className={'btn btn-outline mr-2'}>Clear form</button>
                  <button className="btn">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

export default FromBuilderPlaceInForm;
