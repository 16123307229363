import React from 'react';

export const SubmittersFieldElement = function FromElement({ element }) {
  return (
    <>
      <label className="form-label">{element.options.name ? element.options.name : `Select${element.options.labels.length > 0 && 'ed'} Submitters Field`}</label>
      <p className={'form-submitters'}>{element.options.labels.map((item) => item.label).join(' ')}</p>
      {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}
    </>
  );
};
