import React, { useEffect, useState } from 'react';
import { MultiSelect } from '../other/MultiSelect';
// import {Tooltip} from "react-tooltip";
import Select from 'react-select';
// import {getFacilitiesByCompanySettingsInit} from "../../servises/facility";
import { getUsersListSettingsInit } from '../../servises/user';
import { getCompanyUserGroupListSettingsInit } from '../../servises/company';
import { useAuth } from '../../container/authContext';
import { useParams } from 'react-router-dom';

export const SubmissionSettingsInitSystem = function SubmissionSettingsInitSystem({ formData, handelInitSettings, initData, errors }) {
  const [recipient_settings_init, setRecipientSettingsInit] = useState({});
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [usersGroupsOptions, setUsersGroupsOptions] = useState([]);
  const [individualUsersOptions, setIndividualUsersOptions] = useState([]);
  const { user } = useAuth();
  const { facility: userFacility } = user ?? {};
  const companyId = user.company?.id;
  const { id } = useParams();

  useEffect(() => {
    const { submission_settings } = formData ?? {};
    const isNeedGetCompanyUserGroup =
      (!!submission_settings?.can_initiator_select_submitters && submission_settings?.submitters?.is_users_from_initiator_facility) ||
      (!submission_settings?.submitters?.is_users_from_initiator_facility && recipient_settings_init?.facilities?.length);
    if (isNeedGetCompanyUserGroup) {
      getCompanyUserGroup(userFacility ? [userFacility.id] : []);
    }
  }, [formData, recipient_settings_init?.facilities?.length, userFacility]);

  useEffect(() => {
    if (formData?.submission_settings?.submitters) {
      const prevState = formData.submission_settings.submitters;
      if (prevState.facilities?.length) {
        setFacilityOptions(prevState.facilities.map((item) => ({ value: item.id, label: item.name })));
      }
    }
    // eslint-disable-next-line
  }, [formData, recipient_settings_init]);

  useEffect(() => {
    if (!formData?.submission_settings?.submitters?.is_users_from_initiator_facility) {
      if (!recipient_settings_init?.facilities?.length) {
        const prevState = { ...recipient_settings_init };
        prevState['user_groups'] = [];
        prevState['individual_users'] = [];
        setRecipientSettingsInit(prevState);
        handelInitSettings(prevState, 'submitters_settings');
        setIndividualUsersOptions([]);
        setUsersGroupsOptions([]);
      }
    }
    // eslint-disable-next-line
  }, [recipient_settings_init.facilities]);

  useEffect(() => {
    if (!formData?.recipient_settings?.recipients_only_from_initiator_facility) {
      getUsersList(recipient_settings_init?.facilities ? [...recipient_settings_init.facilities.map((item) => item.value)] : []);
    } else {
      getUsersList(user.facility ? [user.facility.id] : []);
    }
  }, [formData?.recipient_settings?.recipients_only_from_initiator_facility, recipient_settings_init.facilities, recipient_settings_init.user_groups, user.facility]);

  const getCompanyUserGroup = (facilities_ids = []) => {
    getCompanyUserGroupListSettingsInit({
      company_id: companyId,
      status: 'active',
      form_id: id,
      field: 'submitters_settings',
      facilities_ids: facilities_ids,
    })
      .then((res) => {
        const options = res.map((item) => ({ value: item.id, label: item.name, entity_type: 'user_group' }));
        setUsersGroupsOptions(options);
        if (options) {
          const newState = [];
          const prevState = {
            ...recipient_settings_init,
            user_groups: initData?.submitters_settings?.user_groups,
          };
          prevState['user_groups']?.forEach((item) => {
            if (options.find((option) => option.value === item.value)) {
              newState.push(item);
            }
          });
          prevState['user_groups'] = newState;
          setRecipientSettingsInit(() => prevState);
        }
      })
      .catch((e) => console.log(e));
  };

  const getUsersList = (facilities_ids = []) => {
    getUsersListSettingsInit({
      company_id: companyId,
      status: 'active',
      form_id: id,
      field: 'submitters_settings',
      facilities_ids: facilities_ids,
      user_groups_ids: recipient_settings_init?.user_groups ? [...recipient_settings_init.user_groups.map((item) => item.value)] : [],
    })
      .then((resUsers) => {
        const options = resUsers.map((item) => ({
          value: item.id,
          label: item.last_name + ' ' + item.first_name,
          entity_type: 'user',
        }));
        setIndividualUsersOptions(options);
        if (options) {
          const newState = [];
          const prevState = {
            ...recipient_settings_init,
            individual_users: initData?.submitters_settings?.individual_users,
          };
          prevState['individual_users']?.forEach((item) => {
            if (options.find((option) => option.value === item.value)) {
              newState.push(item);
            }
          });
          prevState['individual_users'] = newState;
          setRecipientSettingsInit(() => prevState);
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (initData?.submitters_settings) {
      setRecipientSettingsInit({ ...initData?.submitters_settings });
    }
  }, [initData]);

  const handelRemove = (key, value) => {
    const prevState = { ...recipient_settings_init };
    prevState[key] = prevState[key].filter((item) => item.value !== value.value);
    setRecipientSettingsInit(() => {
      return prevState;
    });

    handelInitSettings(prevState, 'submitters_settings');
  };

  const handelSettings = (value, key) => {
    const prevState = { ...recipient_settings_init };
    prevState[key] = value;
    setRecipientSettingsInit(() => prevState);

    handelInitSettings(prevState, 'submitters_settings');
  };

  const addNewOrder = () => {
    const prevState = { ...recipient_settings_init };

    if (!prevState['submitters_order']) {
      prevState['submitters_order'] = [
        {
          submitter: null,
          step: 1,
          value: null,
          label: null,
          public: false,
        },
        {
          submitter: null,
          step: 2,
          value: null,
          label: null,
          public: false,
        },
      ];
    }

    prevState['submitters_order'] = [
      ...prevState['submitters_order'],
      {
        submitter: null,
        step: prevState['submitters_order'].length,
        value: null,
        label: null,
        public: false,
      },
    ];

    setRecipientSettingsInit(() => {
      return prevState;
    });

    handelInitSettings(prevState, 'submitters_settings');
  };
  const orderRemove = (element, index) => {
    const prevState = { ...recipient_settings_init };
    prevState['submitters_order'] = prevState['submitters_order'].filter((item) => item !== element);
    prevState['submitters_order'] = prevState['submitters_order'].map((item, index) => {
      item.step = index + 1;
      return item;
    });
    setRecipientSettingsInit(() => {
      return prevState;
    });

    handelInitSettings(prevState, 'submitters_settings');
  };

  const handelOrderSettings = (value, key, index) => {
    const prevState = { ...recipient_settings_init };
    if (!prevState[key]) {
      prevState[key] = [
        {
          submitter: null,
          step: 1,
          value: null,
          entity_type: null,
          public: false,
        },
        {
          submitter: null,
          step: 2,
          value: null,
          label: null,
          entity_type: null,
          public: false,
        },
      ];
    }

    prevState[key][index] = {
      submitter: value.value,
      step: index,
      value: value.value,
      label: value.label,
      entity_type: value.entity_type,
      public: value.public,
    };
    setRecipientSettingsInit(() => {
      return prevState;
    });

    handelInitSettings(prevState, 'submitters_settings');
  };

  return (
    <div className="form-builder-setting-tab-content">
      <div className="form-builder-recipient-settings">
        <p className="info-text">
          <i className="icon-icon-info"></i> Choose submitters and submission rules.
        </p>
        <span className="form-error mb-2 d-flex">{errors?.non_field_errors && errors.non_field_errors[0]}</span>

        <div className="form-control-item-settings">
          <div className="form-settings-label">
            <label className="form-label form-label-big">Specify Submitters (required)</label>
            <span>Submitters are users to whom you are sending this form completion.</span>
          </div>
          <div className="form-settings-inputs">
            <>
              <div className={`form-item form-item-select mb-4`}>
                <label className="form-label form-label-big">Facility</label>
                <div className="select-multy-wrapper">
                  <span className="select-placeholder">Choose one or several facilities</span>
                  <MultiSelect
                    isDisabled={!facilityOptions.length}
                    options={facilityOptions}
                    value={recipient_settings_init.facilities}
                    onChange={(e) => handelSettings(e, 'facilities')}
                    placeholder=""
                    allName="All facilities"
                  />
                  {recipient_settings_init.facilities?.length > 0 && (
                    <>
                      <ul>
                        {recipient_settings_init.facilities.map((item, key) => (
                          <li key={key}>
                            {item.label}
                            <button className="btn btn-icon" onClick={() => handelRemove('facilities', item)}>
                              <img src="/images/x-circle.svg" alt="" />
                            </button>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
                <span className="form-error">{errors?.submitters_settings?.facilities && errors.submitters_settings.facilities[0]}</span>
              </div>

              <div className={`form-item form-item-select mb-4`}>
                <label className="form-label form-label-big">User groups</label>
                <div className="select-multy-wrapper">
                  <span className="select-placeholder">Choose one or several user groups</span>
                  <MultiSelect
                    isDisabled={!usersGroupsOptions.length}
                    options={usersGroupsOptions}
                    value={recipient_settings_init.user_groups}
                    onChange={(e) => handelSettings(e, 'user_groups')}
                    placeholder=""
                    allName="All user groups"
                  />
                  {recipient_settings_init.user_groups?.length > 0 && (
                    <>
                      <ul>
                        {recipient_settings_init.user_groups.map((item, key) => (
                          <li key={key}>
                            {item.label}
                            <button className="btn btn-icon" onClick={() => handelRemove('user_groups', item)}>
                              <img src="/images/x-circle.svg" alt="" />
                            </button>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
                <span className="form-error">{errors?.submitters_settings?.user_groups && errors.submitters_settings?.user_groups[0]}</span>
              </div>

              <div className={`form-item form-item-select mb-4`}>
                <label className="form-label form-label-big">Individual Users</label>
                <div className="select-multy-wrapper">
                  <span className="select-placeholder">Choose one or several individual_users</span>
                  <MultiSelect
                    isDisabled={!recipient_settings_init.individual_users?.length && !individualUsersOptions.length}
                    options={individualUsersOptions}
                    value={recipient_settings_init.individual_users}
                    onChange={(e) => handelSettings(e, 'individual_users')}
                    placeholder=""
                    allName="All individual users"
                  />
                  {recipient_settings_init.individual_users?.length > 0 && (
                    <>
                      <ul className={'individual-users-list'}>
                        {recipient_settings_init.individual_users.map((item, key) => (
                          <li key={key}>
                            {item.label}
                            <button className="btn btn-icon" onClick={() => handelRemove('individual_users', item)}>
                              <img src="/images/x-circle.svg" alt="" />
                            </button>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                  <span className="form-error">{errors?.submitters_settings?.individual_users && errors.submitters_settings?.individual_users[0]}</span>
                </div>
              </div>
            </>
          </div>
        </div>
        {formData.submission_settings.has_strict_order_of_submissions && (
          <div className="form-control-item-settings">
            <div className="form-settings-label">
              <label className="form-label form-label-big">Specify strict order of submitters (required)</label>
              <span>it is necessary to provide a clear and definite sequence for the form submitting </span>
            </div>
            <div className="form-settings-inputs">
              <>
                <div className="form-item mb-4">
                  <label className="form-label form-label-big">Step 1</label>
                  <Select
                    classNamePrefix="react-select"
                    className={`form-control-select`}
                    options={[
                      ...(recipient_settings_init.individual_users ? recipient_settings_init.individual_users : []),
                      ...(recipient_settings_init?.user_groups ? recipient_settings_init?.user_groups : []),
                    ]}
                    value={recipient_settings_init.submitters_order ? recipient_settings_init.submitters_order[0] : null}
                    onChange={(e) => handelOrderSettings(e, 'submitters_order', 0)}
                  ></Select>
                  <span className="form-error mb-2">
                    {errors?.submitters_settings?.strict_order &&
                      errors?.submitters_settings?.strict_order[0]?.hasOwnProperty('submitter') &&
                      errors?.submitters_settings?.strict_order[0].submitter}
                  </span>
                  <span className="form-error mb-2">
                    {errors?.submitters_settings?.strict_order && typeof errors?.submitters_settings?.strict_order[0] === 'string' && errors?.submitters_settings?.strict_order[0]}
                  </span>
                </div>
                <div className="form-item mb-4">
                  <label className="form-label form-label-big">Step 2</label>
                  <Select
                    classNamePrefix="react-select"
                    className={`form-control-select`}
                    options={[
                      ...(recipient_settings_init.individual_users ? recipient_settings_init.individual_users : []),
                      ...(recipient_settings_init?.user_groups ? recipient_settings_init?.user_groups : []),
                    ]}
                    value={recipient_settings_init.submitters_order ? recipient_settings_init.submitters_order[1] : null}
                    onChange={(e) => handelOrderSettings(e, 'submitters_order', 1)}
                  ></Select>
                  <span className="form-error mb-2">
                    {errors?.submitters_settings?.strict_order &&
                      errors?.submitters_settings?.strict_order[1]?.hasOwnProperty('submitter') &&
                      errors?.submitters_settings?.strict_order[1].submitter}
                  </span>
                  <span className="form-error mb-2">
                    {errors?.submitters_settings?.strict_order && typeof errors?.submitters_settings?.strict_order[1] === 'string' && errors?.submitters_settings?.strict_order[1]}
                  </span>
                </div>

                {recipient_settings_init.submitters_order?.slice(2, recipient_settings_init.submitters_order.length).map((item, index) => (
                  <div className="form-item mb-4" key={index}>
                    <label className="form-label form-label-big">Step {item?.step + 1}</label>
                    <div className="d-flex mb-0">
                      <Select
                        classNamePrefix="react-select"
                        className={`form-control-select`}
                        options={[
                          ...(recipient_settings_init.individual_users ? recipient_settings_init.individual_users : []),
                          ...(recipient_settings_init?.user_groups ? recipient_settings_init?.user_groups : []),
                        ]}
                        value={item ? item : null}
                        onChange={(e) => handelOrderSettings(e, 'submitters_order', item.step)}
                      ></Select>

                      <button className="btn btn-icon btn-remove ml-4" onClick={() => orderRemove(item, item.step)}>
                        <i className="icon-remove"></i>
                      </button>
                    </div>
                    <span className="form-error mb-2">
                      {errors?.submitters_settings?.strict_order &&
                        errors?.submitters_settings?.strict_order[item.step]?.hasOwnProperty('submitter') &&
                        errors?.submitters_settings?.strict_order[item.step].submitter}
                    </span>
                    <span className="form-error mb-2">
                      {errors?.submitters_settings?.strict_order &&
                        typeof errors?.submitters_settings?.strict_order[item.step] === 'string' &&
                        errors?.submitters_settings?.strict_order[item.step]}
                    </span>
                  </div>
                ))}
                <span className="add-facility cursor-pointer" onClick={addNewOrder}>
                  <i className="icon-plus-svgrepo-com mr-2"></i>Add order
                </span>
              </>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
