import React, { useEffect, useState } from 'react';
import Layout from '../../layout/init-form';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Components from '../../components/from-builder/settings/components';
import { useLocation, useParams } from 'react-router-dom';
import { getGeneralFromById } from '../../servises/form';
import { Tooltip } from 'react-tooltip';

function ViewGeneralFormPage() {
  const [tabIndex, setTabIndex] = useState(0);
  const [formData, setFormData] = useState({});
  const [initData, setInitData] = useState({});
  const [errors, setError] = useState({});
  const { id } = useParams();
  const location = useLocation();

  const [tabs, setTab] = useState([
    {
      name: 'View Form',
      component: 'view-general-form',
      index: 0,
      url: `/general-view-form/${id}`,
    },
    {
      name: 'Move to another folder',
      component: 'replace-the-form',
      index: 1,
      isDisable: false,
      url: `/general-view-form/${id}/replace-the-form`,
    },
    {
      name: 'Share copy with company',
      component: 'share-with-company',
      index: 2,
      isDisable: false,
      url: `/general-view-form/${id}/share-with-company`,
    },
  ]);

  useEffect(() => {
    if (id) {
      getGeneralFromById(id, { status: 'all' })
        .then((res) => {
          setFormData(res);
          if (res.deleted_at) {
            setTab([
              {
                name: 'View Form',
                component: 'view-general-form',
                index: 0,
                url: `/general-view-form/${id}`,
              },
              {
                name: 'Share copy with company',
                component: 'share-with-company',
                index: 1,
                isDisable: true,
                url: `/general-view-form/${id}/share-with-company`,
              },
              {
                name: 'Replace the form',
                component: 'replace-the-form',
                index: 2,
                isDisable: true,
                url: `/general-view-form/${id}/replace-the-form`,
              },
            ]);
          }

          if (res?.form_fields) {
            setInitData({ ...initData, form_fields: JSON.parse(JSON.stringify(res?.form_fields)) });
          }
        })
        .catch((e) => console.log(e));
    }
    // eslint-disable-next-line
  }, [id, location]);

  const handelInitSettings = (res, key) => {
    setInitData({ ...initData, [key]: res });
  };

  return (
    <Layout title="Form Builder - Settings" pageClassName="form-builder-wrapper-settings init-page">
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <div className="form-builder-aside-left p-0">
          <TabList className="form-builder-aside-tab">
            {tabs.map((item, index) => (
              <Tab disabled={item.isDisable} key={item.index}>
                {item.name}
                {item.hasError && (
                  <>
                    <i data-tooltip-id={`table-tooltip-${index}`} className="icon-icon-info tab__error"></i>
                    <Tooltip opacity={1} id={`table-tooltip-${index}`} place="bottom" content={'Please fill in all required fields'} />
                  </>
                )}
              </Tab>
            ))}
          </TabList>
        </div>
        <div className={`form-builder-setting-tab p-0 ${tabIndex === 0 ? 'bg-transparent' : ''}`}>
          {tabs.map((tab) => (
            <TabPanel key={tab.index}>{Components(tab, formData, handelInitSettings, initData, setError, errors)}</TabPanel>
          ))}
        </div>
      </Tabs>
    </Layout>
  );
}

export default ViewGeneralFormPage;
