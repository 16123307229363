import React, { useEffect, useState } from 'react';
import Layout from '../../layout/default';
import { useNavigate, useParams } from 'react-router-dom';
// import dayjs from "dayjs";
import { activateUser, deactivateUser, getUserById, resendSetLoginLink } from '../../servises/user';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import dayjs from 'dayjs';
import UserModal from '../../components/company/user-modal';
import { getFacilitiesByCompany } from '../../servises/facility';
import DeactivateModal from '../../components/company/deactivate-modal';
import { usePermission } from '../../container/permissionContext';
import { redirectToLibrary } from '../../utility';
import CopyModal from '../../components/company/copy-modal';
import { DATE_FORMAT } from '../../constants';

function UserProfile() {
  const { user_id, id } = useParams();
  const [user, setUser] = useState({});
  const [defaultIndex, setDefaultIndex] = useState(0);
  const [facilities, setFacilities] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenRegister, setIsOpenRegister] = useState(false);
  const [moreIsOpen, setMoreIsOpen] = useState(false);
  const [deactivateIsOpen, setIsOpenDeactivate] = useState(false);
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState([
    {
      url: '/companies/',
      name: 'Company List',
    },
  ]);
  const navigation = useNavigate();
  const { isEmployee, hasPermission } = usePermission();
  useEffect(() => {
    if (isEmployee() && !hasPermission('Users, Positions management')) {
      redirectToLibrary(navigation);
    }
  }, [isEmployee, navigation, hasPermission]);

  useEffect(() => {
    initPage();
    // eslint-disable-next-line
  }, [user_id]);
  const initPage = () => {
    getUserById(user_id)
      .then((res) => {
        setUser(res);
        let breadcrumbs = [
          {
            url: `/company/${res.company.id}`,
            name: res.company.name,
          },
          {
            url: `/company/${res.company.id}/users`,
            name: 'User List',
          },
          {
            url: `/company/${res.company.id}/user/${res.id}`,
            name: `${res.first_name} ${res.last_name}`,
          },
        ];
        setPageBreadcrumbs(breadcrumbs);
        let action = null;

        if (isEmployee()) {
          action = 'users_positions_management';
        }
        getFacilitiesByCompany(res.company.id, 'getAll', action)
          .then((resFacilities) => {
            setFacilities(resFacilities);
          })
          .catch((e) => console.log(e));
      })
      .catch((e) => console.log(e));
  };
  const openAddModal = () => {
    setIsOpen(true);
  };

  function closeAddModal() {
    setIsOpen(false);
  }

  const closeRegisterModal = () => {
    setIsOpenRegister(false);
  };

  const handelEditUser = () => {
    getUserById(user_id)
      .then((res) => {
        setUser(res);
      })
      .catch((e) => console.log(e));
    setIsOpen(false);
  };

  const handelDeactivate = () => {
    deactivateUser(user_id)
      .then((res) => initPage())
      .catch((e) => console.log(e));
    handleDeactivateIsOpen();
  };

  const handelActivate = () => {
    activateUser(user_id)
      .then((res) => initPage())
      .catch((e) => console.log(e));
  };

  const handleDeactivateIsOpen = () => {
    setIsOpenDeactivate(false);
  };

  const canEdit = () => {
    if (!isEmployee()) {
      return true;
    }

    return user.role === 'Employee';
  };

  const sendPassLink = () => {
    resendSetLoginLink(user_id)
      .then((res) => {
        console.log(res);
        closeRegisterModal();
      })
      .catch((e) => console.log(e));
  };

  return (
    <Layout breadcrumbs={pageBreadcrumbs} title={'User Profile'}>
      <div className={`page-wrapper ${user.deleted_at ? 'page-wrapper-deactivated' : ''}`}>
        <div className="company-profile">
          {user.first_name && (
            <>
              <div className="company-profile-header">
                <div className="user-profile-header">
                  <div>
                    <span className="date-add">
                      Date Added: {dayjs(user.created_at).format(DATE_FORMAT)}{' '}
                      {user.deleted_at && <span className="date-add-deactivated">Deactivated: {dayjs(user.deleted_at).format(DATE_FORMAT)}</span>}
                    </span>
                    <h2 className="company-profile-title">
                      {user.last_name} {user.first_name}
                    </h2>
                    <h3 className="company-profile-subtitle">{user.role}</h3>
                  </div>
                </div>
                <div className="company-profile-header-action">
                  {!user.deleted_at && canEdit() && (
                    <>
                      <button className="btn btn-outline" onClick={openAddModal}>
                        <i className="icon-icon-edit mr-2"></i> Edit
                      </button>
                      <button className="btn btn-outline btn-danger" onClick={() => setIsOpenDeactivate(true)}>
                        <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <g clipPath="url(#clip0_1495_6510)">
                            <path
                              d="M12.5001 7.50008L7.50008 12.5001M7.50008 7.50008L12.5001 12.5001M18.3334 10.0001C18.3334 14.6025 14.6025 18.3334 10.0001 18.3334C5.39771 18.3334 1.66675 14.6025 1.66675 10.0001C1.66675 5.39771 5.39771 1.66675 10.0001 1.66675C14.6025 1.66675 18.3334 5.39771 18.3334 10.0001Z"
                              stroke="white"
                              strokeWidth="1.67"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_1495_6510">
                              <rect width="20" height="20" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                        Deactivate
                      </button>
                    </>
                  )}
                  {user.deleted_at && canEdit() && (
                    <button className="btn btn-outline btn-success" onClick={handelActivate}>
                      <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1484_3438)">
                          <path
                            d="M18.3332 9.2333V9.99997C18.3321 11.797 17.7503 13.5455 16.6743 14.9848C15.5983 16.4241 14.0859 17.477 12.3626 17.9866C10.6394 18.4961 8.79755 18.4349 7.1119 17.8121C5.42624 17.1894 3.98705 16.0384 3.00897 14.5309C2.03089 13.0233 1.56633 11.24 1.68457 9.4469C1.80281 7.65377 2.49751 5.94691 3.66507 4.58086C4.83263 3.21482 6.41049 2.26279 8.16333 1.86676C9.91617 1.47073 11.7501 1.65192 13.3915 2.3833M18.3332 3.3333L9.99984 11.675L7.49984 9.17497"
                            stroke="#12B76A"
                            strokeWidth="1.67"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_1484_3438">
                            <rect width="20" height="20" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                      Activate
                    </button>
                  )}
                </div>
              </div>
              <div className="company-profile-tab user-profile-tab">
                <Tabs selectedIndex={defaultIndex} onSelect={(index) => setDefaultIndex(index)}>
                  <TabList>
                    <Tab>Main Information</Tab>
                    <Tab onClick={() => navigation(`/company/${id}/user/${user_id}/forms`)}>Forms</Tab>
                  </TabList>

                  <TabPanel>
                    <h3 className="company-profile-title">
                      <img src="/images/company-add-icon-3.svg" className="mr-2" alt="" />
                      Personal Information
                    </h3>
                    <div className="user-profile-tab-content">
                      <div className="user-profile-tab-item">
                        <h4 className="title">Facility Name:</h4>
                        <p>
                          <strong>{user.facility?.name}</strong>
                        </p>

                        <h4 className="title">Position:</h4>
                        <p>
                          <strong>{user.position?.name}</strong>
                        </p>
                      </div>
                      <div className="user-profile-tab-item user-profile-tab-user-group">
                        <div>
                          <h4 className="title">Users Group:</h4>
                          {user.user_groups.map(
                            (item, index) =>
                              index < 3 && (
                                <p key={item.id}>
                                  <strong>{item.name}</strong>
                                </p>
                              ),
                          )}

                          {user.user_groups.length > 3 && (
                            <>
                              {moreIsOpen && (
                                <div className="more-group">
                                  {user.user_groups.map(
                                    (item, index) =>
                                      index >= 3 && (
                                        <p key={item.id}>
                                          <strong>{item.name}</strong>
                                        </p>
                                      ),
                                  )}
                                </div>
                              )}
                              <span className="show-more cursor-pointer" onClick={() => setMoreIsOpen(!moreIsOpen)}>
                                {!moreIsOpen ? `${user.user_groups.length - 3} more...` : 'Show Less'}
                              </span>
                            </>
                          )}
                        </div>
                        {/*<div>*/}
                        {/*    <button className="btn btn-outline">Change group</button>*/}
                        {/*</div>*/}
                      </div>
                      <div className="user-profile-tab-item ">
                        <h4 className="title">Email:</h4>
                        <p>
                          <strong>{user?.email?.length ? user.email : '-'}</strong>
                        </p>

                        <h4 className="title">Phone Number:</h4>
                        <p>
                          <strong>{user.phone}</strong>
                        </p>
                      </div>
                    </div>

                    <h3 className="company-profile-title">
                      <img src="/images/settings-icon.svg" className="mr-2" alt="" />
                      System Information
                    </h3>
                    <div className="user-profile-tab-content">
                      <div className="user-profile-tab-item">
                        {user.creator && (
                          <>
                            <h4 className="title">Added by:</h4>
                            <p>
                              <strong>
                                {user.creator.last_name} {user.creator.first_name}
                              </strong>
                            </p>
                          </>
                        )}
                        <h4 className="title">Date Added:</h4>
                        <p>
                          <strong>{dayjs(user.created_at).format(DATE_FORMAT)}</strong>
                        </p>
                      </div>

                      <div className="user-profile-tab-item">
                        <h4 className="title">Preferred notification type:</h4>
                        <p>
                          <strong>{user?.preferred_notification_type}</strong>
                        </p>
                      </div>

                      <div className="user-profile-tab-item">
                        {!user?.is_activated && hasPermission('Users, Positions management') && (
                          <button className={'btn'} onClick={() => setIsOpenRegister(true)}>
                            Complete registration
                          </button>
                        )}
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <h3 className="company-profile-title">Forms</h3>
                  </TabPanel>
                </Tabs>
              </div>

              {modalIsOpen && (
                <UserModal
                  isEditMode
                  companyName={user.company.name}
                  userData={user}
                  facilities={facilities}
                  closeModal={closeAddModal}
                  companyId={user.company.id}
                  isOpen={modalIsOpen}
                  openModal={openAddModal}
                  handelCreateUser={handelEditUser}
                />
              )}
              <DeactivateModal
                isOpen={deactivateIsOpen}
                closeModal={handleDeactivateIsOpen}
                handelSuccess={handelDeactivate}
                title="User Profile Deactivation"
                subtitle="Deactivation will temporarily hide this user from the system. You can reactivate it anytime in this user profile."
              />

              <CopyModal
                closeModal={closeRegisterModal}
                isOpen={modalIsOpenRegister}
                title={'Registration completion confirmation'}
                subtitle={'Are you sure you want to send the registration completion link to this user?'}
                button={'Confirm'}
                handelSuccess={sendPassLink}
              />
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default UserProfile;
