import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams, createSearchParams } from 'react-router-dom';
import { RenderForm } from './render-form';
import useOutsideClick from '../../hook/useOutsideClick';
import { activateForm, deactivateForm, createForm } from '../../servises/form';
import { useAuth } from '../../container/authContext';
import DeactivateModal from '../company/deactivate-modal';
import CopyModal from '../company/copy-modal';
import { usePermission } from '../../container/permissionContext';
import { useIsMobile } from '../../hook/useIsMobile';
import DeleteFormConfirmationModal from './components/delete-form-confirmation-modal';
import { removeDrafts } from '../../servises/folder/form-avaliable';
import { isResponseOk } from '../../utility/isResponseOk';

export const ViewForm = function ViewForm(props) {
  const { formData, initData, handelInitSettings, tabs, changeTab, handleSubmitSettings, errors, duplicateElement, removeElement } = props;
  const navigation = useNavigate();
  const { id } = useParams();
  const ref = useRef();
  const isMobile = useIsMobile();
  const { user } = useAuth();
  const companyId = user.company?.id;
  const backLinkUrl = `/corporate-library/forms/forms-available-to-me${formData.folder_id ? '?folder_id=' + formData.folder_id : ''}`;

  const [deactivateIsOpen, setIsOpenDeactivate] = useState(false);
  const [copyIsOpen, setIsOpenCopy] = useState(false);
  const { hasPermissionByCodeName, isSuperAdmin, isCompanySystemAdministrator } = usePermission();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isOpenDeleteFormConfirmModal, setIsOpenDeleteFormConfirmModal] = useState(false);

  useEffect(() => {
    setIsDisabled(formData.deleted_at);
  }, [formData]);

  const handleDeactivateIsOpen = () => {
    setIsOpenDeactivate(false);
  };
  const handleCopyIsOpen = () => {
    setIsOpenCopy(false);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useOutsideClick(ref, closeDropdown);

  const handelCopy = () => {
    const bodyRequest = JSON.parse(JSON.stringify(formData));

    bodyRequest['name'] = `${formData.name} (Copy)`;
    bodyRequest['title_options']['options']['value'] = `${formData.name} (Copy)`;

    if (formData?.access_settings?.facilities) {
      bodyRequest['access_settings']['facilities'] = formData.access_settings.facilities.map((item) => item.id);
    }
    if (formData?.access_settings?.individual_users) {
      bodyRequest['access_settings']['individual_users'] = formData.access_settings.individual_users.map((item) => item.id);
    }
    if (formData?.access_settings?.user_groups) {
      bodyRequest['access_settings']['user_groups'] = formData.access_settings.user_groups.map((item) => item.id);
    }

    if (formData?.recipient_settings?.facilities) {
      bodyRequest['recipient_settings']['facilities'] = formData.recipient_settings.facilities.map((item) => item.id);
    }
    if (formData?.recipient_settings?.individual_users) {
      bodyRequest['recipient_settings']['individual_users'] = formData.recipient_settings.individual_users.map((item) => item.id);
    }
    if (formData?.recipient_settings?.user_groups) {
      bodyRequest['recipient_settings']['user_groups'] = formData.recipient_settings.user_groups.map((item) => item.id);
    }

    if (formData?.submission_settings?.submitters?.facilities) {
      bodyRequest['submission_settings']['submitters']['facilities'] = formData.submission_settings.submitters.facilities.map((item) => item.id);
    }
    if (formData?.submission_settings?.submitters?.individual_users) {
      bodyRequest['submission_settings']['submitters']['individual_users'] = formData.submission_settings.submitters.individual_users.map((item) => item.id);
    }
    if (formData?.submission_settings?.submitters?.user_groups) {
      bodyRequest['submission_settings']['submitters']['user_groups'] = formData.submission_settings.submitters.user_groups.map((item) => item.id);
    }

    if (formData?.submission_settings?.submitters?.units) {
      bodyRequest['submission_settings']['submitters']['units'] = formData.submission_settings.submitters.units.map((item) => item.id);
    }
    if (formData?.submission_settings?.submitters?.contacts) {
      bodyRequest['submission_settings']['submitters']['contacts'] = formData.submission_settings.submitters.contacts.map((item) => item.id);
    }

    if (formData?.filling_type === 'PUBLIC') {
      delete bodyRequest['notifications_settings'];
    }

    delete bodyRequest['id'];
    bodyRequest['company_id'] = companyId;
    bodyRequest['status'] = 'draft';
    delete bodyRequest['folder_id'];

    handleCopyIsOpen(false);

    createForm(bodyRequest)
      .then((res) => {
        navigation(`/initialization-form/${res.id}`);
      })
      .catch((e) => console.log(e));
  };
  const handelDeactivate = () => {
    deactivateForm(id)
      .then((res) => {
        window.location.reload();
      })
      .catch((e) => console.log(e));
  };

  const handelActivated = () => {
    activateForm(id)
      .then((res) => {
        window.location.reload();
      })
      .catch((e) => console.log(e));
  };

  const [tab, setTab] = useState(null);

  useEffect(() => {
    setTab(tabs.find((item) => item.component === 'submission-settings-init'));
  }, [tabs]);

  const onChangeTab = () => {
    changeTab(tab);
  };

  const handleDeleteForm = useCallback(async () => {
    try {
      const response = await removeDrafts(id);
      if (isResponseOk(response)) {
        navigation(
          {
            pathname: `/corporate-library/forms/forms-available-to-me`,
            search: createSearchParams({ folder_id: formData.folder_id ?? '' }).toString(),
          },
          {
            replace: true,
          },
        );
      }
    } catch (e) {
      console.log(e);
    }
  }, [formData.folder_id, id, navigation]);

  return (
    <div className="init-form-tab mobile-init">
      <div className="init-form-tab-content">
        {hasPermissionByCodeName('corporate_library_management') && (
          <div className="init-form-action">
            {isDisabled && (
              <button className="btn btn-left btn-success btn-text" onClick={() => handelActivated(formData)}>
                <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1484_3438)">
                    <path
                      d="M18.3332 9.2333V9.99997C18.3321 11.797 17.7503 13.5455 16.6743 14.9848C15.5983 16.4241 14.0859 17.477 12.3626 17.9866C10.6394 18.4961 8.79755 18.4349 7.1119 17.8121C5.42624 17.1894 3.98705 16.0384 3.00897 14.5309C2.03089 13.0233 1.56633 11.24 1.68457 9.4469C1.80281 7.65377 2.49751 5.94691 3.66507 4.58086C4.83263 3.21482 6.41049 2.26279 8.16333 1.86676C9.91617 1.47073 11.7501 1.65192 13.3915 2.3833M18.3332 3.3333L9.99984 11.675L7.49984 9.17497"
                      stroke="#12B76A"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1484_3438">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Activate
              </button>
            )}

            {!isDisabled && (
              <button
                className={'btn btn-white'}
                onClick={() => {
                  navigation(`/form-builder/${formData.id}/constructor`);
                }}
              >
                <i className={'icon-icon-edit mr-2'}></i>Edit
              </button>
            )}

            <div className="dropdown ml-4" ref={ref}>
              <button onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="btn btn-outline">
                <img src="/images/more-vertical.svg" alt="" />
              </button>
              {isDropdownOpen && (
                <ul className="dropdown-menu">
                  {!isDisabled && (
                    <>
                      <li>
                        <button className="btn btn-left btn-full btn-text" onClick={() => setIsOpenCopy(true)}>
                          <i className="icon-copy mr-2"></i>Copy Form
                        </button>
                      </li>
                      <li>
                        <button className="btn btn-left btn-full btn-text" onClick={() => setIsOpenDeactivate(true)}>
                          <i className="icon-icon-x mr-2"></i>Archive
                        </button>
                      </li>
                    </>
                  )}
                  {(isSuperAdmin() || isCompanySystemAdministrator()) && (
                    <li>
                      <button className="btn btn-left btn-full btn-danger btn-text" onClick={() => setIsOpenDeleteFormConfirmModal(true)}>
                        <i className="icon-remove mr-2" />
                        Delete
                      </button>
                    </li>
                  )}
                </ul>
              )}
            </div>
          </div>
        )}

        {formData.form_fields && (
          <div className={'form-view-wrapper'}>
            <RenderForm
              errors={errors?.form_fields}
              isSubmitter={formData?.submission_settings?.is_one_submitter}
              isDisabled={formData.deleted_at}
              isPrint={false}
              title={
                formData?.title_options
                  ? {
                      ...formData?.title_options,
                      options: { ...formData?.title_options.options, value: formData.name },
                    }
                  : formData.name
              }
              formElement={initData.form_fields ? initData.form_fields : []}
              handelInitSettings={handelInitSettings}
              duplicateElement={duplicateElement}
              removeElement={removeElement}
            />
            {isMobile && (
              <div className={'form-view-actions'}>
                <Link
                  to={`/corporate-library/forms/forms-available-to-me${formData.folder_id ? '?folder_id=' + formData.folder_id : ''}`}
                  className={`btn btn-mobile btn-outline ${isDisabled ? 'pointer-none' : ''}`}
                >
                  <svg className={'mr-2'} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M15.8327 10.0013H4.16602M4.16602 10.0013L9.99935 15.8346M4.16602 10.0013L9.99935 4.16797"
                      stroke="#101828"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Cancel
                </Link>

                <button disabled={!formData.filling_type || isDisabled} className="btn btn" onClick={handleSubmitSettings}>
                  Initiate
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="form-control-actions">
        <Link to={backLinkUrl} className={`btn btn-outline btn-wide`}>
          <svg className={'mr-2'} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              d="M15.8327 10.0013H4.16602M4.16602 10.0013L9.99935 15.8346M4.16602 10.0013L9.99935 4.16797"
              stroke="#101828"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Back
        </Link>
        {tab && !tab?.isDisable ? (
          <button disabled={tab?.isDisable} className="btn btn-outline btn-outline-secondary btn-wide" onClick={onChangeTab}>
            Next
            <svg className={'ml-2'} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M4.16602 10.0013H15.8327M15.8327 10.0013L9.99935 4.16797M15.8327 10.0013L9.99935 15.8346"
                stroke="#7F56D9"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        ) : (
          <button className="btn btn-wide" onClick={handleSubmitSettings} style={{ height: 41 }}>
            Submit
          </button>
        )}
      </div>

      <DeactivateModal
        isOpen={deactivateIsOpen}
        closeModal={handleDeactivateIsOpen}
        handelSuccess={handelDeactivate}
        title="Archive the Form"
        button={'Archive'}
        subtitle="Are you sure you want to Archive this form? It will be unavailable for initiation then."
      />
      <CopyModal isOpen={copyIsOpen} closeModal={handleCopyIsOpen} handelSuccess={handelCopy} title="Copy form" subtitle="The copy of this form will appear in Drafts folder" />

      {isOpenDeleteFormConfirmModal && (
        <DeleteFormConfirmationModal isOpen={isOpenDeleteFormConfirmModal} onDelete={handleDeleteForm} onClose={() => setIsOpenDeleteFormConfirmModal(false)} />
      )}
    </div>
  );
};
