import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import RequiredForSetting from './components/required-for';

export const FileUploadElementSettings = function FromElement({ element, handlerChangeOption, duplicateElement }) {
  const [label, setLabel] = useState(element.options.label);
  const [instructions, setInstructions] = useState(element.options.instructions);
  const [fileSize, setFileSize] = useState(element.options.fileSize);
  // const [fileCount, setFileCount] = useState(element.options.characterLimit);
  const [fileType, setFileType] = useState(element.options.fileType);
  const [alignment, setAlignment] = useState(element.options.alignment);
  const [size, setSize] = useState(element.options.size);
  const options = [
    { value: '.png,', label: '.png' },
    { value: '.jpg,.jpeg,', label: '.jpg' },
    { value: '.webp,', label: '.webp' },
    { value: '.pdf', label: '.pdf' },
    { value: '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, ', label: '.doc' },
  ];

  useEffect(() => {
    setLabel(element.options.label);
    setSize(element.options.size);
    setInstructions(element.options.instructions);
    setFileSize(element.options.fileSize);
    // setFileCount(element.options.fileCount)
    setFileType(element.options.fileType);
    setAlignment(element.options.alignment);
  }, [element]);

  return (
    <div className="tabs-item">
      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Field Alignment</h3>
        <div className="form-group">
          <span className="form-radio-button">
            <input
              type="radio"
              name="alignment"
              value="left"
              checked={alignment === 'left'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'alignment', element.id);
                setAlignment(e.target.value);
              }}
            />
            <span>Left</span>
          </span>
          <span className="form-radio-button">
            <input
              type="radio"
              name="alignment"
              value="center"
              checked={alignment === 'center'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'alignment', element.id);
                setAlignment(e.target.value);
              }}
            />
            <span>Center</span>
          </span>
          <span className="form-radio-button">
            <input
              type="radio"
              name="alignment"
              value="right"
              checked={alignment === 'right'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'alignment', element.id);
                setAlignment(e.target.value);
              }}
            />
            <span>Right</span>
          </span>
        </div>
      </div>
      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Input Size</h3>
        <div className="form-group">
          <span className="form-radio-button">
            <input
              type="radio"
              name="size"
              value="small"
              checked={size === 'small'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'size', element.id);
                setSize(e.target.value);
              }}
            />
            <span>Small</span>
          </span>
          <span className="form-radio-button">
            <input
              type="radio"
              name="size"
              value="medium"
              checked={size === 'medium'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'size', element.id);
                setSize(e.target.value);
              }}
            />
            <span>Medium</span>
          </span>
          <span className="form-radio-button">
            <input
              type="radio"
              name="size"
              value="large"
              checked={size === 'large'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'size', element.id);
                setSize(e.target.value);
              }}
            />
            <span>Large</span>
          </span>
        </div>
      </div>

      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Name Label</h3>
        <div className="form-control-item">
          <input
            type="text"
            className="form-control"
            placeholder="Enter text"
            value={label}
            onChange={(e) => {
              setLabel(e.target.value);
            }}
            onBlur={(e) => {
              handlerChangeOption(e.target.value, 'label', element.id);
              handlerChangeOption(e.target.value.replace(/ /g, '-').toLowerCase(), 'name', element.id);
            }}
          />
        </div>
      </div>

      <RequiredForSetting element={element} onChange={handlerChangeOption} />

      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Instructions</h3>
        <div className="form-control-item">
          <textarea
            className="form-control"
            placeholder="Enter Instructions"
            value={instructions}
            onChange={(e) => {
              setInstructions(e.target.value);
            }}
            onBlur={(e) => {
              handlerChangeOption(e.target.value, 'instructions', element.id);
            }}
          ></textarea>
        </div>
      </div>

      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">File Type</h3>
        <div className="form-control-item">
          <Select
            classNamePrefix="react-select-multi"
            className="form-control-select"
            value={fileType}
            options={options}
            isMulti
            onChange={(value) => {
              setFileType(value);
              handlerChangeOption(value, 'fileType', element.id);
            }}
          ></Select>
        </div>
      </div>

      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">File Size (mb)</h3>
        <div className="form-control-item">
          <input
            type="number"
            className="form-control"
            placeholder="Enter Instructions"
            value={fileSize}
            onChange={(e) => {
              setFileSize(e.target.value);
            }}
            onBlur={(e) => {
              handlerChangeOption(e.target.value, 'fileSize', element.id);
            }}
          />
        </div>
      </div>

      {/*<div className="property-item">*/}
      {/*    <h3 className="form-builder-aside-property-sub-title">File Count</h3>*/}
      {/*    <div className="form-control-item">*/}
      {/*        <input*/}
      {/*            type="number"*/}
      {/*            className="form-control"*/}
      {/*            placeholder="Enter Instructions"*/}
      {/*            value={fileCount}*/}
      {/*            onChange={(e) => {setFileCount(e.target.value)}}*/}
      {/*            onBlur={(e) => { handlerChangeOption(e.target.value, 'fileCount', element.id) }}*/}
      {/*        />*/}
      {/*    </div>*/}
      {/*</div>*/}
    </div>
  );
};
