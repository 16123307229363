import React, { useEffect, useState } from 'react';
import Layout from '../../layout/default';
import dayjs from 'dayjs';
import ReactPaginate from 'react-paginate';
import { getNotificationsList, markAsRead, notificationDelete } from '../../servises/notifications';
import { DATE_FORMAT } from '../../constants';
const Notifications = () => {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [checkedArray, setCheckedArray] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(null);
  const [isMarkAll, setIsMarkAll] = useState(false);
  const [pageBreadcrumbs] = useState([
    {
      url: '/notifications/',
      name: 'Notification panel',
    },
  ]);

  useEffect(() => {
    initPage();
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    if (isMarkAll) {
      setCheckedArray([...notifications]);
    } else {
      setCheckedArray([]);
    }
  }, [isMarkAll, notifications]);

  const initPage = () => {
    const params = {};
    params['page'] = page;

    getNotificationsList(params)
      .then((res) => {
        setNotifications(res.results);
        if (isMarkAll) {
          setCheckedArray(res.results);
        }
        setUnreadCount(res.unread_count);
        setTotalPage(res.total_pages);
        setCheckedArray([]);
      })
      .catch((e) => console.log(e));
  };

  const addToMark = (item) => {
    if (checkedArray.find((checked) => checked.id === item.id)) {
      setCheckedArray([...checkedArray.filter((checked) => checked.id !== item.id)]);
    } else {
      setCheckedArray([...checkedArray, item]);
    }
  };

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  const handelMarkAsRead = () => {
    markAsRead({ ids: isMarkAll ? [] : checkedArray.map((item) => item.id), all: isMarkAll })
      .then((res) => {
        initPage();
        setCheckedArray([]);
      })
      .catch((e) => console.log(e));
  };
  const handelDelete = () => {
    notificationDelete({ ids: isMarkAll ? [] : checkedArray.map((item) => item.id), all: isMarkAll })
      .then((res) => {
        initPage();
        setCheckedArray([]);
      })
      .catch((e) => console.log(e));
  };

  const goTo = (item) => {
    if (item?.data?.link) {
      markAsRead({ ids: [item.id] })
        .then((res) => {
          initPage();
          setCheckedArray([]);
          window.location.replace(item.data.link);
        })
        .catch((e) => console.log(e));
    }
  };

  return (
    <Layout isUpdated={unreadCount} title="Notification panel" breadcrumbs={pageBreadcrumbs}>
      <div className={'notifications'}>
        <h2 className={'notifications-title'}>Notification panel {unreadCount > 0 && <span>({unreadCount} new notifications)</span>}</h2>

        <div className="table-responsive table-default table-full">
          <div className="table-wrapper">
            <div className="table">
              <div className={`${!notifications.length ? 'tr-disabled' : ''} tr`}>
                <div className="th th-checkbox">
                  <div className={'form-control-checkbox'}>
                    <input type="checkbox" onChange={() => setIsMarkAll(!isMarkAll)} checked={isMarkAll} />
                    <span className={''}></span>
                  </div>
                </div>
                <div className="th">All</div>
                <div className="th"></div>
                <div className="th th-actions">
                  <button onClick={handelMarkAsRead} className={'btn btn-outline mr-2'}>
                    <svg className={'mr-2'} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <g clipPath="url(#clip0_5205_29216)">
                        <path
                          d="M18.3337 9.23355V10.0002C18.3326 11.7972 17.7507 13.5458 16.6748 14.9851C15.5988 16.4244 14.0864 17.4773 12.3631 17.9868C10.6399 18.4963 8.79804 18.4351 7.11238 17.8124C5.42673 17.1896 3.98754 16.0386 3.00946 14.5311C2.03138 13.0236 1.56682 11.2403 1.68506 9.44714C1.80329 7.65402 2.498 5.94715 3.66556 4.58111C4.83312 3.21506 6.41098 2.26303 8.16382 1.867C9.91665 1.47097 11.7505 1.65216 13.392 2.38355M18.3337 3.33355L10.0003 11.6752L7.50033 9.17521"
                          stroke="#101828"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_5205_29216">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    Mark as read
                  </button>
                  <button onClick={handelDelete} className={'btn btn-outline btn-danger'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path
                        d="M2.5 4.99984H4.16667M4.16667 4.99984H17.5M4.16667 4.99984V16.6665C4.16667 17.1085 4.34226 17.5325 4.65482 17.845C4.96738 18.1576 5.39131 18.3332 5.83333 18.3332H14.1667C14.6087 18.3332 15.0326 18.1576 15.3452 17.845C15.6577 17.5325 15.8333 17.1085 15.8333 16.6665V4.99984H4.16667ZM6.66667 4.99984V3.33317C6.66667 2.89114 6.84226 2.46722 7.15482 2.15466C7.46738 1.8421 7.89131 1.6665 8.33333 1.6665H11.6667C12.1087 1.6665 12.5326 1.8421 12.8452 2.15466C13.1577 2.46722 13.3333 2.89114 13.3333 3.33317V4.99984M8.33333 9.1665V14.1665M11.6667 9.1665V14.1665"
                        stroke="#B42318"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              {notifications.length > 0 &&
                notifications.map((item) => (
                  <div key={item.id} className={`tr ${item.is_read ? 'tr-is-read' : ''}`}>
                    <div className={'td th-checkbox'}>
                      <div className={'form-control-checkbox'}>
                        <input type="checkbox" onChange={() => addToMark(item)} checked={checkedArray.find((notification) => notification.id === item.id)} />{' '}
                        <span className={''}></span>
                      </div>
                    </div>
                    <div className={'td'}>{item.data.text}</div>
                    {/* eslint-disable-next-line*/}
                    <div className={'td'}>
                      {item.data.link ? (
                        <a className={'cursor-pointer'} onClick={() => goTo(item)}>
                          View Submission
                        </a>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className={'td'}>{dayjs(new Date(item.created_at)).format(DATE_FORMAT)}</div>
                  </div>
                ))}

              {!notifications.length && (
                <div className={'empty-table'}>
                  <div>
                    <p>
                      <strong>No Notifications</strong>
                    </p>
                    <p>Currently, there are no notifications</p>
                  </div>
                </div>
              )}
            </div>
            {totalPage > 1 && (
              <ReactPaginate
                className={'react-pagination'}
                breakLabel="..."
                nextLabel="next ->"
                pageRangeDisplayed={5}
                pageCount={totalPage}
                previousLabel="<- previous"
                onPageChange={handlePageClick}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Notifications;
