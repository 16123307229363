import React, { memo, useCallback } from 'react';
import './styles.scss';
import Modal from 'react-modal';

const DeleteFormConfirmationModal = ({ isOpen, onClose, onDelete }) => {
  let layout = null;

  const handleDelete = useCallback(onDelete, [onDelete]);

  if (isOpen) {
    layout = (
      <Modal isOpen={isOpen} ariaHideApp={false} className="modal-default confirmation-modal" overlayClassName={'confirmation-modal-overlay'} onRequestClose={onClose}>
        <h2 className="modal-header">
          <div className={'title'}>
            <img src="/images/alert-icon.svg" alt="" />
            Confirm form deletion
          </div>
          <button className="btn btn-icon" onClick={onClose}>
            <i className="icon-icon-x" />
          </button>
        </h2>
        <div className="modal-body">
          <p className="description">Do you want to delete this form? All form submissions of this form will be deleted too</p>
        </div>
        <div className="modal-footer">
          <button className="btn btn-outline" onClick={onClose}>
            Cancel
          </button>
          <button className="btn btn-left btn-danger" onClick={handleDelete}>
            <i className="icon-remove mr-2" />
            Delete
          </button>
        </div>
      </Modal>
    );
  }

  return layout;
};

export default memo(DeleteFormConfirmationModal);
