import React, { useEffect, useRef, useState } from 'react';
import useOutsideClick from '../../../hook/useOutsideClick';
import { getPublicFolder } from '../../../servises/folder/form-avaliable';
import { useAuth } from '../../../container/authContext';
import DatePicker from 'react-datepicker';
import { DATE_PICKER_DATE_FORMAT, DATE_PICKER_TIME_FORMAT } from '../../../constants';
import Select from 'react-select';
import {
  ARCHIVE_DATE_KEY,
  ONE_TIME_INITIATION_OR_ARCHIVING_KEY,
  RECURRENT_INITIATION_TIME_KEY,
  RECURRENT_INITIATION_START_DAY_KEY,
  ARCHIVE_TIME_KEY,
  AUTOMATICALLY_ARCHIVE_FORM_KEY,
  INITIATE_FORM_IMMEDIATELY_KEY,
  RECURRENT_INITIATION_OR_ARCHIVING_KEY,
  recurrentRepeatEveryOptions,
  RECURRENT_INITIATION_REPEAT_EVERY_KEY,
  RECURRENT_INITIATION_END_DAY_KEY,
  recurrentRepeatPeriodsOptions,
  RECURRENT_INITIATION_REPEAT_PERIOD_KEY,
  weekDays,
  RECURRENT_INITIATION_REPEAT_DAYS_KEY,
  RECURRENT_INITIATION_REPEAT_MONTH_DAYS_KEY,
  RECURRENT_INITIATION_REPEAT_YEAR_MONTHS_KEY,
  ARCHIVE_WITH_NEXT_INITIATION_KEY,
  ARCHIVE_TIME_OFFSET_KEY,
  ARCHIVE_TIME_OFFSET_UNIT_KEY,
  archiveTimeOffsetUnitsOptions,
  ARCHIVE_RELATIVE_TO_INITIATION_KEY,
  archiveRelativeToInitiationOptions,
  ONE_TIME_INITIATION_START_TIME_KEY,
  ONE_TIME_INITIATION_START_DATE_KEY,
} from './contstants';
import MultiDatePicker, { DateObject } from 'react-multi-date-picker';
import { getDaysInInitiationPeriod, getMonthIndexByName, getMonthsInInitiationPeriod } from '../submition-settings-frequency/utils';
import { get } from 'lodash';
import dayjs from 'dayjs';
import { INITIATOR_CAN_SET_FREQUENCY_SETTING_KEY } from '../../from-builder/settings/components/initiator-can-set-frequency-setting';

const MenuItem = ({ data, handelFolder, folder }) => {
  const { id, name, folders } = data;

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (name === 'Forms Available To Me') {
      setIsActive(true);
    } else {
      setIsActive(folder && folder.id === id);
    }
  }, [folder, id, name]);

  return (
    <li className={isActive ? 'active-folder' : ''}>
      <span
        className={'cursor-pointer'}
        onClick={() => {
          if (name !== 'Forms Available To Me') {
            handelFolder(data, 'public_folder_id');
          }
        }}
      >
        {!folders.length && (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M20.0005 17.4C20.0005 17.8774 19.8108 18.3352 19.4733 18.6728C19.1357 19.0104 18.6779 19.2 18.2005 19.2H3.80049C3.3231 19.2 2.86526 19.0104 2.5277 18.6728C2.19013 18.3352 2.00049 17.8774 2.00049 17.4V4.8C2.00049 4.32261 2.19013 3.86477 2.5277 3.52721C2.86526 3.18964 3.3231 3 3.80049 3H8.30049L10.1005 5.7H18.2005C18.6779 5.7 19.1357 5.88964 19.4733 6.22721C19.8108 6.56477 20.0005 7.02261 20.0005 7.5V17.4Z"
                stroke="#98A2B3"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </>
        )}
        {!!folders.length && (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
              <path d="M2.50049 3.75L5.00049 6.25L7.50049 3.75" stroke="#98A2B3" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path
                  d="M20.7502 11.5484V20.2984C20.7502 20.4841 20.6753 20.6621 20.5419 20.7934C20.4086 20.9247 20.2277 20.9984 20.0391 20.9984H4.7502C4.53802 20.9984 4.33454 20.9155 4.18451 20.7678C4.03448 20.6201 3.9502 20.4198 3.9502 20.2109V9.18594C3.9502 8.97708 4.03448 8.77678 4.18451 8.62909C4.33454 8.48141 4.53802 8.39844 4.7502 8.39844H9.28353C9.45662 8.39844 9.62505 8.4537 9.76353 8.55594L12.5369 10.6034C12.6753 10.7057 12.8438 10.7609 13.0169 10.7609H19.9502C20.1624 10.7609 20.3659 10.8439 20.5159 10.9916C20.6659 11.1393 20.7502 11.3396 20.7502 11.5484Z"
                  stroke="#98A2B3"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.05029 7.34922V4.98672C6.05029 4.77786 6.13985 4.57756 6.29925 4.42987C6.45866 4.28219 6.67486 4.19922 6.90029 4.19922H11.717C11.9009 4.19922 12.0798 4.25448 12.227 4.35672L15.1736 6.40422C15.3208 6.50645 15.4997 6.56172 15.6836 6.56172H23.0503C23.2757 6.56172 23.4919 6.64469 23.6513 6.79237C23.8107 6.94006 23.9003 7.14036 23.9003 7.34922V16.0992C23.9003 16.1911 23.8807 16.2822 23.8428 16.3671C23.8048 16.452 23.7492 16.5292 23.679 16.5942C23.6088 16.6592 23.5255 16.7108 23.4339 16.7459C23.3422 16.7811 23.244 16.7992 23.1447 16.7992H21.7003"
                  stroke="#98A2B3"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </>
        )}
        {name}
      </span>

      {!!folders.length && (
        <ul className="folder-select-ul-inner">
          {folders.map((i) => (
            <MenuItem data={i} key={i.id} handelFolder={handelFolder} folder={folder} />
          ))}
        </ul>
      )}
    </li>
  );
};

export const SubmissionSettingsInitPublic = ({ formData, handelInitSettings, initData, errors }) => {
  const { user } = useAuth();
  const wrapperRef = useRef(null);
  const companyId = user.company?.id;
  const oneTimeInitiationOrArchivingValue = initData[ONE_TIME_INITIATION_OR_ARCHIVING_KEY];
  const recurrentInitiationOrArchivingValue = initData[RECURRENT_INITIATION_OR_ARCHIVING_KEY];
  const initiateFormImmediatelyValue = initData[INITIATE_FORM_IMMEDIATELY_KEY];
  const automaticallyArchiveFormValue = initData[AUTOMATICALLY_ARCHIVE_FORM_KEY];
  const recurrentInitiationStartDay = initData[RECURRENT_INITIATION_START_DAY_KEY];
  const recurrentInitiationEndDay = initData[RECURRENT_INITIATION_END_DAY_KEY];
  const recurrentInitiationTime = initData[RECURRENT_INITIATION_TIME_KEY];
  const recurrentInitiationRepeatEvery = initData[RECURRENT_INITIATION_REPEAT_EVERY_KEY];
  const recurrentInitiationRepeatEveryValue = recurrentInitiationRepeatEvery ? recurrentRepeatEveryOptions.find((o) => o.value === recurrentInitiationRepeatEvery) : null;
  const recurrentInitiationRepeatPeriod = initData[RECURRENT_INITIATION_REPEAT_PERIOD_KEY];
  const recurrentInitiationRepeatPeriodValue = recurrentInitiationRepeatPeriod ? recurrentRepeatPeriodsOptions.find((o) => o.value === recurrentInitiationRepeatPeriod) : null;
  const recurrentInitiationRepeatDays = initData[RECURRENT_INITIATION_REPEAT_DAYS_KEY] ?? [];
  const recurrentInitiationRepeatMonthDays = initData[RECURRENT_INITIATION_REPEAT_MONTH_DAYS_KEY] ?? [];
  const recurrentInitiationRepeatYearMonths = initData[RECURRENT_INITIATION_REPEAT_YEAR_MONTHS_KEY] ?? [];
  const archiveWithNextInitiation = initData[ARCHIVE_WITH_NEXT_INITIATION_KEY];
  const archiveTimeOffset = initData[ARCHIVE_TIME_OFFSET_KEY];
  const archiveTimeOffsetUnit = initData[ARCHIVE_TIME_OFFSET_UNIT_KEY];
  const archiveTimeOffsetUnitValue = archiveTimeOffsetUnit ? archiveTimeOffsetUnitsOptions.find((o) => o.value === archiveTimeOffsetUnit) : null;
  const archiveRelativeToInitiation = initData[ARCHIVE_RELATIVE_TO_INITIATION_KEY];
  const archiveRelativeToInitiationValue = archiveRelativeToInitiation ? archiveRelativeToInitiationOptions.find((o) => o.value === archiveRelativeToInitiation) : null;
  const initiatorCanSetFrequency = formData?.submission_settings[INITIATOR_CAN_SET_FREQUENCY_SETTING_KEY];
  const oneTimeInitiationStartDate = initData[ONE_TIME_INITIATION_START_DATE_KEY];
  const oneTimeInitiationStartTime = initData[ONE_TIME_INITIATION_START_TIME_KEY];

  const archiveDate = initData[ARCHIVE_DATE_KEY];
  const archiveDateValue = archiveDate ? new Date(archiveDate) : null;
  const archiveTime = initData[ARCHIVE_TIME_KEY];
  const archiveTimeValue = archiveTime ? new Date(archiveTime) : null;

  const [foldersList, setFoldersList] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (formData && foldersList.length) {
      const folders = getFoldersList(foldersList);
      setCurrentFolder(folders.find((item) => item.id === formData.folder_id));
    }
  }, [formData, foldersList]);

  useEffect(() => {
    if (initData.public_folder_id && foldersList.length) {
      const folders = getFoldersList(foldersList);
      setCurrentFolder(folders.find((item) => item.id === initData.public_folder_id.id));
    }
  }, [initData, foldersList]);

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  const closeMenu = () => setIsOpen(false);

  useOutsideClick(wrapperRef, closeMenu);

  const init = () => {
    getPublicFolder({ company_id: companyId })
      .then((res) => {
        // setFoldersList(res[0].folders)
        setFoldersList(res);
      })
      .catch((e) => console.log(e));
  };

  const getFoldersList = (folders) => {
    const list = [];
    getProp(folders);

    function getProp(o) {
      o.forEach((item) => {
        list.push(item);
        if (item.folders.length) {
          getProp(item.folders);
        }
      });
    }

    return list;
  };

  const handelFolder = (data, key) => {
    setCurrentFolder(data);
    handelInitSettings(data, key);
  };

  const renderRecurrentInitiationRepeatPeriodSettings = () => {
    let layout = null;
    if (recurrentInitiationRepeatPeriod) {
      switch (recurrentInitiationRepeatPeriod) {
        case 'weekly': {
          layout = (
            <div className={'form-item mb-4'}>
              <label>Repeat on these days:</label>
              <div className={'week-grid'}>
                {weekDays.map((day, dayIndex) => {
                  const isSelected = !!recurrentInitiationRepeatDays[dayIndex];
                  return (
                    <div key={day} className={'form-control-checkbox-span'}>
                      <input type="checkbox" checked={isSelected} onChange={() => handelInitSettings(dayIndex, RECURRENT_INITIATION_REPEAT_DAYS_KEY)} />
                      <span>{day}</span>
                    </div>
                  );
                })}
              </div>
              {errors?.frequency?.week_days && <span className="form-error mb-2">{errors.frequency?.week_days[0]}</span>}
            </div>
          );
          break;
        }
        case 'monthly': {
          const daysInInitiationPeriod = getDaysInInitiationPeriod(recurrentInitiationStartDay, recurrentInitiationEndDay);
          layout = (
            <div className={'form-item multi-date-picker disabled-change-month mb-4'}>
              <label className="form-label">Initiation Date</label>
              <div className={'clear-datepicker'}>
                <MultiDatePicker
                  multiple
                  format={'DD'}
                  editable={false}
                  placeholder={'Choose Days'}
                  disabled={!recurrentInitiationStartDay}
                  currentDate={
                    new DateObject({
                      year: 2024,
                      month: 12,
                      day: 1,
                    })
                  }
                  mapDays={({ date }) => {
                    const isActive = recurrentInitiationEndDay ? daysInInitiationPeriod.includes(date.day) : true;
                    return {
                      disabled: !isActive,
                    };
                  }}
                  value={recurrentInitiationRepeatMonthDays}
                  onChange={(dates) => {
                    handelInitSettings(
                      dates.map((value) => value),
                      RECURRENT_INITIATION_REPEAT_MONTH_DAYS_KEY,
                    );
                  }}
                />
              </div>

              {errors?.frequency?.month_day && <span className="form-error mb-2">{errors.frequency?.month_day[0]}</span>}
            </div>
          );
          break;
        }
        case 'yearly': {
          const daysInInitiationPeriod = getDaysInInitiationPeriod(recurrentInitiationStartDay, recurrentInitiationEndDay);
          layout = (
            <>
              <div className={'form-item mb-4'}>
                <label htmlFor="">Repeat on these months:</label>
                <div className={'week-grid month-grid'}>
                  {getMonthsInInitiationPeriod(recurrentInitiationStartDay, recurrentInitiationEndDay).map((item) => {
                    const monthIndex = getMonthIndexByName(item);
                    const isSelected = get(initData, `[${recurrentInitiationRepeatYearMonths}][${monthIndex}]`, null);
                    return (
                      <div key={item} className={'form-control-checkbox-span'}>
                        <input type="checkbox" checked={isSelected} onChange={() => handelInitSettings(monthIndex, RECURRENT_INITIATION_REPEAT_YEAR_MONTHS_KEY)} />
                        <span>{item}</span>
                      </div>
                    );
                  })}
                </div>
                {errors?.frequency?.months && <span className="form-error mb-2">{errors.frequency?.months[0]}</span>}
              </div>
              <div className={'form-item multi-date-picker disabled-change-month mb-4'}>
                <label className="form-label">Initiation Date</label>
                <div className={'clear-datepicker'}>
                  <MultiDatePicker
                    multiple
                    format={'DD'}
                    editable={false}
                    placeholder={'Choose Days'}
                    disabled={!recurrentInitiationStartDay}
                    currentDate={
                      new DateObject({
                        year: 2024,
                        month: 12,
                        day: 1,
                      })
                    }
                    mapDays={({ date }) => {
                      const isActive = recurrentInitiationEndDay ? daysInInitiationPeriod.includes(date.day) : true;
                      return {
                        disabled: !isActive,
                      };
                    }}
                    value={recurrentInitiationRepeatMonthDays}
                    onChange={(dates) => {
                      handelInitSettings(
                        dates.map((value) => value),
                        RECURRENT_INITIATION_REPEAT_MONTH_DAYS_KEY,
                      );
                    }}
                  />
                </div>
                {errors?.frequency?.month_day && <span className="form-error mb-2">{errors.frequency?.month_day[0]}</span>}
              </div>
            </>
          );
          break;
        }
      }
    }
    return layout;
  };

  const renderTimeSelectCustomInput = (time) => {
    return (
      <div style={{ lineHeight: 1.1 }}>
        {time ? dayjs(time).format(DATE_PICKER_TIME_FORMAT) : <span style={{ color: 'var(--secondary-on-surface, #98A2B3)', opacity: 0.5 }}>Choose time</span>}
      </div>
    );
  };

  const renderOneTimeInitiationArchiveSettings = () => {
    let layout = null;
    if (oneTimeInitiationOrArchivingValue) {
      if (automaticallyArchiveFormValue) {
        layout = (
          <>
            <div className="d-flex time-select mb-0" style={{ alignItems: 'flex-start' }}>
              <div className={'form-item form-item-select mb-4'}>
                <label className="form-label">Archive Date</label>
                <DatePicker
                  isClearable
                  minDate={new Date()}
                  className={'form-control'}
                  selected={archiveDateValue}
                  placeholderText={'Choose Date'}
                  dateFormat={DATE_PICKER_DATE_FORMAT}
                  onChange={(date) => handelInitSettings(date, ARCHIVE_DATE_KEY)}
                />
                {errors?.archive_settings?.archive_datetime && <span className="form-error mb-2">{errors?.archive_settings?.archive_datetime[0]}</span>}
              </div>
              <div className={'form-item form-item-select form-item-time mb-4'}>
                <label className="form-label">Archive Time</label>
                <DatePicker
                  isClearable
                  showTimeSelect
                  timeCaption={null}
                  timeIntervals={60}
                  showTimeSelectOnly
                  className={'form-control'}
                  selected={archiveTimeValue}
                  placeholderText={'Choose Time'}
                  dateFormat={DATE_PICKER_TIME_FORMAT}
                  customInput={renderTimeSelectCustomInput(archiveTimeValue)}
                  onChange={(date) => handelInitSettings(date, ARCHIVE_TIME_KEY)}
                />
              </div>
            </div>
          </>
        );
      }
    }
    return layout;
  };

  const renderRecurrentInitiationArchiveCustomSettings = () => {
    let layout = null;
    if (recurrentInitiationOrArchivingValue && automaticallyArchiveFormValue) {
      layout = (
        <>
          <div className={'form-control-item-settings'}>
            <div className="form-settings-label">
              <label className="form-label form-label-big">Archive the form at the same time as the next initiation</label>
            </div>
            <div className="form-settings-inputs">
              <div className="d-flex">
                <div className={`form-control-radio`}>
                  <input
                    type="radio"
                    name={ARCHIVE_WITH_NEXT_INITIATION_KEY}
                    checked={archiveWithNextInitiation}
                    onChange={() => handelInitSettings(true, ARCHIVE_WITH_NEXT_INITIATION_KEY)}
                  />
                  <span>Yes</span>
                </div>
                <div className={`form-control-radio`}>
                  <input
                    type="radio"
                    name={ARCHIVE_WITH_NEXT_INITIATION_KEY}
                    checked={!archiveWithNextInitiation}
                    onChange={() => handelInitSettings(false, ARCHIVE_WITH_NEXT_INITIATION_KEY)}
                  />
                  <span>No</span>
                </div>
              </div>
              {!archiveWithNextInitiation && (
                <>
                  <div className="d-flex time-select" style={{ alignItems: 'flex-start' }}>
                    <div className="form-item" style={{ maxWidth: 110 }}>
                      <label className="form-label">Number</label>
                      <input
                        type="number"
                        className={'form-control'}
                        value={archiveTimeOffset}
                        name={ARCHIVE_TIME_OFFSET_KEY}
                        onChange={(e) => handelInitSettings(e.target.value, ARCHIVE_TIME_OFFSET_KEY)}
                      />
                      {errors?.archive_settings?.time_value && <span className="form-error mb-2">{errors?.archive_settings?.time_value[0]}</span>}
                    </div>
                    <div className="form-item">
                      <label className="form-label">Hours / Days</label>
                      <Select
                        placeholder="Select"
                        className="form-control-select"
                        classNamePrefix="react-select"
                        value={archiveTimeOffsetUnitValue}
                        options={archiveTimeOffsetUnitsOptions}
                        onChange={(option) => handelInitSettings(option.value, ARCHIVE_TIME_OFFSET_UNIT_KEY)}
                      />
                      {errors?.archive_settings?.time_unit && <span className="form-error mb-2">{errors?.archive_settings?.time_unit[0]}</span>}
                    </div>
                    <div className="form-item">
                      <label className="form-label">Before / After next Initiation </label>
                      <Select
                        placeholder="Select"
                        className="form-control-select"
                        classNamePrefix="react-select"
                        value={archiveRelativeToInitiationValue}
                        options={archiveRelativeToInitiationOptions}
                        onChange={(option) => handelInitSettings(option.value, ARCHIVE_RELATIVE_TO_INITIATION_KEY)}
                      />
                      {errors?.archive_settings?.time_relation && <span className="form-error mb-2">{errors?.archive_settings?.time_relation[0]}</span>}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      );
    }
    return layout;
  };

  const renderArchiveSettings = () => {
    let layout = null;
    if (oneTimeInitiationOrArchivingValue || recurrentInitiationOrArchivingValue) {
      layout = (
        <>
          <div className={'form-control-item-settings'}>
            <div className="form-settings-label">
              <label className="form-label form-label-big">Should the form be automatically archived</label>
            </div>
            <div className="form-settings-inputs">
              <div className="d-flex">
                <div className={`form-control-radio`}>
                  <input
                    type="radio"
                    name={AUTOMATICALLY_ARCHIVE_FORM_KEY}
                    checked={automaticallyArchiveFormValue}
                    onChange={() => handelInitSettings(true, AUTOMATICALLY_ARCHIVE_FORM_KEY)}
                  />
                  <span>Yes</span>
                </div>
                <div className={`form-control-radio`}>
                  <input
                    type="radio"
                    name={AUTOMATICALLY_ARCHIVE_FORM_KEY}
                    checked={!automaticallyArchiveFormValue}
                    onChange={() => handelInitSettings(false, AUTOMATICALLY_ARCHIVE_FORM_KEY)}
                  />
                  <span>No</span>
                </div>
              </div>
              {renderOneTimeInitiationArchiveSettings()}
            </div>
          </div>

          {renderRecurrentInitiationArchiveCustomSettings()}
        </>
      );
    }

    return layout;
  };

  return (
    <>
      <div className={`form-item form-item-select form-control-item-settings z-index-10`}>
        <div className="form-settings-label">
          <label className="form-label form-label-big">Place the form in the Company Library (required)</label>
          <span>
            You need to place the form in the following folder. Folder can contain other nested folders, so you will have the ability to delve deeper into the folder structure.{' '}
          </span>
        </div>
        <div className="form-settings-inputs">
          <div className={`form-control-item-group form-item form-item-select`}>
            <label className="form-label form-label-big">Public Folder</label>
            <div className="folder-select" ref={wrapperRef}>
              <div className="folder-select-input cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
                {!currentFolder && <span className="folder-select-input-placeholder">Choose folder</span>}
                {currentFolder && currentFolder.name}
                <i className="icon-arrow-down"></i>
              </div>
              {isOpen && (
                <div className="folder-select-menu">
                  <ul className="folder-select-ul">
                    {foldersList.map((folder) => (
                      <MenuItem data={folder} key={folder.id} folder={currentFolder} handelFolder={handelFolder}></MenuItem>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            {errors?.public_folder_id && <span className={'form-error'}>{errors?.public_folder_id[0]}</span>}
          </div>
        </div>
      </div>

      {initiatorCanSetFrequency && (
        <>
          <div className={`form-control-item-settings`}>
            <div className="form-settings-label">
              <label className="form-label form-label-big">One-time initiation</label>
            </div>
            <div className="form-settings-inputs">
              <div className="d-flex">
                <div className={`form-control-radio`}>
                  <input
                    type="radio"
                    name={ONE_TIME_INITIATION_OR_ARCHIVING_KEY}
                    checked={oneTimeInitiationOrArchivingValue}
                    disabled={recurrentInitiationOrArchivingValue}
                    onChange={() => handelInitSettings(true, ONE_TIME_INITIATION_OR_ARCHIVING_KEY)}
                  />
                  <span>Yes</span>
                </div>
                <div className={`form-control-radio`}>
                  <input
                    type="radio"
                    name={ONE_TIME_INITIATION_OR_ARCHIVING_KEY}
                    disabled={recurrentInitiationOrArchivingValue}
                    checked={!oneTimeInitiationOrArchivingValue}
                    onChange={() => handelInitSettings(false, ONE_TIME_INITIATION_OR_ARCHIVING_KEY)}
                  />
                  <span>No</span>
                </div>
              </div>

              {oneTimeInitiationOrArchivingValue && (
                <>
                  <label className="form-label">Will the form be initiated immediately?</label>
                  <div className="d-flex mb-2">
                    <div className={`form-control-radio`}>
                      <input
                        type="radio"
                        name={INITIATE_FORM_IMMEDIATELY_KEY}
                        checked={initiateFormImmediatelyValue}
                        onChange={() => handelInitSettings(true, INITIATE_FORM_IMMEDIATELY_KEY)}
                      />
                      <span>Yes</span>
                    </div>
                    <div className={`form-control-radio`}>
                      <input
                        type="radio"
                        name={INITIATE_FORM_IMMEDIATELY_KEY}
                        checked={!initiateFormImmediatelyValue}
                        onChange={() => handelInitSettings(false, INITIATE_FORM_IMMEDIATELY_KEY)}
                      />
                      <span>No</span>
                    </div>
                  </div>

                  {!initiateFormImmediatelyValue && (
                    <>
                      <div className={'d-flex time-select mb-2'} style={{ alignItems: 'flex-start' }}>
                        <div className={'form-item form-item-select'} style={{ width: '100%' }}>
                          <label className="form-label">Start Day</label>
                          <DatePicker
                            isClearable
                            minDate={new Date()}
                            className={'form-control'}
                            placeholderText={'Choose Date'}
                            dateFormat={DATE_PICKER_DATE_FORMAT}
                            selected={oneTimeInitiationStartDate}
                            onChange={(date) => handelInitSettings(date, ONE_TIME_INITIATION_START_DATE_KEY)}
                          />
                          {errors?.start_date_time && <span className="form-error mb-2">{errors?.start_date_time[0]}</span>}
                        </div>
                      </div>

                      <div className={'d-flex time-select mb-0'}>
                        <div className={'form-item form-item-select form-item-time'} style={{ width: '100%' }}>
                          <label className="form-label">Start Time</label>
                          <DatePicker
                            isClearable
                            showTimeSelect
                            timeCaption={null}
                            timeIntervals={60}
                            showTimeSelectOnly
                            className={'form-control'}
                            placeholderText={'Choose Time'}
                            selected={oneTimeInitiationStartTime}
                            dateFormat={DATE_PICKER_TIME_FORMAT}
                            customInput={renderTimeSelectCustomInput(oneTimeInitiationStartTime)}
                            onChange={(date) => handelInitSettings(date, ONE_TIME_INITIATION_START_TIME_KEY)}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>

          {oneTimeInitiationOrArchivingValue && renderArchiveSettings()}

          <div className={'form-control-item-settings'}>
            <div className="form-settings-label">
              <label className="form-label form-label-big">Recurrent initiation</label>
            </div>
            <div className="form-settings-inputs">
              <div className="d-flex mb-2">
                <div className={`form-control-radio`}>
                  <input
                    type="radio"
                    name={RECURRENT_INITIATION_OR_ARCHIVING_KEY}
                    disabled={oneTimeInitiationOrArchivingValue}
                    checked={recurrentInitiationOrArchivingValue}
                    onChange={() => handelInitSettings(true, RECURRENT_INITIATION_OR_ARCHIVING_KEY)}
                  />
                  <span>Yes</span>
                </div>
                <div className={`form-control-radio`}>
                  <input
                    type="radio"
                    name={RECURRENT_INITIATION_OR_ARCHIVING_KEY}
                    disabled={oneTimeInitiationOrArchivingValue}
                    checked={!recurrentInitiationOrArchivingValue}
                    onChange={() => handelInitSettings(false, RECURRENT_INITIATION_OR_ARCHIVING_KEY)}
                  />
                  <span>No</span>
                </div>
              </div>

              {recurrentInitiationOrArchivingValue && (
                <>
                  <div className={'d-flex time-select mb-2'} style={{ alignItems: 'flex-start' }}>
                    <div className={'form-item form-item-select'}>
                      <label className="form-label">First initiation Day</label>
                      <DatePicker
                        isClearable
                        minDate={new Date()}
                        className={'form-control'}
                        placeholderText={'Choose Date'}
                        dateFormat={DATE_PICKER_DATE_FORMAT}
                        selected={recurrentInitiationStartDay}
                        onChange={(date) => handelInitSettings(date, RECURRENT_INITIATION_START_DAY_KEY)}
                      />
                      {errors?.frequency?.first_initiation_day && <span className="form-error mb-2">{errors.frequency?.first_initiation_day[0]}</span>}
                    </div>
                    <div className={'form-item form-item-select'}>
                      <label className="form-label">Last initiation Day (optional)</label>
                      <DatePicker
                        isClearable
                        className={'form-control'}
                        placeholderText={'Choose Date'}
                        dateFormat={DATE_PICKER_DATE_FORMAT}
                        selected={recurrentInitiationEndDay}
                        minDate={recurrentInitiationStartDay ?? new Date()}
                        onChange={(date) => handelInitSettings(date, RECURRENT_INITIATION_END_DAY_KEY)}
                      />
                    </div>
                  </div>

                  <div className={'d-flex time-select mb-2'} style={{ alignItems: 'flex-start' }}>
                    <div className={'form-item form-item-select'}>
                      <label className="form-label">Repeat every</label>
                      <Select
                        placeholder="Select Quantity"
                        className="form-control-select"
                        classNamePrefix="react-select"
                        options={recurrentRepeatEveryOptions}
                        value={recurrentInitiationRepeatEveryValue}
                        onChange={(option) => handelInitSettings(option.value, RECURRENT_INITIATION_REPEAT_EVERY_KEY)}
                      />
                      {errors?.frequency?.times && <span className="form-error mb-2">{errors.frequency?.times[0]}</span>}
                    </div>
                    <div className={'form-item form-item-select'}>
                      <label className="form-label opacity-0">Repeat period</label>
                      <Select
                        placeholder="Select Period"
                        className="form-control-select"
                        classNamePrefix="react-select"
                        options={recurrentRepeatPeriodsOptions}
                        value={recurrentInitiationRepeatPeriodValue}
                        onChange={(option) => handelInitSettings(option.value, RECURRENT_INITIATION_REPEAT_PERIOD_KEY)}
                      />
                      {errors?.frequency?.type && <span className="form-error mb-2">{errors.frequency?.type[0]}</span>}
                    </div>
                  </div>

                  {renderRecurrentInitiationRepeatPeriodSettings()}

                  <div className={'d-flex time-select'}>
                    <div className={'form-item form-item-select form-item-time'} style={{ width: '100%' }}>
                      <label className="form-label">Initiation Time</label>
                      <DatePicker
                        isClearable
                        showTimeSelect
                        timeCaption={null}
                        timeIntervals={60}
                        showTimeSelectOnly
                        className={'form-control'}
                        placeholderText={'Choose Time'}
                        selected={recurrentInitiationTime}
                        dateFormat={DATE_PICKER_TIME_FORMAT}
                        customInput={renderTimeSelectCustomInput(recurrentInitiationTime)}
                        onChange={(date) => handelInitSettings(date, RECURRENT_INITIATION_TIME_KEY)}
                      />
                      {errors?.frequency?.time && <span className="form-error mb-2">{errors.frequency?.time[0]}</span>}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          {recurrentInitiationOrArchivingValue && renderArchiveSettings()}
        </>
      )}
    </>
  );
};
