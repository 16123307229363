import React, { useEffect, useRef, useState } from 'react';
import { EmptyPage } from '../other/EmptyPage';
import FacilityItem from './facility-item';
import { activatedFacility, deactivateFacility, deleteFacility } from '../../servises/facility';
import DeactivateModal from './deactivate-modal';
import CopyModal from './copy-modal';
import { copyFacilityApi } from '../../servises/company';
import useOutsideClick from '../../hook/useOutsideClick';

function FacilityTab({ facilities, openAddModal, openAddAttachModal, handelEdit, handelCreateFacility }) {
  const [facilitiesList, setFacilitiesList] = useState(facilities);
  const [search, setSearch] = useState('');
  const [deleteIsOpen, setIsOpenDelete] = useState(false);
  const [copyFacilityOpen, setIsOpenCopy] = useState(false);
  const [current, setCurrent] = useState(null);
  const [error, setError] = useState([]);
  const [copyError, setCopyError] = useState([]);
  const handleDeleteIsOpen = () => {
    setIsOpenDelete(false);
    setCurrent(null);
    setError([]);
  };
  const handelDeleteOpen = (facility) => {
    setIsOpenDelete(true);
    setCurrent(facility);
  };

  const handleCopyIsClose = () => {
    setIsOpenCopy(false);
    setCurrent(null);
    setCopyError([]);
  };
  const handleCopyIsOpen = (facility) => {
    setIsOpenCopy(true);
    setCurrent(facility);
  };

  useEffect(() => {
    let filteredData = facilities;
    if (search) {
      filteredData = facilities.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));
    }
    setFacilitiesList(filteredData);
  }, [search, facilities]);

  const handelCopy = () => {
    copyFacilityApi(current.id, {
      should_copy_positions: true,
      should_copy_user_groups: true,
    })
      .then((res) => {
        handleCopyIsClose();
        handelCreateFacility();
      })
      .catch((e) => {
        if (e?.response?.data) {
          setCopyError(e.response.data);
        }
      });
  };
  const handelDeactivate = (facility) => {
    deactivateFacility(facility.id)
      .then((res) => handelCreateFacility())
      .catch((e) => console.log(e));
  };
  const handelDelete = () => {
    deleteFacility(current.id)
      .then((res) => {
        handelCreateFacility();
        handleDeleteIsOpen();
      })
      .catch((e) => {
        if (e?.response?.data) {
          setError(e.response.data);
        }
      });
  };

  const handelActivated = (facility) => {
    activatedFacility(facility.id)
      .then((res) => handelCreateFacility())
      .catch((e) => console.log(e));
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const ref = useRef();
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  useOutsideClick(ref, closeDropdown);

  return (
    <>
      <div className="library-filter">
        <div className="library-filter-search form-control-item">
          <input type="text" className="form-control" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search" />
          <i className="icon-search"></i>
        </div>
        <div>
          <div className="dropdown" ref={ref}>
            <button onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="btn">
              <i className="icon-plus-svgrepo-com mr-2"></i> Add facility
            </button>
            {isDropdownOpen && (
              <ul className="dropdown-menu">
                <li>
                  <button className="btn ml-0 btn-left btn-full btn-text" onClick={openAddModal}>
                    <i className="icon-plus-svgrepo-com mr-2"></i> Add manually
                  </button>
                </li>
                <li>
                  <button className={'btn ml-0 btn-full btn-text'} onClick={openAddAttachModal}>
                    <i className="icon-file-text mr-2"></i> Add from the submission
                  </button>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
      <div className="company-profile-facilities-grid">
        {facilitiesList.map((item) => (
          <FacilityItem
            key={item.id}
            facility={item}
            handelEdit={handelEdit}
            handelCopy={handleCopyIsOpen}
            handelDeactivate={handelDeactivate}
            handelActivated={handelActivated}
            handelDelete={handelDeleteOpen}
          />
        ))}
      </div>
      {!facilitiesList.length && !search && (
        <EmptyPage
          imageUrl={'/images/empty-facility.svg'}
          title={'No facility listed yet '}
          subtitle={'Simply click the button “Add Facility”  to begin the Facility registration process'}
        />
      )}
      {!facilitiesList.length && search && (
        <EmptyPage
          imageUrl={'/images/no-resoult.png'}
          title={'Unfortunately, nothing was found for your query.'}
          subtitle={"Try to change the range of keywords. We're here to help you find what you're looking for."}
        />
      )}

      <DeactivateModal
        isOpen={deleteIsOpen}
        closeModal={handleDeleteIsOpen}
        handelSuccess={handelDelete}
        errors={error}
        title="Confirm Facility deletion"
        button={'Delete'}
        subtitle="This action is irreversible and will permanently remove all associated entities. Deleted information cannot be restored. "
      ></DeactivateModal>

      <CopyModal
        isOpen={copyFacilityOpen}
        closeModal={handleCopyIsClose}
        title={'Copy Facility'}
        subtitle={'Are you sure you want to copy this facility?'}
        button={'Copy'}
        handelSuccess={handelCopy}
        errors={copyError}
      />
    </>
  );
}

export default FacilityTab;
