import client from '../../api';
import axios from 'axios';
import qs from 'qs';

const CancelToken = axios.CancelToken;
let cancel;

export const getFacilities = async ({ companyId }) => {
  if (cancel !== undefined) {
    cancel();
  }
  return await client.get(`/api/corporate-library/report-builder/statistics/facilities/`, {
    params: { company_id: companyId },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
};
export const getUserGroups = async ({ companyId, facilitiesIDs }) => {
  if (cancel !== undefined) {
    cancel();
  }
  return await client.get(`/api/corporate-library/report-builder/statistics/user-groups/`, {
    params: { company_id: companyId, facilities_ids: facilitiesIDs },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
};
export const getFormsList = async ({ companyId, facilitiesIDs, initiationDateAfter, initiationDateBefore, userGroupsIDs }) => {
  if (cancel !== undefined) {
    cancel();
  }
  const params = {
    company_id: companyId,
  };

  if (facilitiesIDs) params.facilities_ids = facilitiesIDs;
  if (initiationDateAfter) params.initiation_date_after = new Date(initiationDateAfter).toISOString();
  if (initiationDateBefore) {
    const endDate = new Date(initiationDateBefore);
    endDate.setHours(23, 59, 59, 999);
    params.initiation_date_before = endDate.toISOString();
  }
  if (userGroupsIDs) params.user_groups_ids = userGroupsIDs;

  return await client.get(`/api/corporate-library/report-builder/statistics/forms/`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
};
export const getReport = async ({ companyId, formsIDs, facilitiesIDs, initiationDateAfter, initiationDateBefore, userGroupsIDs }) => {
  if (cancel !== undefined) {
    cancel();
  }

  const params = {
    company_id: companyId,
    forms_ids: formsIDs,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  if (facilitiesIDs) params.facilities_ids = facilitiesIDs;
  if (initiationDateAfter) params.initiation_date_after = new Date(initiationDateAfter).toISOString();
  if (initiationDateBefore) {
    const endDate = new Date(initiationDateBefore);
    endDate.setHours(23, 59, 59, 999);
    params.initiation_date_before = endDate.toISOString();
  }
  if (userGroupsIDs) params.user_groups_ids = userGroupsIDs;
  try {
    return await client.get(`/api/corporate-library/report-builder/statistics/download/`, {
      params,
      responseType: 'blob',
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
  }
};
