import React, { useEffect, useRef, useState } from 'react';
import { MultiSelect } from '../../other/MultiSelect';
import Select from 'react-select';
import { Tooltip } from 'react-tooltip';
import useOutsideClick from '../../../hook/useOutsideClick';
import { getPublicFolder } from '../../../servises/folder/form-avaliable';
import { useAuth } from '../../../container/authContext';

const MenuItem = ({ data, handelFolder, folder }) => {
  const { id, name, folders } = data;

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(folder && folder.id === id);
  }, [folder, id, name]);

  return (
    <li className={isActive ? 'active-folder' : ''}>
      <span
        className={'cursor-pointer'}
        onClick={() => {
          handelFolder(id, 'public_folder_id');
        }}
      >
        {!folders.length && (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M20.0005 17.4C20.0005 17.8774 19.8108 18.3352 19.4733 18.6728C19.1357 19.0104 18.6779 19.2 18.2005 19.2H3.80049C3.3231 19.2 2.86526 19.0104 2.5277 18.6728C2.19013 18.3352 2.00049 17.8774 2.00049 17.4V4.8C2.00049 4.32261 2.19013 3.86477 2.5277 3.52721C2.86526 3.18964 3.3231 3 3.80049 3H8.30049L10.1005 5.7H18.2005C18.6779 5.7 19.1357 5.88964 19.4733 6.22721C19.8108 6.56477 20.0005 7.02261 20.0005 7.5V17.4Z"
                stroke="#98A2B3"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </>
        )}
        {!!folders.length && (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
              <path d="M2.50049 3.75L5.00049 6.25L7.50049 3.75" stroke="#98A2B3" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path
                  d="M20.7502 11.5484V20.2984C20.7502 20.4841 20.6753 20.6621 20.5419 20.7934C20.4086 20.9247 20.2277 20.9984 20.0391 20.9984H4.7502C4.53802 20.9984 4.33454 20.9155 4.18451 20.7678C4.03448 20.6201 3.9502 20.4198 3.9502 20.2109V9.18594C3.9502 8.97708 4.03448 8.77678 4.18451 8.62909C4.33454 8.48141 4.53802 8.39844 4.7502 8.39844H9.28353C9.45662 8.39844 9.62505 8.4537 9.76353 8.55594L12.5369 10.6034C12.6753 10.7057 12.8438 10.7609 13.0169 10.7609H19.9502C20.1624 10.7609 20.3659 10.8439 20.5159 10.9916C20.6659 11.1393 20.7502 11.3396 20.7502 11.5484Z"
                  stroke="#98A2B3"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.05029 7.34922V4.98672C6.05029 4.77786 6.13985 4.57756 6.29925 4.42987C6.45866 4.28219 6.67486 4.19922 6.90029 4.19922H11.717C11.9009 4.19922 12.0798 4.25448 12.227 4.35672L15.1736 6.40422C15.3208 6.50645 15.4997 6.56172 15.6836 6.56172H23.0503C23.2757 6.56172 23.4919 6.64469 23.6513 6.79237C23.8107 6.94006 23.9003 7.14036 23.9003 7.34922V16.0992C23.9003 16.1911 23.8807 16.2822 23.8428 16.3671C23.8048 16.452 23.7492 16.5292 23.679 16.5942C23.6088 16.6592 23.5255 16.7108 23.4339 16.7459C23.3422 16.7811 23.244 16.7992 23.1447 16.7992H21.7003"
                  stroke="#98A2B3"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </>
        )}
        {name}
      </span>

      {!!folders.length && (
        <ul className="folder-select-ul-inner">
          {folders.map((i) => (
            <MenuItem data={i} key={i.id} handelFolder={handelFolder} folder={folder} />
          ))}
        </ul>
      )}
    </li>
  );
};

export const MixedSettings = function MixedSettings({
  public_folder_id,
  submission_settings,
  handelSettings,
  handelRemove,
  handelSubmittersSettings,
  facilityOptions,
  usersGroupsOptions,
  individualUsersOptions,
  addNewOrder,
  handelOrderSettings,
  orderRemove,
  errors,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  const [foldersList, setFoldersList] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const { user } = useAuth();
  const companyId = user.company?.id;
  const closeMenu = () => {
    setIsOpen(false);
  };
  // console.log(public_folder_id)

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (submission_settings.public_folder_id) {
      if (foldersList.length) {
        const listFolder = getFoldersList(foldersList);
        setCurrentFolder(listFolder.find((item) => submission_settings.public_folder_id === item.id));
      }
    }
    // eslint-disable-next-line
  }, [submission_settings, foldersList]);

  const getFoldersList = (folders) => {
    const list = [];
    getProp(folders);

    function getProp(o) {
      o.forEach((item) => {
        list.push(item);
        if (item.folders.length) {
          getProp(item.folders);
        }
      });
    }

    return list;
  };
  const init = () => {
    getPublicFolder({ company_id: companyId })
      .then((res) => {
        setFoldersList(res);
        const listFolder = getFoldersList(res);
        if (public_folder_id) {
          setCurrentFolder(listFolder.find((item) => public_folder_id === item.id));
        } else if (submission_settings.public_folder_id) {
          setCurrentFolder(listFolder.find((item) => submission_settings.public_folder_id === item.id));
        }
      })
      .catch((e) => console.log(e));
  };

  useOutsideClick(wrapperRef, closeMenu);

  return (
    <>
      <div className={`form-item form-item-select form-control-item-settings  z-index-10`}>
        <div className="form-settings-label">
          <label className="form-label form-label-big">Place the form in the Company Library (required)</label>
          <span>
            You need to place the form in the following folder. Folder can contain other nested folders, so you will have the ability to delve deeper into the folder structure.{' '}
          </span>
        </div>
        <div className="form-settings-inputs">
          <div className={`form-control-item-group form-item form-item-select`}>
            <label className="form-label form-label-big">Public Folder</label>
            <div className="folder-select" ref={wrapperRef}>
              <div className="folder-select-input cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
                {!currentFolder && <span className="folder-select-input-placeholder">Choose folder</span>}
                {currentFolder && currentFolder.name}
                <i className="icon-arrow-down"></i>
              </div>
              {isOpen && (
                <div className="folder-select-menu">
                  <ul className="folder-select-ul">
                    {foldersList.map((folder) => (
                      <MenuItem data={folder} key={folder.id} folder={currentFolder} handelFolder={handelSettings}></MenuItem>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <span className="form-error">{errors?.public_folder_id && errors.public_folder_id[0]}</span>
            <span className="form-error mb-2">{errors?.submitters_order_steps?.public}</span>
          </div>
        </div>
      </div>

      <div className="form-control-item-settings">
        <div className="form-settings-label">
          <label className="form-label form-label-big">Define form submitters</label>
          <span>Please specify who will be form submitters: system users, contacts or unregistered users</span>
        </div>
        <div className="form-settings-inputs">
          <div className={`form-item form-item-select mb-4`}>
            <label className="form-label form-label-big">Facility</label>
            <div className="select-multy-wrapper">
              <span className="select-placeholder">Choose one or several facilities</span>
              <MultiSelect
                isDisabled={!facilityOptions.length}
                options={facilityOptions}
                value={submission_settings.submitters?.facilities}
                onChange={(e) => handelSubmittersSettings(e, 'facilities')}
                placeholder=""
                allName="All facilities"
              ></MultiSelect>
              {submission_settings.submitters?.facilities?.length > 0 && (
                <>
                  <ul>
                    {submission_settings.submitters.facilities.map((item, key) => (
                      <li key={key}>
                        {item.label}
                        <button className="btn btn-icon" onClick={() => handelRemove('facilities', item)}>
                          <img src="/images/x-circle.svg" alt="" />
                        </button>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
            <span className="form-error mb-2 d-flex">{errors?.submitters?.facilities && errors.submitters?.facilities[0]}</span>
          </div>
          <div className={`form-item form-item-select mb-4`}>
            <label className="form-label form-label-big">User groups</label>
            <div className="select-multy-wrapper">
              <span className="select-placeholder">Choose one or several user groups</span>
              <MultiSelect
                isDisabled={!usersGroupsOptions.length}
                options={usersGroupsOptions}
                value={submission_settings.submitters?.user_groups}
                onChange={(e) => handelSubmittersSettings(e, 'user_groups')}
                placeholder=""
                allName="All user groups"
              ></MultiSelect>
              {submission_settings.submitters?.user_groups?.length > 0 && (
                <>
                  <ul>
                    {submission_settings.submitters.user_groups.map((item, key) => (
                      <li key={key}>
                        {item.label}
                        <button className="btn btn-icon" onClick={() => handelRemove('user_groups', item)}>
                          <img src="/images/x-circle.svg" alt="" />
                        </button>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
            <span className="form-error mb-2 d-flex">{errors?.submitters?.user_groups && errors.submitters?.user_groups[0]}</span>
          </div>

          <div className={`form-item form-item-select mb-4`}>
            <label className="form-label form-label-big">Individual Users</label>
            <div className="select-multy-wrapper">
              <span className="select-placeholder">Choose one or several individual_users</span>
              <MultiSelect
                isDisabled={!individualUsersOptions.length}
                options={individualUsersOptions}
                value={submission_settings.submitters?.individual_users}
                onChange={(e) => handelSubmittersSettings(e, 'individual_users')}
                placeholder=""
                allName="All individual users"
              ></MultiSelect>
              {submission_settings.submitters?.individual_users?.length > 0 && (
                <>
                  <ul className={'individual-users-list'}>
                    {submission_settings.submitters.individual_users
                      .sort(function (a, b) {
                        if (a.label < b.label) {
                          return -1;
                        }
                        if (a.label > b.label) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((item, key) => (
                        <li key={key}>
                          {item.label}
                          <button className="btn btn-icon" onClick={() => handelRemove('individual_users', item)}>
                            <img src="/images/x-circle.svg" alt="" />
                          </button>
                        </li>
                      ))}
                  </ul>
                </>
              )}

              <span className="form-error mb-2 d-flex">{errors?.submitters?.individual_users && errors.submitters?.individual_users[0]}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="form-control-item-settings">
        <div className="form-settings-label">
          <label className="form-label form-label-big">Will the form have a strict order of submitters?</label>
          <span>Please specify whether the form has to be submitted in a strict order of submitters.</span>
        </div>
        <div className="form-settings-inputs">
          <div className="form-item mb-4">
            <label className="form-label form-label-big">Step 1</label>
            <Select
              classNamePrefix="react-select"
              className={`form-control-select`}
              options={[
                ...(submission_settings.submitters?.individual_users ? submission_settings.submitters.individual_users : []),
                ...(submission_settings.submitters?.user_groups ? submission_settings.submitters?.user_groups : []),
              ]}
              value={submission_settings.submitters_order ? submission_settings.submitters_order[0] : null}
              onChange={(e) => handelOrderSettings(e, 'submitters_order', 0)}
            ></Select>
            <span className="form-error mb-2">
              {errors?.submitters_order && errors?.submitters_order[0] && errors?.submitters_order[0]?.hasOwnProperty('submitter') && errors?.submitters_order[0].submitter}
            </span>
            <span className="form-error mb-2">{errors?.submitters_order && typeof errors?.submitters_order[0] === 'string' && errors?.submitters_order[0]}</span>
            <div className={`form-control-checkbox mt-2`}>
              <input
                type="checkbox"
                onChange={(e) =>
                  handelOrderSettings(
                    {
                      ...(submission_settings.submitters_order && submission_settings.submitters_order[0] ? submission_settings.submitters_order[0] : {}),
                      public: e.target.checked ? true : false,
                    },
                    'submitters_order',
                    0,
                  )
                }
                checked={submission_settings?.submitters_order && submission_settings?.submitters_order[0] ? submission_settings?.submitters_order[0]?.public : null}
              />
              <span>
                <i className="icon-icon-info mr-2 ml-2" data-tooltip-id={`table-tooltip-public-1`}></i>
                At this step a form will be placed in the Public folder
              </span>
              <Tooltip opacity={1} id={`table-tooltip-public-1`} place="bottom">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip
              </Tooltip>
            </div>
          </div>
          <div className="form-item mb-4">
            <label className="form-label form-label-big">Step 2</label>
            <Select
              classNamePrefix="react-select"
              className={`form-control-select`}
              options={[
                ...(submission_settings.submitters?.individual_users ? submission_settings.submitters.individual_users : []),
                ...(submission_settings.submitters?.user_groups ? submission_settings.submitters?.user_groups : []),
              ]}
              value={submission_settings.submitters_order ? submission_settings.submitters_order[1] : null}
              onChange={(e) => handelOrderSettings(e, 'submitters_order', 1)}
            ></Select>
            <span className="form-error mb-2">
              {errors?.submitters_order && errors?.submitters_order[1] && errors?.submitters_order[1]?.hasOwnProperty('submitter') && errors?.submitters_order[1].submitter}
            </span>

            <div className={`form-control-checkbox mt-2`}>
              <input
                type="checkbox"
                onChange={(e) =>
                  handelOrderSettings(
                    {
                      ...(submission_settings.submitters_order && submission_settings.submitters_order[1] ? submission_settings.submitters_order[1] : {}),
                      public: e.target.checked ? true : false,
                    },
                    'submitters_order',
                    1,
                  )
                }
                checked={submission_settings?.submitters_order && submission_settings?.submitters_order[1] ? submission_settings?.submitters_order[1]?.public : null}
              />
              <span>
                <i className="icon-icon-info mr-2 ml-2" data-tooltip-id={`table-tooltip-public-1`}></i>
                At this step a form will be placed in the Public folder
              </span>
              <Tooltip opacity={1} id={`table-tooltip-public-1`} place="bottom">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip
              </Tooltip>
            </div>
          </div>

          {submission_settings.submitters_order?.slice(2, submission_settings.submitters_order.length).map((item, index) => (
            <div className="form-item mb-4" key={index}>
              <label className="form-label form-label-big">Step {index + 3}</label>
              <div className="d-flex mb-0">
                <Select
                  classNamePrefix="react-select"
                  className={`form-control-select`}
                  options={[
                    ...(submission_settings.submitters?.individual_users ? submission_settings.submitters.individual_users : []),
                    ...(submission_settings.submitters?.user_groups ? submission_settings.submitters?.user_groups : []),
                  ]}
                  value={item ? item : null}
                  onChange={(e) => handelOrderSettings(e, 'submitters_order', index + 2)}
                ></Select>

                <button className="btn btn-icon btn-remove ml-4" onClick={() => orderRemove(item, index + 2)}>
                  <i className="icon-remove"></i>
                </button>
              </div>
              <span className="form-error mb-2">
                {errors?.submitters_order &&
                  errors?.submitters_order[index + 2] &&
                  errors?.submitters_order[index + 2]?.hasOwnProperty('submitter') &&
                  errors?.submitters_order[index + 2].submitter}
              </span>
              <div className={`form-control-checkbox mt-2`}>
                <input
                  type="checkbox"
                  checked={item.public}
                  onChange={(e) =>
                    handelOrderSettings(
                      {
                        ...item,
                        public: e.target.checked ? true : false,
                      },
                      'submitters_order',
                      index + 2,
                    )
                  }
                />
                <span>
                  <i className="icon-icon-info mr-2 ml-2 z-index" data-tooltip-id={`table-tooltip-public-${item.step + 1}`}></i>
                  At this step a form will be placed in the Public folder
                </span>
                <Tooltip opacity={1} id={`table-tooltip-public-${item.step + 1}`} place="bottom">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip
                </Tooltip>
              </div>
            </div>
          ))}
          <span className="add-facility cursor-pointer" onClick={addNewOrder}>
            <i className="icon-plus-svgrepo-com mr-2"></i>Add order
          </span>
        </div>
      </div>

      <div className="form-control-item-settings">
        <div className="form-settings-label">
          <label className="form-label form-label-big">Can Submitters Edit Previous Submissions?</label>
          <span>Please determine if submitters will have the option to make edits to previous submission.</span>
        </div>
        <div className="form-settings-inputs">
          <div className="d-flex">
            <div className={`form-control-radio`}>
              <input
                name="can_edit_previous_submission"
                type="radio"
                onChange={() => handelSettings(true, 'can_edit_previous_submission')}
                checked={submission_settings.can_edit_previous_submission}
              />
              <span>Yes</span>
              <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-can_edit_previous_submission`}></i>
              <Tooltip opacity={1} id={`table-tooltip-can_edit_previous_submission`} place="bottom">
                The form submitter can edit previous submissions, added by them or other submitters
              </Tooltip>
            </div>
            <div className={`form-control-radio`}>
              <input
                name="can_edit_previous_submission"
                type="radio"
                onChange={() => handelSettings(false, 'can_edit_previous_submission')}
                checked={submission_settings.can_edit_previous_submission === false}
              />
              <span>No</span>
              <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-can_edit_previous_submission-false`}></i>
              <Tooltip opacity={1} id={`table-tooltip-can_edit_previous_submission-false`} place="bottom">
                The form submitter can edit previous submissions, added by them or other submitters
              </Tooltip>
            </div>
          </div>

          <span className="form-error mb-2 d-flex">{errors?.can_edit_previous_submission && errors.can_edit_previous_submission[0]}</span>
        </div>
      </div>
    </>
  );
};
