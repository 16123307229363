import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../../layout/form-builder';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Components from '../../components/from-builder/settings/components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getFromById } from '../../servises/form';
import { RenderForm } from '../../components/init-form/render-form';
import { Tooltip } from 'react-tooltip';

function FromBuilderSettings() {
  const [tabIndex, setTabIndex] = useState(0);
  const [formData, setFormData] = useState({});
  const { id } = useParams();
  const navigation = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('submission')) {
      setTabIndex(1);
    } else if (location.pathname.includes('recipient')) {
      setTabIndex(2);
    } else if (location.pathname.includes('notification')) {
      setTabIndex(3);
    } else {
      setTabIndex(0);
    }
  }, [location]);

  const [tabs, setTabs] = useState([
    {
      name: 'Access Settings',
      component: 'access-settings',
      index: 0,
      url: `/form-builder/${id}/settings/`,
    },
    {
      name: 'Submission Settings',
      component: 'submission-settings',
      index: 1,
      isDisable: false,
      url: `/form-builder/${id}/settings/submission`,
    },
    {
      name: 'Recipient Settings',
      component: 'recipient-settings',
      index: 2,
      isDisable: true,
      url: `/form-builder/${id}/settings/recipient`,
    },
    {
      name: 'Notification Settings',
      component: 'notification-settings',
      index: 3,
      isDisable: true,
      url: `/form-builder/${id}/settings/notification`,
    },
  ]);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [tabItem, setTabItem] = useState('desktop');
  const [orientation, setOrientation] = useState('vertical');
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (id) {
      getFromById(id)
        .then((res) => {
          setFormData(res);
        })
        .catch((e) => console.log(e));
    }
  }, [id, location]);

  useEffect(() => {
    if (formData?.submission_settings?.filling_type !== 'PUBLIC') {
      setTabs([
        {
          name: 'Access Settings',
          component: 'access-settings',
          index: 0,
          url: `/form-builder/${id}/settings/`,
          hasError: false,
        },
        {
          name: 'Submission Settings',
          component: 'submission-settings',
          index: 1,
          isDisable: false,
          url: `/form-builder/${id}/settings/submission`,
          hasError: errors?.hasOwnProperty('submission_settings'),
        },
        {
          name: 'Recipient Settings',
          component: 'recipient-settings',
          index: 2,
          isDisable: !(formData?.submission_settings?.filling_type === 'SPECIAL' || formData?.submission_settings?.filling_type === 'MIXED'),
          url: `/form-builder/${id}/settings/recipient`,
          hasError: false,
        },
        {
          name: 'Notification Settings',
          component: 'notification-settings',
          index: 3,
          isDisable: !(formData?.submission_settings?.filling_type === 'SPECIAL' || formData?.submission_settings?.filling_type === 'MIXED'),
          url: `/form-builder/${id}/settings/notification`,
          hasError: false,
        },
      ]);
    }

    // eslint-disable-next-line
  }, [formData, errors]);

  const goTo = (url) => {
    if (url.includes('submission')) {
      window.location.href = url;
    } else {
      navigation(url);
    }
  };

  const togglePreview = (isOpen) => {
    setIsOpenPreview(isOpen);
  };

  const handleErrors = useCallback((newErrors) => setErrors(newErrors), []);

  return (
    <Layout title="Form Builder - Settings" pageClassName={`${isOpenPreview ? 'modal-open' : ''} form-builder-wrapper-settings`} togglePreview={togglePreview}>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <div className="form-builder-aside-left p-0">
          <TabList className="form-builder-aside-tab">
            {tabs.map((item) => (
              <Tab
                disabled={item.isDisable}
                onClick={() => {
                  if (!item.isDisable) {
                    goTo(item.url);
                  }
                }}
                key={item.index}
              >
                {item.name}

                {item.hasError && (
                  <>
                    <i data-tooltip-id={`table-tooltip-${item.index}`} className="icon-icon-info tab__error"></i>
                    <Tooltip opacity={1} id={`table-tooltip-${item.index}`} place="bottom" content={'Please fill in all required fields'} />
                  </>
                )}
              </Tab>
            ))}
          </TabList>
        </div>
        <div className="form-builder-setting-tab">
          {tabs.map((tab) => {
            return <TabPanel key={tab.index}>{Components(tab, formData, () => null, null, handleErrors, errors)}</TabPanel>;
          })}
        </div>
      </Tabs>

      {isOpenPreview && (
        <div className={'preview-modal init-page'}>
          <div className={'header-wrapper'}>
            <div className={'header-left'}>
              <div className="header-logo">
                <img src="/images/logo.svg" alt="" />
              </div>
            </div>
            <div className={'header-center tabs'}>
              <div className={`tabs-nav-item ${tabItem === 'desktop' ? 'tabs-nav-item-active' : ''}`} onClick={() => setTabItem('desktop')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M4.36719 15L16.299 15C16.958 15 17.4922 14.4404 17.4922 13.75V5C17.4922 4.30964 16.958 3.75 16.299 3.75L4.36719 3.75C3.70821 3.75 3.17401 4.30964 3.17401 5L3.17401 13.75C3.17401 14.4404 3.70821 15 4.36719 15Z"
                    stroke="#667085"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M12.72 17.5H7.94727" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M3.1748 11.875H17.493" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M10.334 15V17.5" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                Desktop
              </div>
              <div className={`tabs-nav-item ${tabItem === 'tablet' ? 'tabs-nav-item-active' : ''}`} onClick={() => setTabItem('tablet')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M15.9668 16.25V3.75C15.9668 3.05964 15.4326 2.5 14.7736 2.5L5.22816 2.5C4.56919 2.5 4.03498 3.05964 4.03498 3.75V16.25C4.03498 16.9404 4.56919 17.5 5.22816 17.5H14.7736C15.4326 17.5 15.9668 16.9404 15.9668 16.25Z"
                    stroke="#667085"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M4.03418 5H15.966" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M4.03418 15H15.966" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                Tablet
              </div>
              <div className={`tabs-nav-item ${tabItem === 'phone' ? 'tabs-nav-item-active' : ''}`} onClick={() => setTabItem('phone')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path
                    d="M12.3881 1.6875H5.94496C5.35188 1.6875 4.87109 2.19118 4.87109 2.8125V15.1875C4.87109 15.8088 5.35188 16.3125 5.94496 16.3125H12.3881C12.9812 16.3125 13.462 15.8088 13.462 15.1875V2.8125C13.462 2.19118 12.9812 1.6875 12.3881 1.6875Z"
                    stroke="#667085"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M4.87109 3.9375H13.462" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M4.87109 14.0625H13.462" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                Phone
              </div>
            </div>
            <div className={'header-right'}>
              <button onClick={() => togglePreview(false)} className={'btn btn-outline d-flex'}>
                <svg className={'mr-2'} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 4.375C3.75 4.375 1.25 10.0006 1.25 10.0006C1.25 10.0006 3.75 15.625 10 15.625C16.25 15.625 18.75 10.0006 18.75 10.0006C18.75 10.0006 16.25 4.375 10 4.375Z"
                    stroke="#202024"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 13.125C11.7259 13.125 13.125 11.7259 13.125 10C13.125 8.27411 11.7259 6.875 10 6.875C8.27411 6.875 6.875 8.27411 6.875 10C6.875 11.7259 8.27411 13.125 10 13.125Z"
                    stroke="#202024"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Close Preview
              </button>
            </div>
          </div>
          <div className={`form-view-wrapper ${'form-view-wrapper-' + tabItem}`}>
            <div className={'preview-modal-actions'}>
              Orientation
              <button className={`btn-orientation ${orientation === 'horizontal' ? 'active' : ''}`} onClick={() => setOrientation('horizontal')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M2.6875 6.625L2.6875 13.375C2.6875 13.9963 3.19118 14.5 3.8125 14.5H16.1875C16.8088 14.5 17.3125 13.9963 17.3125 13.375V6.625C17.3125 6.00368 16.8088 5.5 16.1875 5.5H3.8125C3.19118 5.5 2.6875 6.00368 2.6875 6.625Z"
                    stroke="#7F56D9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M4.9375 14.5L4.9375 5.5" stroke="#7F56D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M15.0625 14.5V5.5" stroke="#7F56D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
              <button className={`btn-orientation ${orientation === 'vertical' ? 'active' : ''}`} onClick={() => setOrientation('vertical')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M13.375 2.6875H6.625C6.00368 2.6875 5.5 3.19118 5.5 3.8125V16.1875C5.5 16.8088 6.00368 17.3125 6.625 17.3125H13.375C13.9963 17.3125 14.5 16.8088 14.5 16.1875V3.8125C14.5 3.19118 13.9963 2.6875 13.375 2.6875Z"
                    stroke="#6941C6"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M5.5 4.9375H14.5" stroke="#6941C6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5.5 15.0625H14.5" stroke="#6941C6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
            </div>
            <div className={`send-form-wrapper ${'send-form-wrapper-' + orientation}`}>
              <div className={'send-form'}>
                <RenderForm
                  isSubmitter={false}
                  isPrint={false}
                  handelInitSettings={() => {}}
                  title={
                    formData.title_options
                      ? {
                          ...formData?.title_options,
                          options: { ...formData?.title_options.options, value: formData.name },
                        }
                      : formData.name
                  }
                  formElement={formData.form_fields}
                />
                <div className="form-control-actions">
                  <button className={'btn btn-outline mr-2'}>Clear form</button>
                  <button className="btn">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

export default FromBuilderSettings;
