import React, { useEffect, useState } from 'react';
import { MultiSelect } from '../../other/MultiSelect';
import { getFacilitiesByCompanySettings } from '../../../services/facility';
import { getUsersListSettings } from '../../../services/user';
import { getCompanyUserGroupListSettings } from '../../../services/company';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../container/authContext';
import { updateForm } from '../../../services/form';
import { Tooltip } from 'react-tooltip';

export const RecipientSettings = function RecipientSettings({ formData, initData }) {
  const { user } = useAuth();
  const companyId = user.company?.id;
  const { id } = useParams();
  const [recipient_settings, setRecipientSettings] = useState({});
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [usersGroupsOptions, setUsersGroupsOptions] = useState([]);
  const [individualUsersOptions, setIndividualUsersOptions] = useState([]);
  const navigation = useNavigate();
  const [errors, setErrors] = useState(null);
  // const [preventLoopUsers, setPreventLoopUsers] = useState(false);
  // const [preventLoopUserGroup, setPreventLoopUserGroup] = useState(false);

  const [facility, setFacility] = useState([]);
  const [usersGroups, setUsersGroups] = useState([]);
  const [individualUsers, setIndividualUsers] = useState([]);

  // useEffect(() => {
  //     if(initData?.recipient_settings) {
  //         setRecipientSettings({...initData?.recipient_settings});
  //
  //         setFacility(initData.recipient_settings?.facilities?.map(item => ({value: item.id, label: item.name})));
  //         setUsersGroups(initData.recipient_settings?.user_groups?.map(item => ({value: item.id, label: item.name, entity_type: 'user_group'})));
  //         setIndividualUsers(initData.recipient_settings?.individual_users?.map(item => ({value: item.id, label: item.first_name + ' ' + item.last_name, entity_type: 'user'})));
  //     }
  //
  //     console.log(2)
  //     // eslint-disable-next-line
  // }, [initData]);

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    if (formData?.recipient_settings) {
      setRecipientSettings(() => {
        const prevState = formData.recipient_settings;
        return prevState;
      });
      setFacility(formData.recipient_settings?.facilities.map((item) => ({ value: item.id, label: item.name })));
      setUsersGroups(
        formData.recipient_settings?.user_groups.map((item) => ({ value: item.id, label: item.name, entity_type: 'user_group' })),
      );
      setIndividualUsers(
        formData.recipient_settings?.individual_users.map((item) => ({
          value: item.id,
          label: item.first_name + ' ' + item.last_name,
          entity_type: 'user',
        })),
      );
    }

    setTimeout(() => {
      setIsFirstLoad(false);
    }, 500);
  }, [formData]);

  useEffect(() => {
    console.log(isFirstLoad);
    if (!isFirstLoad) {
      console.log(1);
      setFacility([]);
      setUsersGroups([]);
      setIndividualUsers([]);
      setIndividualUsersOptions([]);
      setUsersGroupsOptions([]);
      setIsFirstLoad(false);
    }

    if (recipient_settings.can_initiator_select_recipients === false) {
      getFacilities();
    } else if (recipient_settings.can_initiator_select_recipients === true) {
      if (recipient_settings.recipients_only_from_initiator_facility === false) {
        getFacilities();
      } else {
        getCompanyUserGroup(user.facility ? [user.facility.id] : []);
        getUsersList(user.facility ? [user.facility.id] : []);
      }
    }
    // eslint-disable-next-line
  }, [recipient_settings.recipients_only_from_initiator_facility, recipient_settings.can_initiator_select_recipients]);

  useEffect(() => {
    if (
      facility.length > 0 ||
      (recipient_settings.can_initiator_select_recipients && recipient_settings.recipients_only_from_initiator_facility)
    ) {
      getCompanyUserGroup(facility.map((item) => item.value));
    } else {
      setUsersGroupsOptions([]);
      setUsersGroups([]);
      // setPreventLoopUserGroup(false)
    }
    // eslint-disable-next-line
  }, [facility]);

  useEffect(() => {
    if (
      facility.length > 0 ||
      (recipient_settings.can_initiator_select_recipients && recipient_settings.recipients_only_from_initiator_facility)
    ) {
      getUsersList(facility.map((item) => item.value));
    } else {
      setIndividualUsersOptions([]);
      setIndividualUsers([]);
      // setPreventLoopUsers(false)
    }
    // eslint-disable-next-line
  }, [usersGroups, facility]);
  // const initPage = () => {
  //     if(recipient_settings.recipients_only_from_initiator_facility === true) {
  //         getUsersList(user.facility ? [user.facility.id] : []);
  //         getCompanyUserGroup(user.facility ? [user.facility.id] : []);
  //     } else {
  //         getFacilities();
  //     }
  // }

  const getFacilities = () => {
    getFacilitiesByCompanySettings(companyId, 'getAll')
      .then((resFacilities) => {
        setFacilityOptions(resFacilities.map((item) => ({ value: item.id, label: item.name })));
        if (!isFirstLoad) {
          setUsersGroups([]);
          setIndividualUsers([]);
          setIndividualUsersOptions([]);
          setUsersGroupsOptions([]);
        }
      })
      .catch((e) => console.log(e));
  };

  const getUsersList = (facilities_ids = []) => {
    // setPreventLoopUsers(true);
    getUsersListSettings({
      company_id: companyId,
      status: 'active',
      facilities_ids: facilities_ids ? [...facilities_ids] : [],
      user_groups_ids: usersGroups ? [...usersGroups.map((item) => item.value)] : [],
    })
      .then((resUsers) => {
        const options = resUsers.map((item) => ({
          value: item.id,
          label: item.last_name + ' ' + item.first_name,
          entity_type: 'user',
        }));
        setIndividualUsersOptions(options);
        setIndividualUsers((prevState) => {
          const newState = [];
          prevState.forEach((item) => {
            if (options.find((option) => option.value === item.value)) {
              newState.push(item);
            }
          });
          return newState;
        });
        // setPreventLoopUsers(false);
      })
      .catch((e) => console.log(e));
  };

  const getCompanyUserGroup = (facilities_ids) => {
    // setPreventLoopUserGroup(true)
    getCompanyUserGroupListSettings({
      company_id: companyId,
      status: 'active',
      facilities_ids: facilities_ids ? [...facilities_ids] : [],
    })
      .then((res) => {
        const options = res.map((item) => ({
          value: item.id,
          label: item.name,
          entity_type: 'user_group',
        }));
        setUsersGroupsOptions(options);
        setUsersGroups((prevState) => {
          const newState = [];
          prevState.forEach((item) => {
            if (options.find((option) => option.value === item.value)) {
              newState.push(item);
            }
          });
          return newState;
        });

        // setPreventLoopUserGroup(false)
      })
      .catch((e) => console.log(e));
  };

  // const handelRemove = (key, value) => {
  //     setRecipientSettings(() => {
  //         const prevState = {...recipient_settings}
  //         prevState[key] = prevState[key].filter(item => item.value !== value.value)
  //         return prevState
  //     })
  // }

  const handelRemove = (handlerChange, elementToRemove) => {
    handlerChange((prevState) => {
      return prevState.filter((item) => item.value !== elementToRemove.value);
    });
  };

  const handelSettings = (value, key) => {
    setRecipientSettings(() => {
      const prevState = { ...recipient_settings };
      prevState[key] = value;
      return prevState;
    });
  };

  const onUpdate = () => {
    let formData = {
      recipient_settings: JSON.parse(JSON.stringify(recipient_settings)),
    };

    if (recipient_settings.can_initiator_select_recipients === false) {
      if (facility?.length) {
        formData.recipient_settings.facilities = facility.map((item) => item.value);
      } else {
        formData.recipient_settings.facilities = [];
      }
    } else if (recipient_settings.can_initiator_select_recipients === true) {
      if (facility?.length && recipient_settings.recipients_only_from_initiator_facility === false) {
        formData.recipient_settings.facilities = facility.map((item) => item.value);
      } else {
        delete formData.recipient_settings.facilities;
      }
    }

    if (usersGroups?.length) {
      formData.recipient_settings.user_groups = usersGroups.map((item) => item.value);
    } else {
      formData.recipient_settings.user_groups = [];
    }

    if (individualUsers?.length) {
      formData.recipient_settings.individual_users = individualUsers.map((item) => item.value);
    } else {
      formData.recipient_settings.individual_users = [];
    }

    if (Object.keys(recipient_settings).length === 0) {
      navigation(`/form-builder/${id}/settings/notification`);
    } else {
      updateForm(id, formData)
        .then((res) => {
          navigation(`/form-builder/${id}/settings/notification`);
        })
        .catch((e) => {
          console.log(e);
          if (e?.response?.data?.recipient_settings) {
            setErrors(e.response.data.recipient_settings);
          }
        });
    }
  };

  return (
    <div className="form-builder-setting-tab-content">
      <div className="form-builder-recipient-settings">
        {formData?.name && (
          <div className="from-builder-title from-builder-title-setting">
            <h1>
              <img src="/images/file-big-icon.png" className="mr-2" alt="" />
              {formData.name}
            </h1>
          </div>
        )}
        <p className="info-text">
          <i className="icon-icon-info"></i> Recipients are users who will have access to completed submissions of Forms.
        </p>
        <span className="form-error mb-2 d-flex">{errors?.non_field_errors && errors.non_field_errors[0]}</span>

        <div className="form-control-item-settings">
          <div className="form-settings-label">
            <label className="form-label form-label-big">Will form initiator select form recipients? </label>
            {/*<span>Specify if the form initiator can select recipients. Specify users</span>*/}
          </div>
          <div className="form-settings-inputs">
            <div className="d-flex">
              <div className={`form-control-radio`}>
                <input
                  name="can_initiator_select_recipients"
                  type="radio"
                  value="true"
                  onChange={() => handelSettings(true, 'can_initiator_select_recipients')}
                  checked={recipient_settings.can_initiator_select_recipients}
                />
                <span>Yes</span>
                <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-can_initiator_select_recipients`}></i>
                <Tooltip opacity={1} id={`table-tooltip-can_initiator_select_recipients`} place="bottom">
                  Form initiator can select recipients form groups and individuals designated below
                </Tooltip>
              </div>
              <div className={`form-control-radio`}>
                <input
                  name="can_initiator_select_recipients"
                  type="radio"
                  value="false"
                  onChange={() => handelSettings(false, 'can_initiator_select_recipients')}
                  checked={recipient_settings.can_initiator_select_recipients === false}
                />
                <span>No</span>
                <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-can_initiator_select_recipients-no`}></i>
                <Tooltip opacity={1} id={`table-tooltip-can_initiator_select_recipients-no`} place="bottom">
                  Form Creator should select recipients when creating Form
                </Tooltip>
              </div>
            </div>
            <span className="form-error d-flex">
              {errors?.can_initiator_select_recipients && errors.can_initiator_select_recipients[0]}
            </span>

            {recipient_settings.hasOwnProperty('can_initiator_select_recipients') &&
              recipient_settings.can_initiator_select_recipients === false && (
                <>
                  <div className={`form-item form-item-select mb-4`}>
                    <label className="form-label form-label-big">Facility</label>
                    <div className="select-multy-wrapper">
                      <span className="select-placeholder">Choose one or several facilities</span>
                      <MultiSelect
                        isDisabled={!facilityOptions.length}
                        options={facilityOptions}
                        value={facility}
                        onChange={setFacility}
                        placeholder=""
                        allName="All facilities"
                      ></MultiSelect>
                      {facility?.length > 0 && (
                        <>
                          <ul>
                            {facility.map((item, key) => (
                              <li key={key}>
                                {item.label}
                                <button className="btn btn-icon" onClick={() => handelRemove(setFacility, item)}>
                                  <img src="/images/x-circle.svg" alt="" />
                                </button>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                    <span className="form-error">{errors?.facilities && errors.facilities[0]}</span>
                  </div>

                  <div className={`form-item form-item-select mb-4`}>
                    <label className="form-label form-label-big">User groups</label>
                    <div className="select-multy-wrapper">
                      <span className="select-placeholder">Choose one or several user groups</span>
                      <MultiSelect
                        isDisabled={!usersGroupsOptions.length}
                        options={usersGroupsOptions}
                        value={usersGroups}
                        onChange={setUsersGroups}
                        placeholder=""
                        allName="All user groups"
                      ></MultiSelect>
                      {usersGroups?.length > 0 && (
                        <>
                          <ul>
                            {usersGroups.map((item, key) => (
                              <li key={key}>
                                {item.label}
                                <button className="btn btn-icon" onClick={() => handelRemove(setUsersGroups, item)}>
                                  <img src="/images/x-circle.svg" alt="" />
                                </button>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                    <span className="form-error">{errors?.user_groups && errors.user_groups[0]}</span>
                  </div>

                  <div className={`form-item form-item-select mb-4`}>
                    <label className="form-label form-label-big">Individual Users</label>
                    <div className="select-multy-wrapper">
                      <span className="select-placeholder">Choose one or several individual_users</span>
                      <MultiSelect
                        isDisabled={!individualUsersOptions.length}
                        options={individualUsersOptions}
                        value={individualUsers}
                        onChange={setIndividualUsers}
                        placeholder=""
                        allName="All individual users"
                      ></MultiSelect>
                      {individualUsers?.length > 0 && (
                        <>
                          <ul className={'individual-users-list'}>
                            {individualUsers
                              .sort(function (a, b) {
                                if (a.label < b.label) {
                                  return -1;
                                }
                                if (a.label > b.label) {
                                  return 1;
                                }
                                return 0;
                              })
                              .map((item, key) => (
                                <li key={key}>
                                  {item.label}
                                  <button className="btn btn-icon" onClick={() => handelRemove(setIndividualUsers, item)}>
                                    <img src="/images/x-circle.svg" alt="" />
                                  </button>
                                </li>
                              ))}
                          </ul>
                        </>
                      )}
                      <span className="form-error">{errors?.individual_users && errors.individual_users[0]}</span>
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
        {recipient_settings.hasOwnProperty('can_initiator_select_recipients') && recipient_settings.can_initiator_select_recipients && (
          <div className="form-control-item-settings">
            <div className="form-settings-label">
              <label className="form-label form-label-big">Can the initiator assign recipients only from their facility?</label>
              {/*<span>Сhooses between two options for assigning users: either from the initiator's facility or specific users assigned by the form creator.</span>*/}
            </div>
            <div className="form-settings-inputs">
              <div className="d-flex">
                <div className={`form-control-radio`}>
                  <input
                    name="recipients_only_from_initiator_facility"
                    type="radio"
                    value="true"
                    onChange={() => handelSettings(true, 'recipients_only_from_initiator_facility')}
                    checked={recipient_settings.recipients_only_from_initiator_facility}
                  />
                  <span>Yes</span>
                  <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-recipients_only_from_initiator_facility`}></i>
                  <Tooltip opacity={1} id={`table-tooltip-recipients_only_from_initiator_facility`} place="bottom">
                    Initiator will be able to assign recipients only from his facility.
                  </Tooltip>
                </div>
                <div className={`form-control-radio`}>
                  <input
                    name="recipients_only_from_initiator_facility"
                    type="radio"
                    value="false"
                    onChange={() => handelSettings(false, 'recipients_only_from_initiator_facility')}
                    checked={recipient_settings.recipients_only_from_initiator_facility === false}
                  />
                  <span>No</span>
                  <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-recipients_only_from_initiator_facility-no`}></i>
                  <Tooltip opacity={1} id={`table-tooltip-recipients_only_from_initiator_facility-no`} place="bottom">
                    Initiator can assign recipients her facility and/or from other facilities.
                  </Tooltip>
                </div>
              </div>
              <span className="form-error mb-2 d-flex">
                {errors?.recipients_only_from_initiator_facility && errors.recipients_only_from_initiator_facility[0]}
              </span>

              {recipient_settings.hasOwnProperty('recipients_only_from_initiator_facility') && (
                <div className="form-settings-inputs">
                  {recipient_settings.recipients_only_from_initiator_facility === false && (
                    <div className={`form-item form-item-select mb-4`}>
                      <label className="form-label form-label-big">Facility</label>
                      <div className="select-multy-wrapper">
                        <span className="select-placeholder">Choose one or several facilities</span>
                        <MultiSelect
                          isDisabled={!facilityOptions.length}
                          options={facilityOptions}
                          value={facility}
                          onChange={setFacility}
                          placeholder=""
                          allName="All facilities"
                        ></MultiSelect>
                        {facility?.length > 0 && (
                          <>
                            <ul>
                              {facility.map((item, key) => (
                                <li key={key}>
                                  {item.label}
                                  <button className="btn btn-icon" onClick={() => handelRemove(setFacility, item)}>
                                    <img src="/images/x-circle.svg" alt="" />
                                  </button>
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                      </div>
                      <span className="form-error">{errors?.facilities && errors.facilities[0]}</span>
                    </div>
                  )}

                  <div className={`form-item form-item-select mb-4`}>
                    <label className="form-label form-label-big">User groups</label>
                    <div className="select-multy-wrapper">
                      <span className="select-placeholder">Choose one or several user groups</span>
                      <MultiSelect
                        isDisabled={!usersGroupsOptions.length}
                        options={usersGroupsOptions}
                        value={usersGroups}
                        onChange={setUsersGroups}
                        placeholder=""
                        allName="All user groups"
                      ></MultiSelect>
                      {usersGroups?.length > 0 && (
                        <>
                          <ul>
                            {usersGroups.map((item, key) => (
                              <li key={key}>
                                {item.label}
                                <button className="btn btn-icon" onClick={() => handelRemove(setUsersGroups, item)}>
                                  <img src="/images/x-circle.svg" alt="" />
                                </button>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                    <span className="form-error">{errors?.user_groups && errors.user_groups[0]}</span>
                  </div>

                  <div className={`form-item form-item-select mb-4`}>
                    <label className="form-label form-label-big">Individual Users</label>
                    <div className="select-multy-wrapper">
                      <span className="select-placeholder">Choose one or several individual users</span>
                      <MultiSelect
                        isDisabled={!individualUsersOptions.length}
                        options={individualUsersOptions}
                        value={individualUsers}
                        onChange={setIndividualUsers}
                        placeholder=""
                        allName="All individual users"
                      ></MultiSelect>
                      {individualUsers?.length > 0 && (
                        <>
                          <ul className={'individual-users-list'}>
                            {individualUsers
                              .sort(function (a, b) {
                                if (a.label < b.label) {
                                  return -1;
                                }
                                if (a.label > b.label) {
                                  return 1;
                                }
                                return 0;
                              })
                              .map((item, key) => (
                                <li key={key}>
                                  {item.label}
                                  <button className="btn btn-icon" onClick={() => handelRemove(setIndividualUsers, item)}>
                                    <img src="/images/x-circle.svg" alt="" />
                                  </button>
                                </li>
                              ))}
                          </ul>
                        </>
                      )}
                      <span className="form-error">{errors?.individual_users && errors.individual_users[0]}</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="form-control-actions">
        <button className="btn btn-outline" onClick={() => navigation(`/form-builder/${id}/settings/submission`)}>
          Back to Submission Settings
        </button>
        <button className="btn" onClick={onUpdate}>
          Save Changes
        </button>
      </div>
    </div>
  );
};
