import React, { useEffect, useLayoutEffect, useState } from 'react';
import { UserMenu } from '../../components/auth/UserMenu';
import { SideBarMenu } from '../../components/other/SideBarMenu';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../container/authContext';
import { Link } from 'react-router-dom';
import { getNotificationsList } from '../../servises/notifications';
import HeaderLogOutButton from '../../components/header/log-out-button';

function FormBuilder({ isUpdated = 0, title, content, breadcrumbs = [], companyUpdated = null, ...props }) {
  useLayoutEffect(() => {
    document.title = `${title}`;
  });
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const location = useLocation();

  const [countNotification, setCountNotification] = useState(isUpdated);

  useEffect(() => {
    getNotificationsList()
      .then((res) => {
        setCountNotification(res.unread_count);
      })
      .catch((e) => console.log(e));
  }, [isUpdated]);
  return (
    <div className={`form-builder-layout ${props.className}`}>
      <header className="header-wrapper">
        <div className="header-left">
          <div className="header-logo">
            <img src="/images/logo.svg" alt="" />
          </div>
          <div className="pageBreadcrumbs">
            {breadcrumbs.map((item, index) => (
              <span className={'cursor-pointer'} onClick={() => navigate(item.url)} key={index}>
                {item.name}
              </span>
            ))}
          </div>
        </div>
        <div className="header-right">
          <div className="header-right__main">
            <UserMenu companyUpdated={companyUpdated} />

            <Link className={`btn btn-outline btn-notification ${location.pathname.includes('notifications') ? 'btn-notification-active' : ''}`} to={'/notifications'}>
              {countNotification > 0 && <span className={'counter'}>{countNotification}</span>}
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M13.73 21C13.5542 21.3031 13.3018 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z"
                  stroke="#7F56D9"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>
          </div>

          <HeaderLogOutButton />
        </div>
      </header>
      <div className="form-builder-wrapper">
        <aside className="sidebar-menu sidebar-menu-mini">
          <SideBarMenu />
        </aside>
        <main className="default-layout">{props.children}</main>
      </div>
    </div>
  );
}

export default FormBuilder;
