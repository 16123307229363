import React from 'react';
import { BreadcrumbsItem } from './breadcrumbs-item';

export const Breadcrumbs = function Breadcrumbs({ breadcrumbs, goToLevel }) {
  return (
    <ul className="breadcrumbs">
      {breadcrumbs.map((item, index) => {
        if (item) {
          return <BreadcrumbsItem isLast={breadcrumbs.length - 1 === index} key={index} item={item} index={index} goToLevel={goToLevel} />;
        }
        return '';
      })}
    </ul>
  );
};
