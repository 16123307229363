import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { SUBMITTERS_OPTIONS } from './submitters-field-element-settings';
import { get } from 'lodash';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { TIME_FORMAT } from '../../../constants';
import { convert12HourTo24HourFormat } from '../../../utility/dates/convert12HourTo24HourFormat';
import cx from 'classnames';
import AutoHeightTextArea from './components/AutoHeightTextArea';

const columnTypeOptions = [
  { value: 'single-line', label: 'Single Line' },
  { value: 'number', label: 'Number' },
  { value: 'dropdown', label: 'Dropdown' },
  { value: 'date', label: 'Date' },
  { value: 'time', label: 'Time' },
  { value: 'electronic-signature', label: 'Electronic Signature' },
  { value: 'current-user', label: 'User info field' },
];

const rolesOptions = [
  { value: undefined, label: '----' },
  { value: 'initiator', label: 'Initiator' },
  { value: 'submitter', label: 'Submitter' },
];

const dropdownDefaultOption = { value: undefined, label: '----' };

const getDropdownDefaultOptions = (options) => {
  let newOptions = [];
  if (Array.isArray(options)) {
    newOptions = [dropdownDefaultOption, ...options];
  }
  return newOptions;
};

export const TableElementSettings = function FromElement({ element, duplicateElement, handlerChangeOption }) {
  const copyRowsData = element.options?.need_copy_rows_data;

  const [cols, setCols] = useState(element.cols);
  const [rows, setRows] = useState(element.rows);
  const [isActive, setIsActive] = useState(null);
  const [addRowsButton, setAddRowsButton] = useState(element.options.addRowsButton);
  const [label, setLabel] = useState(element.options.label);
  const [type, setType] = useState(element.options.type);
  const [instructions, setInstructions] = useState(element.options.instructions);
  const [alignment, setAlignment] = useState(element.options.alignment);
  const [tableAlignment, setTableAlignment] = useState(element.options.tableAlignment);
  const [showQuestions, setShowQuestions] = useState(element.options.showQuestions);
  const [enableScroll, setEnableScroll] = useState(element.options.enableScroll);
  const [rowHeaderTitle, setRowHeaderTitle] = useState(element.options.rowHeaderTitle ? element.options.rowHeaderTitle : 'Question');
  // const [size, setSize] = useState(element.options.size);

  const dailyRowsCopyTime = useMemo(() => {
    let time;
    if (element?.options?.daily_copy_rows_time) {
      const timeString = convert12HourTo24HourFormat(element?.options?.daily_copy_rows_time);
      const [hours, minutes] = timeString.split(':').map(Number);
      const currentDate = new Date();
      currentDate.setHours(hours, minutes);
      time = currentDate;
    }
    return time;
  }, [element?.options?.daily_copy_rows_time]);

  // Date or time column value
  const getDateTimeColumnValueOption = useCallback((value) => {
    return rolesOptions.find((r) => r.value === value);
  }, []);

  useEffect(() => {
    setCols(element.cols);
    setRows(element.rows);
    // setSize(element.options.size)
    setInstructions(element.options.instructions);
    setLabel(element.options.label);
    setType(element.options.type);
    setAlignment(element.options.alignment);
    setShowQuestions(element.options.showQuestions);
    setEnableScroll(element.options.enableScroll);
    setRowHeaderTitle(element.options.rowHeaderTitle);
    setTableAlignment(element.options.tableAlignment);
    setAddRowsButton(element.options.addRowsButton);
  }, [element]);

  const removeCols = (item) => {
    setCols((prevState) => {
      const newState = prevState.filter((col) => col.id !== item.id);
      handlerChangeOption(newState, 'colsCrud', element.id);
      return newState;
    });
  };

  const handleSetStateDailyRowsCopyTime = useCallback(
    (date) => {
      let time;
      if (date) {
        time = dayjs(date).format(TIME_FORMAT);
      }
      handlerChangeOption(time, 'dailyRowsCopyTime', element.id);

      if (!date) {
        handlerChangeOption(undefined, 'need_copy_rows_data', element.id);
      }
    },
    [element.id, handlerChangeOption],
  );

  const addCol = () => {
    setCols((prevState) => {
      const newState = [...prevState];
      newState.push({
        id: 'new' + new Date().getTime(),
        label: `Answer ${newState.length + 1}`,
        width: '160px',
        type: { value: 'single-line', label: 'Single Line' },
        options: [],
      });

      handlerChangeOption([...newState], 'colsCrud', element.id);
      return newState;
    });
  };

  const removeRows = (item) => {
    setRows((prevState) => {
      const newState = prevState.filter((row) => row.id !== item.id);
      handlerChangeOption(newState, 'rowsCrud', element.id);
      return newState;
    });
  };

  const updateColsState = () => {
    handlerChangeOption(cols, 'colsCrud', element.id);
  };
  const updateColsStateType = (colsType) => {
    handlerChangeOption(colsType, 'colsCrud', element.id);
  };

  const updateRowsState = () => {
    handlerChangeOption(rows, 'rowsCrud', element.id);
  };

  const addOptionToItem = (item, index) => {
    setCols((prevState) => {
      const newState = prevState.map((obj, objIndex) => {
        if (index === objIndex) {
          const newOptions = [...obj.options];
          newOptions.push({
            id: 'new' + new Date().getTime(),
            label: `Option`,
            value: 'new' + new Date().getTime(),
          });

          return { ...obj, options: [...newOptions] };
        }

        return obj;
      });

      handlerChangeOption([...newState], 'colsCrud', element.id);

      return newState;
    });
  };

  const editOption = (item, index, value) => {
    setCols((prevState) => {
      return prevState.map((obj, objIndex) => {
        if (index === objIndex) {
          const newOptions = [...obj.options];

          const newCol = {
            ...obj,
            options: [
              ...newOptions.map((option) => {
                if (option.id === item.id) {
                  option.label = value;
                  option.value = value.replace(/ /g, '-').toLowerCase();
                  option.id = value.replace(/ /g, '-').toLowerCase();
                }
                return option;
              }),
            ],
          };

          delete newCol.defaultOptionId;

          return newCol;
        }

        return obj;
      });
    });
  };

  const removeDropdownOption = (item, index) => {
    setCols((prevState) => {
      const newState = prevState.map((obj, objIndex) => {
        if (index === objIndex) {
          const newCol = { ...obj, options: obj.options.filter((option) => option.id !== item.id) };

          if (obj.defaultOptionId && obj.defaultOptionId === item.id) {
            delete newCol.defaultOptionId;
          }

          return newCol;
        }
        return obj;
      });

      handlerChangeOption([...newState], 'colsCrud', element.id);

      return newState;
    });
  };

  const addRows = () => {
    setRows((prevState) => {
      const newState = [...prevState];
      newState.push({
        id: 'new' + new Date().getTime(),
        label: `Question ${newState.length + 1}`,
      });

      handlerChangeOption([...newState], 'rowsCrud', element.id);
      return newState;
    });
  };

  const handleChangeAutoSetValueForRole = useCallback(
    (colIndex, value) => {
      setCols((prevState) => {
        const newState = prevState.map((col, prevIndex) => {
          if (colIndex === prevIndex) {
            col.autoSetValueFor = value?.value;
          }
          return col;
        });
        updateColsStateType(newState);
        return newState;
      });
    },
    [updateColsStateType],
  );

  const handleChangeDateTimeEditOption = useCallback(
    (colIndex) => {
      setCols((prevState) => {
        const newState = prevState.map((col, prevIndex) => {
          if (colIndex === prevIndex) {
            const newIsEditableValue = !col.editable;
            col.editable = newIsEditableValue;

            if (newIsEditableValue) {
              delete col.autoSetValueFor;
            } else {
              delete col.editRole;
            }
          }
          return col;
        });
        updateColsStateType(newState);
        return newState;
      });
    },
    [updateColsStateType],
  );

  const handleChangeDateTimeEditRole = useCallback(
    (colIndex, value) => {
      setCols((prevState) => {
        const newState = prevState.map((col, prevIndex) => {
          if (colIndex === prevIndex) {
            col.editRole = value?.value;
          }
          return col;
        });
        updateColsStateType(newState);
        return newState;
      });
    },
    [updateColsStateType],
  );

  const handleChangeColumnType = useCallback(
    (e, index) => {
      setCols((prevState) => {
        const newState = prevState.map((col, prevIndex) => {
          if (index === prevIndex) {
            col.type = e;

            if (e?.value === 'current-user') {
              col.options = SUBMITTERS_OPTIONS;
            } else if (e?.value === 'dropdown') {
              col.options = [
                {
                  id: 'new' + new Date().getTime(),
                  label: `Option`,
                  value: 'new' + new Date().getTime(),
                },
              ];
            } else {
              col.options = [];
            }
          }
          return col;
        });

        updateColsStateType(newState);
        return newState;
      });
    },
    [updateColsStateType],
  );

  // eslint-disable-next-line react/display-name
  const AutomaticAdditionRowsInput = forwardRef((args, ref) => {
    const { value, onClick } = args;
    return (
      <div className="form-control automatic--addition--rows--date" onClick={onClick} ref={ref} style={{ lineHeight: 1, cursor: 'pointer' }}>
        {value ? value : <span style={{ color: '#98a2b3' }}>Choose Time</span>}
      </div>
    );
  });

  const setDefaultDropdownOption = useCallback(
    (item, optionId) => {
      if (Array.isArray(cols)) {
        let defaultOptionId = null;
        if (optionId) {
          const option = item?.options?.find((o) => o?.id === optionId);
          if (option) {
            defaultOptionId = optionId;
          }
        }
        const newCols = [...cols].map((col) => {
          if (col?.id === item.id) {
            const newCol = { ...col };
            if (defaultOptionId) {
              newCol.defaultOptionId = defaultOptionId;
            } else {
              delete newCol.defaultOptionId;
            }
            return newCol;
          }
          return col;
        });

        handlerChangeOption(newCols, 'colsCrud', element.id);
      }
    },
    [cols, element.id, handlerChangeOption],
  );

  const renderDropdownSettings = (col, colIndex) => {
    let layout = null;
    if (col && col.type?.value === 'dropdown') {
      const defaultOptionValue = col.defaultOptionId ? col?.options.find((o) => o?.id === col.defaultOptionId) : dropdownDefaultOption;
      layout = (
        <>
          <div className="additional-dropdown">
            <h3 className="form-builder-aside-property-sub-title">Answer - {col.label}</h3>

            {col.options.map((option, key) => (
              <div key={key} className="form-control-item">
                <input
                  type="text"
                  className={`form-control`}
                  placeholder="Enter text"
                  value={option.label}
                  onBlur={updateColsState}
                  onChange={(e) => {
                    editOption(option, colIndex, e.target.value);
                  }}
                />

                <div className="settings-actions">
                  <button className="btn btn-remove btn-icon" onClick={() => removeDropdownOption(option, colIndex)}>
                    <i className="icon-remove" />
                  </button>
                </div>
              </div>
            ))}
            <button className="btn-link" onClick={() => addOptionToItem(col, colIndex)}>
              <i className="icon-plus-svgrepo-com"></i>
              Add dropdown choice
            </button>

            <h3 className="form-builder-aside-property-sub-title">Default option</h3>
            <div className="form-control-item">
              <Select
                classNamePrefix="react-select"
                className="form-control-select"
                value={defaultOptionValue}
                onChange={(e) => setDefaultDropdownOption(col, e?.id)}
                options={getDropdownDefaultOptions(col.options)}
              />
            </div>
          </div>
        </>
      );
    }
    return layout;
  };

  return (
    <div className="tabs-item">
      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Field Alignment</h3>
        <div className="form-group">
          <span className="form-radio-button">
            <input
              type="radio"
              name="alignment"
              value="left"
              checked={alignment === 'left'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'alignment', element.id);
                setAlignment(e.target.value);
              }}
            />
            <span>Left</span>
          </span>
          <span className="form-radio-button">
            <input
              type="radio"
              name="alignment"
              value="center"
              checked={alignment === 'center'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'alignment', element.id);
                setAlignment(e.target.value);
              }}
            />
            <span>Center</span>
          </span>
          <span className="form-radio-button">
            <input
              type="radio"
              name="alignment"
              value="right"
              checked={alignment === 'right'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'alignment', element.id);
                setAlignment(e.target.value);
              }}
            />
            <span>Right</span>
          </span>
        </div>
      </div>

      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Table Alignment</h3>
        <div className="form-group">
          <span className="form-radio-button">
            <input
              type="radio"
              name="tableAlignment"
              value="left"
              checked={tableAlignment === 'left'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'tableAlignment', element.id);
                setTableAlignment(e.target.value);
              }}
            />
            <span>Left</span>
          </span>
          <span className="form-radio-button">
            <input
              type="radio"
              name="tableAlignment"
              value="center"
              checked={tableAlignment === 'center'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'tableAlignment', element.id);
                setTableAlignment(e.target.value);
              }}
            />
            <span>Center</span>
          </span>
          <span className="form-radio-button">
            <input
              type="radio"
              name="tableAlignment"
              value="right"
              checked={tableAlignment === 'right'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'tableAlignment', element.id);
                setTableAlignment(e.target.value);
              }}
            />
            <span>Right</span>
          </span>
        </div>
      </div>

      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Name Label</h3>
        <div className="form-control-item">
          <input
            type="text"
            className="form-control"
            placeholder="Enter text"
            value={label}
            onChange={(e) => {
              setLabel(e.target.value);
            }}
            onBlur={(e) => {
              handlerChangeOption(e.target.value, 'label', element.id);
              handlerChangeOption(e.target.value.replace(/ /g, '-').toLowerCase(), 'name', element.id);
            }}
          />
        </div>
      </div>
      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Hide First Column</h3>
        <div className="form-control-item">
          <div className={`form-control-checkbox`}>
            <input
              name={'addRowsButton'}
              type="checkbox"
              checked={showQuestions}
              onChange={(e) => {
                handlerChangeOption(!showQuestions, 'showQuestions', element.id);
                setShowQuestions(!showQuestions);
              }}
            />
            <span>Hide First Column</span>
          </div>
        </div>
      </div>

      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Enable Auto Scroll</h3>
        <div className="form-control-item">
          <div className={`form-control-checkbox`}>
            <input
              name={'addRowsButton'}
              type="checkbox"
              checked={enableScroll}
              onChange={(e) => {
                handlerChangeOption(!enableScroll, 'enableScroll', element.id);
                setEnableScroll(!enableScroll);
              }}
            />
            <span>Enable Auto Scroll</span>
          </div>
        </div>
      </div>

      {!showQuestions && (
        <div className="property-item">
          <h3 className="form-builder-aside-property-sub-title">Title Name First Column</h3>
          <div className="form-control-item">
            <input
              type="text"
              className="form-control"
              placeholder="Enter text"
              value={rowHeaderTitle}
              onChange={(e) => {
                setRowHeaderTitle(e.target.value);
              }}
              onBlur={(e) => {
                handlerChangeOption(e.target.value, 'rowHeaderTitle', element.id);
              }}
            />
          </div>
        </div>
      )}

      <div className="property-item d-none">
        <h3 className="form-builder-aside-property-sub-title">Type Inputs</h3>
        <div className="form-control-item">
          <Select
            classNamePrefix="react-select"
            className="form-control-select"
            value={type}
            onChange={(value) => {
              setType(value);
              handlerChangeOption(value, 'type', element.id);
            }}
            options={columnTypeOptions}
          />
        </div>
      </div>

      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Rows</h3>
        {rows.map((item, index) => {
          let inputLayout;

          if (!showQuestions) {
            inputLayout = (
              <input
                type="text"
                className="form-control"
                placeholder="Enter text"
                value={item.label}
                onChange={(e) => {
                  setRows((prevState) => {
                    return prevState.map((row, prevIndex) => {
                      if (index === prevIndex) {
                        row.label = e.target.value;
                        row.id = e.target.value.replace(/ /g, '-').toLowerCase() + '-' + new Date().getTime();
                      }

                      return row;
                    });
                  });
                }}
                onBlur={updateRowsState}
              />
            );
          } else {
            inputLayout = <span>{`Row ${++index}`}</span>;
          }

          return (
            <div className="form-control-item" key={index}>
              {inputLayout}
              <div className="settings-actions">
                <button className="btn btn-remove btn-icon" onClick={() => removeRows(item)}>
                  <i className="icon-remove"></i>
                </button>
              </div>
            </div>
          );
        })}
        <button className="btn-link" onClick={addRows}>
          <i className="icon-plus-svgrepo-com"></i>
          Add Row
        </button>
      </div>

      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Columns</h3>
        {cols.map((item, index) => {
          return (
            <div key={index}>
              <h3 className="form-builder-aside-property-sub-title">Column label</h3>
              <div
                className={`form-control-item  ${type.value === 'dropdown' && cols.length > 1 ? 'form-control-item-with-type-dropdown' : ''}`}
                style={{ alignItems: 'flex-start' }}
              >
                <AutoHeightTextArea
                  value={item.label}
                  onChange={(e) => {
                    setCols((prevState) => {
                      return prevState.map((col, prevIndex) => {
                        if (index === prevIndex) {
                          col.label = e.target.value;
                        }
                        return col;
                      });
                    });
                  }}
                  onBlur={updateColsState}
                  placeholder="Enter text"
                  className={cx('form-control', { 'form-control-with-type-dropdown': type.value === 'dropdown' && cols.length > 1 })}
                />

                <div className="settings-actions">
                  {type.value === 'dropdown' && (
                    <button className="btn btn-dropdown btn-icon" onClick={() => setIsActive(isActive === index ? null : index)}>
                      <i className="icon-file-text"></i>
                    </button>
                  )}
                  {cols.length > 1 && (
                    <button className="btn btn-remove btn-icon" style={{ marginTop: 15 }} onClick={() => removeCols(item)}>
                      <i className="icon-remove" />
                    </button>
                  )}
                </div>
              </div>

              <div className="property-item">
                <h3 className="form-builder-aside-property-sub-title">Type Column Inputs</h3>
                <div className="form-control-item">
                  <Select
                    classNamePrefix="react-select"
                    className="form-control-select"
                    value={item.type}
                    onChange={(e) => handleChangeColumnType(e, index)}
                    options={columnTypeOptions}
                  />
                </div>

                {item?.type?.value === 'current-user' && (
                  <>
                    <h3 className="form-builder-aside-property-sub-title">User Info</h3>
                    <div className="form-control-item">
                      <Select
                        classNamePrefix="react-select-multi"
                        className="form-control-select"
                        hideSelectedOptions={false}
                        value={get(item, 'options')}
                        options={SUBMITTERS_OPTIONS}
                        isMulti
                        onChange={(value) => {
                          setCols((prevState) => {
                            const newState = prevState.map((col, prevIndex) => {
                              if (index === prevIndex) {
                                col.options = value;
                              }
                              return col;
                            });
                            updateColsStateType(newState);
                            return newState;
                          });
                        }}
                      />
                    </div>
                  </>
                )}

                {(item?.type?.value === 'date' || item?.type?.value === 'time') && (
                  <>
                    <h3 className="form-builder-aside-property-sub-title">Editable</h3>
                    <div className={`form-control-checkbox w-full`}>
                      <input name={'addRowsButton'} type="checkbox" checked={item.editable} onChange={() => handleChangeDateTimeEditOption(index)} />
                      <span>Editable</span>
                    </div>

                    {item.editable ? (
                      <>
                        <h3 className="form-builder-aside-property-sub-title">Who can edit this column</h3>
                        <div className="form-control-item">
                          <Select
                            value={getDateTimeColumnValueOption(item.editRole)}
                            options={rolesOptions}
                            classNamePrefix="react-select"
                            className="form-control-select"
                            onChange={(value) => handleChangeDateTimeEditRole(index, value)}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <h3 className="form-builder-aside-property-sub-title">Set the value for</h3>
                        <div className="form-control-item">
                          <Select
                            value={getDateTimeColumnValueOption(item.autoSetValueFor)}
                            options={rolesOptions}
                            classNamePrefix="react-select"
                            className="form-control-select"
                            onChange={(value) => handleChangeAutoSetValueForRole(index, value)}
                          />
                        </div>
                      </>
                    )}
                  </>
                )}

                {item?.type?.value === 'electronic-signature' && (
                  <>
                    <h3 className="form-builder-aside-property-sub-title">Date and Time</h3>
                    <div className="form-control-item d-block">
                      <div className={`form-control-checkbox w-full`}>
                        <input
                          name={'addRowsButton'}
                          type="checkbox"
                          checked={Boolean(item.hasDate)}
                          onChange={(e) => {
                            setCols((prevState) => {
                              const newState = prevState.map((col, prevIndex) => {
                                if (index === prevIndex) {
                                  col.hasDate = !col.hasDate;
                                }
                                return col;
                              });
                              updateColsStateType(newState);
                              return newState;
                            });
                          }}
                        />
                        <span>Show Date</span>
                      </div>
                      <div className={`form-control-checkbox w-full`}>
                        <input
                          name={'addRowsButton'}
                          type="checkbox"
                          checked={Boolean(item.hasTime)}
                          onChange={(e) => {
                            setCols((prevState) => {
                              const newState = prevState.map((col, prevIndex) => {
                                if (index === prevIndex) {
                                  col.hasTime = !col.hasTime;
                                }
                                return col;
                              });
                              updateColsStateType(newState);
                              return newState;
                            });
                          }}
                        />
                        <span>Show Time</span>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {renderDropdownSettings(item, index)}
            </div>
          );
        })}
        <button className="btn-link" onClick={() => addCol()}>
          <i className="icon-plus-svgrepo-com" />
          Add Column
        </button>
      </div>

      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Ability to add a new row inside the table</h3>
        <div className="form-switcher">
          <input
            type="checkbox"
            name={'addRowsButton'}
            checked={Boolean(element?.options?.addNewRowInsideTable)}
            onChange={(e) => {
              handlerChangeOption(e.target.checked, 'addNewRowInsideTable', element.id);
            }}
          />
          <span>{addRowsButton ? 'ON' : 'OFF'}</span>
        </div>
      </div>

      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Ability to add a new row to the end of the table</h3>
        <div className="form-switcher">
          <input
            type="checkbox"
            name={'addRowsButton'}
            checked={Boolean(addRowsButton)}
            onChange={(e) => {
              handlerChangeOption(e.target.checked, 'addRowsButton', element.id);
            }}
          />
          <span>{addRowsButton ? 'ON' : 'OFF'}</span>
        </div>
      </div>

      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Time of automatic addition of rows</h3>
        <div className="form-control-item automatic--addition--rows--date">
          <DatePicker
            className={'form-control automatic--addition--rows--date'}
            showTimeSelect
            showTimeSelectOnly
            isClearable
            timeIntervals={60}
            timeCaption=""
            dateFormat="h:mm aa"
            selected={dailyRowsCopyTime}
            placeholderText={'Choose Time'}
            customInput={<AutomaticAdditionRowsInput />}
            onChange={(date) => handleSetStateDailyRowsCopyTime(date)}
          />
        </div>
      </div>

      {dailyRowsCopyTime && (
        <div className="property-item">
          <h3 className="form-builder-aside-property-sub-title">Copy rows data</h3>
          <div className="form-switcher">
            <input
              type="checkbox"
              checked={Boolean(copyRowsData)}
              onChange={(e) => {
                handlerChangeOption(e.target.checked, 'need_copy_rows_data', element.id);
              }}
            />
            <span>{copyRowsData ? 'ON' : 'OFF'}</span>
          </div>
        </div>
      )}

      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Instructions</h3>
        <div className="form-control-item">
          <textarea
            className="form-control"
            placeholder="Enter Instructions"
            value={instructions}
            onChange={(e) => {
              setInstructions(e.target.value);
            }}
            onBlur={(e) => {
              handlerChangeOption(e.target.value, 'instructions', element.id);
            }}
          />
        </div>
      </div>
    </div>
  );
};
