import React, { useLayoutEffect, useState } from 'react';
import Router from './router';

let mobile = require('is-mobile');

function App() {
  const isUserAgentMobile = (req) => {
    return mobile({ ua: req });
  };

  const isUserAgentTablet = (req) => {
    return mobile({ ua: req, tablet: true, featureDetect: true });
  };

  // const isMobile = function isMobile() {
  //   let check = false;
  //   // eslint-disable-next-line
  //   check = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  //
  //   return check;
  // };

  const [size, setSize] = useState(false);

  useLayoutEffect(() => {
    function updateSize() {
      setSize(isUserAgentMobile() || isUserAgentTablet());
    }

    window.addEventListener('resize', updateSize);

    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <div className={`${size ? 'mobile' : ''}`}>
      <Router />
    </div>
  );
}

export default App;
