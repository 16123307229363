import React, { useEffect } from 'react';
import { useAuth } from '../../../container/authContext';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../../constants';

export const SubmittersFieldElement = function FromElement({ element, isSubmitter, handlerChange }) {
  const { options } = element ?? {};
  const { user } = useAuth();
  const location = useLocation();
  const { pathname, search } = location;
  const autoAddInfoFor = options?.autoAddInfoFor?.value;
  const valueInput = element?.options?.valueInput;
  const isInitForm = pathname.toLowerCase().includes('initialization-form');
  const isSendForm = pathname.toLowerCase().includes('form-view');

  const needShowInitiatorInfo = isSubmitter || (isInitForm && autoAddInfoFor === 'initiator');
  const needShowSubmitterInfo = isSendForm && autoAddInfoFor === 'submitter';
  const needShowUserInfo = needShowInitiatorInfo || needShowSubmitterInfo;

  const getToken = () => {
    if (search.includes('?token=')) {
      return search.split('?token=')[1];
    }
    return null;
  };

  function parseJwt(token) {
    if (token) {
      let base64Url = token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(''),
      );

      return JSON.parse(jsonPayload);
    }
    return null;
  }

  useEffect(() => {
    if (!options.valueInput) {
      if (needShowUserInfo) {
        setSubmitterCurrentUserData();
      }
    }
  }, [needShowInitiatorInfo, needShowSubmitterInfo, options.valueInput]);

  const setSubmitterCurrentUserData = () => {
    let submitter = '';

    element.options.labels.forEach((item) => {
      switch (item.value) {
        case 'name': {
          submitter = submitter + `${user?.first_name}`;
          break;
        }
        case 'surname': {
          submitter = submitter + ` ${user?.last_name}`;
          break;
        }
        case 'position': {
          if (user?.position?.name) {
            submitter = submitter + ` ${user?.position?.name}`;
          }
          break;
        }
        case 'date': {
          submitter = submitter + ` ${dayjs().format(DATE_FORMAT)}`;
          break;
        }
      }
    });

    if (parseJwt(getToken())?.sub_ins === 'UnregisteredSubmitter') {
      submitter = 'Unregistered Submitter';
    }

    handlerChange(element, submitter);

    return submitter;
  };

  return (
    <>
      <label className="form-label">
        {element.options.name ? element.options.name : `Select${element.options.labels.length > 0 && 'ed'} Submitters Field`}
      </label>
      <p className={'form-submitters'}>
        {valueInput?.length ? element.options.valueInput : element.options.labels.map((item) => item.label).join(' ')}
      </p>
      {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}
    </>
  );
};
