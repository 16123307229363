import React, { useEffect, useRef, useState } from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';
import useOutsideClick from '../../hook/useOutsideClick';
import { updateFormAdmin } from '../../servises/form';
import { getAvailableGeneralFolder } from '../../servises/folder/form-avaliable';
const MenuItem = ({ data, handelFolder, folder }) => {
  const { id, name, folders } = data;

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (name === 'Forms Available To Me') {
      setIsActive(true);
    } else {
      setIsActive(folder && folder.id === id);
    }
  }, [folder, id, name]);

  return (
    <li className={isActive ? 'active-folder' : ''}>
      <span
        className={'cursor-pointer'}
        onClick={() => {
          if (name !== 'Forms Available To Me') {
            handelFolder(data);
          }
        }}
      >
        {!folders.length && (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M20.0005 17.4C20.0005 17.8774 19.8108 18.3352 19.4733 18.6728C19.1357 19.0104 18.6779 19.2 18.2005 19.2H3.80049C3.3231 19.2 2.86526 19.0104 2.5277 18.6728C2.19013 18.3352 2.00049 17.8774 2.00049 17.4V4.8C2.00049 4.32261 2.19013 3.86477 2.5277 3.52721C2.86526 3.18964 3.3231 3 3.80049 3H8.30049L10.1005 5.7H18.2005C18.6779 5.7 19.1357 5.88964 19.4733 6.22721C19.8108 6.56477 20.0005 7.02261 20.0005 7.5V17.4Z"
                stroke="#98A2B3"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </>
        )}
        {!!folders.length && (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
              <path d="M2.50049 3.75L5.00049 6.25L7.50049 3.75" stroke="#98A2B3" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path
                  d="M20.7502 11.5484V20.2984C20.7502 20.4841 20.6753 20.6621 20.5419 20.7934C20.4086 20.9247 20.2277 20.9984 20.0391 20.9984H4.7502C4.53802 20.9984 4.33454 20.9155 4.18451 20.7678C4.03448 20.6201 3.9502 20.4198 3.9502 20.2109V9.18594C3.9502 8.97708 4.03448 8.77678 4.18451 8.62909C4.33454 8.48141 4.53802 8.39844 4.7502 8.39844H9.28353C9.45662 8.39844 9.62505 8.4537 9.76353 8.55594L12.5369 10.6034C12.6753 10.7057 12.8438 10.7609 13.0169 10.7609H19.9502C20.1624 10.7609 20.3659 10.8439 20.5159 10.9916C20.6659 11.1393 20.7502 11.3396 20.7502 11.5484Z"
                  stroke="#98A2B3"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.05029 7.34922V4.98672C6.05029 4.77786 6.13985 4.57756 6.29925 4.42987C6.45866 4.28219 6.67486 4.19922 6.90029 4.19922H11.717C11.9009 4.19922 12.0798 4.25448 12.227 4.35672L15.1736 6.40422C15.3208 6.50645 15.4997 6.56172 15.6836 6.56172H23.0503C23.2757 6.56172 23.4919 6.64469 23.6513 6.79237C23.8107 6.94006 23.9003 7.14036 23.9003 7.34922V16.0992C23.9003 16.1911 23.8807 16.2822 23.8428 16.3671C23.8048 16.452 23.7492 16.5292 23.679 16.5942C23.6088 16.6592 23.5255 16.7108 23.4339 16.7459C23.3422 16.7811 23.244 16.7992 23.1447 16.7992H21.7003"
                  stroke="#98A2B3"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </>
        )}
        {name}
      </span>

      {!!folders.length && (
        <ul className="folder-select-ul-inner">
          {folders.map((i) => (
            <MenuItem data={i} key={i.id} handelFolder={handelFolder} folder={folder} />
          ))}
        </ul>
      )}
    </li>
  );
};
export const ReplaceTheForm = function ViewForm({ formData, initData, handelError, handelInitSettings }) {
  const { id } = useParams();
  const [foldersList, setFoldersList] = useState([]);
  const [current_folder, setFolder] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [errors, setErrors] = useState(null);
  const wrapperRef = useRef(null);
  const navigation = useNavigate();
  const closeMenu = () => {
    setIsOpen(false);
  };

  useOutsideClick(wrapperRef, closeMenu);

  useEffect(() => {
    init(null);
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (formData && formData.folder_id && foldersList.length) {
      const folders = getFoldersList(foldersList);
      setFolder(folders.find((item) => item.id === formData.folder_id));
    }
  }, [formData, foldersList]);

  const init = (folder_id = null) => {
    getAvailableGeneralFolder({ folder_id })
      .then((res) => {
        // setFoldersList(res[0].folders)
        setFoldersList(res);
      })
      .catch((e) => console.log(e));
  };

  const getFoldersList = (folders) => {
    const list = [];
    getProp(folders);

    function getProp(o) {
      o.forEach((item) => {
        list.push(item);
        if (item.folders.length) {
          getProp(item.folders);
        }
      });
    }
    return list;
  };

  const saveInFolder = () => {
    const formData = {
      folder_id: current_folder ? current_folder.id : null,
      is_draft: !current_folder?.id,
    };
    updateFormAdmin(id, formData)
      .then(() => {
        navigation('/general-library/forms');
      })
      .catch((e) => {
        if (e?.response?.data) {
          setErrors(e.response.data);
        }
        console.log(e);
      });
  };

  const handelFolder = (item) => {
    setFolder(item);
    setIsOpen(false);
  };
  return (
    <div className="init-form-tab">
      <div className="init-form-tab-content form-builder-setting-tab-content p-0">
        <div className="form-builder-recipient-settings form-builder-recipient-settings-init">
          {formData?.name && (
            <div className="from-builder-title from-builder-title-setting">
              <h1>
                <img src="/images/file-big-icon.png" className="mr-2" alt="" />
                {formData.name}
              </h1>
            </div>
          )}
          {errors?.non_field_errors && <span className="form-error mb-2 d-flex">{errors?.non_field_errors && errors.non_field_errors[0]}</span>}
          <div className={`form-item form-item-select form-control-item-settings`}>
            <div className="form-settings-label">
              <label className="form-label form-label-big">Place the form in the General Library</label>
              <span>
                By default, all created forms are saved in the 'Drafts' section, but you also need to place the form in the following folder.<br></br>
                <br></br>
                Folder can contain other nested folders, so you will have the ability to delve deeper into the folder structure.{' '}
              </span>
            </div>
            <div className="form-settings-inputs">
              <div className={`form-control-item-group form-item form-item-select`}>
                <label className="form-label">General Folders</label>
                <div className="folder-select" ref={wrapperRef}>
                  <div className="folder-select-input cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
                    {!current_folder && <span className="folder-select-input-placeholder">Choose folder</span>}
                    {current_folder && current_folder.name}
                    <i className="icon-arrow-down"></i>
                  </div>
                  {isOpen && (
                    <div className="folder-select-menu">
                      <ul className="folder-select-ul">
                        {foldersList.map((folder) => (
                          <MenuItem data={folder} key={folder.id} folder={current_folder} handelFolder={handelFolder}></MenuItem>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <span className="form-error">{errors?.folder_id && errors.folder_id[0]}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="form-control-actions">
        <Link to={`/general-library`} className="btn btn-outline">
          Back to General Library
        </Link>
        <button className={'btn mr-2'} onClick={saveInFolder}>
          Save Changes
        </button>
      </div>
    </div>
  );
};
