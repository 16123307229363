import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../../layout/default';
import { MultiSelect } from '../../../components/other/MultiSelect';
import Select from 'react-select';
import { useAuth } from '../../../container/authContext';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import FileItem from '../../../components/report-builder/file-item';
import { getReportBuilder, getSubmissionsBuilder } from '../../../services/folder/form-avaliable';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { usePermission } from '../../../container/permissionContext';
import { DATE_FORMAT } from '../../../constants';
import { useDebounce } from 'use-debounce';
import { FolderItem } from '../../../components/library/folders/folder';
import { Breadcrumbs } from '../../../components/library/Breadcrumbs';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import PageLoader from '../../../components/other/PageLoader';
import PageTabs from '../componets/PageTabs';
import { localToUTCStringForDateRange } from '../../../utility/dates/localToUTCForDateRange';

const Font = Quill.import('formats/font');
Font.whitelist = ['arial', 'times-new-roman'];
Quill.register(Font, true);

const initialFormsModalBreadcrumbs = [{ name: 'Company Library' }];
const formWrapperStyles = {
  flex: 'initial',
  width: 'calc(20% - 16px)',
  minWidth: 175,
};

const getBreadcrumbs = (breadcrumbs) => {
  const list = [];

  function getProp(o) {
    list.push(o);
    if (o.children) {
      getProp(o.children);
    }
  }

  if (breadcrumbs) {
    getProp(breadcrumbs);
  }

  // Fix formsBreadcrumbs first link
  if (list[0]) {
    list[0].name = 'Company Library';
    list[0].url = '/corporate-library/forms/submissions';
  }

  return list;
};

export default function ReportBuilder() {
  const { user } = useAuth();
  const { isSuperAdmin, hasPermissionByCodeName } = usePermission();
  const company_id = user?.company?.id;
  const [sortOption] = useState([
    {
      value: 'submitters_alphabetically',
      label: 'Submitters Alphabetically',
    },
    {
      value: 'initiation_date_asc',
      label: 'Initiation date asc',
    },
    {
      value: 'initiation_date_desc',
      label: 'Initiation date desc',
    },
  ]);
  const [headerOption, setHeaderOption] = useState([]);
  const [reportHtml, setReportHtml] = useState('');
  const [header, setHeader] = useState([]);
  const [isDisable, setIsDisable] = useState(true);
  const [showHeaderFields, setShowHeaderFields] = useState(true);
  const [showFields, setShowFields] = useState(true);
  const [reportSearch, setReportSearch] = useState('');
  const [fieldOption, setFieldOption] = useState([]);
  const [field, setField] = useState([]);
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sort, setSort] = useState('');
  const [formName, setFormName] = useState('');
  const [pageBreadcrumbs] = useState([
    {
      url: `/report-builder`,
      name: 'Report Builder',
    },
  ]);
  const [selectedForm, setSelectedForm] = useState([]);
  const [isGenerate, setIsGenerate] = useState(false);
  const [isOpenFormsModal, setIsOpenFormsModal] = useState(false);
  const navigator = useNavigate();
  const [forms, setForms] = useState([]);
  const [folders, setFolders] = useState([]);
  const [file, setFile] = useState(null);
  const [isModalPrint, setIsModalPrint] = useState(false);
  const [formsBreadcrumbs, setFormsBreadcrumbs] = useState(initialFormsModalBreadcrumbs);
  const [modules] = useState({
    toolbar: [
      [{ font: ['arial', 'times-new-roman'] }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ color: ['black', 'grey', 'silver', 'red', 'maroon', 'lime', 'green', 'aqua', 'blue', 'navy', 'orange', 'yellow', 'fuchsia'] }],
      ['bold', 'italic', 'underline'],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '+1' }, { indent: '-1' }],
      ['clean'],
    ],
  });
  const [formats] = useState(['font', 'size', 'color', 'bold', 'italic', 'underline', 'list', 'bullet', 'indent', 'align', 'image']);
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [isFormsLoading, setIsFormsLoading] = useState(false);
  const [showEachFieldFromNewLine, setShowEachFieldFromNewLine] = useState(false);
  const [isReportBuilderLoading, setIsReportBuilderLoading] = useState(false);
  const [isListMode, setIsListMode] = useState(false);

  const [debouncedSearch] = useDebounce(search, 300);
  const isFirstFormsLoadingRef = useRef(true);

  const clearBuilder = () => {
    setShowFields(true);
    setShowHeaderFields(true);
    setIsDisable(true);
    setIsModalPrint(false);
    setIsGenerate(false);
    setSelectedForm(null);
    setFile(null);
    setStartDate(null);
    setEndDate(null);
    setSelectedForm([]);
    setFieldOption([]);
    setHeaderOption([]);
    setHeader([]);
    setField([]);
    setFormName('');
    setReportSearch('');
    setSort('');
    setReportHtml('');
    setSearch('');
  };

  const closeModalPrint = () => {
    setIsModalPrint(false);
  };

  const printModal = async () => {
    await setIsModalPrint(true);
    window.print();
    await setIsModalPrint(false);
  };

  const openFormsModal = () => setIsOpenFormsModal(true);

  const handelAttach = (form) => {
    setFile(form);
  };

  useEffect(() => {
    handleForm();
    // eslint-disable-next-line
  }, [reportSearch, startDate, endDate]);

  const handleForm = () => {
    if (file?.id) {
      setIsReportBuilderLoading(true);

      const bodyRequest = { get_all: true, id: file.id, company_id: company_id, search: reportSearch };
      setFieldOption([]);
      setHeaderOption([]);
      if (!reportSearch) {
        delete bodyRequest.search;
      }

      if (startDate && endDate) {
        bodyRequest['initiation_date_after'] = localToUTCStringForDateRange(startDate, 'start');
        bodyRequest['initiation_date_before'] = localToUTCStringForDateRange(endDate, 'end');
      } else {
        delete bodyRequest['initiation_date_after'];
        delete bodyRequest['initiation_date_before'];
      }

      getReportBuilder(bodyRequest)
        .then((res) => {
          setSelectedForm(res);
          if (res.length) {
            setFormName(res[0].original_form_name);
            const options = res[0].form_fields
              .filter(
                (item) =>
                  !(
                    item.type === 'table' ||
                    item.type === 'file-attach' ||
                    item.type === 'file-upload' ||
                    item.type === 'single-line-header'
                  ),
              )
              .map((item) => {
                const option = { value: item.id, label: item.options.label };
                if (item.name === 'Submitters') {
                  option.label = item.options.name;
                }

                if (item.type === 'name') {
                  option.label = 'Full Name';
                }
                if (item.type === 'text') {
                  option.label = 'Text Field';
                }

                if (item.type === 'address') {
                  option.label = 'Address';
                }
                if (item.type === 'rating') {
                  option.label = 'Rating';
                }

                return option;
              });

            setFieldOption(options);
            setHeaderOption(options);
          } else {
            setFormName('');
            setFieldOption([]);
            setHeaderOption([]);
          }

          setField([]);
          setHeader([]);
          setReportHtml('');
          setIsGenerate(false);
          setSort('');
          handleCloseFormsModal();
        })
        .catch((e) => console.log(e))
        .finally(() => setIsReportBuilderLoading(false));
    }
  };

  const deleteItem = (id) => {
    const newState = selectedForm.filter((item) => item.submitted_at !== id);
    setSelectedForm(newState);

    if (!newState.length) {
      setFile(null);
    }
  };

  const searchForms = (folder_id) => {
    setIsFormsLoading(true);
    setFolders([]);
    setForms([]);

    const requestBody = { get_all: true, company_id: user.company.id, folder_id };
    let searchParam = debouncedSearch;

    if (isListMode && !debouncedSearch.length) {
      searchParam = ' ';
    }

    requestBody.search = searchParam;

    getSubmissionsBuilder(requestBody)
      .then((res) => {
        if (res) {
          if (debouncedSearch || isListMode) {
            setFolders([]);
            setForms(res);
          } else {
            const { data, breadcrumbs } = res;
            setFormsBreadcrumbs([...getBreadcrumbs(breadcrumbs)]);

            const newCurrentFolderId = getBreadcrumbs(breadcrumbs)[getBreadcrumbs(breadcrumbs).length - 1]?.id;
            setCurrentFolderId(newCurrentFolderId);

            if (Array.isArray(data)) {
              setFolders(data.filter((item) => item.type === 'folder'));
              setForms(data.filter((item) => item.type === 'form'));
            }
          }
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setIsFormsLoading(false));
  };

  useEffect(() => {
    if (isOpenFormsModal) {
      if (!currentFolderId) {
        searchForms();
      } else {
        if (isFirstFormsLoadingRef.current) {
          isFirstFormsLoadingRef.current = false;
        } else {
          searchForms(currentFolderId);
        }
      }
    }
    // eslint-disable-next-line
  }, [debouncedSearch, isOpenFormsModal, currentFolderId, isListMode]);

  useEffect(() => {
    setIsDisable(!file);
  }, [file]);

  const handleDateChange = (date) => {
    setStartDate(date[0]);
    setEndDate(date[1]);
  };

  useEffect(() => {
    if (!company_id) {
      navigator('/companies');
    }
    if (!isSuperAdmin() && !hasPermissionByCodeName('report_building')) {
      navigator('/corporate-library');
    }

    // eslint-disable-next-line
  }, [company_id]);

  if (!company_id || (!isSuperAdmin() && !hasPermissionByCodeName('report_building'))) {
    return '';
  }

  const getFieldString = (submittedData) => {
    let resHtml = ``;

    submittedData.forEach((item) => {
      resHtml += `<p><strong>${item.name}${item.submitter_full_name ? ` ${item.submitter_full_name}` : ''}:</strong> `;
      let str = '';
      item.submitted_data.forEach((submitted_data) => {
        let nameField = '';

        if (showEachFieldFromNewLine) {
          str += '<br />';
        }

        if (showFields) {
          if (submitted_data.type === 'submitters-field' || submitted_data.type === 'rating') {
            nameField = submitted_data.options.name;
          } else {
            nameField = submitted_data.options.label;
          }
        }
        if (submitted_data.type === 'dropdown') {
          str =
            str +
            `${nameField ? nameField + ': ' : ''}${submitted_data?.options?.valueInput?.label ? submitted_data?.options?.valueInput?.label : '---'}; `;
        } else if (submitted_data.type === 'radiobutton') {
          str =
            str +
            `${nameField ? nameField + ': ' : ''}${submitted_data?.options?.valueInput ? submitted_data?.inputs.find((item) => item.id === submitted_data?.options?.valueInput)?.label : '---'}; `;
        } else if (submitted_data.type === 'checkbox') {
          str =
            str +
            `${nameField ? nameField + ': ' : ''}${
              submitted_data?.inputs?.filter((item) => item.valueInput)?.length
                ? submitted_data?.inputs
                    ?.filter((item) => item.valueInput)
                    .map((item) => item.label)
                    .join(', ')
                : '---'
            }; `;
        } else if (submitted_data.type === 'signature') {
          if (submitted_data?.options?.valueInput) {
            str = str + `${nameField ? nameField + ': ' : ''} <img src="${submitted_data?.options?.valueInput}" alt="" />`;
          } else {
            str = str + `${nameField ? nameField + ': ' : ''} ---`;
          }
        } else if (submitted_data.type === 'time' || submitted_data.type === 'date') {
          if (submitted_data?.options?.valueInput) {
            str =
              str +
              `${nameField ? nameField + ': ' : ''}${dayjs(submitted_data?.options?.valueInput).format(submitted_data.type === 'time' ? 'hh:mm a; ' : `${DATE_FORMAT}; `)}`;
          } else {
            str = str + `${nameField ? nameField + ': ' : ''} ---`;
          }
        } else if (submitted_data.type === 'address' || submitted_data.type === 'name') {
          submitted_data.inputs.forEach((input) => {
            if (showFields) {
              nameField = input.label;
            }

            if (input.name === 'state') {
              str = str + `${nameField ? nameField + ': ' : ''}${input?.valueInput?.label ? input.valueInput.label + '; ' : '--- ;'}`;
            } else {
              str = str + `${nameField ? nameField + ': ' : ''}${input.valueInput ? input.valueInput + '; ' : '--- ;'}`;
            }
          });
        } else {
          str =
            str +
            `${nameField ? nameField + ': ' : ''}${submitted_data?.options?.valueInput ? submitted_data?.options?.valueInput : '---'}; `;
        }
      });
      resHtml += str + `</p> <p> </p>`;
    });

    return resHtml;
  };

  const getHeaderString = (submittedData) => {
    let resHtml = '';

    submittedData.forEach((item) => {
      resHtml = resHtml + `<p>${item.original_form_name}: `;
      let str = '';
      item.submitted_data.forEach((submitted_data) => {
        let nameField = '';

        if (showEachFieldFromNewLine) {
          str += '<br />';
        }

        if (submitted_data.type === 'submitters-field' || submitted_data.type === 'rating') {
          nameField = submitted_data.options.name;
        } else {
          nameField = submitted_data.options.label;
        }

        if (submitted_data.type === 'dropdown') {
          str =
            str +
            `${nameField ? nameField + ': ' : ''}${submitted_data?.options?.valueInput?.label ? submitted_data?.options?.valueInput?.label : '---'}; `;
        } else if (submitted_data.type === 'radiobutton') {
          str =
            str +
            `${nameField ? nameField + ': ' : ''}${submitted_data?.options?.valueInput ? submitted_data?.inputs.find((item) => item.id === submitted_data?.options?.valueInput)?.label : '---'}; `;
        } else if (submitted_data.type === 'checkbox') {
          str =
            str +
            `${nameField ? nameField + ': ' : ''}${
              submitted_data?.inputs?.filter((item) => item.valueInput)?.length
                ? submitted_data?.inputs
                    ?.filter((item) => item.valueInput)
                    .map((item) => item.label)
                    .join(', ')
                : '---'
            }; `;
        } else if (submitted_data.type === 'signature') {
          if (submitted_data?.options?.valueInput) {
            str = str + `${nameField ? nameField + ': ' : ''} <img src="${submitted_data?.options?.valueInput}" alt="" />`;
          } else {
            str = str + `${nameField ? nameField + ': ' : ''} ---`;
          }
        } else if (submitted_data.type === 'time' || submitted_data.type === 'date') {
          if (submitted_data?.options?.valueInput) {
            str =
              str +
              `${nameField ? nameField + ': ' : ''}${dayjs(submitted_data?.options?.valueInput).format(submitted_data.type === 'time' ? 'hh:mm a; ' : `${DATE_FORMAT}; `)}`;
          } else {
            str = str + `${nameField ? nameField + ': ' : ''} ---`;
          }
        } else if (submitted_data.type === 'address' || submitted_data.type === 'name') {
          submitted_data.inputs.forEach((input) => {
            if (showFields) {
              nameField = input.label;
            }

            if (input.name === 'state') {
              str = str + `${nameField ? nameField + ': ' : ''}${input?.valueInput?.label ? input.valueInput.label + '; ' : '--- ;'}`;
            } else {
              str = str + `${nameField ? nameField + ': ' : ''}${input.valueInput ? input.valueInput + '; ' : '--- ;'}`;
            }
          });
        } else if (submitted_data.type === 'text') {
          str = str + `${nameField ? nameField + ': ' : ''}${submitted_data?.options?.value ? submitted_data?.options?.value : '---'}; `;
        } else {
          str =
            str +
            `${nameField ? nameField + ': ' : ''}${submitted_data?.options?.valueInput ? submitted_data?.options?.valueInput : '---'}; `;
        }
      });
      resHtml += str + `</p> <p> </p>`;
    });

    return submittedData[0].submitted_data.length ? resHtml : '';
  };

  const generateReport = () => {
    setIsGenerate(false);

    const headerData = selectedForm.map((item) => {
      const res = [];
      item.submitted_data.forEach((submitted_data) => {
        header.forEach((header_item) => {
          if (submitted_data.id === header_item.value) {
            res.push(submitted_data);
          }
        });
      });
      return {
        submitted_data: res,
        submitter_full_name: item.submitter_full_name,
        submitted_at: item.submitted_at,
        name: item.name,
        original_form_name: item.original_form_name,
      };
    });

    const submittedData = selectedForm.map((item) => {
      const res = [];
      item.submitted_data.forEach((submitted_data) => {
        field.forEach((field_item) => {
          if (submitted_data.id === field_item.value) {
            res.push(submitted_data);
          }
        });
      });
      return {
        submitted_data: res,
        submitter_full_name: item.submitter_full_name,
        submitted_at: item.submitted_at,
        name: item.name,
        original_form_name: item.original_form_name,
      };
    });

    if (sort?.value === 'submitters_alphabetically' || sort?.value === 'initiation_date_asc') {
      headerData.sort(function (a, b) {
        if (a.submitter_full_name > b.submitter_full_name) {
          return 1;
        }
        if (a.submitter_full_name < b.submitter_full_name) {
          return -1;
        }
        return 0;
      });
      submittedData.sort(function (a, b) {
        if (a.submitter_full_name > b.submitter_full_name) {
          return 1;
        }
        if (a.submitter_full_name < b.submitter_full_name) {
          return -1;
        }
        return 0;
      });
    }

    if (sort?.value === 'initiation_date_asc') {
      headerData.sort(function (a, b) {
        if (a.submitted_at > b.submitted_at) {
          return 1;
        }
        if (a.submitted_at < b.submitted_at) {
          return -1;
        }
        return 0;
      });
      submittedData.sort(function (a, b) {
        if (a.submitted_at > b.submitted_at) {
          return 1;
        }
        if (a.submitted_at < b.submitted_at) {
          return -1;
        }
        return 0;
      });
    }
    if (sort?.value === 'initiation_date_desc') {
      headerData.sort(function (a, b) {
        if (a.submitted_at > b.submitted_at) {
          return -1;
        }
        if (a.submitted_at < b.submitted_at) {
          return 1;
        }
        return 0;
      });
      submittedData.sort(function (a, b) {
        if (a.submitted_at > b.submitted_at) {
          return -1;
        }
        if (a.submitted_at < b.submitted_at) {
          return 1;
        }
        return 0;
      });
    }

    setReportHtml(
      `<p><strong class="ql-size-large">Summary Report For: ${selectedForm[0].original_form_name}</strong></p><p> </p> ${showHeaderFields ? getHeaderString([headerData[0]]) : ''}${getFieldString(submittedData)}`,
    );
    setIsGenerate(true);
  };

  const handelEdit = (e) => {
    setReportHtml(e);
  };

  const changeFolder = (folderId) => {
    setCurrentFolderId(folderId);
  };

  const goToPrev = (id, type) => {
    if (type === 'folder') {
      changeFolder(id);
    }
  };

  // Clear all forms modal states
  const clearFormsModalState = () => {
    setSearch('');
    setFolders([]);
    setForms([]);
    setFormsBreadcrumbs(initialFormsModalBreadcrumbs);
    setCurrentFolderId(null);
    setIsListMode(false);
    isFirstFormsLoadingRef.current = true;
  };

  const handleCloseFormsModal = () => {
    setIsOpenFormsModal(false);
    clearFormsModalState();
  };

  const handleSelectForm = () => handleForm();

  const renderFormsModal = () => {
    return (
      <Modal
        className="modal-default modal-with-tabs pt-4"
        isOpen={isOpenFormsModal}
        onRequestClose={handleCloseFormsModal}
        ariaHideApp={false}
        style={{ content: { maxWidth: 1160 } }}
      >
        <div className="modal-body">
          <div className="library" style={{ marginTop: 26 }}>
            <DndProvider backend={HTML5Backend}>
              <Breadcrumbs breadcrumbs={formsBreadcrumbs} goToLevel={goToPrev} />
            </DndProvider>

            <div className="library-filter">
              <div className="library-filter-search form-control-item">
                <input
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  type="text"
                  className="form-control"
                  placeholder="Search"
                />
                <i className="icon-search"></i>
              </div>

              <button className={`btn btn-outline`} onClick={() => setIsListMode(!isListMode)}>
                {!isListMode && (
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M2.50033 4.16797C2.04009 4.16797 1.66699 4.54106 1.66699 5.0013C1.66699 5.46154 2.04009 5.83464 2.50033 5.83464H2.50866C2.9689 5.83464 3.34199 5.46154 3.34199 5.0013C3.34199 4.54106 2.9689 4.16797 2.50866 4.16797H2.50033Z"
                      fill="#101828"
                    />
                    <path
                      d="M6.66699 4.16797C6.20676 4.16797 5.83366 4.54106 5.83366 5.0013C5.83366 5.46154 6.20676 5.83464 6.66699 5.83464H17.5003C17.9606 5.83464 18.3337 5.46154 18.3337 5.0013C18.3337 4.54106 17.9606 4.16797 17.5003 4.16797H6.66699Z"
                      fill="#101828"
                    />
                    <path
                      d="M6.66699 9.16797C6.20676 9.16797 5.83366 9.54106 5.83366 10.0013C5.83366 10.4615 6.20676 10.8346 6.66699 10.8346H17.5003C17.9606 10.8346 18.3337 10.4615 18.3337 10.0013C18.3337 9.54106 17.9606 9.16797 17.5003 9.16797H6.66699Z"
                      fill="#101828"
                    />
                    <path
                      d="M5.83366 15.0013C5.83366 14.5411 6.20676 14.168 6.66699 14.168H17.5003C17.9606 14.168 18.3337 14.5411 18.3337 15.0013C18.3337 15.4615 17.9606 15.8346 17.5003 15.8346H6.66699C6.20676 15.8346 5.83366 15.4615 5.83366 15.0013Z"
                      fill="#101828"
                    />
                    <path
                      d="M1.66699 10.0013C1.66699 9.54106 2.04009 9.16797 2.50033 9.16797H2.50866C2.9689 9.16797 3.34199 9.54106 3.34199 10.0013C3.34199 10.4615 2.9689 10.8346 2.50866 10.8346H2.50033C2.04009 10.8346 1.66699 10.4615 1.66699 10.0013Z"
                      fill="#101828"
                    />
                    <path
                      d="M2.50033 14.168C2.04009 14.168 1.66699 14.5411 1.66699 15.0013C1.66699 15.4615 2.04009 15.8346 2.50033 15.8346H2.50866C2.9689 15.8346 3.34199 15.4615 3.34199 15.0013C3.34199 14.5411 2.9689 14.168 2.50866 14.168H2.50033Z"
                      fill="#101828"
                    />
                  </svg>
                )}

                {isListMode && (
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M8.44444 3H3V8.44444H8.44444V3Z"
                      stroke="#101828"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path d="M17 3H11.5556V8.44444H17V3Z" stroke="#101828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path
                      d="M17 11.5556H11.5556V17H17V11.5556Z"
                      stroke="#101828"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.44444 11.5556H3V17H8.44444V11.5556Z"
                      stroke="#101828"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </button>
            </div>

            {!!folders?.length && (
              <>
                <h3 className="library-subtitle">Folders</h3>
                <div className="library-wrapper">
                  {folders.map((item) => (
                    <FolderItem changeFolder={changeFolder} key={item.id} item={item} wrapperStyles={formWrapperStyles} />
                  ))}
                </div>
              </>
            )}

            {forms.length > 0 && (
              <>
                <h3 className="library-subtitle mt-2">
                  Forms {!!debouncedSearch?.length && <span className={'total'}>(Total: {forms.length} Forms)</span>}
                </h3>
                <div className="library-wrapper forms">
                  {forms.map((item) => (
                    <FileItem
                      file={file}
                      isGeneral={!company_id}
                      handelAttach={handelAttach}
                      isAttach
                      deleteFile={() => {}}
                      item={item}
                      url={`${item.id}`}
                      key={item.id}
                      wrapperStyles={formWrapperStyles}
                    />
                  ))}
                </div>
              </>
            )}
            {!folders?.length && !forms?.length && !!debouncedSearch && (
              <>
                <div className="empty-page">
                  <h3 className="empty-page-title">Unfortunately, nothing was found for your query.</h3>
                  <h4 className="empty-page-subtitle">
                    Try to change the range of keywords. We're here to help you find what you're looking for.
                  </h4>
                </div>
              </>
            )}
            {!folders?.length && !forms.length && !debouncedSearch && (
              <>
                <div className="empty-page">
                  <h3 className="empty-page-title">This folder is empty</h3>
                  <h4 className="empty-page-subtitle">This folder currently does not contain any forms. </h4>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn btn-outline" onClick={handleCloseFormsModal}>
            Cancel
          </button>
          <button className="btn d-flex" onClick={handleSelectForm} disabled={isDisable}>
            Select
          </button>
        </div>
      </Modal>
    );
  };

  return (
    <Layout title={'Report Builder'} formsBreadcrumbs={pageBreadcrumbs}>
      <div style={{ width: '100%' }}>
        <PageTabs />
        <div className={'report-builder'}>
          <div className={'report-builder-content'}>
            {!isGenerate && (
              <div className={'report-builder-empty'}>
                <h3>Build Your Custom Report</h3>
                <p>
                  To create a report, please make sure to specify all the required parameters on the right-hand panel and click the
                  'Generate Report' button
                </p>
              </div>
            )}
            {isGenerate && (
              <>
                <div className={'report-builder-editor'}>
                  <div className={'report-builder-header'}>
                    <h3>
                      <img src="images/icon-file-list.svg" alt="" />
                      {selectedForm[0].original_form_name}
                    </h3>
                    <div className={'actions'}>
                      <button onClick={() => setIsModalPrint(true)} className={'btn btn-outline'}>
                        <svg className={'mr-2'} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path
                            d="M10 4.375C3.75 4.375 1.25 10.0006 1.25 10.0006C1.25 10.0006 3.75 15.625 10 15.625C16.25 15.625 18.75 10.0006 18.75 10.0006C18.75 10.0006 16.25 4.375 10 4.375Z"
                            stroke="#202024"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10 13.125C11.7259 13.125 13.125 11.7259 13.125 10C13.125 8.27411 11.7259 6.875 10 6.875C8.27411 6.875 6.875 8.27411 6.875 10C6.875 11.7259 8.27411 13.125 10 13.125Z"
                            stroke="#202024"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Preview
                      </button>
                      <button onClick={printModal} className={'btn btn-outline'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <g clipPath="url(#clip0_5127_29710)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M4.16683 1.66927C4.16683 1.20903 4.53993 0.835938 5.00016 0.835938H15.0002C15.4604 0.835938 15.8335 1.20903 15.8335 1.66927V6.66927H16.6668C17.3299 6.66927 17.9658 6.93266 18.4346 7.4015C18.9034 7.87034 19.1668 8.50623 19.1668 9.16927V13.3359C19.1668 13.999 18.9034 14.6349 18.4346 15.1037C17.9658 15.5725 17.3299 15.8359 16.6668 15.8359H15.8335V18.3359C15.8335 18.7962 15.4604 19.1693 15.0002 19.1693H5.00016C4.53993 19.1693 4.16683 18.7962 4.16683 18.3359V15.8359H3.3335C2.67045 15.8359 2.03457 15.5725 1.56573 15.1037C1.09689 14.6349 0.833496 13.999 0.833496 13.3359V9.16927C0.833496 8.50623 1.09689 7.87034 1.56573 7.4015C2.03457 6.93266 2.67045 6.66927 3.3335 6.66927H4.16683V1.66927ZM5.8335 17.5026H14.1668V12.5026H5.8335V17.5026ZM15.8335 14.1693V11.6693C15.8335 11.209 15.4604 10.8359 15.0002 10.8359H5.00016C4.53993 10.8359 4.16683 11.209 4.16683 11.6693V14.1693H3.3335C3.11248 14.1693 2.90052 14.0815 2.74424 13.9252C2.58796 13.7689 2.50016 13.557 2.50016 13.3359V9.16927C2.50016 8.94826 2.58796 8.73629 2.74424 8.58001C2.90052 8.42373 3.11248 8.33594 3.3335 8.33594H16.6668C16.8878 8.33594 17.0998 8.42373 17.2561 8.58001C17.4124 8.7363 17.5002 8.94826 17.5002 9.16927V13.3359C17.5002 13.5569 17.4124 13.7689 17.2561 13.9252C17.0998 14.0815 16.8878 14.1693 16.6668 14.1693H15.8335ZM14.1668 2.5026V6.66927H5.8335V2.5026H14.1668Z"
                              fill="#101828"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_5127_29710">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <ReactQuill theme="snow" modules={modules} formats={formats} value={reportHtml} onChange={handelEdit} />
                </div>
                {/*<div className="html-area" dangerouslySetInnerHTML={{ __html: reportHtml }}></div>*/}
              </>
            )}
          </div>
          <aside className={'report-builder-aside'}>
            <h2 className={'title d-flex justify-space-between'}>
              Report Builder{' '}
              <button onClick={clearBuilder} className={'btn btn-outline m-0'}>
                Clear
              </button>
            </h2>
            <div className={'form-item mb-6'}>
              <label className={'form-label'}>Select a form</label>
              <div className={'form-item-wrapper'}>
                <input
                  value={formName}
                  onClick={openFormsModal}
                  readOnly
                  type="text"
                  className={'form-control'}
                  placeholder={'Select...'}
                />
                <svg className={'icon-search'} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11C18 12.8858 17.2543 14.5974 16.0417 15.8561C16.0073 15.8825 15.9743 15.9114 15.9428 15.9429C15.9113 15.9744 15.8824 16.0074 15.856 16.0418C14.5973 17.2543 12.8857 18 11 18C7.13401 18 4 14.866 4 11ZM16.6176 18.0319C15.078 19.2635 13.125 20 11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 13.125 19.2635 15.0781 18.0319 16.6177L21.707 20.2929C22.0975 20.6834 22.0975 21.3166 21.707 21.7071C21.3165 22.0976 20.6833 22.0976 20.2928 21.7071L16.6176 18.0319Z"
                    fill="#98A2B3"
                  />
                </svg>
              </div>
            </div>
            <div className={'form-item mb-6'}>
              <label className={'form-label'}>Initiation date</label>
              <DatePicker
                selected={startDate}
                className="form-control"
                onChange={handleDateChange}
                selectsRange
                startDate={startDate}
                endDate={endDate}
                isClearable
                dateFormat={'MM-dd-yyyy'}
                disabledKeyboardNavigation
                placeholderText={'Select...'}
              />
            </div>

            <div className={'form-item mb-6'}>
              <label className={'form-label'}>Submission name</label>
              <div className={'form-item-wrapper'}>
                <input
                  value={reportSearch}
                  onChange={(e) => setReportSearch(e.target.value)}
                  type="text"
                  className={'form-control'}
                  placeholder={'Select...'}
                />
                <svg className={'icon-search'} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11C18 12.8858 17.2543 14.5974 16.0417 15.8561C16.0073 15.8825 15.9743 15.9114 15.9428 15.9429C15.9113 15.9744 15.8824 16.0074 15.856 16.0418C14.5973 17.2543 12.8857 18 11 18C7.13401 18 4 14.866 4 11ZM16.6176 18.0319C15.078 19.2635 13.125 20 11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 13.125 19.2635 15.0781 18.0319 16.6177L21.707 20.2929C22.0975 20.6834 22.0975 21.3166 21.707 21.7071C21.3165 22.0976 20.6833 22.0976 20.2928 21.7071L16.6176 18.0319Z"
                    fill="#98A2B3"
                  />
                </svg>
              </div>
              <div className={'result'}>
                {!!selectedForm.length && (
                  <>
                    <h3 className={'result-title'}>{selectedForm.length} submissions found with names:</h3>
                    <ul className={'result-list'}>
                      {selectedForm.map((item) => (
                        <li key={item.submitted_at} className={'result-item'}>
                          {item.name}
                          {item.submitter_full_name ? ` - ${item.submitter_full_name}` : ''}

                          <svg
                            onClick={() => {
                              deleteItem(item.submitted_at);
                            }}
                            className={'ml-2'}
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <g clipPath="url(#clip0_3616_31251)">
                              <path
                                d="M12.4998 7.4974L7.49984 12.4974M7.49984 7.4974L12.4998 12.4974M18.3332 9.9974C18.3332 14.5998 14.6022 18.3307 9.99984 18.3307C5.39746 18.3307 1.6665 14.5998 1.6665 9.9974C1.6665 5.39502 5.39746 1.66406 9.99984 1.66406C14.6022 1.66406 18.3332 5.39502 18.3332 9.9974Z"
                                stroke="#FDA29B"
                                strokeWidth="1.67"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_3616_31251">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            </div>
            <div className={'form-item mb-6'}>
              <div className={`form-control-checkbox`}>
                <input type="checkbox" checked={showHeaderFields} onChange={() => setShowHeaderFields(!showHeaderFields)} />
                <span>Show header fields</span>
              </div>
              {showHeaderFields && (
                <>
                  <label className={'form-label'}>Header fields</label>
                  <MultiSelect
                    isDisabled={!headerOption.length}
                    options={headerOption}
                    value={header}
                    onChange={setHeader}
                    placeholder="Select "
                    allName="All Header"
                  ></MultiSelect>
                </>
              )}
            </div>
            <div className={'form-item mb-6'}>
              <div className={`form-control-checkbox`}>
                <input type="checkbox" checked={showFields} onChange={() => setShowFields(!showFields)} />
                <span>Show fields name</span>
              </div>
              <>
                <label className={'form-label'}>Fields to combine</label>
                <MultiSelect
                  isDisabled={!fieldOption.length}
                  options={fieldOption}
                  value={field}
                  onChange={setField}
                  placeholder="Select"
                  allName="All Fields to combine"
                ></MultiSelect>
              </>
            </div>

            <div className={'form-item mb-6'}>
              <label className={'form-label'}>Sort by</label>
              <Select
                options={sortOption}
                value={sort}
                onChange={setSort}
                className="form-control-select"
                placeholder="Select"
                classNamePrefix="react-select"
              ></Select>
            </div>

            <div className={'form-item mb-6'}>
              <div className={`form-control-checkbox`}>
                <input
                  type="checkbox"
                  checked={!!showEachFieldFromNewLine}
                  onChange={() => setShowEachFieldFromNewLine((prevState) => !prevState)}
                />
                <span>Show each field from a new line</span>
              </div>
            </div>

            <button disabled={!field.length && !header.length} onClick={generateReport} className={'btn'}>
              <svg className={'mr-2'} xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                <g clipPath="url(#clip0_5076_1432)">
                  <path
                    d="M19.6668 3.33136V8.33136M19.6668 8.33136H14.6668M19.6668 8.33136L15.8002 4.69803C14.9045 3.80196 13.7965 3.14737 12.5795 2.79534C11.3625 2.44331 10.0761 2.40532 8.8404 2.68491C7.60472 2.9645 6.46 3.55256 5.51305 4.39421C4.56611 5.23586 3.8478 6.30368 3.42516 7.49803M1.3335 16.6647V11.6647M1.3335 11.6647H6.3335M1.3335 11.6647L5.20016 15.298C6.09579 16.1941 7.20381 16.8487 8.42084 17.2007C9.63787 17.5527 10.9242 17.5907 12.1599 17.3111C13.3956 17.0316 14.5403 16.4435 15.4873 15.6018C16.4342 14.7602 17.1525 13.6924 17.5752 12.498"
                    stroke="white"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5076_1432">
                    <rect width="20" height="20" fill="white" transform="translate(0.5)" />
                  </clipPath>
                </defs>
              </svg>
              Generate Report
            </button>
          </aside>
        </div>
      </div>

      {renderFormsModal()}

      <Modal
        className="modal-default print-modal general-form-tab-content"
        isOpen={isModalPrint}
        onRequestClose={closeModalPrint}
        ariaHideApp={false}
      >
        <h2 className="modal-header">
          <span>
            <button onClick={() => window.print()} className={'btn btn-outline  btn-outline-secondary d-flex'}>
              <svg className={'mr-2'} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g clipPath="url(#clip0_4773_10494)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.16634 1.66536C4.16634 1.20513 4.53944 0.832031 4.99967 0.832031H14.9997C15.4599 0.832031 15.833 1.20513 15.833 1.66536V6.66536H16.6663C17.3294 6.66536 17.9653 6.92876 18.4341 7.3976C18.9029 7.86644 19.1663 8.50232 19.1663 9.16536V13.332C19.1663 13.9951 18.9029 14.631 18.4341 15.0998C17.9653 15.5686 17.3294 15.832 16.6663 15.832H15.833V18.332C15.833 18.7923 15.4599 19.1654 14.9997 19.1654H4.99967C4.53944 19.1654 4.16634 18.7923 4.16634 18.332V15.832H3.33301C2.66997 15.832 2.03408 15.5686 1.56524 15.0998C1.0964 14.631 0.833008 13.9951 0.833008 13.332V9.16536C0.833008 8.50232 1.0964 7.86644 1.56524 7.3976C2.03408 6.92876 2.66997 6.66536 3.33301 6.66536H4.16634V1.66536ZM5.83301 17.4987H14.1663V12.4987H5.83301V17.4987ZM15.833 14.1654V11.6654C15.833 11.2051 15.4599 10.832 14.9997 10.832H4.99967C4.53944 10.832 4.16634 11.2051 4.16634 11.6654V14.1654H3.33301C3.11199 14.1654 2.90003 14.0776 2.74375 13.9213C2.58747 13.765 2.49967 13.553 2.49967 13.332V9.16536C2.49967 8.94435 2.58747 8.73239 2.74375 8.57611C2.90003 8.41983 3.11199 8.33203 3.33301 8.33203H16.6663C16.8874 8.33203 17.0993 8.41983 17.2556 8.57611C17.4119 8.73239 17.4997 8.94435 17.4997 9.16536V13.332C17.4997 13.553 17.4119 13.765 17.2556 13.9213C17.0993 14.0776 16.8874 14.1654 16.6663 14.1654H15.833ZM14.1663 2.4987V6.66536H5.83301V2.4987H14.1663Z"
                    fill="#7F56D9"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4773_10494">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Print
            </button>
          </span>
          <button className="btn btn-icon" onClick={closeModalPrint}>
            <i className="icon-icon-x"></i>
          </button>
        </h2>
        <div className={'print'}>
          <div className="html-area ql-editor" dangerouslySetInnerHTML={{ __html: reportHtml }}></div>
        </div>
      </Modal>

      {(isReportBuilderLoading || isFormsLoading) && <PageLoader />}
    </Layout>
  );
}
