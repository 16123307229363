import Layout from '../../../layout/default';
import PageTabs from '../componets/PageTabs';
import React, { useEffect, useMemo, useState } from 'react';
import { getFacilities, getFormsList, getReport, getUserGroups } from '../../../servises/reports/statistics';
import { useAuth } from '../../../container/authContext';
import { isResponseOk } from '../../../utility/isResponseOk';
import { MultiSelect } from '../../../components/other/MultiSelect';
import { useDebounce } from 'use-debounce';
import DatePicker from 'react-datepicker';
import { DATE_PICKER_DATE_FORMAT } from '../../../constants';
import FormsModal from './components/FormsModal';

const StatisticsPage = () => {
  const { user } = useAuth();
  const companyId = user.company?.id;

  const [facilities, setFacilities] = useState(null);
  const [userGroups, setUerGroups] = useState(null);
  const [selectedFacilities, setSelectedFacilities] = useState(null);
  const [selectedUerGroups, setSelectedUerGroups] = useState(null);
  const [initiationDateAfter, setInitiationDateAfter] = useState(null);
  const [initiationDateBefore, setInitiationDateBefore] = useState(null);
  const [isOpenSelectFormsModal, setIsOpenSelectFormsModal] = useState(false);
  const [forms, setForms] = useState(null);
  const [selectedForms, setSelectedForms] = useState([]);

  const [debouncedSelectedFacilities] = useDebounce(selectedFacilities, 500);

  useEffect(() => {
    (async () => {
      if (companyId) {
        const facilitiesResponse = await getFacilities({ companyId });
        if (isResponseOk(facilitiesResponse)) {
          setFacilities(facilitiesResponse.data);
        }
      }
    })();
  }, [companyId]);

  useEffect(() => {
    setSelectedUerGroups(null);

    (async () => {
      if (debouncedSelectedFacilities?.length) {
        const facilitiesIDs = debouncedSelectedFacilities.map(({ value }) => value);
        const response = await getUserGroups({ companyId, facilitiesIDs });

        if (isResponseOk(response)) {
          setUerGroups(response.data);
        } else {
          setUerGroups(null);
        }
      } else {
        setUerGroups(null);
      }
    })();
  }, [companyId, debouncedSelectedFacilities]);

  useEffect(() => {
    if (isOpenSelectFormsModal) {
      handleGetForms();
    }
  }, [isOpenSelectFormsModal]);

  useEffect(() => {
    setSelectedForms([]);
  }, [forms]);

  const selectedFormsInputValue = useMemo(() => {
    return (
      forms
        ?.filter(({ id }) => selectedForms.includes(id))
        ?.map(({ name }) => name)
        ?.join(', ') ?? ''
    );
  }, [forms, selectedForms]);

  const facilitiesOptions = useMemo(() => {
    return (
      facilities?.map(({ id, name }) => {
        return { value: id, label: name };
      }) ?? []
    );
  }, [facilities]);

  const userGroupsOptions = useMemo(() => {
    return (
      userGroups?.map(({ id, name }) => {
        return { value: id, label: name };
      }) ?? []
    );
  }, [userGroups]);

  const handleFormInitiationPeriod = (date) => {
    const [startDate, endDate] = date;
    setInitiationDateAfter(startDate);
    setInitiationDateBefore(endDate);
    setForms(null);
  };

  const handleOpenSelectFormsModal = () => setIsOpenSelectFormsModal(true);

  const handleCloseSelectFormsModal = () => setIsOpenSelectFormsModal(false);

  const handleSelectForms = (formsIDs) => {
    setSelectedForms(formsIDs);
    handleCloseSelectFormsModal();
  };

  const handleGetForms = async () => {
    const facilitiesIDs = debouncedSelectedFacilities?.map(({ value }) => value);
    const userGroupsIDs = selectedUerGroups?.map(({ value }) => value);

    const response = await getFormsList({
      companyId,
      initiationDateBefore,
      facilitiesIDs,
      initiationDateAfter,
      userGroupsIDs,
    });
    if (isResponseOk(response)) {
      setForms(response.data);
    } else {
      setForms(null);
    }
  };

  const handleSelectFacilities = (facilities) => {
    setSelectedFacilities(facilities);
    setSelectedUerGroups(null);
    setForms(null);
  };

  const handleSelectUserGroups = (userGroups) => {
    setSelectedUerGroups(userGroups);
    setForms(null);
  };

  const generateReport = async () => {
    const facilitiesIDs = debouncedSelectedFacilities?.map(({ value }) => value);
    const userGroupsIDs = selectedUerGroups?.map(({ value }) => value);
    try {
      const response = await getReport({
        companyId,
        formsIDs: selectedForms,
        initiationDateAfter,
        initiationDateBefore,
        userGroupsIDs,
        facilitiesIDs,
      });
      if (isResponseOk(response)) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'statistics.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const renderAside = () => {
    return (
      <aside className={'report-builder-aside'}>
        <h2 className={'title d-flex justify-space-between'}>Statistics</h2>
        <div className={'form-item mb-6'}>
          <label className={'form-label'}>Facility</label>
          <MultiSelect
            isDisabled={!facilities?.length}
            options={facilitiesOptions}
            value={selectedFacilities}
            placeholder="Select "
            allName="All Facilities"
            onChange={handleSelectFacilities}
          />
        </div>
        <div className={'form-item mb-6'}>
          <label className={'form-label'}>User groups</label>
          <MultiSelect
            isDisabled={!userGroups?.length}
            options={userGroupsOptions}
            value={selectedUerGroups}
            placeholder="Select "
            allName="All User Groups"
            onChange={handleSelectUserGroups}
          />
        </div>
        <div className={'form-item mb-6'}>
          <label className={'form-label'}>Period of form initiation</label>
          <DatePicker
            isClearable
            selectsRange
            selected={initiationDateAfter}
            className="form-control"
            startDate={initiationDateAfter}
            endDate={initiationDateBefore}
            dateFormat={DATE_PICKER_DATE_FORMAT}
            disabledKeyboardNavigation
            placeholderText={'Select...'}
            onChange={handleFormInitiationPeriod}
          />
        </div>

        <div className={'form-item mb-6'}>
          <label className={'form-label'}>Select a form</label>
          <div className={'form-item-wrapper'}>
            <input value={selectedFormsInputValue} onClick={handleOpenSelectFormsModal} readOnly type="text" className={'form-control'} placeholder={'Select...'} />
            <svg className={'icon-search'} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11C18 12.8858 17.2543 14.5974 16.0417 15.8561C16.0073 15.8825 15.9743 15.9114 15.9428 15.9429C15.9113 15.9744 15.8824 16.0074 15.856 16.0418C14.5973 17.2543 12.8857 18 11 18C7.13401 18 4 14.866 4 11ZM16.6176 18.0319C15.078 19.2635 13.125 20 11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 13.125 19.2635 15.0781 18.0319 16.6177L21.707 20.2929C22.0975 20.6834 22.0975 21.3166 21.707 21.7071C21.3165 22.0976 20.6833 22.0976 20.2928 21.7071L16.6176 18.0319Z"
                fill="#98A2B3"
              />
            </svg>
          </div>
        </div>

        <button disabled={!selectedForms.length} onClick={generateReport} className={'btn'}>
          <svg className={'mr-2'} xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
            <g clipPath="url(#clip0_5076_1432)">
              <path
                d="M19.6668 3.33136V8.33136M19.6668 8.33136H14.6668M19.6668 8.33136L15.8002 4.69803C14.9045 3.80196 13.7965 3.14737 12.5795 2.79534C11.3625 2.44331 10.0761 2.40532 8.8404 2.68491C7.60472 2.9645 6.46 3.55256 5.51305 4.39421C4.56611 5.23586 3.8478 6.30368 3.42516 7.49803M1.3335 16.6647V11.6647M1.3335 11.6647H6.3335M1.3335 11.6647L5.20016 15.298C6.09579 16.1941 7.20381 16.8487 8.42084 17.2007C9.63787 17.5527 10.9242 17.5907 12.1599 17.3111C13.3956 17.0316 14.5403 16.4435 15.4873 15.6018C16.4342 14.7602 17.1525 13.6924 17.5752 12.498"
                stroke="white"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_5076_1432">
                <rect width="20" height="20" fill="white" transform="translate(0.5)" />
              </clipPath>
            </defs>
          </svg>
          Generate Report
        </button>
      </aside>
    );
  };

  return (
    <Layout title={'Statistics'}>
      <div style={{ width: '100%' }}>
        <PageTabs />
        <div className={'report-builder'} style={{ height: 'calc(100% - 56px)' }}>
          <div className={'report-builder-content'}>
            <div className={'report-builder-empty'}>
              <h3>Download Excel Statistics File</h3>
              <p>To Download a Statistic report, please make sure to specify all the required parameters on the right-hand panel and click the 'Generate Report' button</p>
            </div>
          </div>
          {renderAside()}
        </div>
      </div>
      {isOpenSelectFormsModal && (
        <FormsModal
          formsSuggest={forms}
          selectedForms={selectedForms}
          previousSelectedForms={selectedForms}
          onClose={handleCloseSelectFormsModal}
          onConfirmFormsSelection={handleSelectForms}
        />
      )}
    </Layout>
  );
};

export default StatisticsPage;
