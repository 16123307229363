import React from 'react';
import InitiatorCanEditSetting from './components/initiator-can-edit';

export const TextElementSettings = function FromElement({ element, duplicateElement, handlerChangeOption }) {
  return (
    <div className="tabs-item">
      {/*<div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Duplicate Field</h3>
        <button className="btn btn-outline" onClick={() => duplicateElement(element.id)}>
          <i className="icon-copy mr-2"></i>Duplicate
        </button>
        <span className="text-secondary">Duplicate this field with all saved settings</span>
      </div>*/}
      <InitiatorCanEditSetting element={element} onChange={handlerChangeOption} />
    </div>
  );
};
