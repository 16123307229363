export const dateToISOString = (dateTime) => {
  let isoString;
  if (dateTime) {
    const date = new Date(dateTime);
    if (!isNaN(date.getTime())) {
      isoString = date.toISOString();
    } else {
      throw new Error(`Invalid date for conversion: ${dateTime}`);
    }
  } else {
    isoString = new Date().toISOString();
  }
  return isoString;
};
