import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { stateUSA } from '../../constants/state';
import Select from 'react-select';
import { getState, validateEmail } from '../../utility';
import { createFacility, updateFacility } from '../../servises/facility';
import InputMask from 'react-input-mask';
import { uploadFile } from '../../servises/upload';
import FileLoader from '../other/FileLoader';

function FacilityModal({ isOpen, editMode, isShortEdit, companyName, closeModal, companyId, facilityData, handelCreateFacility }) {
  const [errorFacilities, setErrorFacilities] = useState({});

  const [facility, setFacility] = useState({
    name: '',
    nickname: '',
    license_number: '',
    state_of_location: '',
    address: '',
    monthly_fee: '',
    signer_name: '',
    signer_lastname: '',
    signer_email: '',
    signer_phone: '',
    contact_person_name: '',
    contact_person_lastname: '',
    contact_person_email: '',
    contact_person_phone: '',
    contract_file: '',
    company_id: companyId,
  });

  useEffect(() => {
    if (facilityData) {
      setFacility((prevState) => {
        const newState = { ...facilityData };
        newState.state_of_location = getState(newState.state_of_location);
        return newState;
      });
    } else {
      resetForm();
    }
    // eslint-disable-next-line
  }, [facilityData]);

  const [loader, setLoader] = useState(false);
  const handleFile = (event) => {
    setLoader(true);
    uploadFile(event.target.files[0])
      .then((res) => {
        console.log(res);
        setFacility({
          ...facility,
          [event.target.id]: res,
        });
        setLoader(false);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoader(false);
      });
  };
  const handleFacilityChange = (value, key) => {
    setFacility({
      ...facility,
      [key]: value,
    });

    if ((key === 'signer_email' || key === 'contact_person_email') && value) {
      if (validateEmail(value)) {
        delete errorFacilities[key];
      }
      return;
    }

    if (errorFacilities.hasOwnProperty(key)) {
      delete errorFacilities[key];
    }
  };

  // const handleFile = (event) => {
  //     setFacility({
  //         ...facility,
  //         [event.target.id]: event.target.files[0],
  //     });
  // };
  // const removeFile = () => {
  //     setFacility({
  //         ...facility,
  //         contact_file: null,
  //     });
  // };

  const resetForm = () => {
    setFacility({
      name: '',
      nickname: '',
      license_number: '',
      state_of_location: '',
      address: '',
      monthly_fee: '',
      signer_name: '',
      signer_lastname: '',
      signer_email: '',
      signer_phone: '',
      contact_person_name: '',
      contact_person_lastname: '',
      contact_person_email: '',
      contact_person_phone: '',
      contract_file: '',
      company_id: companyId,
    });
    setErrorFacilities({});
  };
  const handleSubmit = () => {
    const formDate = { ...facility };

    if (facility.contract_file) {
      formDate.contract_file = facility.contract_file.uuid;
    }

    if (facility.other_files) {
      formDate.other_files = facility.other_files.map((item) => item.uuid);
    }

    if (!editMode) {
      formDate.state_of_location = facility?.state_of_location?.value;

      formDate.company_id = companyId;
      createFacility(formDate)
        .then((res) => {
          resetForm();
          handelCreateFacility(res);
        })
        .catch((e) => {
          if (e?.response?.data) {
            setErrorFacilities(JSON.parse(JSON.stringify(e.response.data)));
          }
        });
    } else {
      formDate.state_of_location = facility.state_of_location.value;
      updateFacility(formDate, facilityData.id)
        .then((res) => {
          resetForm();
          handelCreateFacility(res);
        })
        .catch((e) => {
          if (e?.response?.data) {
            setErrorFacilities(JSON.parse(JSON.stringify(e.response.data)));
          }
        });
    }
  };
  const removeFile = () => {
    setFacility({
      ...facility,
      contract_file: null,
    });
  };
  const handelCloseModal = () => {
    closeModal();
    resetForm();
  };
  return (
    <Modal className="modal-default" isOpen={isOpen} onRequestClose={handelCloseModal} contentLabel="Example Modal" ariaHideApp={false}>
      {loader && <FileLoader />}
      <h2 className="modal-header">
        {facilityData ? 'Edit' : 'Add New'} Facility{' '}
        <button className="btn btn-icon" onClick={handelCloseModal}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>
      {!!companyName && <h3 className="modal-subheader">for {companyName} company</h3>}

      <div className="form-wrapper">
        <h3 className="form-wrapper-title">
          <img src="/images/company-add-icon-4.svg" alt="" />
          Facility information
        </h3>
        <div className={`form-item ${errorFacilities.name ? 'form-item-error' : ''}`}>
          <label className="form-label">Facility Name</label>
          <input
            value={facility.name}
            onChange={(e) => handleFacilityChange(e.target.value, 'name')}
            type="text"
            className="form-control"
            placeholder="Enter the official legal name of the Facility"
          />
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorFacilities.name && errorFacilities.name[0]}</span>
        </div>
        <div className={`form-item form-item-half ${errorFacilities.nickname ? 'form-item-error' : ''}`}>
          <label className="form-label">Facility Nickname</label>
          <input
            id="nickname"
            value={facility.nickname}
            onChange={(e) => handleFacilityChange(e.target.value, 'nickname')}
            type="text"
            className="form-control"
            placeholder="Enter the Facility Brand Name"
          />
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorFacilities.nickname && errorFacilities.nickname[0]}</span>
        </div>
        <div className={`form-item form-item-half ${errorFacilities.license_number ? 'form-item-error' : ''}`}>
          <label className="form-label">License Number</label>
          <input
            value={facility.license_number}
            onChange={(e) => handleFacilityChange(e.target.value, 'license_number')}
            type="text"
            className="form-control"
            placeholder="Enter the Facility Brand Name"
          />
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorFacilities.license_number && errorFacilities.license_number[0]}</span>
        </div>

        <div className={`form-item form-item-select ${errorFacilities.state_of_location ? 'form-item-error' : ''}`}>
          <label className="form-label">State</label>
          <Select
            options={stateUSA}
            className="form-control-select"
            classNamePrefix="react-select"
            value={facility.state_of_location}
            onChange={(e) => handleFacilityChange(e, 'state_of_location')}
            placeholder="Select State"
          ></Select>
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorFacilities.state_of_location && errorFacilities.state_of_location[0]}</span>
        </div>

        <div className={`form-item form-item-mail ${errorFacilities.address ? 'form-item-error' : ''}`}>
          <label className="form-label">Address</label>
          <img className="mail-icon" src="/images/map-pin.svg" alt="" />
          <input
            value={facility.address}
            onChange={(e) => handleFacilityChange(e.target.value, 'address')}
            type="text"
            className="form-control"
            placeholder="Enter the legal address"
          />
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorFacilities.address && errorFacilities.address[0]}</span>
        </div>

        <div className={`form-item ${errorFacilities.monthly_fee ? 'form-item-error' : ''}`}>
          <label className="form-label">Monthly Fee</label>
          <span className="form-control-monthly_fee">
            <input value={facility.monthly_fee} onChange={(e) => handleFacilityChange(e.target.value, 'monthly_fee')} type="text" className="form-control" />
          </span>
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorFacilities.monthly_fee && errorFacilities.monthly_fee[0]}</span>
        </div>
      </div>
      {!isShortEdit && (
        <>
          <div className="form-wrapper">
            <h3 className="form-wrapper-title">
              <img src="/images/company-add-icon-2.svg" alt="" />
              Authorized Officer information
            </h3>
            <div className={`form-item form-item-half ${errorFacilities.signer_name ? 'form-item-error' : ''}`}>
              <label className="form-label">Authorized Officer Name</label>
              <input
                id="signer_name"
                value={facility.signer_name}
                onChange={(e) => handleFacilityChange(e.target.value, 'signer_name')}
                type="text"
                className="form-control"
                placeholder="Enter First Name"
              />
              <img className="danger-icon" src="/images/red-alert.svg" alt="" />
              <span className="form-error">{errorFacilities.signer_name && errorFacilities.signer_name[0]}</span>
            </div>
            <div className={`form-item form-item-half ${errorFacilities.signer_lastname ? 'form-item-error' : ''}`}>
              <label className="form-label">Authorized Officer Last Name</label>
              <input
                id="signer_lastname"
                value={facility.signer_lastname}
                onChange={(e) => handleFacilityChange(e.target.value, 'signer_lastname')}
                type="text"
                className="form-control"
                placeholder="Enter Last Name"
              />
              <img className="danger-icon" src="/images/red-alert.svg" alt="" />
              <span className="form-error">{errorFacilities.signer_lastname && errorFacilities.signer_lastname[0]}</span>
            </div>
            <div className={`form-item form-item-half ${errorFacilities.signer_phone ? 'form-item-error' : ''}`}>
              <label className="form-label">Authorized Officer Phone Number</label>
              {/*<input*/}
              {/*    id="signer_phone"*/}
              {/*    value={facility.signer_phone}*/}
              {/*    onChange={(e) => handleFacilityChange(e.target.value, 'signer_phone')}*/}
              {/*    type="text"*/}
              {/*    className="form-control"*/}
              {/*    placeholder="XXX-XXX-XXXX"*/}
              {/*/>*/}
              <InputMask
                placeholder="XXX-XXX-XXXX"
                mask="999-999-9999"
                value={facility.signer_phone}
                onChange={(e) => handleFacilityChange(e.target.value, 'signer_phone')}
                className="form-control"
              ></InputMask>

              <img className="danger-icon" src="/images/red-alert.svg" alt="" />
              <span className="form-error">{errorFacilities.signer_phone && errorFacilities.signer_phone[0]}</span>
            </div>
            <div className={`form-item form-item-mail form-item-half ${errorFacilities.signer_email ? 'form-item-error' : ''}`}>
              <label className="form-label">Authorized Officer Email</label>
              <img className="mail-icon" src="/images/mail-icon.svg" alt="" />
              <input
                id="signer_email"
                value={facility.signer_email}
                onChange={(e) => handleFacilityChange(e.target.value, 'signer_email')}
                type="email"
                className="form-control"
                placeholder="mail@example.com"
              />
              <img className="danger-icon" src="/images/red-alert.svg" alt="" />
              <span className="form-error">{errorFacilities.signer_email && errorFacilities.signer_email[0]}</span>
            </div>
            <div className="form-item">
              {!facility.contract_file && (
                <div className={`form-control-file form-control-file-default`}>
                  <input accept=".jpg,.webp,.jpeg,.png,.pdf,image/*,.doc,.docx,video/mp4,video/x-m4v,video/*" type="file" id="contract_file" onChange={handleFile} />
                  <div>
                    <img className="icon-icon-upload" src="/images/upload-svg.svg" alt="" />
                    <span>Click to upload</span>
                    <span className="sub-text">You can upload pdf, word and png / jpg</span>
                  </div>

                  {/*{file && <span>{file.name}</span>}*/}
                </div>
              )}
              {facility.contract_file && (
                <>
                  <ul className="file-list">
                    <li className={'align-center'}>
                      <div className="d-flex">
                        <img src="/images/icon-file-list.svg" alt="" />
                        <p>{facility.contract_file.name}</p>
                      </div>
                      <button className="btn btn-icon" onClick={removeFile}>
                        <i className="icon-remove"></i>
                      </button>
                    </li>
                  </ul>
                </>
              )}
              {!facility.contract_file && <p className="text-secondary mt-2">No files uploaded</p>}
              <span className="form-error">{errorFacilities.contract_file && errorFacilities.contract_file[0]}</span>
            </div>
          </div>
          <div className="form-wrapper">
            <h3 className="form-wrapper-title">
              <img src="/images/company-add-icon-3.svg" alt="" />
              Contact Person information
            </h3>
            <div className={`form-item form-item-half ${errorFacilities.contact_person_name ? 'form-item-error' : ''}`}>
              <label className="form-label">Contact Person Name</label>
              <input
                placeholder="Enter First Name"
                id="contact_person_name"
                value={facility.contact_person_name}
                onChange={(e) => handleFacilityChange(e.target.value, 'contact_person_name')}
                type="text"
                className="form-control"
              />
              <img className="danger-icon" src="/images/red-alert.svg" alt="" />
              <span className="form-error">{errorFacilities.contact_person_name && errorFacilities.contact_person_name[0]}</span>
            </div>
            <div className={`form-item form-item-half ${errorFacilities.contact_person_lastname ? 'form-item-error' : ''}`}>
              <label className="form-label">Contact Person Last Name</label>
              <input
                placeholder="Enter Last Name"
                id="contact_person_lastname"
                value={facility.contact_person_lastname}
                onChange={(e) => handleFacilityChange(e.target.value, 'contact_person_lastname')}
                type="text"
                className="form-control"
              />
              <img className="danger-icon" src="/images/red-alert.svg" alt="" />
              <span className="form-error">{errorFacilities.contact_person_lastname && errorFacilities.contact_person_lastname[0]}</span>
            </div>
            <div className={`form-item form-item-half ${errorFacilities.contact_person_phone ? 'form-item-error' : ''}`}>
              <label className="form-label">Contact Person Phone Number</label>
              {/*<input*/}
              {/*    id="contact_person_phone"*/}
              {/*    value={facility.contact_person_phone}*/}
              {/*    onChange={(e) => handleFacilityChange(e.target.value, 'contact_person_phone')}*/}
              {/*    type="text"*/}
              {/*    className="form-control"*/}
              {/*    placeholder="XXX-XXX-XXXX"*/}
              {/*/>*/}
              <InputMask
                placeholder="XXX-XXX-XXXX"
                mask="999-999-9999"
                value={facility.contact_person_phone}
                onChange={(e) => handleFacilityChange(e.target.value, 'contact_person_phone')}
                className="form-control"
              ></InputMask>
              <img className="danger-icon" src="/images/red-alert.svg" alt="" />
              <span className="form-error">{errorFacilities.contact_person_phone && errorFacilities.contact_person_phone[0]}</span>
            </div>
            <div className={`form-item form-item-mail form-item-half ${errorFacilities.contact_person_email ? 'form-item-error' : ''}`}>
              <label className="form-label">Contact Person Email</label>
              <img className="mail-icon" src="/images/mail-icon.svg" alt="" />
              <input
                id="contact_person_email"
                value={facility.contact_person_email}
                onChange={(e) => handleFacilityChange(e.target.value, 'contact_person_email')}
                type="email"
                className="form-control"
                placeholder="mail@example.com"
              />
              <img className="danger-icon" src="/images/red-alert.svg" alt="" />
              <span className="form-error">{errorFacilities.contact_person_email && errorFacilities.contact_person_email[0]}</span>
            </div>
          </div>
        </>
      )}
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={handelCloseModal}>
          Cancel
        </button>
        <button className="btn" onClick={handleSubmit}>
          Save
        </button>
      </div>
    </Modal>
  );
}

export default FacilityModal;
