import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { EmptyPage } from '../other/EmptyPage';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../constants';

function UserGroupTab({ userGroup, openAddModal, totalPage, handlePageClick, filtersUser, facilities, handelFilter, totalItem, onSearch }) {
  const { id } = useParams();
  const [userGroupList, setUserGroupList] = useState(userGroup);
  const [search, setSearch] = useState('');

  const navigate = useNavigate();
  const location = useLocation();
  const [column, setColumn] = useState([
    {
      name: 'Name',
    },
    {
      name: 'Type',
    },
    {
      name: 'Facility',
    },
    {
      name: 'Members',
    },
    {
      name: 'Permissions',
    },
  ]);
  useEffect(() => {
    setColumn([
      {
        name: 'Name',
        total: totalItem,
      },
      {
        name: 'Type',
      },
      {
        name: 'Facility',
      },
      {
        name: 'Members',
      },
      {
        name: 'Permissions',
      },
    ]);
  }, [totalItem]);
  const [filters, setFilters] = useState((prevState) => {
    const newState = filtersUser;
    if (filtersUser) {
      newState['status'] = { value: 'active', label: 'Active' };
      newState['type'] = { value: null, label: 'All' };
    }

    return newState;
  });
  const [filtersIsOpen, setFiltersIsOpen] = useState(false);
  const [facilitiesOption, setFacilitiesOption] = useState([]);
  useEffect(() => {
    setUserGroupList(userGroup);
  }, [userGroup]);

  const clearFilter = () => {
    setFilters({
      facilities_ids: [],
      status: { value: 'active', label: 'Active' },
      search: '',
      type: {
        value: null,
        label: 'All',
      },
    });
  };
  const onFilter = (value, key) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };
  useEffect(() => {
    if (facilities) {
      setFacilitiesOption(facilities.map((item) => ({ value: item.id, label: item.name })));
    }
  }, [facilities]);

  useEffect(() => {
    if (filtersUser) {
      const newFilter = { ...filters };
      for (let key in newFilter) {
        if (typeof newFilter[key] === 'object' && !Array.isArray(newFilter[key]) && newFilter[key] !== null && newFilter[key].value !== null) {
          if (newFilter[key].value) {
            newFilter[key] = newFilter[key].value;
          } else {
            newFilter[key] = dayjs(newFilter[key]).format(DATE_FORMAT);
          }
        }

        if (Array.isArray(newFilter[key])) {
          newFilter[key] = newFilter[key].map((item) => item.value);
        }
      }

      handelFilter(newFilter);
    }

    // eslint-disable-next-line
  }, [filters]);

  return (
    <>
      <div className="library-filter">
        {!filtersUser && (
          <div className="library-filter-search form-control-item">
            <input
              type="text"
              className="form-control"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                onSearch(e.target.value);
              }}
              placeholder="Search"
            />
            <i className="icon-search"></i>
          </div>
        )}
        {filtersUser && (
          <>
            <div className="library-filter-search form-control-item">
              <input type="text" className="form-control" value={filters.search} onChange={(e) => onFilter(e.target.value, 'search')} placeholder="Search" />
              <i className="icon-search"></i>
            </div>
            <div>
              <button className="btn btn-outline" onClick={() => setFiltersIsOpen(!filtersIsOpen)}>
                <i className="icon-icon-sliders-rotate"></i>
              </button>
            </div>
          </>
        )}
        <div>
          <button className="btn" onClick={openAddModal}>
            <i className="icon-plus-svgrepo-com mr-2"></i> Add Custom User Group
          </button>
        </div>
      </div>
      {filtersIsOpen && (
        <div className="library-filters">
          <div className="library-filters-wrapper">
            <div className="form-item">
              <label className="form-label">Facility</label>
              <Select
                isMulti
                hideSelectedOptions={false}
                options={facilitiesOption}
                value={filters.facilities_ids}
                onChange={(e) => onFilter(e, 'facilities_ids')}
                className="form-control-select"
                classNamePrefix="react-select-multi"
              ></Select>
            </div>
            <div className="form-item">
              <label className="form-label">User group type</label>
              <Select
                options={[
                  {
                    value: null,
                    label: 'All',
                  },
                  {
                    value: 'CUSTOM',
                    label: 'Custom',
                  },
                  {
                    value: 'DEFAULT',
                    label: 'Default',
                  },
                ]}
                value={filters.type}
                onChange={(e) => onFilter(e, 'type')}
                className="form-control-select"
                classNamePrefix="react-select"
              ></Select>
            </div>
          </div>

          <button className="btn btn-outline" onClick={clearFilter}>
            Clear All
          </button>
        </div>
      )}
      {userGroupList.length > 0 && (
        <div className="table-responsive table-default table-full">
          <div className="table-wrapper">
            <div className="table">
              <div className="tr">
                {column.map((item, index) => (
                  <div className={`th ${item.name === 'Members' ? 'th-center' : ''}`} key={index}>
                    {item.name}
                    {!!item.total && <span className="th-total">(Total: {item.total} user groups)</span>}
                  </div>
                ))}
              </div>
              {userGroupList.map((item) => (
                <div
                  className={`tr cursor-pointer ${item.deleted_at ? 'tr-deactivated' : ''}`}
                  key={item.id}
                  onClick={() => navigate(`/company/${id}/${location.pathname.includes('user-groups') ? 'user-groups' : 'user-group'}/${item.id}`)}
                >
                  <div className="td td-name">{item.name}</div>
                  <div className="td">{item.type}</div>
                  <div className="td td-facility">{item.facility.name}</div>
                  <div className="td td-center">{item.users_count}</div>
                  <div className="td td-action">
                    {item.actions?.map((action) => (
                      <div key={action.id}>{action.name}</div>
                    ))}
                  </div>
                </div>
              ))}
            </div>

            {totalPage > 1 && (
              <ReactPaginate
                className={'react-pagination'}
                breakLabel="..."
                nextLabel="next ->"
                pageRangeDisplayed={5}
                pageCount={totalPage}
                previousLabel="<- previous"
                onPageChange={handlePageClick}
              />
            )}
          </div>
        </div>
      )}

      {!userGroupList.length && !search && (
        <EmptyPage
          imageUrl={'/images/empty-user-group.png'}
          title={'At the moment, there are no user groups'}
          subtitle={'Simply click the button “Add User Group”  to start the user group creation process'}
        />
      )}
      {!userGroupList.length && search && (
        <EmptyPage
          imageUrl={'/images/no-resoult.png'}
          title={'Unfortunately, nothing was found for your query.'}
          subtitle={"Try to change the range of keywords. We're here to help you find what you're looking for."}
        />
      )}
    </>
  );
}

export default UserGroupTab;
