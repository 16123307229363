import React, { useEffect, useRef } from 'react';
import SignaturePad from 'react-signature-canvas';
import { useIsRequiredElement } from '../../../hook/useIsRequiredElement';
import ElementErrorMessage from './components/element-error-message';

export const SignatureElement = function FromElement({ element, handlerChange, error }) {
  const isRequiredElement = useIsRequiredElement(element);
  const sigPad = useRef();

  useEffect(() => {
    if (sigPad.current) {
      if (element.options.valueInput) {
        sigPad.current.fromDataURL(element.options.valueInput);
      }

      console.log(sigPad.current);
      // function resizeCanvas() {
      //     const ratio =  Math.max(window.devicePixelRatio || 1, 1);
      //     sigPad.current.getCanvas().width = sigPad.current.offsetWidth * ratio;
      //     sigPad.current.getCanvas().height = sigPad.current.offsetHeight * ratio;
      //     sigPad.current.getCanvas().getContext("2d").scale(ratio, ratio);
      // }
      //
      // window.addEventListener("resize", resizeCanvas);
      //
      // resizeCanvas();
      //
      // return () => window.removeEventListener('resize', resizeCanvas);
    }
    // eslint-disable-next-line
  }, [sigPad]);

  const onDrawEnd = () => {
    if (sigPad.current) {
      handlerChange(element, sigPad.current.toDataURL());
    }
  };

  const Clear = () => {
    sigPad.current.clear();
  };
  return (
    <>
      <label htmlFor={element.id} className="form-label">
        {element.options.label} <span className="required">{isRequiredElement ? '*' : ''}</span>
      </label>
      <div className={`sigCanvas-wrapper sigCanvas-${element.options.size}`}>
        <SignaturePad
          className={`${element.options.readOnly ? 'readonly' : ''}`}
          ref={sigPad}
          penColor="black"
          clearOnResize={false}
          canvasProps={{ className: `sigCanvas` }}
          onEnd={onDrawEnd}
        />
      </div>

      <span className={`sigCanvas-clear ${element.options.readOnly ? 'readonly' : ''}`} onClick={Clear}>
        Clear
      </span>

      <ElementErrorMessage message={error} />
      {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}
    </>
  );
};
