import React, { useEffect, useRef, memo, useState, Fragment } from 'react';
import Select from 'react-select';
import ResizableGrid from '../../../utility/resizebleTable';
import cx from 'classnames';
import { debounce, get, cloneDeep } from 'lodash';
import { FORM_CREATOR } from '../../../constants/roles';

export const TABLE_COLUMN_MIN_WIDTH = 50;
let timeoutId;

export const TableElement = memo(function FromElement({ element, handlerChangeWidth, isActive, handleChangeTableColumn }) {
  // { [rowId]: { [colId]: '' } }
  const [colsValue, setColsValue] = useState({});

  const refTable = useRef();
  const refTableWrapper = useRef();

  const handlerChange = (width, n) => {
    refTableWrapper.current.style.width = refTable.current.offsetWidth + 'px';
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      handlerChangeWidth(n.id, width, element.id);
    }, 300);
  };

  useEffect(() => {
    ResizableGrid(refTable.current, handlerChange);
    refTableWrapper.current.style.width = refTable.current.offsetWidth + 'px';

    //eslint-disable-next-line
  }, [refTable, element]);

  useEffect(() => {
    if (element?.rows) {
      const newColsValue = element?.rows.reduce((acc, row) => {
        if (row?.cols) {
          acc[row.id] = {};
          row.cols.forEach((col) => {
            if (col) {
              const { id: colId, value } = col;
              acc[row.id][colId] = value;
            }
          });
        }

        return acc;
      }, {});
      setColsValue(newColsValue);
    }
  }, [element?.rows]);

  const debouncedHandleChange = useRef(
    debounce((value, rowId, colId) => {
      if (element && rowId) {
        const colIndex = element.cols?.findIndex((col) => col.id === colId);
        if (typeof colIndex === 'number' && colIndex !== -1) {
          const newElement = cloneDeep(element);
          const col = newElement.cols[colIndex];
          const rowIndex = element.rows?.findIndex((row) => row.id === rowId);
          const row = get(element, `rows[${rowIndex}]`);

          if (typeof rowIndex === 'number' && rowIndex !== -1) {
            const newRowCols = Array.isArray(row.cols) ? cloneDeep(row.cols) : [];
            const newRowCol = cloneDeep(col);
            if (value) {
              newRowCol.value = value;
              newRowCol.filledByRole = FORM_CREATOR;
            } else {
              delete newRowCol.value;
              delete newRowCol.filledByRole;
            }

            newRowCols[colIndex] = newRowCol;

            newElement.rows[rowIndex].cols = newRowCols;

            handleChangeTableColumn({ elementId: element.id, rowIndex, colIndex, col: newRowCol });
          }
        }
      }
    }, 300),
  );

  const handleChangeSingleLine = (e, rowId, colId) => {
    const value = e.target.value;
    setColsValue((prevState) => {
      const newState = { ...prevState };
      if (!newState[rowId]) {
        newState[rowId] = {};
      }
      newState[rowId][colId] = value;
      return newState;
    });
    debouncedHandleChange.current(value, rowId, colId);
  };

  const renderTHead = () => {
    return (
      <thead>
        <tr>
          {!element.options.showQuestions && (
            <th
              className={cx('first-section', { 'th-disabled': !isActive })}
              style={{
                minWidth: TABLE_COLUMN_MIN_WIDTH,
                width: element?.options?.width,
                maxWidth: element?.options?.width,
              }}
            >
              {element.options.rowHeaderTitle ? element.options.rowHeaderTitle : 'Question'}
            </th>
          )}
          {element.cols.map((col) => {
            return (
              <th id={col.id} key={col.id} className={cx({ 'th-disabled': !isActive })} style={{ width: col.width, maxWidth: col.width, minWidth: TABLE_COLUMN_MIN_WIDTH }}>
                {col.label}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  const renderTBodyRowCol = (row) => {
    return (
      <>
        {!element.options.showQuestions && <td className={'first-section'}>{row.label}</td>}
        {element.cols.map((col) => {
          const colType = col?.type?.value;
          // From state
          const value = get(colsValue, `[${row?.id}][${col?.id}]`);
          let inputLayout;

          if (colType) {
            switch (colType) {
              case 'dropdown': {
                inputLayout = <Select menuPosition={'fixed'} classNamePrefix="react-select" className="form-control-select" options={col.options} />;
                break;
              }
              case 'current-user': {
                inputLayout = <button className={'btn btn-outline btn-outline-secondary btn-signature'} />;
                break;
              }
              case 'single-line': {
                inputLayout = (
                  <input value={value} placeholder={'Enter Text'} type="text" className="form-control single-line" onChange={(e) => handleChangeSingleLine(e, row?.id, col.id)} />
                );
                break;
              }
              default: {
                inputLayout = <input type="text" className="form-control" />;
              }
            }
          }

          return (
            <td key={col.id} className={cx({ 'current-user': col?.type?.value })}>
              {inputLayout}
            </td>
          );
        })}
      </>
    );
  };

  const renderTBody = () => {
    return (
      <tbody>
        {element.rows.map((row, rowIndex) => (
          <Fragment key={row?.id ?? rowIndex}>{row && <tr>{renderTBodyRowCol(row)}</tr>}</Fragment>
        ))}
      </tbody>
    );
  };

  return (
    <>
      <div className="form-control-table-wrapper">
        <div id={element.id} ref={refTableWrapper} className="form-control-table-responsive">
          <label htmlFor={element.id} className="form-label w-full">
            {element.options.label} <span className="required">{element.options.requiredFor ? '*' : ''}</span>
          </label>
          <div className={`form-control-table-alignment-wrapper ${element.options.tableAlignment}`}>
            <div style={{ display: 'inline-block' }}>
              <table id={element.id} ref={refTable} className={cx('form-control-table')} style={{ tableLayout: 'fixed' }}>
                {renderTHead()}
                {renderTBody()}
              </table>
            </div>
          </div>
        </div>
      </div>
      {element.options.addRowsButton && <button className={'btn btn-text ml-auto d-flex'}>Add New Row</button>}
      {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}
    </>
  );
});
