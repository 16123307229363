import React, { useState, useEffect } from 'react';
import Layout from '../../layout/default';
import { Breadcrumbs } from '../../components/library/Breadcrumbs';

import { Link, useNavigate } from 'react-router-dom';
import { getGeneralDrafts, removeGeneralDrafts } from '../../servises/folder/form-avaliable';
import { useAuth } from '../../container/authContext';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import DeactivateModal from '../../components/company/deactivate-modal';
import { DraftItem } from '../../components/library/draft-item';
function CorporateLibrary() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [breadcrumbs] = useState([
    {
      name: 'General Library Forms',
      url: '/general-library',
    },
    {
      name: 'Drafts',
      url: '/drafts',
    },
  ]);

  const [formsSearch, setFormsSearch] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  useEffect(() => {
    changeFolder();
    // eslint-disable-next-line
  }, [user]);

  const goToPrev = (id, type) => {
    if (type === 'root') {
      navigate(id, { replace: true });
    }
  };

  const changeFolder = () => {
    getGeneralDrafts()
      .then((res) => {
        setFormsSearch(res);
      })
      .catch((e) => console.log(e));
  };
  const handleDeactivateIsOpen = () => {
    setShowModal(false);
  };
  const handelRemove = () => {
    handleDeactivateIsOpen();
    removeGeneralDrafts(currentId)
      .then(() => {
        changeFolder();
      })
      .catch((e) => console.log(e));
  };

  return (
    <Layout title="General Library - drafts">
      <div className="library">
        <DndProvider backend={HTML5Backend}>
          <Breadcrumbs breadcrumbs={breadcrumbs} goToLevel={goToPrev} />
        </DndProvider>
        <h2 className="library-title">
          <Link to={'/general-library/forms/'} className="mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M15.8337 10.0013H4.16699M4.16699 10.0013L10.0003 15.8346M4.16699 10.0013L10.0003 4.16797"
                stroke="#101828"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>
          Drafts
        </h2>

        <>
          {formsSearch?.length > 0 && (
            <>
              <h3 className="library-subtitle mt-2">Forms</h3>
              <div className="library-wrapper">
                {formsSearch.map((item) => (
                  <DraftItem item={item} key={item.id} url={`/form-builder/${item.id}/constructor`} setCurrentId={setCurrentId} setShowModal={setShowModal}></DraftItem>
                ))}
              </div>
            </>
          )}
          {!formsSearch?.length && (
            <>
              <div className="empty-page">
                <h3 className="empty-page-title">This folder is empty</h3>
                <h4 className="empty-page-subtitle">This folder currently does not contain any forms. </h4>
              </div>
            </>
          )}
        </>
        <DeactivateModal
          isOpen={showModal}
          closeModal={handleDeactivateIsOpen}
          handelSuccess={handelRemove}
          button={'Remove'}
          title="Remove Form Draft"
          subtitle="Are you sure you want to remove this form draft?&nbsp; &nbsp; &nbsp; &nbsp;  It will be unavailable then."
        ></DeactivateModal>
      </div>
    </Layout>
  );
}

export default CorporateLibrary;
