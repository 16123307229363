/**
 * @param {string} utcDateString - "YYYY-MM-DDTHH:mm:ss".
 * @returns {Date}.
 * @throws {Error}.
 */
export const utcToLocalDate = (utcDateString) => {
  let localDate;
  try {
    if (utcDateString) {
      const utcDate = new Date(utcDateString);
      if (!(utcDate instanceof Date) || isNaN(utcDate.getTime())) {
        throw new Error(`Invalid UTC date: ${utcDateString}`);
      }
      localDate = new Date(utcDate + ' UTC');
    }
  } catch (e) {
    console.log(e);
  }
  return localDate;
};
