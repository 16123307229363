import client from '../api';
import axios from 'axios';
import qs from 'qs';

const CancelToken = axios.CancelToken;
let cancel;
let cancelReport;

export const getAvailableFolder = async (params) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`/api/corporate-library/forms/folders/forms-available-folders/`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const getAvailableGeneralFolder = async (params) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`/api/general-library/forms/folders/place-in-form/`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const getShareGeneralFolder = async (params) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`/api/general-library/file/tree-of-company-file-folders/`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const shareGeneralFolder = async (data) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.post(`/api/general-library/file/share-with-company/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const getPublicFolder = async (params) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`/api/corporate-library/forms/folders/public-forms-folders/`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const getCorporateAvailableToMeForms = async (params) => {
  try {
    if (cancel !== undefined) {
      cancel();
    }
    return await client.get(`/api/corporate-library/forms-available/`, {
      params,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
  }
};

export const getPublicFolderForms = async (params) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`/api/corporate-library/public-forms/`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const archiveInitiatedForm = async (id) => {
  try {
    if (cancel !== undefined) {
      cancel();
    }

    return await client.delete(`/api/corporate-library/initiated-forms/${id}/`, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
  }
};

export const activateInitiatedForm = async (formId) => {
  try {
    if (cancel !== undefined) {
      cancel();
    }

    return await client.patch(`/api/corporate-library/initiated-forms/${formId}/restore/`, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
  }
};

export const deleteInitiatedForm = async (id) => {
  try {
    if (cancel !== undefined) {
      cancel();
    }
    return await client.delete(`/api/corporate-library/initiated-forms/${id}/hard-delete`, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
  }
};

export const getDrafts = async (params) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`/api/corporate-library/drafts/`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const getGeneralDrafts = async (params) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`/api/general-library/forms/explorer/drafts/`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const removeDrafts = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }
  return await client.delete(`/api/corporate-library/forms/${id}/hard-delete/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
};
export const removeGeneralDrafts = async (id) => {
  try {
    if (cancel !== undefined) {
      cancel();
    }
    return await client.delete(`/api/general-library/forms/${id}`, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
  }
};

export const getCorporateAvailableFolderSearch = async (params) => {
  const response = await client.get(`/api/corporate-library/forms-available/search/`, {
    params,
  });

  return response.data;
};
export const getGeneralFolderSearch = async (params) => {
  const response = await client.get(`/api/general-library/forms/`, {
    params,
  });

  return response.data;
};

export const getFilesSearch = async (params) => {
  const response = await client.get(`/api/corporate-library/files/`, {
    params,
  });

  return response.data;
};

export const getReportFormSearch = async (params) => {
  const response = await client.get(`/api/corporate-library/files/`, {
    params,
  });

  return response.data;
};

export const getFilesGeneralSearch = async (params) => {
  const response = await client.get(`/api/general-library/file/`, {
    params,
  });

  return response.data;
};

export const getPublicFolderSearch = async (params) => {
  try {
    const response = await client.get(`/api/corporate-library/public-forms/search/`, {
      params,
    });

    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const createFolder = async (data) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.post(`/api/corporate-library/forms/folders/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const createGeneralFolder = async (data) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.post(`/api/general-library/forms/folders/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const createFilesFolder = async (data) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.post(`/api/corporate-library/files/folders/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const createFilesGeneralFolder = async (data) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.post(`/api/general-library/file/folders/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const createFiles = async (data) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.post(`/api/corporate-library/files/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const createGeneralFiles = async (data) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.post(`/api/general-library/file/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const editFolder = async (data, id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.patch(`/api/corporate-library/forms/folders/${id}/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const editGeneralFolder = async (data, id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.patch(`/api/general-library/forms/folders/${id}/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const editFilesFolder = async (data, id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.patch(`/api/corporate-library/files/folders/${id}/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const editFilesGeneralFolder = async (data, id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.patch(`/api/general-library/file/folders/${id}/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const deleteFolder = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.delete(`/api/corporate-library/forms/folders/${id}/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const deleteGeneralFolder = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.delete(`/api/general-library/forms/folders/${id}/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const deleteFiles = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.delete(`/api/corporate-library/files/${id}/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const deleteGeneralFiles = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.delete(`/api/general-library/file/${id}/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const deleteFilesFolder = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.delete(`/api/corporate-library/files/folders/${id}/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const deleteFilesGeneralFolder = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.delete(`/api/general-library/file/folders/${id}/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const activeFilesGeneral = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.patch(`/api/general-library/file/${id}/restore/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const getFormsAssignedToMe = async (params) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`/api/corporate-library/forms-assigned-to-me/`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const getFormsInProgress = async (params) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`/api/corporate-library/forms-in-progress/`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const getSubmissions = async (params) => {
  try {
    if (cancel !== undefined) {
      cancel();
    }
    return await client.get(`/api/corporate-library/submissions-folder/forms/`, {
      params,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
  }
};

export const getSubmissionsProfile = async (params) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`/api/users/my-submissions/forms/`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const getSubmissionsProfileById = async (user_id, params) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`/api/users/${user_id}/submissions/forms/`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const getSubmissionsBuilder = async (params) => {
  if (cancel !== undefined) {
    cancel();
  }
  const response = await client.get(`/api/corporate-library/report-builder/forms/`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
  return response.data;
};

export const getReportBuilder = async (params) => {
  if (cancelReport !== undefined) {
    cancelReport();
  }

  const response = await client.get(`/api/corporate-library/report-builder/`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancelReport = c;
    }),
  });

  return response.data;
};

export const getSubmission = async (corporate_form_id, params) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`/api/corporate-library/submissions-folder/forms/${corporate_form_id}/`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });

  return response.data;
};

export const getSubmissionProfile = async (corporate_form_id, params) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`/api/users/my-submissions/${corporate_form_id}/`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });

  return response.data;
};
export const getSubmissionProfileById = async (corporate_form_id, user_id, params) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`/api/users/${user_id}/submissions/${corporate_form_id}/`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });

  return response.data;
};

export const getSubmissionItem = async (corporate_form_id, id, params) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`/api/corporate-library/submissions-folder/forms/${corporate_form_id}/${id}/submission/`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });

  return response.data;
};

export const getGeneralStatic = async (params) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`/api/general-library/forms/explorer/`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const getFilesFoler = async (params) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`/api/corporate-library/files/explorer/`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const getFilesGeneralFolder = async (params) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`/api/general-library/file/explorer/`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const generateSubmissionFormsPDF = async (args) => {
  try {
    if (cancel !== undefined) {
      cancel();
    }
    const { formID, params, requestBody } = args;
    return await client.post(`/api/corporate-library/submissions-folder/forms/${formID}/export-to-pdf/`, requestBody, {
      params,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
  }
};
