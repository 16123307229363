import React from 'react';
import { TextElementSettings } from './elemments/text-element-settings';
import { TitleElementSettings } from './elemments/title-element-settings';
import { SingleLineElementSettings } from './elemments/single-line-element-settings';
import { MultilineElementSettings } from './elemments/multiline-element-settings';
import { NumberElementSettings } from './elemments/number-element-settings';
import { PhoneElementSettings } from './elemments/phone-element-settings';
import { EmailElementSettings } from './elemments/email-element-settings';
import { FullNameElementSettings } from './elemments/full-name-element-settings';
import { AddressElementSettings } from './elemments/address-element-settings';
import { DateElementSettings } from './elemments/date-element-settings';
import { TimeElementSettings } from './elemments/time-element-settings';
import { DropdownElementSettings } from './elemments/dropdown-element-settings';
import { CheckboxElementSettings } from './elemments/checkbox-element-settings';
import { LinkElementSettings } from './elemments/link-element-settings';
import { RatingElementSettings } from './elemments/rating-element-settings';
import { SignatureElementSettings } from './elemments/signature-element-settings';
import { TableElementSettings } from './elemments/table-element-settings';
import { SingleLineHeaderElementSettings } from './elemments/single-line-header-element-settings';
import { SubmittersFieldElementSettings } from './elemments/submitters-field-element-settings';
import { FileUploadElementSettings } from './elemments/file-upload-element-settings';
import { LoginElementSettings } from './elemments/login-element-settings';
import { FileAttachElementSettings } from './elemments/file-attach-element-settings';

export const FormBuilderElementSettings = ({ element, isOpen, onClose, handlerChangeOption, handlerChangeTitle, duplicateElement, saveElement }) => {
  return (
    <div className={`form-builder-aside-property ${isOpen ? 'form-builder-aside-property-open' : ''}`}>
      {element && (
        <>
          <h2 className="form-builder-aside-property-title">
            {element.name} Properties{' '}
            <button className="btn btn-icon" onClick={onClose}>
              <i className="icon-icon-x"></i>
            </button>
          </h2>
          <div className="tabs">
            <div className="tabs-content">
              {element.type === 'title' && <TitleElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeTitle} />}
              {element.type === 'text' && <TextElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />}
              {element.type === 'single-line' && <SingleLineElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />}
              {element.type === 'login' && <LoginElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />}
              {element.type === 'single-line-header' && (
                <SingleLineHeaderElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />
              )}
              {element.type === 'submitters-field' && (
                <SubmittersFieldElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />
              )}
              {element.type === 'signature' && <SignatureElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />}
              {element.type === 'multiline-line' && <MultilineElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />}
              {element.type === 'number' && <NumberElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />}
              {element.type === 'phone' && <PhoneElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />}
              {element.type === 'email' && <EmailElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />}
              {element.type === 'name' && <FullNameElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />}
              {element.type === 'address' && <AddressElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />}
              {element.type === 'date' && <DateElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />}
              {element.type === 'time' && <TimeElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />}
              {element.type === 'link' && <LinkElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />}
              {element.type === 'rating' && <RatingElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />}
              {element.type === 'file-upload' && <FileUploadElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />}
              {element.type === 'file-attach' && <FileAttachElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />}
              {element.type === 'dropdown' && (
                <DropdownElementSettings
                  element={element}
                  duplicateElement={duplicateElement}
                  onClose={onClose}
                  saveElement={saveElement}
                  handlerChangeOption={handlerChangeOption}
                />
              )}
              {element.type === 'checkbox' && (
                <CheckboxElementSettings
                  element={element}
                  duplicateElement={duplicateElement}
                  onClose={onClose}
                  saveElement={saveElement}
                  handlerChangeOption={handlerChangeOption}
                />
              )}
              {element.type === 'radiobutton' && (
                <CheckboxElementSettings
                  element={element}
                  duplicateElement={duplicateElement}
                  onClose={onClose}
                  saveElement={saveElement}
                  handlerChangeOption={handlerChangeOption}
                />
              )}
              {element.type === 'table' && (
                <TableElementSettings element={element} duplicateElement={duplicateElement} onClose={onClose} saveElement={saveElement} handlerChangeOption={handlerChangeOption} />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
