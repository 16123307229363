import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePermission } from '../../container/permissionContext';

function TabsMobile() {
  const location = useLocation();
  const navigate = useNavigate();
  const { isSuperAdmin, isCompanySystemAdministrator } = usePermission();
  return (
    <div className={'tabs-mobile'}>
      <div
        className={`tabs-mobile-item ${location.pathname.includes('forms-available-to-me') ? 'active' : ''}`}
        onClick={() => navigate(`/corporate-library/forms/forms-available-to-me`)}
      >
        Available
      </div>
      {!(isSuperAdmin() || isCompanySystemAdministrator()) && (
        <>
          <div
            className={`tabs-mobile-item ${location.pathname.includes('forms-assigned-to-me') ? 'active' : ''}`}
            onClick={() => navigate(`/corporate-library/forms/forms-assigned-to-me`)}
          >
            Assigned
          </div>
          <div
            className={`tabs-mobile-item ${location.pathname.includes('forms-in-progress') ? 'active' : ''}`}
            onClick={() => navigate(`/corporate-library/forms/forms-in-progress`)}
          >
            In progress
          </div>
        </>
      )}

      <div className={`tabs-mobile-item ${location.pathname.includes('public-forms') ? 'active' : ''}`} onClick={() => navigate(`/corporate-library/forms/public-forms`)}>
        Public
      </div>
    </div>
  );
}

export default TabsMobile;
