import React, { useEffect, useState } from 'react';
import Layout from '../../layout/default';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../container/authContext';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { getFacilitiesByCompany } from '../../servises/facility';
import { getCompanyUnit } from '../../servises/company';
import ResidentTab from '../../components/company/resident-tab';
import ResidentModal from '../../components/company/resident-modal';
import { getCompanyContactsList, importContact } from '../../servises/contacts';
import { usePermission } from '../../container/permissionContext';
import { redirectToLibrary } from '../../utility';
import ImportModal from '../../components/company/import-modal';

function CompanyList() {
  const { id } = useParams();
  const [users, setUsers] = useState([]);
  const [userPage, setUserPage] = useState(1);
  const [totalUserPage, setTotalUserPage] = useState([]);
  const [totalUser, setTotalUser] = useState(0);
  const navigation = useNavigate();
  const [filtersUser, setFiltersUser] = useState({
    facilities_ids: [],
    units_ids: [],
    date_added_before: '',
    date_added_after: '',
    status: 'active',
    search: '',
  });
  const [modalIsOpen, setIsOpen] = useState(false);
  const { user } = useAuth();
  const [facilities, setFacilities] = useState([]);
  const [units, setUnits] = useState([]);
  const [pageBreadcrumbs] = useState([
    {
      url: `/company/${id}`,
      name: `${user.company.name}`,
    },
    {
      url: `/company/${id}/residents`,
      name: 'Resident List',
    },
  ]);
  const { isEmployee, hasPermission } = usePermission();
  useEffect(() => {
    if (isEmployee() && !hasPermission('Contacts management')) {
      redirectToLibrary(navigation);
    }
  }, [isEmployee, navigation, hasPermission]);
  const [defaultIndex, setDefaultIndex] = useState(1);

  useEffect(() => {
    initPage();
    // eslint-disable-next-line
  }, [id, userPage, filtersUser]);

  const handlePageUserClick = (event) => {
    setUserPage(event.selected + 1);
  };
  const initPage = () => {
    let params = {
      page: userPage,
      ...filtersUser,
    };

    getCompanyContactsList(id, params)
      .then((resUsers) => {
        setUsers(resUsers.results);
        setTotalUserPage(resUsers.total_pages);
        setTotalUser(resUsers.count);
      })
      .catch((e) => console.log(e));
    let action = null;

    if (isEmployee()) {
      action = 'contacts_management';
    }
    getFacilitiesByCompany(id, 'getAll', action)
      .then((resFacilities) => {
        setFacilities(resFacilities);
      })
      .catch((e) => console.log(e));

    getCompanyUnit(id, 'getAll', filtersUser.facilities_ids)
      .then((res) => {
        setUnits(res);
      })
      .catch((e) => console.log(e));
  };
  function closeAddModal() {
    setIsOpen(false);
  }
  const handelCreateUser = () => {
    initPage();
    closeAddModal();
  };
  const openAddModal = () => {
    setIsOpen(true);
  };

  const applyFilters = (filters) => {
    setFiltersUser(filters);
  };

  const [importModalIsOpen, setImportModalIsOpen] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [errors, setErrors] = useState(null);

  const handelImportUser = (file) => {
    if (!file) {
      setIsPending(false);
      setErrors(null);
      return;
    }

    setIsPending(true);
    setErrors(null);
    const formData = new FormData();
    formData.append('file', file.file);

    importContact(formData, id)
      .then((res) => {
        initPage();
        closeImportModal();
        setIsPending(false);
      })
      .catch((e) => {
        if (e?.response?.data) {
          setErrors(e?.response?.data);
        }
        setIsPending(false);
      });
  };
  function closeImportModal() {
    setImportModalIsOpen(false);
  }

  return (
    <Layout title={'Residents List'} breadcrumbs={pageBreadcrumbs}>
      <div className={`page-wrapper`}>
        <div className="company-profile">
          <div className="company-profile-tab">
            <Tabs selectedIndex={defaultIndex} onSelect={(index) => setDefaultIndex(index)}>
              <TabList>
                <Tab
                  disabled={isEmployee() && !hasPermission('Users, Positions management')}
                  onClick={() => {
                    if (hasPermission('Users, Positions management')) {
                      navigation(`/company/${id}/users`);
                    }
                    if (!isEmployee()) {
                      navigation(`/company/${id}/users`);
                    }
                  }}
                >
                  System Users
                </Tab>
                <Tab>Residents</Tab>
              </TabList>

              <TabPanel>
                {/*<h3 className="company-profile-title">Users</h3>*/}
                Users
              </TabPanel>
              <TabPanel>
                <ResidentTab
                  units={units}
                  facilities={facilities}
                  handelFilter={applyFilters}
                  filtersUser={filtersUser}
                  totalUser={totalUser}
                  onUpdate={handelCreateUser}
                  totalPage={totalUserPage}
                  handlePageClick={handlePageUserClick}
                  users={users}
                  openAddModal={openAddModal}
                  openImport={setImportModalIsOpen}
                ></ResidentTab>
                <ImportModal
                  handelError={setErrors}
                  isPending={isPending}
                  onSubmit={handelImportUser}
                  errors={errors}
                  closeModal={closeImportModal}
                  isOpen={importModalIsOpen}
                  title={'Import Contact from File'}
                ></ImportModal>

                <ResidentModal
                  companyName={user.company.name}
                  facilities={facilities}
                  closeModal={closeAddModal}
                  companyId={id}
                  isOpen={modalIsOpen}
                  openModal={openAddModal}
                  handelCreateUser={handelCreateUser}
                />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default CompanyList;
