import React, { useState } from 'react';
import LoginModal from '../login-modal';
import cx from 'classnames';
import { useIsRequiredElement } from '../../../hook/useIsRequiredElement';
import ElementErrorMessage from './components/element-error-message';

export const LoginElement = function FromElement({ element, handlerChange, addNewLogin, error }) {
  const isRequiredElement = useIsRequiredElement(element);
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    if (!element.options.valueInput) {
      setIsOpen(true);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handelChange = (userInfo) => {
    handlerChange(element, userInfo);
    closeModal();
  };

  return (
    <div className={cx('form-control-item form-control-item-login', { [`alignment-${element.options.alignment}`]: element.options.alignment })}>
      <label htmlFor={element.id} className="form-label">
        {element.options.label} <span className="required">{isRequiredElement ? '*' : ''}</span>
      </label>
      <div
        className={cx({
          [`form-control-${element.options.size}`]: !!element.options.size,
        })}
      >
        <input onClick={openModal} value={element.options.valueInput} readOnly name={element.options.name} id={element.id} type="text" className={`form-control`} />
        {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}

        {element?.options?.canAddNew && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <span className="add-facility" onClick={() => addNewLogin(element)}>
              Add New
            </span>
          </div>
        )}
      </div>
      <ElementErrorMessage message={error} />
      {isOpen && <LoginModal element={element} title={'User Login'} isOpen={isOpen} closeModal={closeModal} handelSuccess={handelChange} />}
    </div>
  );
};
