import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { usePermission } from '../../../../container/permissionContext';
import { getFileGeneralInfo, getFileInfo } from '../../../../servises/facility';
import { getFileById } from '../../../../servises/upload';
import FileLoader from '../../../other/FileLoader';
import { useDrag } from 'react-dnd';
import cx from 'classnames';
import { patchFile } from '../../../../servises/libraries/corporate-library';
import { isResponseOk } from '../../../../utility/isResponseOk';
import { getFileExtFromFileName } from '../../../../utility/files/getFileExtFromFileName';
import { isWebVideoByFileExt } from '../../../../utility/files/isWebVideoByFileExt';

export const FileWithDnd = (props) => {
  const { item, url, deleteFile, isGeneral, isAttach, handelAttach, file, onFilePreview, moveFile } = props;
  const { hasPermissionByCodeName, isSuperAdmin, isCompanySystemAdministrator } = usePermission();

  const [loader, setLoader] = useState(false);
  const [isOpenTooltip, setIsOpenTooltip] = useState(false);

  const [, drag] = useDrag(() => ({
    type: 'file',
    item: { item },
    canDrag: (isSuperAdmin() || isCompanySystemAdministrator()) && !!hasPermissionByCodeName('corporate_library_management'),
    end: async (item, monitor) => {
      try {
        const dropResult = monitor.getDropResult();
        if (dropResult?.item) {
          if (dropResult?.item.id !== item.item.id) {
            const dragFile = item.item;
            const dropFolder = dropResult?.item;

            const patchFileRes = await patchFile(dragFile.id, { ...dragFile, folder_id: dropFolder.id });

            if (isResponseOk(patchFileRes)) {
              moveFile(dropFolder.id);
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const downloadFile = ({ name, downloadUrl }) => {
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = name;
    a.rel = 'noreferrer';
    a.target = '_blank';
    a.click();
  };

  const handleClickFile = () => {
    setIsOpenTooltip(false);
    if (url) {
      if (isGeneral) {
        if (!isAttach) {
          setLoader(true);
          getFileGeneralInfo(item.id)
            .then((fileInfoRes) => {
              getFileById(fileInfoRes.file.uuid)
                .then((res) => {
                  const fileData = { ...fileInfoRes, url: res.message, downloadUrl: res.message };
                  downloadFile(fileData);
                })
                .catch((e) => {
                  console.log(e);
                  setLoader(false);
                });
            })
            .catch((e) => {
              console.log(e);
              setLoader(false);
            });
        } else {
          handelAttach(item);
        }
      } else {
        if (!isAttach) {
          setLoader(true);
          getFileInfo(item.id)
            .then((fileInfoRes) => {
              if (fileInfoRes.file) {
                const fileExtension = getFileExtFromFileName(fileInfoRes?.file?.name);
                const isWebVideo = isWebVideoByFileExt(fileExtension);
                getFileById(fileInfoRes.file.uuid)
                  .then((fileByIdRes) => {
                    if (fileByIdRes?.config === 'success' && fileByIdRes?.message) {
                      const fileData = { ...fileInfoRes, url: fileByIdRes.message, downloadUrl: fileByIdRes.message };
                      if (onFilePreview && isWebVideo) {
                        onFilePreview(fileData);
                      } else {
                        downloadFile(fileData);
                      }
                    }

                    setLoader(false);
                  })
                  .catch((e) => {
                    console.log(e);
                    setLoader(false);
                  });
              }
            })
            .catch((e) => {
              console.log(e);
              setLoader(false);
            });
        } else {
          handelAttach(item);
        }
      }
    }
  };

  return (
    <div style={{ display: 'contents' }}>
      <Tooltip isOpen={isOpenTooltip} role={'tooltip'} opacity={1} id={`table-tooltip-${item.id}`} place="top" content={item.name} positionStrategy={'absolute'} />
      <div
        ref={drag}
        data-tooltip-id={`table-tooltip-${item.id}`}
        className={cx('library-item cursor-pointer', {
          'library-item-active': file?.id === item.id,
        })}
        onClick={handleClickFile}
        onMouseEnter={() => setIsOpenTooltip(true)}
        onMouseLeave={() => setIsOpenTooltip(false)}
      >
        <div className="library-item-text">
          <img src="/images/file-big-icon.png" alt="" /> {item.name}
        </div>
        {(hasPermissionByCodeName('corporate_library_management') || hasPermissionByCodeName('files_management')) && !isAttach && (
          <button
            className={'btn btn-remove btn-icon mr-4'}
            onClick={(e) => {
              e.stopPropagation();
              deleteFile(item.id);
            }}
          >
            <i className={'icon-remove'} />
          </button>
        )}
        {loader && <FileLoader />}
      </div>
    </div>
  );
};
