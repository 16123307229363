import React, { memo, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { usePermission } from '../../container/permissionContext';
import { getFileGeneralInfo, getFileInfo } from '../../servises/facility';
import { getFileById } from '../../servises/upload';
import FileLoader from '../other/FileLoader';
import { isBlobVideo } from '../../utility/isBlobVideo';
import cx from 'classnames';

export const FileItem = (props) => {
  const { item, url, deleteFile, isGeneral, isAttach, handelAttach, file, onFilePreview, wrapperStyles } = props;
  const { hasPermissionByCodeName } = usePermission();

  const [loader, setLoader] = useState(false);
  const [fileBlob, setFileBlob] = useState(null);

  const downloadBlobFile = (blob) => {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = item.name;
    a.click();
  };

  const handleClickFile = () => {
    if (url) {
      if (isGeneral) {
        if (!isAttach) {
          setLoader(true);
          getFileGeneralInfo(item.id)
            .then((res) => {
              const patt = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gim;
              const str = res?.name.match(patt);

              if (str[0] === '.pdf') {
                getFileById(res.file.uuid)
                  .then((res) => {
                    setLoader(false);
                    const newWin = window.open(URL.createObjectURL(res), '_blank');
                    if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                      downloadBlobFile(res);
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                    setLoader(false);
                  });
              } else if (res?.file?.uuid) {
                getFileById(res.file.uuid)
                  .then((res) => {
                    downloadBlobFile(res);
                  })
                  .catch((e) => {
                    console.log(e);
                    setLoader(false);
                  });
              }
            })
            .catch((e) => {
              console.log(e);
              setLoader(false);
            });
        } else {
          handelAttach(item);
        }
      } else {
        if (!isAttach) {
          setLoader(true);

          let fileInfoRes = null;

          getFileInfo(item.id)
            .then((res) => {
              const pattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gim;
              const str = res.name.match(pattern);

              fileInfoRes = res;

              if (str[0] === '.pdf') {
                getFileById(res.file.uuid)
                  .then((res) => {
                    setLoader(false);
                    const newWin = window.open(URL.createObjectURL(res), '_blank');
                    if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                      downloadBlobFile(res);
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                    setLoader(false);
                  });
              } else if (res?.file?.uuid) {
                if (fileBlob) {
                  const isVideo = isBlobVideo(fileBlob);
                  const url = URL.createObjectURL(fileBlob);

                  if (onFilePreview && isVideo) {
                    onFilePreview({ ...fileInfoRes, url });
                  } else {
                    downloadBlobFile(fileBlob);
                  }

                  setLoader(false);
                } else {
                  getFileById(res.file.uuid)
                    .then((fileByIdRes) => {
                      setLoader(false);

                      const isVideo = isBlobVideo(fileByIdRes);
                      const url = URL.createObjectURL(fileByIdRes);

                      if (onFilePreview && isVideo) {
                        onFilePreview({ ...fileInfoRes, url });
                      } else {
                        downloadBlobFile(fileByIdRes);
                      }

                      setFileBlob(fileByIdRes);
                    })
                    .catch((e) => {
                      console.log(e);
                      setLoader(false);
                    });
                }
              }
            })
            .catch((e) => {
              console.log(e);
              setLoader(false);
            });
        } else {
          handelAttach(item);
        }
      }
    }
  };

  return (
    <>
      <Tooltip role={'tooltip'} opacity={1} id={`table-tooltip-${item.id}`} place="top" content={item.name} positionStrategy={'absolute'} />
      <div
        style={wrapperStyles}
        data-tooltip-id={`table-tooltip-${item.id}`}
        className={cx('library-item cursor-pointer', {
          'library-item-active': file?.id === item.id,
        })}
        onClick={handleClickFile}
      >
        <div className="library-item-text">
          <img src="/images/file-big-icon.png" alt="" /> {item.name}
        </div>
        {(hasPermissionByCodeName('corporate_library_management') || hasPermissionByCodeName('files_management')) && !isAttach && (
          <button
            className={'btn btn-remove btn-icon mr-4'}
            onClick={(e) => {
              e.stopPropagation();
              deleteFile(item.id);
            }}
          >
            <i className={'icon-remove'} />
          </button>
        )}
        {loader && <FileLoader />}
      </div>
    </>
  );
};

export default memo(FileItem);
