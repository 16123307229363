import React, { useEffect, useState } from 'react';

export const LinkElementSettings = function FromElement({ element, handlerChangeOption, duplicateElement }) {
  const [content, setContent] = useState(element.options.value);
  const [link, setLink] = useState(element.options.value);
  const [label, setLabel] = useState(element.options.label ? element.options.label : '');
  const [alignment, setAlignment] = useState(element.options.alignment);
  const [fontSizeOption, setFontSizeOption] = useState(element.options.fontSize);
  const [initiatorCanEdit, setInitiatorCanEdit] = useState(element.options.initiator_can_edit);

  useEffect(() => {
    setContent(element.options.value);
    setLink(element.options.link);
    setAlignment(element.options.alignment);
    setInitiatorCanEdit(element.options.initiator_can_edit);
  }, [element]);

  return (
    <div className="tabs-item">
      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Label</h3>
        <div className="form-control-item">
          <input
            type="text"
            className="form-control"
            placeholder="Enter text"
            value={label}
            onChange={(e) => {
              setLabel(e.target.value);
            }}
            onBlur={(e) => {
              handlerChangeOption(e.target.value, 'label', element.id);
            }}
          />
        </div>
      </div>
      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Link Url</h3>
        <div className="form-control-item">
          <input
            type="text"
            className="form-control"
            placeholder="Enter text"
            value={link}
            onChange={(e) => {
              setLink(e.target.value);
              setContent(e.target.value);
            }}
            onBlur={(e) => {
              handlerChangeOption(e.target.value, 'link', element.id);
              handlerChangeOption(e.target.value, 'value', element.id);
            }}
          />
        </div>
      </div>
      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Link Name</h3>
        <div className="form-control-item">
          <input
            type="text"
            className="form-control"
            placeholder="Enter text"
            value={content}
            onChange={(e) => {
              setContent(e.target.value);
            }}
            onBlur={(e) => {
              handlerChangeOption(e.target.value, 'value', element.id);
            }}
          />
        </div>
      </div>

      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Text Alignment</h3>
        <div className="form-group">
          <span className="form-radio-button">
            <input
              type="radio"
              name="size"
              value="left"
              checked={alignment === 'left'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'alignment', element.id);
                setAlignment(e.target.value);
              }}
            />
            <span>Left</span>
          </span>
          <span className="form-radio-button">
            <input
              type="radio"
              name="size"
              value="center"
              checked={alignment === 'center'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'alignment', element.id);
                setAlignment(e.target.value);
              }}
            />
            <span>Center</span>
          </span>
          <span className="form-radio-button">
            <input
              type="radio"
              name="size"
              value="right"
              checked={alignment === 'right'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'alignment', element.id);
                setAlignment(e.target.value);
              }}
            />
            <span>Right</span>
          </span>
        </div>
      </div>

      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Font Size</h3>
        <div className="form-control-item">
          <input
            type="number"
            className="form-control"
            placeholder="Enter text"
            value={fontSizeOption}
            onChange={(e) => {
              setFontSizeOption(e.target.value);
            }}
            onBlur={(e) => handlerChangeOption(e.target.value, 'fontSize', element.id)}
          />
        </div>
      </div>

      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Initiator Can Edit</h3>
        <div className="form-switcher">
          <input
            type="checkbox"
            checked={Boolean(initiatorCanEdit)}
            onChange={(e) => {
              handlerChangeOption(!initiatorCanEdit, 'initiator_can_edit', element.id);
              setInitiatorCanEdit(!initiatorCanEdit);
            }}
          />
          <span>{initiatorCanEdit ? 'ON' : 'OFF'}</span>
        </div>
      </div>

      {/*<div className="property-item">*/}
      {/*    <h3 className="form-builder-aside-property-sub-title">Required</h3>*/}
      {/*    <div className="form-switcher">*/}
      {/*        <input type="checkbox" />*/}
      {/*        <span>ON</span>*/}
      {/*    </div>*/}
      {/*</div>*/}
    </div>
  );
};
