import React, { useEffect, useState } from 'react';
import Select from 'react-select';

export const optionKey = 'autoAddInfoFor';

export const SUBMITTERS_OPTIONS = [
  { value: 'name', label: 'Name' },
  { value: 'surname', label: 'Last Name' },
  { value: 'position', label: 'Position' },
  { value: 'date', label: 'Date' },
];

const autoAddInfoOptions = [
  { value: 'any', label: '----' },
  { value: 'initiator', label: 'Initiator' },
  { value: 'submitter', label: 'Submitter' },
];

export const SubmittersFieldElementSettings = function FromElement({ element, handlerChangeOption, duplicateElement }) {
  const [label, setLabel] = useState(element.options.labels);
  const [name, setName] = useState(element.options.name);
  const [alignment, setAlignment] = useState(element.options.alignment);
  const [autoAddInfoFor, setAutoAddInfoFor] = useState(element.options?.[optionKey]);

  useEffect(() => {
    setLabel(element.options.labels);
    setName(element.options.name);
    setAlignment(element.options.alignment);
    setAutoAddInfoFor(element.options?.[optionKey]);
  }, [element]);

  const handleAutoAddInfoChange = (value) => {
    setAutoAddInfoFor(value);
    handlerChangeOption(value?.value === 'any' ? undefined : value, optionKey, element.id);
  };

  return (
    <div className="tabs-item">
      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Field Alignment</h3>
        <div className="form-group">
          <span className="form-radio-button">
            <input
              type="radio"
              name="alignment"
              value="left"
              checked={alignment === 'left'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'alignment', element.id);
                setAlignment(e.target.value);
              }}
            />
            <span>Left</span>
          </span>
          <span className="form-radio-button">
            <input
              type="radio"
              name="alignment"
              value="center"
              checked={alignment === 'center'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'alignment', element.id);
                setAlignment(e.target.value);
              }}
            />
            <span>Center</span>
          </span>
          <span className="form-radio-button">
            <input
              type="radio"
              name="alignment"
              value="right"
              checked={alignment === 'right'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'alignment', element.id);
                setAlignment(e.target.value);
              }}
            />
            <span>Right</span>
          </span>
        </div>
      </div>
      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Submitters Field Name</h3>
        <div className="form-control-item">
          <input
            className="form-control"
            value={name}
            onChange={(value) => {
              setName(value.target.value);
              handlerChangeOption(value.target.value, 'name', element.id);
            }}
          ></input>
        </div>
      </div>

      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Submitters Field</h3>
        <div className="form-control-item">
          <Select
            classNamePrefix="react-select-multi"
            className="form-control-select"
            hideSelectedOptions={false}
            value={label}
            options={SUBMITTERS_OPTIONS}
            isMulti
            onChange={(value) => {
              setLabel(value);
              handlerChangeOption(value, 'labels', element.id);
            }}
          />
        </div>
      </div>

      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Automatically add info for</h3>
        <div className="form-control-item">
          <Select
            hideSelectedOptions={false}
            className="form-control-select"
            classNamePrefix="react-select"
            value={autoAddInfoFor}
            options={autoAddInfoOptions}
            onChange={handleAutoAddInfoChange}
          />
        </div>
      </div>
    </div>
  );
};
