import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { createUserGroup, editUserGroup } from '../../servises/user-group';
import { Tooltip } from 'react-tooltip';
import { MultiSelect } from '../other/MultiSelect';

function FacilityModal({ isOpen, companyName, closeModal, userGroupData, handelCreateUserGroup, facilities, facility }) {
  const [errorUserGroup, setErrorUserGroup] = useState({});
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [userGroup, setUserGroup] = useState({
    name: '',
    facility_ids: facility ? [{ value: facility.id, label: facility.name }] : [],
    is_special: false,
  });

  useEffect(() => {
    setFacilityOptions(facilities.filter((item) => !item.deleted_at).map((item) => ({ value: item.id, label: item.name })));
  }, [facilities]);

  useEffect(() => {
    if (userGroupData) {
      setUserGroup({ ...userGroupData });
    }
  }, [userGroupData]);

  const handleChange = (value, key) => {
    setUserGroup({
      ...userGroup,
      [key]: value,
    });

    if (errorUserGroup.hasOwnProperty(key)) {
      delete errorUserGroup[key];
    }
  };

  const resetForm = () => {
    setUserGroup({
      name: '',
      facility_ids: facility ? [{ value: facility.id, label: facility.name }] : [],
      is_special: false,
    });
    setErrorUserGroup({});
  };
  const handelCloseModal = () => {
    closeModal();
    resetForm();
  };
  const handleSubmit = async () => {
    if (userGroupData) {
      const formData = { name: userGroup.name, is_special: userGroup.is_special };

      delete formData.id;
      delete formData.deleted_at;
      delete formData.created_at;
      delete formData.users;
      delete formData.company;
      delete formData.actions;

      editUserGroup(formData, userGroupData.id)
        .then(() => {
          resetForm();
          handelCreateUserGroup();
        })
        .catch((e) => {
          if (e?.response?.data) {
            setErrorUserGroup(JSON.parse(JSON.stringify(e.response.data)));
          }
        });
    } else {
      const formData = { name: userGroup.name, is_special: userGroup.is_special, facility_ids: userGroup.facility_ids.map((item) => item.value) };
      createUserGroup(formData)
        .then((res) => {
          resetForm();
          handelCreateUserGroup();
        })
        .catch((e) => {
          if (e?.response?.data) {
            setErrorUserGroup(JSON.parse(JSON.stringify(e.response.data)));
          }
        });
    }
  };

  const handelRemove = (item) => {
    let newState = [...userGroup.facility_ids];
    newState = newState.filter((facility) => facility.value !== item.value);
    setUserGroup({
      ...userGroup,
      facility_ids: newState,
    });
  };

  return (
    <Modal className="modal-default" isOpen={isOpen} onRequestClose={handelCloseModal} contentLabel="Example Modal" ariaHideApp={false}>
      <h2 className="modal-header">
        {userGroupData ? 'Edit' : 'Add New'} Custom User Group{' '}
        <button className="btn btn-icon" onClick={handelCloseModal}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>
      {!!companyName && <h3 className="modal-subheader">for {companyName} company</h3>}
      <div className="form-wrapper">
        <h3 className="form-wrapper-title">
          <img src="/images/company-add-icon-5.svg" alt="" />
          Group information
        </h3>
        <div className={`form-item form-item-75 ${errorUserGroup.name ? 'form-item-error' : ''}`}>
          <label className="form-label">Name</label>
          <input placeholder="Enter name" value={userGroup.name} onChange={(e) => handleChange(e.target.value, 'name')} type="text" className="form-control" />
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorUserGroup.name && Array.isArray(errorUserGroup.name) && errorUserGroup.name[0]}</span>
          <span className="form-error">{errorUserGroup.name && typeof errorUserGroup.name === 'string' && errorUserGroup.name}</span>
        </div>
        <div className="form-item d-flex pt-5 form-item-25">
          <div className="form-control-checkbox mb-0">
            <input checked={userGroup.is_special} onChange={(e) => handleChange(!userGroup.is_special, 'is_special')} type="checkbox" />
            <span>Is Special</span>
            <Tooltip
              opacity={1}
              id="is-special-tooltip"
              place="bottom"
              content={
                'If you want this group of employees to be able to be selected regardless of the facility to which the form initiator belongs - indicate that it is "Special"'
              }
            />
          </div>
          <i data-tooltip-id="is-special-tooltip" className="ml-2 icon-icon-info"></i>
        </div>
        {!userGroupData && (
          <div className={`form-item form-item-select form-control-item-settings ${errorUserGroup.facility_ids ? 'form-item-error' : ''}`}>
            <label className="form-label">Facility</label>
            <div className="select-multy-wrapper">
              <span className="select-placeholder">Select Facility</span>
              <MultiSelect
                isDisabled={!facilityOptions.length}
                options={facilityOptions}
                value={userGroup.facility_ids}
                onChange={(e) => handleChange(e, 'facility_ids')}
                placeholder=""
                allName="All facilities"
              ></MultiSelect>
              {userGroup.facility_ids.length > 0 && (
                <>
                  <ul>
                    {userGroup.facility_ids.map((item, key) => (
                      <li key={key}>
                        {item.label}
                        <button className="btn btn-icon" onClick={() => handelRemove(item)}>
                          <img src="/images/x-circle.svg" alt="" />
                        </button>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>

            <img className="danger-icon" src="/images/red-alert.svg" alt="" />
            <span className="form-error">{errorUserGroup.facility_ids && errorUserGroup.facility_ids[0]}</span>
          </div>
        )}
      </div>
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={handelCloseModal}>
          Cancel
        </button>
        <button className="btn" onClick={handleSubmit}>
          Save
        </button>
      </div>
    </Modal>
  );
}

export default FacilityModal;
