import React from 'react';
import { MultiSelect } from '../../other/MultiSelect';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import { Tooltip } from 'react-tooltip';

export const SpecialSettings = function SubmissionSettings(props) {
  const {
    submission_settings,
    handelSettings,
    handelRemove,
    handelSubmittersSettings,
    handelOrderSettings,
    unitOptions,
    facilityOptions,
    contactsOptions,
    usersGroupsOptions,
    individualUsersOptions,
    addNewOrder,
    orderRemove,
    removeUser,
    addNewUser,
    handelUserSettings,
    errors,
  } = props;

  return (
    <>
      <div className="form-control-item-settings">
        <div className="form-settings-label">
          <label className="form-label form-label-big">Choose Amount of Submitters</label>
          {/*<span>Select multiple submitters if, after the initiation process, the form needs to be sent to the next submitter.</span>*/}
        </div>
        <div className="form-settings-inputs">
          <div className="d-flex">
            <div className={`form-control-radio`}>
              <input name="is_one_submitter" type="radio" onChange={() => handelSettings(true, 'is_one_submitter')} checked={submission_settings.is_one_submitter} />
              <span>One</span>
              <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-is_one_submitter`}></i>
              <Tooltip opacity={1} id={`table-tooltip-is_one_submitter`} place="bottom">
                The Form will be sent directly from Initiator to Recipient. The initiator here is also the submitter.<br></br>
                This is the most simple Form, that does not require any response to its initiation. Examples: an employee submitting a repair request to maintenance department
              </Tooltip>
            </div>
            <div className={`form-control-radio`}>
              <input name="is_one_submitter" type="radio" onChange={() => handelSettings(false, 'is_one_submitter')} checked={submission_settings.is_one_submitter === false} />
              <span>Multiple</span>
              <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-is_one_submitter-multiple`}></i>
              <Tooltip opacity={1} id={`table-tooltip-is_one_submitter-multiple`} place="bottom">
                Any Form, that after initiation requires submission (response or completion) by one or more users.<br></br>
                Examples: Vacation requests that require response from HR; Multiple employees signing the same form...
              </Tooltip>
            </div>
          </div>
          <span className="form-error mb-2 d-flex">{errors?.is_one_submitter && errors.is_one_submitter[0]}</span>
        </div>
      </div>

      {/* if Choose Amount of Submitters = Multiple */}
      {submission_settings.is_one_submitter === false && (
        <>
          <div className="form-control-item-settings">
            <div className="form-settings-label">
              <label className="form-label form-label-big">Will the form initiator choose submitters?</label>
              {/*<span>Please specify whether the form initiator possesses the capability to choose the submitters</span>*/}
            </div>
            <div className="form-settings-inputs">
              <div className="d-flex">
                <div className={`form-control-radio`}>
                  <input
                    name="can_initiator_select_submitters"
                    type="radio"
                    onChange={() => handelSettings(true, 'can_initiator_select_submitters')}
                    checked={submission_settings.can_initiator_select_submitters}
                  />
                  <span>Yes</span>
                  <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-can_initiator_select_submitters`}></i>
                  <Tooltip opacity={1} id={`table-tooltip-can_initiator_select_submitters`} place="bottom">
                    The user who initiates the form has to specify submitters before it can be initiated.
                  </Tooltip>
                </div>
                <div className={`form-control-radio`}>
                  <input
                    name="can_initiator_select_submitters"
                    type="radio"
                    onChange={() => handelSettings(false, 'can_initiator_select_submitters')}
                    checked={submission_settings.can_initiator_select_submitters === false}
                  />
                  <span>No</span>
                  <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-can_initiator_select_submitters-false`}></i>
                  <Tooltip opacity={1} id={`table-tooltip-can_initiator_select_submitters-false`} place="bottom">
                    The form creator has to specify form submitters, the form initiator won`t have ability to change form submitters.
                  </Tooltip>
                </div>
              </div>
              <span className="form-error mb-2 d-flex">{errors?.can_initiator_select_submitters && errors.can_initiator_select_submitters[0]}</span>
            </div>
          </div>
        </>
      )}

      {/* if Can the form initiator choose submitters? = true */}
      {submission_settings.can_initiator_select_submitters !== undefined && submission_settings.is_one_submitter === false && (
        <>
          <div className="form-control-item-settings">
            <div className="form-settings-label">
              <label className="form-label form-label-big">Define form submitters type</label>
              {/*<span>Please specify who will be form submitters: system users, contacts or unregistered users</span>*/}
            </div>
            <div className="form-settings-inputs">
              <div className="d-flex">
                <div className={`form-control-radio`}>
                  <input
                    name="submitters_type"
                    type="radio"
                    onChange={() => handelSubmittersSettings('user', 'submitters_type')}
                    checked={submission_settings.submitters?.submitters_type === 'user'}
                  />
                  <span>System users</span>
                  <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-submitters_type-user`}></i>
                  <Tooltip opacity={1} id={`table-tooltip-submitters_type-user`} place="bottom">
                    Employees of the company
                  </Tooltip>
                </div>
                <div className={`form-control-radio`}>
                  <input
                    name="submitters_type"
                    type="radio"
                    onChange={() => handelSubmittersSettings('contact', 'submitters_type')}
                    checked={submission_settings.submitters?.submitters_type === 'contact'}
                  />
                  <span>Contacts</span>
                  <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-submitters_type-contact`}></i>
                  <Tooltip opacity={1} id={`table-tooltip-submitters_type-contact`} place="bottom">
                    Residents and their Representatives
                  </Tooltip>
                </div>
                <div className={`form-control-radio`}>
                  <input
                    name="submitters_type"
                    type="radio"
                    onChange={() => handelSubmittersSettings('unregistered', 'submitters_type')}
                    checked={submission_settings.submitters?.submitters_type === 'unregistered'}
                  />
                  <span>Unregistered</span>
                  <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-submitters_type-unregistered`}></i>
                  <Tooltip opacity={1} id={`table-tooltip-submitters_type-unregistered`} place="bottom">
                    Individuals who are not currently related to the company, but need to be sent a Form for completion<br></br>
                    Example: Job applicants, third party providers, physicians, vendors, suppliers.
                  </Tooltip>
                </div>
              </div>
              <span className="form-error">{errors?.submitters && errors?.submitters[0] && typeof errors?.submitters[0] === 'string' && errors?.submitters[0]}</span>
              <span className="form-error mb-2">{errors?.submitters?.submitters_type && errors?.submitters?.submitters_type[0]}</span>

              {submission_settings.submitters?.submitters_type === 'unregistered' && submission_settings.can_initiator_select_submitters === false && (
                <>
                  <div className={'form-item mb-4 form-item-title'}>
                    <span className={'d-flex'}>
                      <img src="/images/company-add-icon-3.svg" className={'mr-2'} alt="" />
                      Submitter 1 information
                    </span>
                  </div>
                  <div className={`form-item mb-4`}>
                    <label className="form-label">First Name</label>
                    <input
                      placeholder="Enter First Name"
                      value={
                        submission_settings.submitters['unregistered_submitters'] && submission_settings.submitters['unregistered_submitters'][0]
                          ? submission_settings.submitters['unregistered_submitters'][0].first_name
                          : ''
                      }
                      onChange={(e) => handelUserSettings(e.target.value, 'first_name', 0)}
                      type="text"
                      className="form-control"
                    />
                    {/*<img className="danger-icon" src="/images/red-alert.svg" alt=""/>*/}
                    <span className="form-error">
                      {errors?.submitters?.unregistered_submitters &&
                        errors?.submitters?.unregistered_submitters[0] &&
                        errors.submitters?.unregistered_submitters[0].first_name &&
                        errors.submitters?.unregistered_submitters[0].first_name[0]}
                    </span>
                    <span className="form-error">
                      {errors?.submitters?.unregistered_submitters &&
                        errors?.submitters?.unregistered_submitters[0] &&
                        typeof errors?.submitters?.unregistered_submitters[0] === 'string' &&
                        errors?.submitters?.unregistered_submitters[0]}
                    </span>
                  </div>
                  <div className={`form-item mb-4`}>
                    <label className="form-label">Last Name</label>
                    <input
                      placeholder="Enter Last Name"
                      value={
                        submission_settings.submitters['unregistered_submitters'] && submission_settings.submitters['unregistered_submitters'][0]
                          ? submission_settings.submitters['unregistered_submitters'][0].last_name
                          : ''
                      }
                      onChange={(e) => handelUserSettings(e.target.value, 'last_name', 0)}
                      type="text"
                      className="form-control"
                    />
                    {/*<img className="danger-icon" src="/images/red-alert.svg" alt=""/>*/}
                    <span className="form-error">
                      {errors?.submitters?.unregistered_submitters &&
                        errors?.submitters?.unregistered_submitters[0] &&
                        errors.submitters?.unregistered_submitters[0].last_name &&
                        errors.submitters?.unregistered_submitters[0].last_name[0]}
                    </span>
                  </div>
                  <div className={`form-item  form-item-mail mb-4`}>
                    <label className="form-label">
                      Email<span className="optional">optional</span>
                    </label>
                    {/*<img className="mail-icon"  src="/images/mail-icon.svg" alt=""/>*/}
                    <input
                      placeholder="mail@example.com"
                      id="email"
                      value={
                        submission_settings.submitters['unregistered_submitters'] && submission_settings.submitters['unregistered_submitters'][0]
                          ? submission_settings.submitters['unregistered_submitters'][0].email
                          : ''
                      }
                      onChange={(e) => handelUserSettings(e.target.value, 'email', 0)}
                      type="text"
                      className="form-control"
                    />
                    {/*<img className="danger-icon" src="/images/red-alert.svg" alt=""/>*/}
                    <span className="form-error">
                      {errors?.submitters?.unregistered_submitters &&
                        errors?.submitters?.unregistered_submitters[0] &&
                        errors.submitters?.unregistered_submitters[0].email &&
                        errors.submitters?.unregistered_submitters[0].email[0]}
                    </span>
                  </div>
                  <div className={`form-item mb-4`}>
                    <label className="form-label">Phone Number</label>
                    <InputMask
                      placeholder="XXX-XXX-XXXX"
                      mask="999-999-9999"
                      value={
                        submission_settings.submitters['unregistered_submitters'] && submission_settings.submitters['unregistered_submitters'][0]
                          ? submission_settings.submitters['unregistered_submitters'][0].phone
                          : ''
                      }
                      onChange={(e) => handelUserSettings(e.target.value, 'phone', 0)}
                      className="form-control"
                    ></InputMask>

                    {/*<input placeholder="XXX-XXX-XXXX" value={user.phone} onChange={(e) => handleChange(e.target.value, 'phone')} type="text" className="form-control" />*/}
                    {/*<img className="danger-icon" src="/images/red-alert.svg" alt=""/>*/}
                    {/*<span className="form-error">{errorUser.phone && errorUser.phone[0]}</span>*/}
                    <span className="form-error">
                      {errors?.submitters?.unregistered_submitters &&
                        errors?.submitters?.unregistered_submitters[0] &&
                        errors.submitters?.unregistered_submitters[0].phone &&
                        errors.submitters?.unregistered_submitters[0].phone[0]}
                    </span>
                  </div>

                  {submission_settings.submitters?.unregistered_submitters?.slice(1, submission_settings.submitters?.unregistered_submitters.length).map((item, index) => (
                    <div className="mt-4" key={index}>
                      <div className={'form-item mb-4 form-item-title'}>
                        <span className={'d-flex'}>
                          <img src="/images/company-add-icon-3.svg" className={'mr-2'} alt="" />
                          Submitter {index + 2} information{' '}
                          <button onClick={() => removeUser(item, index + 1)} className="ml-2 btn btn-remove btn-icon">
                            <i className="icon-remove"></i>
                          </button>
                        </span>
                      </div>
                      <div className={`form-item mb-4`}>
                        <label className="form-label">First Name</label>
                        <input
                          placeholder="Enter First Name"
                          value={item ? item.first_name : ''}
                          onChange={(e) => handelUserSettings(e.target.value, 'first_name', index + 1)}
                          type="text"
                          className="form-control"
                        />
                        <span className="form-error">
                          {errors?.submitters?.unregistered_submitters[index + 1] &&
                            errors.submitters?.unregistered_submitters[index + 1].first_name &&
                            errors.submitters?.unregistered_submitters[index + 1].first_name[0]}
                        </span>

                        {/*<img className="danger-icon" src="/images/red-alert.svg" alt=""/>*/}
                        {/*<span className="form-error">{errorUser.first_name && errorUser.first_name[0]}</span>*/}
                      </div>
                      <div className={`form-item mb-4`}>
                        <label className="form-label">Last Name</label>
                        <input
                          placeholder="Enter Last Name"
                          value={item ? item.last_name : ''}
                          onChange={(e) => handelUserSettings(e.target.value, 'last_name', index + 1)}
                          type="text"
                          className="form-control"
                        />
                        <span className="form-error">
                          {errors?.submitters?.unregistered_submitters[index + 1] &&
                            errors.submitters?.unregistered_submitters[index + 1].last_name &&
                            errors.submitters?.unregistered_submitters[index + 1].last_name[0]}
                        </span>
                        {/*<img className="danger-icon" src="/images/red-alert.svg" alt=""/>*/}
                        {/*<span className="form-error">{errorUser.last_name && errorUser.last_name[0]}</span>*/}
                      </div>
                      <div className={`form-item  form-item-mail mb-4`}>
                        <label className="form-label">
                          Email<span className="optional">optional</span>
                        </label>
                        {/*<img className="mail-icon"  src="/images/mail-icon.svg" alt=""/>*/}
                        <input
                          placeholder="mail@example.com"
                          id="email"
                          value={item ? item.email : ''}
                          onChange={(e) => handelUserSettings(e.target.value, 'email', index + 1)}
                          type="text"
                          className="form-control"
                        />
                        <span className="form-error">
                          {errors?.submitters?.unregistered_submitters[index + 1] &&
                            errors.submitters?.unregistered_submitters[index + 1].email &&
                            errors.submitters?.unregistered_submitters[index + 1].email[0]}
                        </span>
                        {/*<img className="danger-icon" src="/images/red-alert.svg" alt=""/>*/}
                        {/*<span className="form-error">{errorUser.email && errorUser.email[0]}</span>*/}
                      </div>
                      <div className={`form-item mb-4`}>
                        <label className="form-label">Phone Number</label>
                        <InputMask
                          placeholder="XXX-XXX-XXXX"
                          mask="999-999-9999"
                          value={item ? item.phone : ''}
                          onChange={(e) => handelUserSettings(e.target.value, 'phone', index + 1)}
                          className="form-control"
                        ></InputMask>
                        <span className="form-error">
                          {errors?.submitters?.unregistered_submitters[index + 1] &&
                            errors.submitters?.unregistered_submitters[index + 1].phone &&
                            errors.submitters?.unregistered_submitters[index + 1].phone[0]}
                        </span>
                        {/*<input placeholder="XXX-XXX-XXXX" value={user.phone} onChange={(e) => handleChange(e.target.value, 'phone')} type="text" className="form-control" />*/}
                        {/*<img className="danger-icon" src="/images/red-alert.svg" alt=""/>*/}
                        {/*<span className="form-error">{errorUser.phone && errorUser.phone[0]}</span>*/}
                      </div>
                    </div>
                  ))}

                  <span className="add-facility cursor-pointer" onClick={addNewUser}>
                    <i className="icon-plus-svgrepo-com mr-2"></i>Add Unregistered User
                  </span>
                </>
              )}

              {submission_settings.submitters?.submitters_type === 'contact' && submission_settings.can_initiator_select_submitters === false && (
                <>
                  <div className={`form-item form-item-select mb-4`}>
                    <label className="form-label form-label-big">Facility</label>
                    <div className="select-multy-wrapper">
                      <span className="select-placeholder">Choose one or several facilities</span>
                      <MultiSelect
                        isDisabled={!facilityOptions.length}
                        options={facilityOptions}
                        value={submission_settings.submitters.facilities}
                        onChange={(e) => handelSubmittersSettings(e, 'facilities')}
                        placeholder=""
                        allName="All facilities"
                      />
                      {submission_settings.submitters?.facilities?.length > 0 && (
                        <>
                          <ul>
                            {submission_settings.submitters.facilities.map((item, key) => (
                              <li key={key}>
                                {item.label}
                                <button className="btn btn-icon" onClick={() => handelRemove('facilities', item)}>
                                  <img src="/images/x-circle.svg" alt="" />
                                </button>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                    <span className="form-error mb-2 d-flex">{errors?.submitters?.facilities && errors?.submitters?.facilities[0]}</span>
                  </div>
                  <div className={`form-item form-item-select mb-4`}>
                    <label className="form-label form-label-big">Unit</label>
                    <div className="select-multy-wrapper">
                      <span className="select-placeholder">Choose one or several units</span>
                      <MultiSelect
                        isDisabled={!unitOptions.length}
                        options={unitOptions}
                        value={submission_settings.submitters.units}
                        onChange={(e) => handelSubmittersSettings(e, 'units')}
                        placeholder=""
                        allName="All units"
                      ></MultiSelect>
                      {submission_settings.submitters?.units?.length > 0 && (
                        <>
                          <ul>
                            {submission_settings.submitters.units.map((item, key) => (
                              <li key={key}>
                                {item.label}
                                <button className="btn btn-icon" onClick={() => handelRemove('units', item)}>
                                  <img src="/images/x-circle.svg" alt="" />
                                </button>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                    <span className="form-error mb-2 d-flex">{errors?.submitters?.units && errors?.submitters?.units[0]}</span>
                  </div>

                  <div className={`form-item form-item-select mb-4`}>
                    <label className="form-label form-label-big">Contact</label>
                    <div className="select-multy-wrapper">
                      <span className="select-placeholder">Choose one or several contacts</span>
                      <MultiSelect
                        isDisabled={!contactsOptions.length}
                        options={contactsOptions}
                        value={submission_settings.submitters.contacts}
                        onChange={(e) => handelSubmittersSettings(e, 'contacts')}
                        placeholder=""
                        allName="All contacts"
                      ></MultiSelect>
                      {submission_settings.submitters?.contacts?.length > 0 && (
                        <>
                          <ul>
                            {submission_settings.submitters.contacts.map((item, key) => (
                              <li key={key}>
                                {item.label}
                                <button className="btn btn-icon" onClick={() => handelRemove('contacts', item)}>
                                  <img src="/images/x-circle.svg" alt="" />
                                </button>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}

                      <span className="form-error mb-2 d-flex">{errors?.submitters?.contacts && errors?.submitters?.contacts[0]}</span>
                    </div>
                    {/*<span className="form-error">{errors?.facilities && errors.facilities[0]}</span>*/}
                  </div>
                  <div></div>
                </>
              )}
            </div>
          </div>

          {submission_settings.submitters?.submitters_type === 'contact' && submission_settings.can_initiator_select_submitters === false && (
            <>
              <div className="form-control-item-settings">
                <div className="form-settings-label">
                  <label className="form-label form-label-big">Define form recievers</label>
                  <span>If both are specified, it means that the form will be sent not only to selected residents, but also to all of their representatives</span>
                </div>
                <div className="form-settings-inputs">
                  <div className="d-flex">
                    <div className={`form-control-radio`}>
                      <input
                        name="contact_person_submitter_type"
                        type="radio"
                        onChange={() => handelSubmittersSettings('contact', 'contact_person_submitter_type')}
                        checked={submission_settings.submitters?.contact_person_submitter_type === 'contact'}
                      />
                      <span>Residents</span>
                    </div>
                    <div className={`form-control-radio`}>
                      <input
                        name="contact_person_submitter_type"
                        type="radio"
                        onChange={() => handelSubmittersSettings('representative', 'contact_person_submitter_type')}
                        checked={submission_settings.submitters?.contact_person_submitter_type === 'representative'}
                      />
                      <span>Representatives </span>
                    </div>
                    <div className={`form-control-radio`}>
                      <input
                        name="contact_person_submitter_type"
                        type="radio"
                        onChange={() => handelSubmittersSettings('both', 'contact_person_submitter_type')}
                        checked={submission_settings.submitters?.contact_person_submitter_type === 'both'}
                      />
                      <span>Both</span>
                    </div>
                  </div>
                  <span className="form-error mb-2 d-flex">{errors?.submitters?.contact_person_submitter_type && errors.submitters?.contact_person_submitter_type[0]}</span>
                </div>
              </div>
            </>
          )}

          {submission_settings.submitters?.submitters_type === 'user' && submission_settings.can_initiator_select_submitters === false && (
            <>
              <div className="form-control-item-settings">
                <div className="form-settings-label"></div>
                <div className="form-settings-inputs">
                  <div className="form-settings-inputs">
                    <div className={`form-item form-item-select mb-4`}>
                      <label className="form-label form-label-big">Facility</label>
                      <div className="select-multy-wrapper">
                        <span className="select-placeholder">Choose one or several facilities</span>
                        <MultiSelect
                          isDisabled={!facilityOptions.length}
                          options={facilityOptions}
                          value={submission_settings.submitters.facilities}
                          onChange={(e) => handelSubmittersSettings(e, 'facilities')}
                          placeholder=""
                          allName="All facilities"
                        />
                        {submission_settings.submitters?.facilities?.length > 0 && (
                          <>
                            <ul>
                              {submission_settings.submitters.facilities.map((item, key) => (
                                <li key={key}>
                                  {item.label}
                                  <button className="btn btn-icon" onClick={() => handelRemove('facilities', item)}>
                                    <img src="/images/x-circle.svg" alt="" />
                                  </button>
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                      </div>
                      <span className="form-error mb-2 d-flex">{errors?.submitters?.facilities && errors?.submitters?.facilities[0]}</span>
                    </div>

                    <div className={`form-item form-item-select mb-4`}>
                      <label className="form-label form-label-big">User groups</label>
                      <div className="select-multy-wrapper">
                        <span className="select-placeholder">Choose one or several user groups</span>
                        <MultiSelect
                          isDisabled={!usersGroupsOptions.length}
                          options={usersGroupsOptions}
                          value={submission_settings.submitters.user_groups}
                          onChange={(e) => handelSubmittersSettings(e, 'user_groups')}
                          placeholder=""
                          allName="All user groups"
                        ></MultiSelect>
                        {submission_settings.submitters?.user_groups?.length > 0 && (
                          <>
                            <ul>
                              {submission_settings.submitters.user_groups.map((item, key) => (
                                <li key={key}>
                                  {item.label}
                                  <button className="btn btn-icon" onClick={() => handelRemove('user_groups', item)}>
                                    <img src="/images/x-circle.svg" alt="" />
                                  </button>
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                      </div>
                      <span className="form-error mb-2 d-flex">{errors?.submitters?.user_groups && errors?.submitters?.user_groups[0]}</span>
                    </div>

                    <div className={`form-item form-item-select mb-4`}>
                      <label className="form-label form-label-big">Individual Users</label>
                      <div className="select-multy-wrapper">
                        <span className="select-placeholder">Choose one or several individual_users</span>
                        <MultiSelect
                          isDisabled={!individualUsersOptions.length}
                          options={individualUsersOptions}
                          value={submission_settings.submitters.individual_users}
                          onChange={(e) => handelSubmittersSettings(e, 'individual_users')}
                          placeholder=""
                          allName="All individual users"
                        ></MultiSelect>
                        {submission_settings.submitters?.individual_users?.length > 0 && (
                          <>
                            <ul className={'individual-users-list'}>
                              {submission_settings.submitters.individual_users
                                .sort(function (a, b) {
                                  if (a.label < b.label) {
                                    return -1;
                                  }
                                  if (a.label > b.label) {
                                    return 1;
                                  }
                                  return 0;
                                })
                                .map((item, key) => (
                                  <li key={key}>
                                    {item.label}
                                    <button className="btn btn-icon" onClick={() => handelRemove('individual_users', item)}>
                                      <img src="/images/x-circle.svg" alt="" />
                                    </button>
                                  </li>
                                ))}
                            </ul>
                          </>
                        )}
                        <span className="form-error mb-2 d-flex">{errors?.submitters?.individual_users && errors?.submitters?.individual_users[0]}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {submission_settings.submitters?.submitters_type === 'user' && submission_settings.can_initiator_select_submitters && (
            <>
              <div className="form-control-item-settings">
                <div className="form-settings-label">
                  <label className="form-label form-label-big">Will submitters be only from the initiator’s facility?</label>
                  <span>Specify whether the form initiator will be able to select form submitters only from the facility they are assigned to.</span>
                </div>
                <div className="form-settings-inputs">
                  <div className="d-flex">
                    <div className={`form-control-radio`}>
                      <input
                        name="is_users_from_initiator_facility"
                        type="radio"
                        onChange={() => handelSubmittersSettings(true, 'is_users_from_initiator_facility')}
                        checked={submission_settings.submitters?.is_users_from_initiator_facility}
                      />
                      <span>Yes</span>
                    </div>
                    <div className={`form-control-radio`}>
                      <input
                        name="is_users_from_initiator_facility"
                        type="radio"
                        value="Multiple"
                        onChange={() => handelSubmittersSettings(false, 'is_users_from_initiator_facility')}
                        checked={submission_settings.submitters?.is_users_from_initiator_facility === false}
                      />
                      <span>No</span>
                    </div>
                  </div>
                  <span className="form-error mb-2 d-flex">{errors?.submitters?.is_users_from_initiator_facility && errors?.submitters?.is_users_from_initiator_facility[0]}</span>

                  {submission_settings.submitters.hasOwnProperty('is_users_from_initiator_facility') && (
                    <>
                      {submission_settings.submitters?.is_users_from_initiator_facility === false && (
                        <div className={`form-item form-item-select mb-4`}>
                          <label className="form-label form-label-big">Facility</label>
                          <div className="select-multy-wrapper">
                            <span className="select-placeholder">Choose one or several facilities</span>
                            <MultiSelect
                              isDisabled={!facilityOptions.length}
                              options={facilityOptions}
                              value={submission_settings.submitters.facilities}
                              onChange={(e) => handelSubmittersSettings(e, 'facilities')}
                              placeholder=""
                              allName="All facilities"
                            ></MultiSelect>
                            {submission_settings.submitters?.facilities?.length > 0 && (
                              <>
                                <ul>
                                  {submission_settings.submitters.facilities.map((item, key) => (
                                    <li key={key}>
                                      {item.label}
                                      <button className="btn btn-icon" onClick={() => handelRemove('facilities', item)}>
                                        <img src="/images/x-circle.svg" alt="" />
                                      </button>
                                    </li>
                                  ))}
                                </ul>
                              </>
                            )}
                          </div>
                          <span className="form-error mb-2 d-flex">{errors?.submitters?.facilities && errors?.submitters?.facilities[0]}</span>
                        </div>
                      )}

                      <div className={`form-item form-item-select mb-4`}>
                        <label className="form-label form-label-big">User groups</label>
                        <div className="select-multy-wrapper">
                          <span className="select-placeholder">Choose one or several user groups</span>
                          <MultiSelect
                            isDisabled={!usersGroupsOptions.length}
                            options={usersGroupsOptions}
                            value={submission_settings.submitters.user_groups}
                            onChange={(e) => handelSubmittersSettings(e, 'user_groups')}
                            placeholder=""
                            allName="All user groups"
                          ></MultiSelect>
                          {submission_settings.submitters?.user_groups?.length > 0 && (
                            <>
                              <ul>
                                {submission_settings.submitters.user_groups.map((item, key) => (
                                  <li key={key}>
                                    {item.label}
                                    <button className="btn btn-icon" onClick={() => handelRemove('user_groups', item)}>
                                      <img src="/images/x-circle.svg" alt="" />
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            </>
                          )}
                        </div>
                        <span className="form-error mb-2 d-flex">{errors?.submitters?.user_groups && errors?.submitters?.user_groups[0]}</span>
                      </div>

                      <div className={`form-item form-item-select mb-4`}>
                        <label className="form-label form-label-big">Individual Users</label>
                        <div className="select-multy-wrapper">
                          <span className="select-placeholder">Choose one or several individual users</span>
                          <MultiSelect
                            isDisabled={!individualUsersOptions.length}
                            options={individualUsersOptions}
                            value={submission_settings.submitters.individual_users}
                            onChange={(e) => handelSubmittersSettings(e, 'individual_users')}
                            placeholder=""
                            allName="All individual users"
                          ></MultiSelect>
                          {submission_settings.submitters?.individual_users?.length > 0 && (
                            <>
                              <ul className={'individual-users-list'}>
                                {submission_settings.submitters.individual_users
                                  .sort(function (a, b) {
                                    if (a.label < b.label) {
                                      return -1;
                                    }
                                    if (a.label > b.label) {
                                      return 1;
                                    }
                                    return 0;
                                  })
                                  .map((item, key) => (
                                    <li key={key}>
                                      {item.label}
                                      <button className="btn btn-icon" onClick={() => handelRemove('individual_users', item)}>
                                        <img src="/images/x-circle.svg" alt="" />
                                      </button>
                                    </li>
                                  ))}
                              </ul>
                            </>
                          )}
                          <span className="form-error mb-2 d-flex">{errors?.submitters?.individual_users && errors?.submitters?.individual_users[0]}</span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}

          {submission_settings.submitters?.submitters_type === 'user' && (
            <>
              <div className="form-control-item-settings">
                <div className="form-settings-label">
                  <label className="form-label form-label-big">Will the form have a strict order of submitters?</label>
                  <span>Please specify whether the form has to be submitted in a strict order of submitters.</span>
                </div>
                <div className="form-settings-inputs">
                  <div className="d-flex">
                    <div className={`form-control-radio`}>
                      <input
                        name="has_strict_order_of_submissions"
                        type="radio"
                        onChange={() => handelSettings(true, 'has_strict_order_of_submissions')}
                        checked={submission_settings.has_strict_order_of_submissions}
                      />
                      <span>Yes</span>
                      <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-has_strict_order_of_submissions`}></i>
                      <Tooltip opacity={1} id={`table-tooltip-has_strict_order_of_submissions`} place="bottom">
                        When employees "A", "B" and "C" need to complete their paris of the same Form, “B" can sign only after "A" has signed, and “C" can sign only after "A" and
                        "B" have signed.
                      </Tooltip>
                    </div>
                    <div className={`form-control-radio`}>
                      <input
                        name="has_strict_order_of_submissions"
                        type="radio"
                        value="Multiple"
                        onChange={() => handelSettings(false, 'has_strict_order_of_submissions')}
                        checked={submission_settings.has_strict_order_of_submissions === false}
                      />
                      <span>No</span>
                      <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-has_strict_order_of_submissions-false`}></i>
                      <Tooltip opacity={1} id={`table-tooltip-has_strict_order_of_submissions-false`} place="bottom">
                        When employees "A", “B° and "C" need to complete their parts of the same Form, they can complete it in any order:
                      </Tooltip>
                    </div>
                  </div>
                  <span className="form-error mb-2 d-flex">{errors?.has_strict_order_of_submissions && errors?.has_strict_order_of_submissions[0]}</span>

                  {submission_settings.has_strict_order_of_submissions && submission_settings.can_initiator_select_submitters === false && (
                    <>
                      <div className="form-item mb-4">
                        <label className="form-label form-label-big">Step 1</label>
                        <Select
                          classNamePrefix="react-select"
                          className={`form-control-select`}
                          options={[
                            ...(submission_settings.submitters.individual_users ? submission_settings.submitters.individual_users : []),
                            ...(submission_settings.submitters?.user_groups ? submission_settings.submitters?.user_groups : []),
                          ]}
                          value={submission_settings.submitters_order ? submission_settings.submitters_order[0] : null}
                          onChange={(e) => handelOrderSettings(e, 'submitters_order', 0)}
                        ></Select>
                        <span className="form-error mb-2">
                          {errors?.submitters_order && errors?.submitters_order[0]?.hasOwnProperty('submitter') && errors?.submitters_order[0].submitter}
                        </span>
                        <span className="form-error mb-2">{errors?.submitters_order && typeof errors?.submitters_order[0] === 'string' && errors?.submitters_order[0]}</span>
                      </div>
                      <div className="form-item mb-4">
                        <label className="form-label form-label-big">Step 2</label>
                        <Select
                          classNamePrefix="react-select"
                          className={`form-control-select`}
                          options={[
                            ...(submission_settings.submitters.individual_users ? submission_settings.submitters.individual_users : []),
                            ...(submission_settings.submitters?.user_groups ? submission_settings.submitters?.user_groups : []),
                          ]}
                          value={submission_settings.submitters_order ? submission_settings.submitters_order[1] : null}
                          onChange={(e) => handelOrderSettings(e, 'submitters_order', 1)}
                        ></Select>
                        <span className="form-error mb-2">
                          {errors?.submitters_order && errors?.submitters_order[1]?.hasOwnProperty('submitter') && errors?.submitters_order[1].submitter}
                        </span>
                      </div>

                      {submission_settings.submitters_order?.slice(2, submission_settings.submitters_order.length).map((item, index) => (
                        <div className="form-item mb-4" key={index}>
                          <label className="form-label form-label-big">Step {index + 3}</label>
                          <div className="d-flex mb-0">
                            <Select
                              classNamePrefix="react-select"
                              className={`form-control-select`}
                              options={[
                                ...(submission_settings.submitters.individual_users ? submission_settings.submitters.individual_users : []),
                                ...(submission_settings.submitters?.user_groups ? submission_settings.submitters?.user_groups : []),
                              ]}
                              value={item ? item : null}
                              onChange={(e) => handelOrderSettings(e, 'submitters_order', index + 2)}
                            ></Select>

                            <button className="btn btn-icon btn-remove ml-4" onClick={() => orderRemove(item, index + 2)}>
                              <i className="icon-remove"></i>
                            </button>
                          </div>
                          <span className="form-error mb-2">
                            {errors?.submitters_order && errors?.submitters_order[index + 2]?.hasOwnProperty('submitter') && errors?.submitters_order[index + 2].submitter}
                          </span>
                        </div>
                      ))}
                      <span className="add-facility cursor-pointer" onClick={addNewOrder}>
                        <i className="icon-plus-svgrepo-com mr-2"></i>Add order
                      </span>
                    </>
                  )}
                </div>
              </div>

              {/* if Will the form have a strict order of submitters? = false */}
              {submission_settings.has_strict_order_of_submissions === false && (
                <div className="form-control-item-settings">
                  <div className="form-settings-label">
                    <label className="form-label form-label-big">Specify submissions number</label>
                    <span>Is the form limited to just one submission to be completed by multiple users or will there be one submission per each submitter</span>
                  </div>
                  <div className="form-settings-inputs">
                    <div className="d-flex">
                      <div className={`form-control-radio`}>
                        <input
                          name="specify_submissions_number"
                          type="radio"
                          onChange={() => handelSettings(true, 'only_one_submission')}
                          checked={submission_settings.only_one_submission}
                        />
                        <span>One</span>
                        <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-specify_submissions_number`}></i>
                        <Tooltip opacity={1} id={`table-tooltip-specify_submissions_number`} place="bottom">
                          There is only one submission has to be completed per initiaton. All submitters will add information in one submission, so each submitter can see
                          information added by other submitters.<br></br>
                          Example, 5 employees signing oné copy (one page) of a new policy, 50 that there are five signatures on the same page.
                        </Tooltip>
                      </div>
                      <div className={`form-control-radio`}>
                        <input
                          name="specify_submissions_number"
                          type="radio"
                          onChange={() => handelSettings(false, 'only_one_submission')}
                          checked={submission_settings.only_one_submission === false}
                        />
                        <span>Multiple</span>
                        <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-specify_submissions_number-false`}></i>
                        <Tooltip opacity={1} id={`table-tooltip-specify_submissions_number-false`} place="bottom">
                          Each submitter adds information to the separate instance of the form.<br></br>
                          Example: Each employee signs his/her own copy of the same policy
                        </Tooltip>
                      </div>
                    </div>
                    <span className="form-error mb-2 d-flex">{errors?.only_one_submission && errors?.only_one_submission[0]}</span>
                  </div>
                </div>
              )}

              {submission_settings.has_strict_order_of_submissions && (
                <div className="form-control-item-settings">
                  <div className="form-settings-label">
                    <label className="form-label form-label-big">Can Submitters Edit Previous Submissions?</label>
                    <span>Please determine if submitters will have the option to make edits to previous submission.</span>
                  </div>
                  <div className="form-settings-inputs">
                    <div className="d-flex">
                      <div className={`form-control-radio`}>
                        <input
                          name="can_edit_previous_submission"
                          type="radio"
                          onChange={() => handelSettings(true, 'can_edit_previous_submission')}
                          checked={submission_settings.can_edit_previous_submission}
                        />
                        <span>Yes</span>
                        <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-can_edit_previous_submission`}></i>
                        <Tooltip opacity={1} id={`table-tooltip-can_edit_previous_submission`} place="bottom">
                          The form submitter can edit previous submissions, added by them or other submitters
                        </Tooltip>
                      </div>
                      <div className={`form-control-radio`}>
                        <input
                          name="can_edit_previous_submission"
                          type="radio"
                          onChange={() => handelSettings(false, 'can_edit_previous_submission')}
                          checked={submission_settings.can_edit_previous_submission === false}
                        />
                        <span>No</span>
                        <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-can_edit_previous_submission-false`}></i>
                        <Tooltip opacity={1} id={`table-tooltip-can_edit_previous_submission-false`} place="bottom">
                          The form submitter can edit previous submissions, added by them or other submitters
                        </Tooltip>
                      </div>
                    </div>
                    <span className="form-error mb-2 d-flex">{errors?.can_edit_previous_submission && errors?.can_edit_previous_submission[0]}</span>
                  </div>
                </div>
              )}

              {/*if Specify submissions number = true */}
              {submission_settings.only_one_submission && submission_settings.has_strict_order_of_submissions === false && (
                <div className="form-control-item-settings">
                  <div className="form-settings-label">
                    <label className="form-label form-label-big">Define the Number of "Submit" clicks</label>
                    <span>Specify amount of submissions ("Submit" clicks) to consider that the form is completed</span>
                  </div>
                  <div className="form-settings-inputs">
                    <div className="form-item">
                      <label>Number "Submit" clicks</label>
                      <input
                        value={submission_settings.count_submission_to_complete}
                        onChange={(e) => handelSettings(e.target.value, 'count_submission_to_complete')}
                        type="number"
                        min={0}
                        className="form-control form-control-clicks-small"
                        placeholder="Enter an integer"
                      />
                    </div>
                    <span className="form-error mt-1 mb-2 d-flex">{errors?.count_submission_to_complete && errors?.count_submission_to_complete[0]}</span>
                  </div>
                </div>
              )}

              {submission_settings.hasOwnProperty('has_strict_order_of_submissions') && (
                <div className="form-control-item-settings">
                  <div className="form-settings-label">
                    <label className="form-label form-label-big">Determine if the form will have a "Save" option</label>
                    <span>Verify whether the form will provide a "Save" feature for the convenience of users.</span>
                  </div>
                  <div className="form-settings-inputs">
                    <div className="d-flex">
                      <div className={`form-control-radio`}>
                        <input name="has_save_button" type="radio" onChange={() => handelSettings(true, 'has_save_button')} checked={submission_settings.has_save_button} />
                        <span>Yes</span>
                        <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-has_save_button`}></i>
                        <Tooltip opacity={1} id={`table-tooltip-has_save_button`} place="bottom">
                          Form submitters will have ability to save information added to the form and submit this form later. A form, saved but not submitted will be placed in the
                          "Forms in progress" folder.
                        </Tooltip>
                      </div>
                      <div className={`form-control-radio`}>
                        <input
                          name="has_save_button"
                          type="radio"
                          onChange={() => handelSettings(false, 'has_save_button')}
                          checked={submission_settings.has_save_button === false}
                        />
                        <span>No</span>
                        <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-has_save_button-false`}></i>
                        <Tooltip opacity={1} id={`table-tooltip-has_save_button-false`} place="bottom">
                          The form submitters can only submit the form. It won`t be possible to save data added to the form and come back to this form later.
                        </Tooltip>
                      </div>
                    </div>
                    <span className="form-error mb-2 d-flex">{errors?.has_save_button && errors?.has_save_button[0]}</span>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}

      {submission_settings.is_one_submitter === true && (
        <div className="form-control-item-settings">
          <div className="form-settings-label">
            <label className="form-label form-label-big">Determine if the form will have a "Save" option</label>
            <span>Verify whether the form will provide a "Save" feature for the convenience of users.</span>
          </div>
          <div className="form-settings-inputs">
            <div className="d-flex">
              <div className={`form-control-radio`}>
                <input name="has_save_button" type="radio" onChange={() => handelSettings(true, 'has_save_button')} checked={submission_settings.has_save_button} />
                <span>Yes</span>
                <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-has_save_button`}></i>
                <Tooltip opacity={1} id={`table-tooltip-has_save_button`} place="bottom">
                  Form submitters will have ability to save information added to the form and submit this form later. A form, saved but not submitted will be placed in the "Forms
                  in progress" folder.
                </Tooltip>
              </div>
              <div className={`form-control-radio`}>
                <input name="has_save_button" type="radio" onChange={() => handelSettings(false, 'has_save_button')} checked={submission_settings.has_save_button === false} />
                <span>No</span>
                <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-has_save_button-false`}></i>
                <Tooltip opacity={1} id={`table-tooltip-has_save_button-false`} place="bottom">
                  The form submitters can only submit the form. It won`t be possible to save data added to the form and come back to this form later.
                </Tooltip>
              </div>
            </div>
            <span className="form-error mb-2 d-flex">{errors?.has_save_button && errors?.has_save_button[0]}</span>
          </div>
        </div>
      )}

      {/* if Choose Amount of Submitters = Multiple */}
      {submission_settings.is_one_submitter === false && (
        <>
          <div className="form-control-item-settings">
            <div className="form-settings-label">
              <label className="form-label form-label-big">Can the initiator of the form specify the period of availability and frequency?</label>
            </div>
            <div className="form-settings-inputs">
              <div className="d-flex">
                <div className={`form-control-radio`}>
                  <input
                    name="can_initiator_specify_period_availability_and_frequency"
                    type="radio"
                    onChange={() => handelSettings(true, 'can_initiator_specify_period_availability_and_frequency')}
                    checked={submission_settings.can_initiator_specify_period_availability_and_frequency}
                  />
                  <span>Yes</span>
                </div>

                <div className={`form-control-radio`}>
                  <input
                    name="can_initiator_specify_period_availability_and_frequency"
                    type="radio"
                    onChange={() => handelSettings(false, 'can_initiator_specify_period_availability_and_frequency')}
                    checked={submission_settings.can_initiator_specify_period_availability_and_frequency === false}
                  />
                  <span>No</span>
                </div>
              </div>
              <span className="form-error mb-2 d-flex">{errors?.can_initiator_specify_period_availability_and_frequency}</span>
            </div>
          </div>
        </>
      )}
    </>
  );
};
